import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { iCallHistoryList } from '../../models/conference.model';
import { AuthService } from '../../services/auth.service';
import { SharedDataService } from '../../services/sharedData.service';
import { VideoCallService } from './../../api-services/video-call.service';
import { ClaimCaseService } from './../../api-services/claim-case.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-call-history-details',
  templateUrl: './call-history-details.component.html',
  styleUrls: ['./call-history-details.component.css']
})
export class CallHistoryDetailsComponent implements OnInit {

  constructor(private router: Router, private service: ClaimCaseService, private authService: AuthService,
    private sharedService: SharedDataService, private videoCallService: VideoCallService,
    private toastr: ToastrService,
    ) { }

  adjusterId: number = this.sharedService.getValue("accountId");
  callHistoryList: iCallHistoryList[] = [];
  validationMessages: any;
  //Error
  errorData: { errorTitle: string; errorDesc: string; };

  ngOnInit(): void {
    this.validationMessages = [];
    this.getCallHistroy();
  }

  //Method to fetch call history in list
  getCallHistroy() {
    this.validationMessages = [];
    this.service.getCallHistory(this.adjusterId).subscribe(result => {
      this.callHistoryList = <iCallHistoryList[]>result;
    }, error => {
      this.populateApiErrorMessages(error.message);
    });
  }

  callBack(conferenceId: number) {
    this.sharedService.setValue('conferenceId', conferenceId);
    this.router.navigateByUrl('video-call');
  }

  viewDetails(conferenceId: number) {
    this.sharedService.setValue('conferenceId', conferenceId);
    this.router.navigateByUrl('review');
  }

  downloadArtifact(conferenceId: number) {
    if (conferenceId <= 0)
      return;
    this.validationMessages = [];
    this.videoCallService.downloadFile(conferenceId).subscribe(
      (response:any) => {
        if(response.size == 0){
          this.toastr.error("Some thing went wrong.");
        }else{
          if (window.navigator.msSaveOrOpenBlob) {
            var blob = response;
            window.navigator.msSaveOrOpenBlob(blob, `VideoCall_${conferenceId}.zip`);
          }
          else {
            var url = window.URL.createObjectURL(response);
            var a = document.createElement('a');
            document.body.appendChild(a);
            a.setAttribute('style', 'display: none');
            a.href = url;
            a.download = `VideoCall_${conferenceId}.zip`;
            a.click();
            window.URL.revokeObjectURL(url);
            a.remove();
          }
        }
       
      },
      error => {
        this.populateApiErrorMessages(error.message);
      });
  }

    goToConversationScreen(confId?: any) {
        this.sharedService.setValue("conversationConfId", confId);
        this.router.navigateByUrl("chat");
  }

  populateApiErrorMessages(message: string) {
    this.validationMessages.push(message);
  }
}
