<div class="container-area">
    <div class="extra-one">
        <div class="content-area bg-content">
            <div class="main-title">
                <span>Ajustes de Cuenta</span>
            </div>
            <div class="main-settings-content">
                <ul class="nav nav-tabs">
                    <li [ngClass]="{'active': userRole == 'Super Admin' && !integrationTabActive && !paymentTabActive}">
                        <a (click)="refreshSettingTab()" data-toggle="tab" href="#settings"
                            *ngIf="userRole == 'Super Admin'">Ajustes</a></li>
                    <li><a (click)="refreshManageUserTab()" data-toggle="tab" href="#m-user" *ngIf="userRole == 'Super Admin'">Adminsitrar Equipo</a></li>
                    <!-- <li [ngClass]="{'active': integrationTabActive && !paymentTabActive}"><a data-toggle="tab" href="#integration" *ngIf="userRole == 'Super Admin'">Integraci&#243;n</a></li> -->
                    <li
                        [ngClass]="{'active': userRole == 'Regular User' && !integrationTabActive && !paymentTabActive }">
                        <a (click)="refreshResetPwdTab()" data-toggle="tab" href="#reset-pwd"
                            *ngIf="userRole == 'Super Admin' || userRole == 'Regular User'">Cambiar Contrase&#241;a</a>
                    </li>
                    <!-- <li><a #tnc (click)="refreshTnCTab()" data-toggle="tab" href="#terms-conditions" *ngIf="userRole == 'Super Admin'">Terms & Conditions</a></li> -->
                    <li><a (click)="refreshConfigurationTab()" data-toggle="tab" href="#config"
                            *ngIf="userRole == 'Super Admin'">Configuraci&#243;n</a></li>
                    <!-- <li><a data-toggle="tab" href="#payment-setting" *ngIf="userRole == 'Super Admin'">Payment Method</a></li>  -->
                    <!--<li [ngClass]="{'active' : !integrationTabActive && paymentTabActive }"><a (click)="refreshMyPaymentTab()" data-toggle="tab" href="#my-payment" *ngIf="userRole == 'Super Admin'">Mis Pagos<span *ngIf="pendingInvoiceCount > 0">{{pendingInvoiceCount}}</span></a></li>-->
                </ul>
                <div class="tab-content">
                    <div id="settings" class="tab-pane fade"
                        [ngClass]="{'in active': userRole == 'Super Admin' && !integrationTabActive && !paymentTabActive}">
                        <app-settings *ngIf="userRole == 'Super Admin'"></app-settings>
                    </div>
                    <div id="m-user" class="tab-pane fade">
                        <app-manage-user *ngIf="userRole == 'Super Admin'"></app-manage-user>
                    </div>
                    <div id="integration" class="tab-pane fade"
                        [ngClass]="{'in active': integrationTabActive && !paymentTabActive}">
                        <app-integration *ngIf="userRole == 'Super Admin'"></app-integration>
                    </div>
                    <div id="reset-pwd" class="tab-pane fade"
                        [ngClass]="{'in active': userRole == 'Regular User' && !integrationTabActive && !paymentTabActive}">
                        <app-reset-password></app-reset-password>
                    </div>
                    <!-- <div id="terms-conditions" class="tab-pane fade">
            <app-terms-conditions *ngIf="userRole == 'Super Admin'"></app-terms-conditions>
          </div> -->
                    <div id="config" class="tab-pane fade">
                        <app-configuration *ngIf="userRole == 'Super Admin'"></app-configuration>
                    </div>
                    <!-- <div id="payment-setting" class="tab-pane fade">
             <app-payment-setting *ngIf="userRole == 'Super Admin'"></app-payment-setting> 
          </div> -->
                    <!--<div id="my-payment" class="tab-pane fade" [ngClass]="{'in active': !integrationTabActive && paymentTabActive}">
            <app-my-payment (pendingCountEmitter)="getPengingCount($event)" *ngIf="userRole == 'Super Admin'"></app-my-payment>
          </div>-->
                </div>
            </div>
        </div>
    </div>
</div>
<div class="mobile-warning">
    <span><img src="../../../assets/images/error-mobile.svg" /> El portal de usuarios de CASCO Safety no es compatible
        con este dispositivo. Por favor, acceda a CASCO Safety en pantalla completa desde su escritorio. </span>
</div>