import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { SharedDataService } from '../../../services/sharedData.service';
import { DashboardService } from '.././../../api-services/dashboard.service';
import { iTeamWidget } from '../../../models/dashboard.model';
import * as Highcharts from 'highcharts';

@Component({
  selector: 'app-your-team',
  templateUrl: './your-team.component.html',
  styleUrls: ['./your-team.component.css']
})
export class YourTeamComponent implements OnInit {

  constructor(private router: Router,
    private service: DashboardService,
    private sharedService: SharedDataService) { }

  tenantId?: number;
  teamGraphData?: iTeamWidget = {};
  options?: any;
  adjusterNameList?: any[];
  claimCountList?: any[];
  initialsList?: any[];

  ngOnInit(): void {
    //this.adjusterNameList = [];
    //this.claimCountList = [];
    //this.initialsList = [];
    //this.tenantId = this.sharedService.getValue("tenantId");
    //this.getTeamGraphData();
  }

  //Method to get team graph data
  getTeamGraphData() {
    this.service.getTeamGraphData(this.tenantId).subscribe(result => {
      if (result != null) {
        this.teamGraphData = <iTeamWidget>result;
        if (this.teamGraphData.teamGraphList.length > 0) {
          this.teamGraphData.teamGraphList.forEach(obj => {
            this.adjusterNameList.push(obj.adjusterName);
            this.claimCountList.push(obj.claimCount);
            this.initialsList.push(obj.initials);
          });
        }

        this.options = {
          chart: {
            type: 'bar',
            height: 260
          },
          title: {
            text: ''
          },
          exporting: {
            enabled: false
          },
          xAxis: {
            //categories: ['Africa', 'America', 'Asia', 'Europe', 'Oceania'],
            categories: this.adjusterNameList,
            title: {
              text: null
            },
            labels: {
              enabled: true,
              //formatter: function () {
              //  return '<span title="My custom title">' + this.value + '</span>';
              //},
              useHTML: true,
              style: {
                fontSize: 12,
                fontWeight: 'bold',
              }
            },
            lineColor: '#ffffff',
            gridLineColor: '#ffffff',
          },
          yAxis: {
            min: 0,
            title: {
              text: '',
              align: ''
            },
            labels: {
              overflow: ''
            },
            lineColor: '#ffffff',
            gridLineColor: '#ffffff',
            visible: false,
          },
          tooltip: {
            enabled: false,
          },
          plotOptions: {
            series: {
              stacking: 'normal',
              states: {
                hover: {
                  enabled: false
                }
              },
              point: {
                events: {
                  mouseOver: function () {
                    this.options.oldColor = this.color;
                    this.graphic.attr("fill", "#B6CAF2");
                  },
                  mouseOut: function () {
                    this.graphic.attr("fill", this.options.oldColor);
                  }
                }
              },
            },
            bar: {
              dataLabels: {
                enabled: true
              }
            }
          },
          //legend: {
          //  layout: 'vertical',
          //  align: 'right',
          //  verticalAlign: 'top',
          //  x: 0,
          //  y: -10,
          //  floating: true,
          //  borderWidth: 1,
          //  backgroundColor:
          //    Highcharts.defaultOptions.legend.backgroundColor || '#FFFFFF',
          //  //shadow: true
          //},
          credits: {
            enabled: false
          },
          series: [{
            name: 'Claims',
            //data: [1000, 31, 635, 203, 200],
            data: this.claimCountList,
            color: '#E6EEFF',
            borderRadius: 5,
            dataLabels: {
              enabled: true,
              rotation: 0,
              color: '#002062',
              align: 'right',
              y: 0, // 10 pixels down from the top
              style: {
                fontSize: '12px',
              },
            }
          }]
        }

        Highcharts.chart('teamBar', this.options);
      }
    }, error => {
    });
  }

}
