import { Injectable } from '@angular/core';
import { ApiClientService } from './api-client.service';
import { Observable } from 'rxjs';
import { HttpParams } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class IntegrationService {

  constructor(private apiClientService: ApiClientService) { }

  // Get storage account redirect URL
  public getStorageRedirectURL(storageType: number): Observable<string> {
    let params = new HttpParams();
    params = params.append("storageType", storageType.toString());

    return this.apiClientService.getSingleWithoutLoader<string>("storageIntegration/redirectToStorageURL", undefined, params);
  }
}
