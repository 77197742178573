import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { SharedDataService } from '../../../services/sharedData.service';
import { SettingService } from '.././../../api-services/setting.service';
import { iResetPassword } from '../../../models/setting.model';
import { NgForm } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { Constants } from '../../../shared/constant';
declare var $: any;

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.css']
})
export class ResetPasswordComponent implements OnInit {

  constructor(private router: Router,
    private service: SettingService,
    private sharedService: SharedDataService,
    private toastr: ToastrService) { }

  @ViewChild('resetPasswordForm')
  resetPasswordForm: NgForm;
  resetPassword?: iResetPassword = {};
  confirmPassword?: string;
  validationMessages: any;
  letterFlag: boolean = false;
  digitFlag: boolean = false;
  lengthFlag: boolean = false;
  charFlag: boolean = false;
  showPasswordFormat?: boolean = null;
  message?: string;

  ngOnInit(): void {    
    this.validationMessages = [];
    this.resetPassword.userId = this.sharedService.getValue("accountId");
  }

  refreshMethod() {
    this.validationMessages = [];
  }

  //Method to reset password
  resetPasswordFun() {
    this.validationMessages = [];

    this.populateValidateMessages();

    if (this.validationMessages.length === 0) {
      this.service.resetPassword(this.resetPassword).subscribe(result => {
        if (result != null) {          
          this.message = <string>result;
          
          if (this.message != "true")
            this.populateApiErrorMessages(this.message);
          else {
            this.successmsg();
            this.letterFlag = this.charFlag = this.digitFlag = this.lengthFlag = this.showPasswordFormat = false;
            this.confirmPassword = this.resetPassword.oldPassword = this.resetPassword.newPassword = "";
            this.showPasswordFormat = null;
          }            
        }
      }, error => {
        this.populateApiErrorMessages(error.message);
      });
    }
  }

  //focus event of password text box to show all password formats
  focusFunction() {
    this.showPasswordFormat = true;
  }

  //method to check all the password formats on change event of password text box
  checkPasswordFormat() {
    if (this.isCharCheck(this.resetPassword.newPassword))
      this.charFlag = true;
    else
      this.charFlag = false;

    if (this.isUpperCaseCheck(this.resetPassword.newPassword) && this.isLowerCaseCheck(this.resetPassword.newPassword))
      this.letterFlag = true;
    else
      this.letterFlag = false;

    if (this.resetPassword.newPassword.length >= 8)
      this.lengthFlag = true;
    else
      this.lengthFlag = false;

    if (this.isDigitCheck(this.resetPassword.newPassword))
      this.digitFlag = true;
    else
      this.digitFlag = false;
  }

  //Check for lower case letter
  isLowerCaseCheck(password) {
    var re = /[a-z]/;
    return re.test(password);
  }

  //Check for upper case letter
  isUpperCaseCheck(password) {
    var re = /[A-Z]/;
    return re.test(password);
  }

  //Check for digit
  isDigitCheck(password) {
    var re = /\d/;
    return re.test(password);
  }

  //Check for special character
  isCharCheck(password) {
    var re = /[!@#$%^&*()]/;
    return re.test(password);
  }

  populateValidateMessages() {
    if (this.resetPassword.oldPassword === undefined || this.resetPassword.oldPassword === null || this.resetPassword.oldPassword === '') {
      this.validationMessages.push(Constants.oldPasswordRequiredMsg);
    }
    else if (this.resetPassword.newPassword === undefined || this.resetPassword.newPassword === null || this.resetPassword.newPassword === '') {
      this.validationMessages.push(Constants.newPasswordRequiredMsg);
    }
    else if (this.confirmPassword === undefined || this.confirmPassword === null || this.confirmPassword === '') {
      this.validationMessages.push(Constants.confPasswordRequiredMsg);
    }
    else if (this.resetPassword.newPassword != this.confirmPassword) {
      this.validationMessages.push(Constants.oldNewPasswordNotMatchMsg);
    }
    else if (!this.lengthFlag || !this.charFlag || !this.digitFlag || !this.letterFlag) {
      this.validationMessages.push(Constants.passwordRulesMsg);
    }
  }
  restrictSpace(e) {
   return this.sharedService.restrictSpace(e);
  }
 
  populateApiErrorMessages(message: string) {
    this.validationMessages.push(message);
  }

  focusConfirmPasswordFunction() {
    this.showPasswordFormat = false;
    this.letterFlag = false;
    this.charFlag = false;
    this.digitFlag = false;
    this.lengthFlag = false;
  }

  checkConfirmPasswordFormat() {
    if (this.isCharCheck(this.confirmPassword))
      this.charFlag = true;
    else
      this.charFlag = false;

    if (this.isUpperCaseCheck(this.confirmPassword) && this.isLowerCaseCheck(this.confirmPassword))
      this.letterFlag = true;
    else
      this.letterFlag = false;

    if (this.confirmPassword.length >= 8)
      this.lengthFlag = true;
    else
      this.lengthFlag = false;

    if (this.isDigitCheck(this.confirmPassword))
      this.digitFlag = true;
    else
      this.digitFlag = false;
  }

  checkPassword() {
    this.validationMessages = [];
    if (this.resetPassword.newPassword != this.confirmPassword) {
      //New password and confirm password are not matching.
      this.validationMessages.push('La contraseña y la contraseña de confirmación no coinciden.');
    }
  }

  successmsg() {
      this.toastr.success("Contraseńa cambiada con éxito.", 'Exito')
  }

  //errorsmsg() {
  //    this.toastr.error("Toastr Error Notification", 'Error')

  //}
  //infotoastr() {
  //    this.toastr.info("Important News", 'Information');
  //}
  //toastrwaring() {
  //    this.toastr.warning("Battery about to Die", 'Warning');
  //}

}
