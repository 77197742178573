import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { Router } from '@angular/router';
import { SharedDataService } from '../../../services/sharedData.service';
import { TermsAndConditionService } from '../../../api-services/terms-and-condition.service';
import { iTermsAndConditionVM } from '../../../models/terms-and-condition.model';
import { ToastrService } from 'ngx-toastr';
import { AngularEditorConfig } from '@kolkov/angular-editor';
import { Constants } from '../../../shared/constant';
declare var $: any;

@Component({
  selector: 'app-terms-conditions',
  templateUrl: './terms-conditions.component.html',
  styleUrls: ['./terms-conditions.component.css']
})
export class TermsConditionsComponent implements OnInit {

  @ViewChild('content') contentElement: ElementRef;

  isTermsAndConditionActive: boolean = false;
  isTermsAndConditionFlag: boolean = false;
  tenantId: number;
  validationMessages: any;  
  termsAndConditionData: iTermsAndConditionVM = {};
  isEditable: boolean = false;
  dbVersionNumber: number = 1;
  dbContent: string = '';
  isContentEditable: boolean = true;
  isEditorNonEditable: boolean = false;

  config: AngularEditorConfig = {
    editable: true,
    spellcheck: true,
    sanitize: false,
    placeholder: 'Enter text here...',
    height: '22rem',
    minHeight: '5rem',    
    translate: 'no',
    defaultParagraphSeparator: 'p',
    defaultFontName: 'Arial',
    toolbarHiddenButtons: [
      [
        'undo',
        'redo',       
        'strikeThrough',
        'subscript',
        'superscript',
        'heading',
        'fontName',
        'fontSize'
      ],
      [
        'textColor',
        'backgroundColor',
        'customClasses',
        'link',
        'unlink',
        'insertImage',
        'insertVideo',
        'insertHorizontalRule',
        'removeFormat'        
      ]
      ]
  };

  constructor(private sharedService: SharedDataService,
      private router: Router,
    private service: TermsAndConditionService,
    private toasterService: ToastrService) { }

  ngOnInit(): void {
    this.tenantId = this.sharedService.getValue("tenantId");
    this.termsAndConditionData.versionNumber = 1;
    this.getTermsAndConditionActive();  
  }

  refreshMethod() {
    this.validationMessages = [];    
    this.getTermsAndConditionActive();
  }

  cancel()
  {
      window.location.href = '/main-settings#terms-conditions';
      window.location.reload();
    //this.getTermsAndConditionActive();
    //this.isEditable = false;
    //this.termsAndConditionData.content = this.dbContent;
  }

  getTermsAndConditionActive()
  {
    this.validationMessages = [];
    this.service.isTenantTermsActive(this.tenantId).subscribe(
      (result) => {
        if (result != null) {
          this.isTermsAndConditionActive = this.isTermsAndConditionFlag = result;
          
          if(this.isTermsAndConditionActive)
          {
            this.service.getTermsAndCondition(this.tenantId).subscribe(
              (result) => {
                if (result != null) {
                  this.termsAndConditionData = <iTermsAndConditionVM>result;
                  this.dbVersionNumber = this.termsAndConditionData.versionNumber;
                  this.dbContent = this.termsAndConditionData.content;
                  this.isEditable = this.isContentEditable = this.isEditorNonEditable = false;
                }
              },
              (error) => {
                this.populateApiErrorMessages(error.message);
              }
            );
          } else {
            this.termsAndConditionData.id = 0;
            this.isEditable = this.isContentEditable = false;
            this.isEditorNonEditable = true;
            this.termsAndConditionData.content = '';
            this.termsAndConditionData.versionNumber = this.dbVersionNumber = 1;
          }      
        }        
      },
      (error) => {
        this.populateApiErrorMessages(error.message);
      }
    );
  }

  changeContent(){
    this.isContentEditable = true;
  }

  populateApiErrorMessages(message: string) {
    this.validationMessages.push(message);
  }

  activateTermsAndConditionFlag(termsAndConditionActivateFlag)
  {
    this.isTermsAndConditionFlag = !termsAndConditionActivateFlag;
  }

  savePublishContent() {
    if (this.sharedService.isContentEmpty(this.termsAndConditionData.content)) {
      this.warningMsg(Constants.contentEmptyMsg);
    }
    else {
      if (this.termsAndConditionData.isPublished && this.termsAndConditionData.content == this.dbContent) {
        this.warningMsg(Constants.alreadyPublished);
      }
      else {
        if (this.termsAndConditionData.isPublished) {
          this.termsAndConditionData.versionNumber += 1;
          this.termsAndConditionData.id = 0;
        }
        this.termsAndConditionData.isPublished = true;
        this.termsAndConditionData.tenantId = this.tenantId;
        this.termsAndConditionData.createdBy = this.tenantId;

        this.saveToDatabase(this.termsAndConditionData);
      }
    }
  }
 
  saveContent()
  {    
    if (this.sharedService.isContentEmpty(this.termsAndConditionData.content)) {
      this.warningMsg(Constants.contentEmptyMsg);
    }
    else {
      this.isContentEditable = this.isEditable = false;
      this.termsAndConditionData.tenantId = this.tenantId;
      this.termsAndConditionData.createdBy = this.tenantId;

      if (this.termsAndConditionData.id > 0)
        this.termsAndConditionData.updatedBy = this.tenantId;

      // For inserting new record in DB
      if (this.termsAndConditionData.isPublished) {
        this.termsAndConditionData.id = 0; 
        this.termsAndConditionData.versionNumber += 1;
        this.termsAndConditionData.isPublished = false;
      }

      this.saveToDatabase(this.termsAndConditionData);
    }
  }

  saveToDatabase(_termsAndConditionData)
  {
    this.service.saveUpdateTermsAndCondition(_termsAndConditionData).subscribe(
      (result) => {
        if (result != null) {
          if (result == true) {
            if (_termsAndConditionData.isPublished)
              this.successMsg("Terms and Conditions saved & published successfully.");
            else
              this.successMsg("Terms and Conditions saved successfully.");

            this.getTermsAndConditionActive();            
          }
        }
      },
      (error) => {
        this.populateApiErrorMessages(error.message);
      }
    );
  }

  successMsg(message) {
      this.toasterService.success(message, 'Exito')
  }
  warningMsg(message?: any) {
      this.toasterService.warning(message, 'Aviso')
  }
  editContent()
  {
    this.isContentEditable = this.isEditable = this.isEditorNonEditable = true;
  }

  previewContent(){
    this.contentElement.nativeElement.innerHTML = this.termsAndConditionData.content;
  }

  removeTermsAndCondition(){

    this.service.removedTenantTerms(this.tenantId).subscribe(
      (result) => {
        if (result == true) {
          $("#remove-terms").modal("hide"); 
          this.dbVersionNumber = 1; // It should start from 1        
          this.cancel();
        }
      },
      (error) => {
        $("#remove-terms").modal("hide");
        this.populateApiErrorMessages(error.message);
      }
    );
  }
}
