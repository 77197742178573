<!-- <script src="https://js.stripe.com/v3/"></script> -->
<div class="all-settings-content">
    <div class="payments-container">
        <!--<div class="payments-initial">
            <img src="../../../../assets/images/purchase-img.svg" />
            <div class="initial-text">
                <span class="initial-top">Haga su vida mas f�cil con Pago Autom�tico.</span>
                <span class="initial-btm">Cuando configura en Pago Autom�tico, automaticamente le debitaremos la cuenta, tarjeta de d�bito o tarjeta de cr�dito que ha seleccionado cada mes por el monto pendiente de sus casos. </span>
            </div>
            <button data-target="#autopay" data-toggle="modal" class="btn comm-btn">Configurar Pago Autom�tico</button>
        </div>-->
        <div class="payment-next">
            <div class="autopay-active-content">
                <div class="active-top">
                    <div class="active-top-text">
                        <img src="../../../../assets/images/activate-ap.svg" />
                        <div class="tt-info">
                            <span class="tt-title">Pago Automatico Activado</span>
                            <span class="tt-part">Monto sera automaticamente debitado de la cuenta, tarjeta de debito o tarjeta de credito que ha escogido cada mes por el total pendientes de sus casos.</span>
                        </div>
                    </div>
                    <div class="btn-right">
                        <button data-target="#deactivate" data-toggle="modal" class="btn">Desactivar</button>
                    </div>
                    <div class="clear"></div>
                </div>
                <div class="active-mdl">
                    <span class="mdl-top-title common">Tarjetas Registradas</span>
                    <div class="card-info">
                        <div class="card-info-top">
                            <div class="row">
                                <div class="col-md-3">
                                    <div class="title-part first">
                                        <span>Nombre en Tarjeta</span>
                                    </div>
                                </div>
                                <div class="col-md-7">
                                    <div class="title-part">
                                        <span>Tarjeta</span>
                                    </div>
                                </div>
                                <div class="col-md-2">
                                    <div class="title-part">
                                        <span>Vence</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="card-info-btm">
                            <div class="row">
                                <div class="col-md-3">
                                    <div class="text-btm first">
                                        <span>Jhon Doe</span>
                                    </div>
                                </div>
                                <div class="col-md-7">
                                    <div class="text-btm">
                                        <span>Card **** 5001</span>
                                    </div>
                                </div>
                                <div class="col-md-2">
                                    <div class="text-btm">
                                        <span>09/2023</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <span class="act-opt">Activo</span>
                    </div>
                </div>
                <div class="active-btm authentication-container">
                    <span class="btm-top-title common">Informacion de Cobranza<a class="edit">Editar</a></span>
                    <!--<span class="address-desc">12529 State Road 535, Ste 531,Orlando, FL, 32836, United States</span>-->
                    <form>
                        <div class="billing-desc">
                            <div class="row">
                                <div class="col-md-12">
                                    <div class="input-block">
                                        <label class="input-label">Direccion Linea 1*</label>
                                        <input type="text" class="form-control" placeholder="Address Line 1" />
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-12">
                                    <div class="input-block">
                                        <label class="input-label">Direccion Linea 2</label>
                                        <input type="text" class="form-control" placeholder="Address Line 2" />
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-3">
                                    <div class="input-block">
                                        <label class="input-label">Ciudad*</label>
                                        <input type="text" class="form-control" placeholder="Orlando" />
                                    </div>
                                </div>
                                <div class="col-md-3">
                                    <div class="input-block">
                                        <label class="input-label">Departamento*</label>
                                        <select class="form-control">
                                            <option>Orlando</option>
                                            <option>Florida</option>
                                        </select>
                                        <div class="arrow"></div>
                                    </div>
                                </div>
                                <div class="col-md-3">
                                    <div class="input-block">
                                        <label class="input-label">Bodigo Postal*</label>
                                        <input type="text" class="form-control" placeholder="32836" />
                                    </div>
                                </div>
                                <div class="col-md-3">
                                    <div class="input-block">
                                        <label class="input-label">Pais*</label>
                                        <select class="form-control">
                                            <option>United States</option>
                                            <option>India</option>
                                        </select>
                                        <div class="arrow"></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="button-block">
                            <button class="btn comm-btn">Guardar</button>
                            <button class="btn comm-btn cancel">Cancelar</button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="modal fade autopay-pop" id="autopay" data-backdrop="static" aria-hidden="true" role="dialog">
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-header pop-title">
                <button type="button" class="close" data-dismiss="modal">
                    <img src="../../../assets/images/close-pop.svg" />
                </button>
                <h4 class="modal-title next-part">
                    <span><a class="back-only"><img src="../../../../assets/images/payment-back.svg" /></a>Configurar Pago Automatico</span>
                </h4>
            </div>
            <div class="modal-body authentication-container">
                <!--<div class="billing-details">
                    <div class="billing-top">
                        <span>Direccion de Cobranza</span>
                    </div>
                    <form>
                        <div class="billing-desc">
                            <div class="row">
                                <div class="col-md-12">
                                    <div class="input-block">
                                        <label class="input-label">Direccion Linea 1*</label>
                                        <input type="text" class="form-control" placeholder="Address Line 1" required />
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-12">
                                    <div class="input-block">
                                        <label class="input-label">Direccion Linea 2</label>
                                        <input type="text" class="form-control" placeholder="Address Line 2" />
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-3">
                                    <div class="input-block">
                                        <label class="input-label">Ciudad*</label>
                                        <input type="text" class="form-control" placeholder="Orlando" required />
                                    </div>
                                </div>
                                <div class="col-md-3">
                                    <div class="input-block">
                                        <label class="input-label">Departamento*</label>
                                        <select class="form-control">
                                            <option>Orlando</option>
                                            <option>Florida</option>
                                        </select>
                                        <div class="arrow"></div>
                                    </div>
                                </div>
                                <div class="col-md-3">
                                    <div class="input-block">
                                        <label class="input-label">Bodigo Postal*</label>
                                        <input type="text" class="form-control" placeholder="32836" maxlength="50" required />
                                    </div>
                                </div>
                                <div class="col-md-3">
                                    <div class="input-block">
                                        <label class="input-label">Pais*</label>
                                        <select class="form-control">
                                            <option>United States</option>
                                            <option>India</option>
                                        </select>
                                        <div class="arrow"></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <button class="btn comm-btn">Avanzar</button>
                    </form>
                </div>-->
                <div class="billing-details next-part">
                    <div class="billing-top">
                        <span>Informacion de Tarjeta</span>
                    </div>
                    <form>
                        <div class="billing-desc">
                            <div class="row">
                                <div class="col-md-4">
                                    <div class="input-block">
                                        <label class="input-label">Name on card</label>
                                        <input type="text" class="form-control" placeholder="John Doe" />
                                    </div>
                                </div>
                                <div class="col-md-4">
                                    <div class="input-block">
                                        <label class="input-label">Numero de Tarjeta</label>
                                        <input type="text" class="form-control" placeholder="4585  2512  6585  6398"/>
                                    </div>
                                </div>
                                <div class="col-md-2">
                                    <div class="input-block">
                                        <label class="input-label">Fecha de Vencimiento</label>
                                        <input type="text" style="padding: 0 0 0 20px;" class="form-control" placeholder="01 /2020" />
                                    </div>
                                </div>
                                <div class="col-md-2">
                                    <div class="input-block">
                                        <label class="input-label">CVC</label>
                                        <input type="text" class="form-control" placeholder="456" />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <button class="btn comm-btn">Configurar Pago Autom�tico</button>
                    </form>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="modal fade deactivate-pop" id="deactivate" data-backdrop="static" aria-hidden="true" role="dialog">
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-header pop-title">
                <button type="button" class="close" data-dismiss="modal">
                    <img src="../../../assets/images/close-pop.svg" />
                </button>
                <h4 class="modal-title next-part">
                    <span>Desactivar Pago Automatico</span>
                </h4>
            </div>
            <div class="modal-body">
                <span>Seguro que quiere desactivar Pago Automatico?</span>
                <div class="button-block">
                    <button class="btn comm-btn cancel" data-dismiss="modal">No</button>
                    <button class="btn comm-btn">Si</button>
                </div>
                
                <div class="row">
                    <validation-summary [validationErrors]="validationMessages"></validation-summary>
                </div>           
        </div>
        
    </div>
</div>
</div>