<div class="authentication-container">
  <div class="container">
    <div class="auth-content confirm-auth">
      <div class="logo-block">
        <img src="../../../../assets/images/auth-logo.svg" />
      </div>
      <div class="auth-title">
        <span>Completar Proceso de Activacion</span>
      </div>
      <div class="confirm-text">
        <span>Su correo electronico es: <a>{{userDetails.emailId}}</a></span>
      </div>
      <form ngNativeValidate #inviteUserForm="ngForm">
        <div class="auth-block">
          <div class="row">
            <div class="col-sm-6 col-md-6">
              <div class="input-block">
                <input (blur)="removeSpaceFromString(inviteUserForm,'firstName')" type="text" class="form-control" placeholder="Primer Nombre" name="firstName" maxlength="50" [(ngModel)]="userDetails.firstName" />
              </div>
            </div>
            <div class="col-sm-6 col-md-6">
              <div class="input-block">
                <input (blur)="removeSpaceFromString(inviteUserForm,'lastName')" type="text" class="form-control" placeholder="Apellidos" name="lastName" maxlength="50" [(ngModel)]="userDetails.lastName" />
              </div>
            </div>
          </div>
        </div>
        <div class="auth-block">
          <div class="row">
            <div class="col-sm-12 col-md-12">
              <div class="input-block">
                <div class="only-extra-part">
                    <input (blur)="removeSpaceFromString(inviteUserForm,'phoneNo')" type="text" class="form-control ph-number" name="phoneNo" [(ngModel)]="userDetails.phoneNo" maxlength="15" minlength="5"
                           [ngClass]="{'code-one': selectedCountry.countryISDCode?.length == 2, 'code-two': selectedCountry.countryISDCode?.length == 3, 'code-three': selectedCountry.countryISDCode?.length == 4, 'code-four': selectedCountry.countryISDCode?.length == 5, 'code-six': selectedCountry.countryISDCode?.length == 7}" placeholder="1234567890"
                           (keypress)="restrictSpecialCharacterForPhoneNumber($event)" (paste)="restrictPhoneNumberCharactersOnPaste($event)" />
                    <span class="only-code">{{selectedCountry.countryISDCode}}</span>
                </div>
                <div class="tel-dd-part">
                  <div class="dropdown">
                    <a class="dropdown-toggle" id="menu1" data-toggle="dropdown">
                      <span>{{selectedCountry.countryCode}}</span><img src="../../../../assets/images/select-arw.svg" />
                    </a>
                    <ul class="dropdown-menu" role="menu" aria-labelledby="menu1">
                      <li class="ph-menu-item" *ngFor="let data of countryList"><a (click)="selectCountry(data)"><span class="country-id">{{data.bothCountryCode}}</span></a></li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="auth-block">
          <div class="row">
            <div class="col-sm-6 col-md-6">
              <div class="input-block">
                <input type="password" class="form-control" placeholder="Contrase&#241;a" name="password" maxlength="50" [(ngModel)]="userDetails.password"
                       (input)="checkPasswordFormat()" (keypress)="restrictSpace($event)" />
                <div class="password-rules">
                  <div class="text-auth" [ngClass]="{'success': letterFlag}">
                    <img class="cross" src="../../../../assets/images/auth-error-cross.svg" />
                    <img class="tick" src="../../../../assets/images/auth-error-tick.svg" />
                    <span class="rules-info">Requiere una letra Mayuscula | Miniscula</span>
                  </div>
                  <div class="text-auth" [ngClass]="{'success': digitFlag}">
                    <img class="cross" src="../../../../assets/images/auth-error-cross.svg" />
                    <img class="tick" src="../../../../assets/images/auth-error-tick.svg" />
                    <span class="rules-info">Requiere un digito : 0-9</span>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-sm-6 col-md-6">
              <div class="input-block">
                <input type="password" class="form-control" placeholder="Confirmar Contrase&#241;a" name="cnfPassword" [(ngModel)]="cnfPassword"
                       maxlength="50" (focus)="focusConfirmPasswordFunction()" (input)="checkConfirmPasswordFormat()" (keypress)="restrictSpace($event)" />
                <div class="password-rules">
                  <div class="text-auth" [ngClass]="{'success': charFlag}">
                    <img class="cross" src="../../../../assets/images/auth-error-cross.svg" />
                    <img class="tick" src="../../../../assets/images/auth-error-tick.svg" />
                    <span class="rules-info">Requiere un Caracter Especial : ! @ # $ . . .</span>
                  </div>
                  <div class="text-auth" [ngClass]="{'success': lengthFlag}">
                    <img class="cross" src="../../../../assets/images/auth-error-cross.svg" />
                    <img class="tick" src="../../../../assets/images/auth-error-tick.svg" />
                    <span class="rules-info">Contrase&#241;a requiere por lo menos 8 caracteres</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div>
          <validation-summary [validationErrors]="validationMessages"></validation-summary>
        </div>
        <div class="button-block">
          <button (click)="saveUserData()" class="btn comm-btn">Avanzar<svg xmlns="http://www.w3.org/2000/svg" width="14.488" height="9.992" viewBox="0 0 14.488 9.992"><path id="arrow" d="M-1.5,16.5H10.964L7.148,12.861a.5.5,0,0,1,.689-.724L12.2,16.289a1,1,0,0,1-.009,1.422l-4.35,4.143a.5.5,0,1,1-.689-.723L10.98,17.5H-1.5A.5.5,0,0,1-2,17a.5.5,0,0,1,.5-.5Z" transform="translate(2 -12)" fill="#fff" /></svg></button>
        </div>
      </form>
    </div>
  </div>
</div>
