import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpParams, HttpHeaders } from '@angular/common/http';
import { ClaimCase } from '../models/claimCase.model';
import { Conference, iCallHistoryList } from '../models/conference.model';
import { ApiClientService } from './api-client.service';
import { FileUploadService } from '../services/file-upload.service';
import { iSaveImport } from '../models/import-batch.model';
import { ContactUsDetails, CustomerViewModel } from '../models/contactus-detail';

@Injectable({
  providedIn: 'root'
})

export class ContactUsDetailService {

  constructor(private apiClientService: ApiClientService) { }

  public sendContactUsRequest(contactUsDetail: ContactUsDetails): Observable<any> {
    return this.apiClientService.post<any>("ContactUsDetail", contactUsDetail);
  }

  public searchClient(emailId: string): Observable<any> {
    let params = new HttpParams();
    params = params.append('emailId', emailId);
    return this.apiClientService.getSingle<any>("ContactUs/searchClient", undefined, params);
  }

  public verifyToken(token: string): Observable<any> {
    let params = new HttpParams();
    params = params.append('token', token);
    return this.apiClientService.getSingle<any>("ContactUs/VerifyToken", undefined, params);
  }

  public verifyCustomer(contactUsDetail: CustomerViewModel): Observable<any> {
    return this.apiClientService.post<any>("ContactUs/VerifyCustomer", contactUsDetail);
  }

}
