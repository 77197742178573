import { Component, OnInit, HostListener } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient, HttpHeaders, HttpResponse, HttpEventType, HttpRequest } from '@angular/common/http';
import { SharedDataService } from '../../../services/sharedData.service';
import { ImportBatchService } from '../../../api-services/import-batch.service';
import { ConversationDetailService } from "../../../api-services/conversation-detail.service";
import { iImporBatch, iSaveImport } from '../../../models/import-batch.model';
import { ToastrService } from 'ngx-toastr';
import { Constants } from '../../../shared/constant';
declare var $: any;

@Component({
  selector: 'app-import-batch-list',
  templateUrl: './import-batch-list.component.html',
  styleUrls: ['./import-batch-list.component.css']
})
export class ImportBatchListComponent implements OnInit {

  importData?: iImporBatch = {};
  showSave: boolean = true;
  userId?: number;
  saveImport?: iSaveImport = {};
  validationMessages: any;
  storageTypeId?: number;
  showMinSizeMsg?: boolean = true;

  constructor(private sharedService: SharedDataService,
    private router: Router,
    private service: ImportBatchService,
    private convService: ConversationDetailService,
    private toastr: ToastrService) { }

  ngOnInit(): void {
    this.getActiveStorageType();
    this.userId = this.sharedService.getValue("accountId");
    this.importData = <iImporBatch>JSON.parse(this.sharedService.getValue("importBatch"));

    if (this.importData.totalCount != 0) {
      this.importData.importList.forEach(obj => {
        obj.fullAddress = [obj.address1, obj.address2, obj.address3, obj.city, obj.state, obj.zipCode].filter(Boolean).join(", ");
        if (obj.isValidAddress1 && obj.isValidCity && obj.isValidState && obj.isValidZipCode)
          obj.isValidAddress = true;
        else
          obj.isValidAddress = false;

        obj.fullPhoneNumber = obj.isdCountryCode + " " + obj.phoneNo;
        if (obj.isValidIsdCode && obj.isValidPhoneNo)
          obj.isValidFullNumber = true;
        else
          obj.isValidFullNumber = false;
      })

      if (this.importData.invalidDataCount == 0)
        this.showSave = true;
      else
        this.showSave = false;
    }
    else
      this.showSave = false;
  }

  //Get active storage type
  public getActiveStorageType() {
    this.validationMessages = [];
    this.convService.getActiveStorageType().subscribe(
      (result) => {
        if (result != null) {
          this.storageTypeId = result;
        }
      },
      (error) => {
        this.populateApiErrorMessages(error.message);
      }
    );
  }

  //Check available size of active storage is less than 75 MB or not
  public getStorageSize() {
    if (this.storageTypeId != Constants.storageAccount["azure"]) {
      this.validationMessages = [];
      this.convService.getStorageSize(this.storageTypeId).subscribe(
        (result) => {
          if (result != null) {
            this.showMinSizeMsg = result;

            if (!this.showMinSizeMsg)
              this.toastrWaring("Debe haber un mínimo de 75 MB disponibles en su almacenamiento.");            
          }
        },
        (error) => {
          this.populateApiErrorMessages(error.message);
        }
      );
    }
    this.saveData();
  }

  //Method to save imported data
  public saveData() {
    this.validationMessages = [];

    this.saveImport.importList = this.importData.importList;
    this.saveImport.userId = this.userId;
    this.saveImport.storageTypeId = this.storageTypeId;

    this.service.saveDataFromFile(this.saveImport).subscribe(result => {
      if (result != null) {
        if (result)
          this.router.navigateByUrl('backlog');
        else
          this.populateApiErrorMessages("Lo sentimos, ¡algo salió mal! Por favor, inténtelo de nuevo más tarde.");
      }
    }, error => {
        this.populateApiErrorMessages("Lo sentimos, ¡algo salió mal! Por favor, inténtelo de nuevo más tarde.");
    });
  }

  //Method to cancel save and go to back to import screen
  public cancelClick() {
    this.validationMessages = [];
    this.service.deleteUploadedFile(this.userId).subscribe(result => {
      if (result != null) {
        if (result == "true")
          this.router.navigateByUrl('import-batch');
      }
    }, error => {
      this.populateApiErrorMessages(error.message);
    });
  }

  populateApiErrorMessages(message: string) {
    this.validationMessages.push(message);
  }

  toastrWaring(msg?: any) {
      this.toastr.warning(msg, 'Aviso');
  }

}
