import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { SharedDataService } from '../../../services/sharedData.service';
import { DashboardService } from '.././../../api-services/dashboard.service';
import { ClaimCaseService } from ".././../../api-services/claim-case.service";
import { iDropDown } from '../../../models/setting.model';
import { iDayViewCount, iDayViewMe, iDayViewTeamMate, iDayViewAll, iDayViewAllList } from '../../../models/dashboard.model';
import { Constants } from '../../../shared/constant';
declare var $: any;

@Component({
  selector: 'app-calendar-view',
  templateUrl: './calendar-view.component.html',
  styleUrls: ['./calendar-view.component.css']
})
export class CalendarViewComponent implements OnInit {

  constructor(private router: Router,
    private service: DashboardService,
    private sharedService: SharedDataService,
    private claimService: ClaimCaseService) { }

  tenantId?: number;
  adjusterId?: number;
  isSuperAdmin?: boolean = true;
  userRole?: string;
  dayViewCount?: iDayViewCount = {};
  teammateList?: iDropDown[] = [];
  selectedTeamMate?: iDropDown = {};
  dayViewDataForMe?: iDayViewMe[] = [];
  dayViewDataForTeamMate?: iDayViewTeamMate[] = [];
  dayViewDataForAll?: iDayViewAll[] = [];
  dayViewAllList?: iDayViewAllList = {};
  dayViewAllSearchList?: iDayViewAllList = {};
  showMe?: boolean = true;
  showAll?: boolean = false;
  showTeamMate?: boolean = false;
  showCube?: boolean = false;
  showMeCube?: boolean = false;
  showAllCube?: boolean = false;
  showTeamMateCube?: boolean = false;
  modalTime?: string;
  modalTimePart?: string;
  showSearchedData?: boolean = false;
  freeTrialEndMessage: string = Constants.freeTrialEndMessage;

  ngOnInit(): void {
    this.adjusterId = this.sharedService.getValue("accountId");
    this.tenantId = this.sharedService.getValue("tenantId");
    this.userRole = this.sharedService.getValue("userRole");

    if (this.userRole == 'Super Admin')
      this.isSuperAdmin = true;
    else
      this.isSuperAdmin = false;

    this.getDayViewCount();
    this.selectMe();

    this.sharedService.rescheduleFlag.subscribe((newItem) => {
      if (newItem) {
        this.getDayViewCount();
        this.selectMe();
      }
    });
  }

  //Method to get day view all counts
  getDayViewCount() {
    this.service.getDayViewCount(this.adjusterId, this.sharedService.getTodayUTCDateAsString(), this.sharedService.getTimezonOffset()).subscribe(result => {
      if (result != null) {
        this.dayViewCount = <iDayViewCount>result;
        this.getTeamMateList();
      }
    }, error => {
    });
  }

  //Method to get list of all team-mates
  getTeamMateList() {
    this.service.getTeamMateList(this.tenantId).subscribe(result => {
      if (result != null) {
        this.teammateList = <iDropDown[]>result;
      }
    }, error => {

    });
  }

  //Method to get day view for Me
  getDayViewForMe() {
    this.service.getDayViewForMe(this.adjusterId, this.sharedService.getTodayUTCDateAsString(), this.sharedService.getTimezonOffset()).subscribe(result => {
      if (result != null) {
        this.dayViewDataForMe = <iDayViewMe[]>result;
        this.showMe = true;
        this.showMeCube = false;
        this.showAll = false;
        this.showAllCube = false;
        this.showTeamMate = false;
        this.showTeamMateCube = false;
        this.showCube = false;
      }
    }, error => {

    });
  }

  //Method to get day view data for a specific team-mate
  getDayViewForSpecificTeamMate() {
    this.service.getDayViewForSpecificTeamMate(this.selectedTeamMate.id, this.sharedService.getTodayUTCDateAsString(), this.sharedService.getTimezonOffset()).subscribe(result => {
      if (result != null) {
        this.dayViewDataForTeamMate = <iDayViewTeamMate[]>result;
        this.showMe = false;
        this.showMeCube = false;
        this.showTeamMateCube = false;
        this.showAll = false;
        this.showAllCube = false;
        this.showTeamMate = true;
        this.showCube = false;
      }
    }, error => {

    });
  }

  //Method to get day view data for all the team-mates
  getDayViewForAll() {
    this.service.getDayViewForAll(this.tenantId, this.sharedService.getTodayUTCDateAsString(), this.sharedService.getTimezonOffset()).subscribe(result => {
      if (result != null) {
        this.dayViewDataForAll = <iDayViewAll[]>result;
        this.showMe = false;
        this.showMeCube = false;
        this.showAll = true;
        this.showAllCube = false;
        this.showTeamMate = false;
        this.showTeamMateCube = false;
        this.showCube = false;
      }
    }, error => {

    });
  }

  //Method to get list of all calls for the same time
  getDayViewForAllList(tenantId?: number, time?: string) {
    this.service.getDayViewForAllList(tenantId, time, this.sharedService.getTodayUTCDateAsString(), this.sharedService.getTimezonOffset()).subscribe(result => {
      if (result != null) {
        this.dayViewAllList = <iDayViewAllList>result;
        this.showMe = false;
        this.showMeCube = false;
        this.showAll = true;
        this.showAllCube = false;
        this.showTeamMate = false;
        this.showTeamMateCube = false;
        this.showCube = false;
      }
    }, error => {

    });
  }

  //To Open modal pop-up to show all list
  showAllList(data?: any) {
    $("#user-data").modal("show");
    this.modalTime = data.time;
    this.modalTimePart = data.timePart;
    var time = data.time + " " + data.timePart;
    this.getDayViewForAllList(this.tenantId, time);
  }

  //select me
  selectMe() {
    this.selectedTeamMate = {};
    this.selectedTeamMate.id = -1;
    this.selectedTeamMate.name = 'Asignarme';

    if (!this.showCube)
      this.getDayViewForMe();
    else
      this.getDayViewForMeCube();
  }

  //select specific team-mate
  selectTeamMate(data?: any) {
    this.selectedTeamMate = {};
    this.selectedTeamMate = data;

    if (!this.showCube)
      this.getDayViewForSpecificTeamMate();
    else
      this.getDayViewForSpecificTeamMateCube();
  }

  //select all
  selectAll() {
    this.selectedTeamMate = {};
    this.selectedTeamMate.id = 0;
    this.selectedTeamMate.name = 'Asignar a Todos';

    if (!this.showCube)
      this.getDayViewForAll();
    else
      this.getDayViewForAllCube();
  }

  searchName(name?: any) {
    if (name === '' || name === null || name === undefined) {
      var time = this.modalTime + " " + this.modalTimePart;
      this.showSearchedData = false;
      this.getDayViewForAllList(this.tenantId, time);
    }
    else {
      this.showSearchedData = true;
      this.dayViewAllSearchList.dayViewAllList = [];
      this.dayViewAllList.dayViewAllList.forEach(obj => {
        if (obj.adjusterName.toLowerCase().includes(name)) {
          this.dayViewAllSearchList.dayViewAllList.push(obj);
        }
      })
    }
  }

  //-------------------------Time-Section Cube Start-----------------------

  //Method to show time-section in cube
  showTimeCube() {
    if (this.showCube == false)
      this.showCube = true;
    else
      this.showCube = false;

    if (this.showMe && this.showCube) {
      this.getDayViewForMeCube();
    }
    else if (this.showMeCube && !this.showCube) {
      this.getDayViewForMe();
    }

    if (this.showTeamMate && this.showCube) {
      this.getDayViewForSpecificTeamMateCube();
    }
    else if (this.showTeamMateCube && !this.showCube) {
      this.getDayViewForSpecificTeamMate();
    }

    if (this.showAll && this.showCube) {
      this.getDayViewForAllCube();
    }
    else if (this.showAllCube && !this.showCube) {
      this.getDayViewForAll();
    }


  }

  //Method to get day view for Me cube
  getDayViewForMeCube() {
    this.service.getDayViewForMe(this.adjusterId, this.sharedService.getTodayUTCDateAsString(), this.sharedService.getTimezonOffset()).subscribe(result => {
      if (result != null) {
        this.dayViewDataForMe = <iDayViewMe[]>result;
        this.showMeCube = true;
        this.showMe = false;
        this.showAll = false;
        this.showTeamMate = false;
        this.showAllCube = false;
        this.showTeamMateCube = false;
        this.showCube = true;
      }
    }, error => {

    });
  }

  //Method to get day view data for a specific team-mate cube
  getDayViewForSpecificTeamMateCube() {
    this.service.getDayViewForSpecificTeamMate(this.selectedTeamMate.id, this.sharedService.getTodayUTCDateAsString(), this.sharedService.getTimezonOffset()).subscribe(result => {
      if (result != null) {
        this.dayViewDataForTeamMate = <iDayViewTeamMate[]>result;
        this.showMeCube = false;
        this.showMe = false;
        this.showAll = false;
        this.showTeamMate = false;
        this.showAllCube = false;
        this.showTeamMateCube = true;
        this.showCube = true;
      }
    }, error => {

    });
  }

  //Method to get day view data for all the team-mates cube
  getDayViewForAllCube() {
    this.service.getDayViewForAll(this.tenantId, this.sharedService.getTodayUTCDateAsString(), this.sharedService.getTimezonOffset()).subscribe(result => {
      if (result != null) {
        this.dayViewDataForAll = <iDayViewAll[]>result;
        this.showMeCube = false;
        this.showMe = false;
        this.showAll = false;
        this.showTeamMate = false;
        this.showAllCube = true;
        this.showTeamMateCube = false;
        this.showCube = true;
      }
    }, error => {

    });
  }

  //Method to get list of all calls for the same time cube
  getDayViewForAllListCube(tenantId?: number, time?: string) {
    this.service.getDayViewForAllList(tenantId, time, this.sharedService.getTodayUTCDateAsString(), this.sharedService.getTimezonOffset()).subscribe(result => {
      if (result != null) {
        this.dayViewAllList = <iDayViewAllList>result;
        this.showMeCube = false;
        this.showMe = false;
        this.showAll = false;
        this.showTeamMate = false;
        this.showAllCube = true;
        this.showTeamMateCube = false;
        this.showCube = true;
      }
    }, error => {

    });
  }

  //To Open modal pop-up to show all list cube
  showAllListCube(data?: any) {
    $("#user-list-data").modal("show");
    this.modalTime = data.time;
    this.modalTimePart = data.timePart;
    var time = data.time + " " + data.timePart;
    this.getDayViewForAllListCube(this.tenantId, time);
  }

  searchNameCube(name?: any) {
    if (name === '' || name === null || name === undefined) {
      this.showSearchedData = false;
      var time = this.modalTime + " " + this.modalTimePart;
      this.getDayViewForAllListCube(this.tenantId, time);
    }
    else {
      this.showSearchedData = true;
      this.dayViewAllSearchList.dayViewAllList = [];
      this.dayViewAllList.dayViewAllList.forEach(obj => {
        if (obj.adjusterName.toLowerCase().includes(name)) {
          this.dayViewAllSearchList.dayViewAllList.push(obj);
        }
      });
    }
  }

  //-------------------------Time-Section Cube End-----------------------

  //Method to go to video call screen
  goToVideoCall(confId?: number) {
    this.sharedService.setValue('conferenceId', confId);
    this.router.navigateByUrl('video-call');
  }

  //Method to go to add new claim screen
  goToAddNewClaimScreen() {
    this.getClaimCount();
    // $("#dashboard-puchase-data").modal("show");    
  }

  getClaimCount() {
    this.claimService.getClaimCount(this.adjusterId).subscribe(
      (result) => {
        if (result != null) {
          if (result) $("#dashboard-puchase-data").modal("show");
          else {
            this.sharedService.setValue("pageType", "AddClaim");
            this.router.navigateByUrl("claim");
          }
        }
      },
      (error) => {
      }
    );
  }
  redirect(){    
    window.open(Constants.meetingURL);
  }

  ngOnDestroy(): void {
    //this.sharedService.rescheduleFlag.unsubscribe();
  }

}
