import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { SharedDataService } from '../../../../services/sharedData.service';
import { TenantRegistrationService } from '../.././../../api-services/tenant-registration.service';
import { iPlanSelection, iSaveRegistrationData, iReturnId, iTenantRegistration, iHubSpotSaveRegistrationData } from '../../../../models/tenant-registration.model';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../../../environments/environment';


@Component({
  selector: 'app-account-selection',
  templateUrl: './account-selection.component.html',
  styleUrls: ['./account-selection.component.css']
})
export class AccountSelectionComponent implements OnInit {

  constructor(private router: Router,
    private service: TenantRegistrationService,
    private sharedService: SharedDataService) { }

  planSelection: iPlanSelection = {};
  validationMessages: any;
  returnId: iReturnId = {};
  registrationData: iSaveRegistrationData = {};
  tenantRegistration: iTenantRegistration = {};
  hubSpotSaveRegistrationData: iHubSpotSaveRegistrationData = {}

  ngOnInit(): void {
    //this.planSelection.tenantId = this.sharedService.getValue("tenantId");

    this.tenantRegistration = <iTenantRegistration>JSON.parse(this.sharedService.getValue("tenantRegistartionData"));

    this.registrationData.firstName = this.tenantRegistration.firstName;
    this.registrationData.lastName = this.tenantRegistration.lastName;
    this.registrationData.businessName = this.tenantRegistration.businessName;
    this.registrationData.phoneNo = this.tenantRegistration.phoneNo;
    this.registrationData.emailId = this.tenantRegistration.emailId;
    this.registrationData.isdCountryId = this.tenantRegistration.isdCountryId;

    // Get a specific point in time (here, the current date/time):
    const d = new Date();

    // Get a DateTimeFormat object for the user's current culture (via undefined)
    // Ask specifically for the long-form of the time zone name in the options
    const dtf = Intl.DateTimeFormat(undefined, { timeZoneName: 'long' });

    // Format the date to parts, and pull out the value of the time zone name
    this.registrationData.timeZoneName = dtf.formatToParts(d).find((part) => part.type == 'timeZoneName').value;    
  }

  saveRegistrationDataInHubSpot() {
       

    this.hubSpotSaveRegistrationData.appregistrationapi = "yes";
    this.hubSpotSaveRegistrationData.firstName = this.registrationData.firstName;
    this.hubSpotSaveRegistrationData.lastName = this.registrationData.lastName;
    this.hubSpotSaveRegistrationData.company = this.registrationData.businessName;
    this.hubSpotSaveRegistrationData.email = this.registrationData.emailId;
    this.hubSpotSaveRegistrationData.phone = this.tenantRegistration.isdCountry.countryISDCode + " " + this.registrationData.phoneNo;
    this.hubSpotSaveRegistrationData.type_of_account = (this.registrationData.planId == 1) ? "Enterprise" : "Individual";

    this.service.saveRegistrationDataInHubSpot(this.hubSpotSaveRegistrationData).subscribe(result => {
      if (result != null) {
        //console.log(result);
      }
    }, error => {
        console.log(error);
    });
  }

  chooseEnterprisePlan() {
    this.planSelection.planId = 1;
  }

  chooseIndividualPlan() {
    this.planSelection.planId = 2;
  }

  //savePlan() {

  //  this.validationMessages = [];

  //  this.populateValidateMessages();

  //  if (this.validationMessages.length === 0) {
  //    this.service.updatePaymentPlan(this.planSelection).subscribe(result => {
  //      if (result != null) {
  //        this.router.navigate(['/thank-you']);
  //      }
  //    }, error => {
  //        this.populateApiErrorMessages(error.message);
  //    });
  //  }
  //}

  savePlan() {

    this.validationMessages = [];

    this.populateValidateMessages();

    if (this.validationMessages.length === 0) {

      this.registrationData.planId = this.planSelection.planId;

      this.service.saveRegistrationData(this.registrationData).subscribe(result => {
        if (result != null) {
          this.returnId = <iReturnId>result;
          this.sharedService.setValue('tenantId', this.returnId.tenantId);
          this.sharedService.setValue('accountId', this.returnId.accountId);

          //For the production environment only make an entry in hubspot
          if (environment.hubspotApiUrl != "") {
            this.saveRegistrationDataInHubSpot();
          }

          localStorage.removeItem("tenantRegistartionData");

          //this.router.navigate(['/activate']);
          this.router.navigate(['/thank-you-message']);
        }
      }, error => {
        this.populateApiErrorMessages(error.message);
      });
    }
  }

  goBackToRegistration() {    
    this.router.navigate(['/register']);
  }

  populateValidateMessages() {

    if (this.planSelection.planId === undefined || this.planSelection.planId === null) {
      this.validationMessages.push('Plan selection is required.');
    }  
  }

  populateApiErrorMessages(message: string) {
    this.validationMessages.push(message);
  }

}
