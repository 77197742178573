import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { SharedDataService } from '../../../services/sharedData.service';
import { DashboardService } from '.././../../api-services/dashboard.service';
import { iDayViewCount } from '../../../models/dashboard.model';

@Component({
  selector: 'app-call-history',
  templateUrl: './call-history.component.html',
  styleUrls: ['./call-history.component.css']
})
export class CallHistoryComponent implements OnInit {

  constructor(private router: Router,
    private service: DashboardService,
    private sharedService: SharedDataService) { }

  adjusterId?: number;
  backlogCount?: iDayViewCount = {};

  ngOnInit(): void {
    this.adjusterId = this.sharedService.getValue("accountId");
    this.getBacklogCount();
  }

  //Method to get backlog counts
  getBacklogCount() {
    this.service.getBacklogCount(this.adjusterId).subscribe(result => {
      if (result != null) {
        this.backlogCount = <iDayViewCount>result;
      }
    }, error => {
    });
  }

}
