<div class="authentication-container">
  <div class="container">
    <div class="auth-content thanks-auth">
      <div class="logo-block">
        <img src="../../../../assets/images/auth-logo.svg" />
      </div>
      <div class="auth-title">
        <span>Muchas gracias por registrarse con CASCO Safety!</span>
      </div>
      <span class="text-line">Uno de nuestros asesores le contactara pronto.</span>
        <div class="button-block">
            <a href="https://adjustify.co/" class="btn comm-btn">Regresar a Inicio</a>
        </div>
      <!--<div class="img-block">
        <img src="../../../../../assets/images/thanks-img-part.svg" />
      </div>
      <div class="row">
        <div class="col-sm-12 col-md-12">
          <div class="input-block extra">
            <input type="text" class="form-control" placeholder="Activation Code" [(ngModel)]="confirmActivation.activationCode" maxlength="50" required />
          </div>
        </div>
      </div>
      <div>
        <validation-summary [validationErrors]="validationMessages"></validation-summary>
      </div>
      <div class="button-block">
        <button class="btn comm-btn" (click)="confirmActivationCode()">Next</button>
      </div>-->
      <!--<div class="next-block">
          <div class="next-title">
              <span>What Next!</span>
          </div>
          <div class="next-text">
              <span>You will recive an Welcome Email from Adjustify & Our Customer Representative will reach out to you soon to help you get started.</span>
          </div>
      </div>-->
    </div>
  </div>
</div>
