import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpParams, HttpHeaders } from '@angular/common/http';
import { ApiClientService } from './api-client.service';
import { iDropDown } from './../models/setting.model';
import {
  iDayViewCount, iDayViewMe, iDayViewTeamMate, iDayViewAll, iDayViewAllList, iUserProfile, iTeamWidget,
  iWebRescheduleRequest, iRescheduleAcceptRequestWeb
} from './../models/dashboard.model';

@Injectable({
  providedIn: 'root'
})

export class DashboardService {

  constructor(private apiClientService: ApiClientService) { }

  //Day view Count
  public getDayViewCount(adjusterId: number, todayDate: string, localeDateOffset: number): Observable<iDayViewCount> {
    let params = new HttpParams();
    params = params.append('adjusterId', adjusterId.toString());
    params = params.append('todayDate', todayDate);
    params = params.append('localeDateOffset', localeDateOffset.toString());

    return this.apiClientService.getSingle<iDayViewCount>("dashboard/getDayViewCount", undefined, params);
  }

  //Day view list of team-mate
  public getTeamMateList(tenantId: number): Observable<iDropDown[]> {
    let params = new HttpParams();
    params = params.append('tenantId', tenantId.toString());

    return this.apiClientService.getList<iDropDown>("dashboard/getTeamMateList", undefined, params);
  }

  //Day view for me
  public getDayViewForMe(adjusterId: number, todayDate: string, localeDateOffset: number): Observable<iDayViewMe[]> {
    let params = new HttpParams();
    params = params.append('adjusterId', adjusterId.toString());
    params = params.append('todayDate', todayDate);
    params = params.append('localeDateOffset', localeDateOffset.toString());

    return this.apiClientService.getList<iDayViewMe>("dashboard/getDayViewForMe", undefined, params);
  }

  //Day view for specific Team-Mate
  public getDayViewForSpecificTeamMate(adjusterId: number, todayDate: string, localeDateOffset: number): Observable<iDayViewTeamMate[]> {
    let params = new HttpParams();
    params = params.append('adjusterId', adjusterId.toString());
    params = params.append('todayDate', todayDate);
    params = params.append('localeDateOffset', localeDateOffset.toString());

    return this.apiClientService.getListWithoutLoader<iDayViewTeamMate>("dashboard/getDayViewForSpecificTeamMate", undefined, params);
  }

  //Day view for All
  public getDayViewForAll(tenantId: number, todayDate: string, localeDateOffset: number): Observable<iDayViewAll[]> {
    let params = new HttpParams();
    params = params.append('tenantId', tenantId.toString());
    params = params.append('todayDate', todayDate);
    params = params.append('localeDateOffset', localeDateOffset.toString());

    return this.apiClientService.getListWithoutLoader<iDayViewAll>("dashboard/getDayViewForAll", undefined, params);
  }

  //Day view for getting list of all the call
  public getDayViewForAllList(tenantId: number, time: string, todayDate: string, localeDateOffset: number): Observable<iDayViewAllList> {
    let params = new HttpParams();
    params = params.append('tenantId', tenantId.toString());
    params = params.append('time', time.toString());
    params = params.append('todayDate', todayDate);
    params = params.append('localeDateOffset', localeDateOffset.toString());

    return this.apiClientService.getSingleWithoutLoader<iDayViewAllList>("dashboard/getDayViewForAllList", undefined, params);
  }

  //Data for user profile
  public getUserProfileData(adjusterId: number): Observable<iUserProfile> {
    let params = new HttpParams();
    params = params.append('adjusterId', adjusterId.toString());

    return this.apiClientService.getSingleWithoutLoader<iUserProfile>("dashboard/getUserProfileData", undefined, params);
  }

  //Backlog Count
  public getBacklogCount(adjusterId: number): Observable<iDayViewCount> {
    let params = new HttpParams();
    params = params.append('adjusterId', adjusterId.toString());

    return this.apiClientService.getSingle<iDayViewCount>("dashboard/getBacklogCount", undefined, params);
  }

  //Completed calls Count
  public getAllCompletedCallsCount(adjusterId: number): Observable<any> {
    let params = new HttpParams();
    params = params.append('adjusterId', adjusterId.toString());

    return this.apiClientService.getSingle<any>("dashboard/getAllCompletedCallsCount", undefined, params);
  }

  //Weekly Count
  public getWeeklyCallCount(adjusterId: number): Observable<iDayViewCount> {
    let params = new HttpParams();
    params = params.append('adjusterId', adjusterId.toString());

    return this.apiClientService.getSingle<iDayViewCount>("dashboard/getWeeklyCallCount", undefined, params);
  }

  //Monthly Count
  public getMonthlyCallCount(adjusterId: number, todayDate: string, localeDateOffset: number): Observable<iDayViewCount> {
    let params = new HttpParams();
    params = params.append('adjusterId', adjusterId.toString());
    params = params.append('todayDate', todayDate);
    params = params.append('localeDateOffset', localeDateOffset.toString());

    return this.apiClientService.getSingle<iDayViewCount>("dashboard/getMonthlyCallCount", undefined, params);
  }

  //Team Graph Data
  public getTeamGraphData(tenantId: number): Observable<iTeamWidget> {
    let params = new HttpParams();
    params = params.append('tenantId', tenantId.toString());

    return this.apiClientService.getSingle<iTeamWidget>("dashboard/getTeamGraphData", undefined, params);
  }

  //Get calendar view
  public getCalendarView(adjusterId: number, startDate: string, endDate: string, localeDateOffset: number, todayDate: string): Observable<any> {
    let params = new HttpParams();
    params = params.append('adjusterId', adjusterId.toString());
    params = params.append('startDate', startDate);
    params = params.append('endDate', endDate);
    params = params.append('localeDateOffset', localeDateOffset.toString());
    params = params.append('todayDate', todayDate);

    return this.apiClientService.getList<any>("dashboard/getCalendarWeekView", undefined, params);
  }

  //Day view Count
  public getRescheduleListWeb(adjusterId: number): Observable<iWebRescheduleRequest> {
    let params = new HttpParams();
    params = params.append('adjusterId', adjusterId.toString());    

    return this.apiClientService.getSingle<iWebRescheduleRequest>("conference/getRescheduleListWeb", undefined, params);
  }

  public rescheduleConferenceWeb(rescheduleRequest: iRescheduleAcceptRequestWeb): Observable<any> {
    return this.apiClientService.post<any>("conference/rescheduleConferenceWeb", rescheduleRequest);
  }

  public acceptConferenceWeb(rescheduleRequest: iRescheduleAcceptRequestWeb): Observable<any> {
    return this.apiClientService.post<any>("conference/acceptConferenceWeb", rescheduleRequest);
  }

   //Get chat/conversation data view
   public getConversationData(adjusterId: number, localeDateOffset: number): Observable<any> {
    let params = new HttpParams();
    params = params.append('adjusterId', adjusterId.toString());
    params = params.append('localeDateOffset', localeDateOffset.toString());

    return this.apiClientService.getList<any>("dashboard/getConversation", undefined, params);
  }

  public getConferenceHistoryForWeb(conferenceId: number, voipToken: string): Observable<any> {
    let params = new HttpParams();
    params = params.append('conferenceId', conferenceId.toString());
    params = params.append('voipToken', voipToken);

    return this.apiClientService.getSingle<any>("conference/getConferenceHistoryForWeb", undefined, params);
  }
}
