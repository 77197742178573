import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpParams, HttpHeaders } from '@angular/common/http';
import { ApiClientService } from './api-client.service';
import {
  iDropDown, iUserSetting, iUpdateSetting, iAddUser, iUserDetails, iGetUser, iResetPassword,
  iCountryList, iTenantStorage, iUserLastLoggedIn, iLossTypeDetail, iBillingDetails
} from './../models/setting.model';
import { TenantPaymentInfo, TenantPaymentMethodCard, iGetInvoice, iGetPaidInvoice, iInvoiceReceipt, iUserInvoiceVM } from './../models/tenant-payment-info.model';

@Injectable({
  providedIn: 'root'
})

export class SettingService {

  constructor(private apiClientService: ApiClientService) { }

  public getUTCList(): Observable<iDropDown[]> {
    return this.apiClientService.getList<iDropDown>("setting/getUTCList", undefined, undefined);
  }

  public getUserSettingsData(userId: number): Observable<iUserSetting> {
    let params = new HttpParams();
    params = params.append('userId', userId.toString());

    return this.apiClientService.getSingle<iUserSetting>("setting/getUserSettingsData", undefined, params);
  }

  public updateDomainName(updateSetting: iUpdateSetting): Observable<any> {
    return this.apiClientService.post<any>("setting/updateDomainName", updateSetting);
  }

  public updateTimeZone(updateSetting: iUpdateSetting): Observable<any> {
    return this.apiClientService.post<any>("setting/updateTimeZone", updateSetting);
  }

  public addUser(addUser: iAddUser): Observable<any> {
    return this.apiClientService.post<any>("setting/addUser", addUser);
  }

  public resendEmail(emailId: string): Observable<any> {
    let params = new HttpParams();
    params = params.append('emailId', emailId.toString());
    return this.apiClientService.getSingle<any>("setting/resendActivationLink", undefined, params);
  }

  public addUserDetails(userData: iUserDetails): Observable<any> {
    return this.apiClientService.post<any>("setting/addUserDetails", userData);
  }

  public getUserList(tenantId: number): Observable<iGetUser[]> {
    let params = new HttpParams();
    params = params.append('tenantId', tenantId.toString());

    return this.apiClientService.getList<iGetUser>("setting/getUserList", undefined, params);
  }

  public deleteUser(userId: number): Observable<any> {
    let params = new HttpParams();
    params = params.append('userId', userId.toString());

    return this.apiClientService.getSingle<any>("setting/deleteUser", undefined, params);
  }

  public checkDomainAvailability(domainName: string, tenantId: number): Observable<any> {
    let params = new HttpParams();
    params = params.append('domainName', domainName.toString());
    params = params.append('tenantId', tenantId.toString());

    return this.apiClientService.getSingleWithoutLoader<any>("setting/checkDomainAvailability", undefined, params);
  }

  public resetPassword(resetPasswordData: iResetPassword): Observable<any> {
    return this.apiClientService.post<any>("setting/resetPassword", resetPasswordData);
  }

  public updateUserSettingData(userSettingData: iUserSetting): Observable<any> {
    return this.apiClientService.post<any>("setting/updateUserSettingData", userSettingData);
  }

  public getCountryList(): Observable<iCountryList[]> {
    return this.apiClientService.getList<iCountryList>("setting/getCountryList", undefined, undefined);
  }

  public getAvailableSlot(date:any,accountId:any): Observable<any[]> {
    let params = new HttpParams();
    params = params.append('date', date);
    params = params.append('accountId', accountId);
    return this.apiClientService.getList<any[]>("setting/GetAvailableSlot", undefined, params);
  }

  // Get tenant storage details
  public getTenantStorages(): Observable<iTenantStorage[]> {
    return this.apiClientService.getList<iTenantStorage>("setting/getTenantStorages", undefined, undefined);
  }

  // Update tenant storage account status
  public updateStorageAccountStatus(storageTypeId: number, accountStatus: boolean): Observable<boolean> {
    let params = new HttpParams();
    params = params.append('storageTypeId', storageTypeId.toString());
    params = params.append('accountStatus', accountStatus.toString());

    return this.apiClientService.getSingle<boolean>("setting/updateStorageAccountStatus", undefined, params);
  }

  //Method to get API key for a tenant
  public getAdjustifyAPIKey(tenantId: number): Observable<any> {
    let params = new HttpParams();
    params = params.append('tenantId', tenantId.toString());

    return this.apiClientService.getSingle<any>("setting/getAdjustifyAPIKey", undefined, params);
  }

  //Method to generate API key for a tenant
  public generateAdjustifyAPIKey(tenantId: number): Observable<any> {
    let params = new HttpParams();
    params = params.append('tenantId', tenantId.toString());

    return this.apiClientService.getSingle<any>("setting/generateAdjustifyAPIKey", undefined, params);
  }

  //Metho to save user's last logged-in time
  public saveUserLastLoggedIn(userLastLoggedInVM: iUserLastLoggedIn): Observable<any> {
    return this.apiClientService.post<any>("setting/saveUserLastLoggedIn", userLastLoggedInVM);
  }

  //Method to get all loss type
  public getLossType(tenantId: number, localeDateOffset: number): Observable<iGetUser[]> {
    let params = new HttpParams();
    params = params.append('tenantId', tenantId.toString());
    params = params.append('localeDateOffset', localeDateOffset.toString());

    return this.apiClientService.getList<iGetUser>("setting/getLossType", undefined, params);
  }

  public getLossTypeWithoutLoader(tenantId: number, localeDateOffset: number): Observable<iGetUser[]> {
    let params = new HttpParams();
    params = params.append('tenantId', tenantId.toString());
    params = params.append('localeDateOffset', localeDateOffset.toString());

    return this.apiClientService.getListWithoutLoader<iGetUser>("setting/getLossType", undefined, params);
  }

  //Method to add new loss type
  public addLossType(lossTypeData: iLossTypeDetail): Observable<any> {
    return this.apiClientService.post<any>("setting/addLossType", lossTypeData);
  }

  //Method to get all pending invoices
  public getPendingInvoices(tenantId: number, localeDateOffset: number): Observable<iGetInvoice[]> {
    let params = new HttpParams();
    params = params.append('tenantId', tenantId.toString());
    params = params.append('localOffset', localeDateOffset.toString());

    return this.apiClientService.getList<iGetInvoice>("setting/getPendingInvoices", undefined, params);
  }

  //Method to get invoice receipt
  public getInvoiceReceipt(referenceId: string): Observable<iInvoiceReceipt> {
    let params = new HttpParams();
    params = params.append('referenceId', referenceId);

    return this.apiClientService.getSingleWithoutLoader<iInvoiceReceipt>("setting/getInvoiceReceipt", undefined, params);
  }

  //Method to get year
  public getYearList(currentYear: string): Observable<iDropDown[]> {
    let params = new HttpParams();
    params = params.append('currentYear', currentYear);    

    return this.apiClientService.getList<iDropDown>("setting/getYearList", undefined, params);
  }

  public getPaidInvoices(tenantId: number, localeDateOffset: number, lastInvoiceId: number, year: number): Observable<iGetPaidInvoice> {
    let params = new HttpParams();
    params = params.append('tenantId', tenantId.toString());
    params = params.append('localOffset', localeDateOffset.toString());
    params = params.append('lastInvoiceId', lastInvoiceId.toString());
    params = params.append('year', year.toString());

    return this.apiClientService.getSingle<iGetPaidInvoice>("setting/getPaidInvoices", undefined, params);
  }

  //Method to activate and de-activate loss type
  public activeDeActiveLossType(lossTypeData: iLossTypeDetail): Observable<any> {
    return this.apiClientService.post<any>("setting/activeDeActiveLossType", lossTypeData);
  }
  public initiatePaymentSetup(tenatPaymentInfo: TenantPaymentInfo): Observable<TenantPaymentInfo> {
    return this.apiClientService.post<TenantPaymentInfo>("setting/initiatePaymentSetup", tenatPaymentInfo);
  }

  public getTenantPaymentInfo(): Observable<TenantPaymentInfo> {
    return this.apiClientService.getSingle<TenantPaymentInfo>("setting/getTenantPaymentInfo", null);
  }

  public createSetupIntent(): Observable<string> {
    return this.apiClientService.getSingle<string>("setting/createSetupIntent", null);
  }

  public updatePaymentMethodWithSubsciption(tenantPaymentInfo: TenantPaymentInfo): Observable<number> {
    return this.apiClientService.post<number>("setting/updatePaymentMethodWithSubsciption", tenantPaymentInfo);
  }

  public updatePaymentMethod(tenantPaymentInfo: TenantPaymentInfo): Observable<number> {
    return this.apiClientService.post<number>("setting/updatePaymentMethod", tenantPaymentInfo);
  }

  public createSubscription(tenantPaymentInfo: TenantPaymentInfo): Observable<number> {
    return this.apiClientService.post<number>("setting/createSubsciption", tenantPaymentInfo);
  }

  public updateBillingAddress(tenatPaymentInfo: TenantPaymentInfo): Observable<TenantPaymentInfo> {
    return this.apiClientService.post<TenantPaymentInfo>("setting/updateBillingAddress", tenatPaymentInfo);
  }

  public detachPaymentMethod(tenatPaymentMethod: TenantPaymentMethodCard): Observable<boolean> {
    return this.apiClientService.post<boolean>("setting/detachPaymentMethod", tenatPaymentMethod);
  }

  public createCheckoutSession(selectedInvoicesId: string[], totalPayAmount: number): Observable<any> {
    let params = new HttpParams();
    params = params.append('selectedInvoicesId', selectedInvoicesId.toString());
    params = params.append('totalPayAmount', totalPayAmount.toString());
    return this.apiClientService.getSingleWithoutLoader<any>("setting/createCheckoutSession", undefined, params);
  }

  public getTenantBillingDetails(tenantId: number) {
    let params = new HttpParams();
    params = params.append('tenantId', tenantId.toString());
    return this.apiClientService.getSingleWithoutLoader<any>("setting/getTenantBillingDetails", undefined, params);
  }

  public updateTenantBillingAddress(billingAddressVM: iBillingDetails): Observable<boolean> {
    return this.apiClientService.post<boolean>("setting/updateTenantBillingAddress", billingAddressVM);
  }

  public getInvoicePDF(invoiceId: number, localeDateOffset: number): Observable<iUserInvoiceVM> {
    let params = new HttpParams();
    params = params.append('invoiceId', invoiceId.toString());
    params = params.append('localOffset', localeDateOffset.toString());
    return this.apiClientService.getSingle<iUserInvoiceVM>("setting/getInvoicePDF", undefined, params);
  }

  public getAllTenantUser(): Observable<any> {
    return this.apiClientService.getList<any>("setting/getAllTenantUser");
  }

}
