import { Component, OnInit, ViewChild, ElementRef, AfterViewInit } from '@angular/core';
import { SharedDataService } from '../../services/sharedData.service';
import { Constants } from '../../shared/constant';
import { SettingService } from '../../api-services/setting.service';
import { iTenantStorage } from '../../models/setting.model';
import { Router, ActivatedRoute, Params, Route } from '@angular/router';
import { ResetPasswordComponent } from './reset-password/reset-password.component';
import { ManageUserComponent } from './manage-user/manage-user.component';
import { SettingsComponent } from './settings/settings.component';
import { TermsConditionsComponent } from './terms-conditions/terms-conditions.component';
import { ConfigurationComponent } from './configuration/configuration.component';
import { MyPaymentComponent } from './my-payment/my-payment.component';

@Component({
  selector: 'app-main-settings',
  templateUrl: './main-settings.component.html',
  styleUrls: ['./main-settings.component.css']
})
export class MainSettingsComponent implements OnInit {

  constructor(private sharedService: SharedDataService, private settingService: SettingService, private activatedRoute: ActivatedRoute) { }

  @ViewChild(ResetPasswordComponent) resetPasswordChild: ResetPasswordComponent;
  @ViewChild(ManageUserComponent) manageUserChild: ManageUserComponent;
  @ViewChild(SettingsComponent) settingChild: SettingsComponent;
  @ViewChild(TermsConditionsComponent) tncChild: TermsConditionsComponent;
  @ViewChild(ConfigurationComponent) configurationChild: ConfigurationComponent;
  @ViewChild('tnc') tncClick: ElementRef;
  @ViewChild(MyPaymentComponent) myPaymentChild: MyPaymentComponent;

  userRole?: string;
  integrationTabActive: boolean = false;
  paymentTabActive: boolean = false;
  activeTab: string;
  pendingInvoiceCount?: number;

  ngOnInit(): void {
    this.userRole = this.sharedService.getValue("userRole");
    this.activatedRoute.queryParams.subscribe(prms => {
        let parm = prms[Constants.active];   
       
        switch(parm){
          case Constants.storage:
            this.integrationTabActive = true;
            break;
          case Constants.payment:
            this.paymentTabActive = true;
            break;
        }
    });    
  }

  getPengingCount(data) {
    this.pendingInvoiceCount = data;
  }

  ngAfterViewInit(): void {
    var pageURL = window.location.href;
    var lastURLSegment = pageURL.substr(pageURL.lastIndexOf('#'));

    if (lastURLSegment == "#terms-conditions") {
      this.triggerFalseClick();
    }
  }



  triggerFalseClick() {
    let el: HTMLElement = this.tncClick.nativeElement;
    el.click();
  }

  refreshResetPwdTab() {
    this.resetPasswordChild.refreshMethod();
  }

  refreshManageUserTab() {
    this.manageUserChild.refreshMethod();
  }

  refreshSettingTab() {
    this.settingChild.refreshMethod();
  }

  refreshTnCTab() {
    this.tncChild.refreshMethod();
  }

  refreshConfigurationTab() {
    this.configurationChild.refreshMethod();
  }

  refreshMyPaymentTab() {
    //this.myPaymentChild.refreshMethod();
  }
}
