import { Injectable } from '@angular/core';
import {
  Router,
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot
} from '@angular/router';

import { AuthService } from './auth.service';
import decode from 'jwt-decode';
import { SharedDataService } from './sharedData.service';
import { TenantRegistrationService } from '../api-services/tenant-registration.service';
import { iTenant } from '../models/tenant-registration.model';

@Injectable({
  providedIn: 'root'
})
export class ImportGuard implements CanActivate {

  constructor(public auth: AuthService, public router: Router, private sharedService: SharedDataService, private tenantService: TenantRegistrationService) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {

    const token = this.sharedService.getValue('token');
    const tenantId = this.sharedService.getValue('tenantId');
    var tenantData: iTenant = {};

    if (token) {

      // decode the token to get its payload
      const tokenPayload = decode(token);

      // check if route is restricted by role
      if (route.data.roles && route.data.roles.indexOf(tokenPayload.userRole) === -1) {
        // role not authorised so redirect to home page
        this.router.navigate(['/']);
        return false;
      }

      //token and refresh token expired, so redirect to login
      if (this.auth.getTokenExpireTime() != undefined && this.auth.getRefreshTokenExpireTime() != undefined && this.auth.isAccessTokenExpired() && this.auth.isRefreshTokenExpired()) {
        this.auth.logout();
        this.router.navigate(['/']);
        return false;
      }

      this.tenantService.getTenantById(this.sharedService.getValue('tenantId')).subscribe(result => {
        if (result != null)
          tenantData = <iTenant>result;
        if (tenantData.isPaymentDone == null || !tenantData.isPaymentDone) {
          this.router.navigate(['/']);
          return false;
        }
      }, error => {
        this.router.navigate(['/']);
        return false;
      });

      // authorised so return true
      return true;
    }

    // not logged in so redirect to login page with the return url
    this.router.navigate(['/']);
    return false;
  }
}
