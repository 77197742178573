<div class="authentication-container">
  <div class="container">
    <div class="auth-content register-auth">
      <div class="logo-block">
        <img src="../../../../assets/images/auth-logo.svg" />
      </div>
      <div class="auth-title">
        <span>Bienvenidos a CASCO Safety</span>
      </div>
      <div class="selection-block">
        <div class="selection-title">
          <span>Favor escoger el tipo de cuenta:</span>
        </div>
        <div class="choose-block">
          <div class="choose-content">
            <a [ngClass]="{'active': planSelection.planId == 1}" (click)="chooseEnterprisePlan()">
              <img src="../../../../assets/images/enterprise-r.svg" />
              <span>Empresa</span>
            </a>
          </div>
          <div class="choose-content right">
            <a [ngClass]="{'active': planSelection.planId == 2}" (click)="chooseIndividualPlan()">
              <img src="../../../../assets/images/individual-r.svg" />
              <span>Individual</span>
            </a>
          </div>
          <div class="clear"></div>
        </div>
          <div>
              <validation-summary [validationErrors]="validationMessages"></validation-summary>
          </div>
        <div class="button-block">
          <div class="btn comm-btn blank" (click)="goBackToRegistration()">
            <svg xmlns="http://www.w3.org/2000/svg" width="14.488" height="9.992" viewBox="0 0 14.488 9.992">
              <defs>
                <style>
                  .a {
                    fill: #404040;
                  }
                </style>
              </defs>
              <path class="a" d="M11.988,16.5H-.476L3.34,12.861a.5.5,0,0,0-.689-.724l-4.358,4.151A.994.994,0,0,0-2,17a1,1,0,0,0,.3.715l4.35,4.143a.5.5,0,1,0,.689-.723L-.492,17.5h12.48a.5.5,0,1,0,0-1Z" transform="translate(2 -12)" />
            </svg>Atras
          </div>
          <div class="btn comm-btn fill" (click)="savePlan()">Finalizar</div>
        </div>
      </div>
    </div>
  </div>
</div>
