<div class="authentication-container">
  <div class="container">
    <div class="auth-content verification-auth">
      <div class="logo-block">
        <img src="../../../../assets/images/auth-logo.svg" />
      </div>
      <div class="auth-title">
        <span>Bienvenido a CASCO Safety</span>
      </div>
      <div class="auth-text">
        <span>Ingresar C&#243;digo de Verificaci&#243;n</span>
      </div>
      <form>
        <div class="auth-block">
          <div class="row">
            <div class="col-sm-12 col-md-12">
              <div class="input-block extra">
                <input type="text" class="form-control" placeholder="C&#243;digo de Verificaci&#243;n" [(ngModel)]="forgotPasswordData.activationCode" id="activattionCode"
                       name="activattionCode" maxlength="50" />
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-sm-12 col-md-12">
              <div class="input-block extra">
                <input type="password" class="form-control" placeholder="Nueva Contrase&#241;a" [(ngModel)]="forgotPasswordData.newPassword" id="newPassword"
                       name="newPassword" maxlength="50" (focus)="focusFunction()" (input)="checkPasswordFormat()" (keypress)="restrictSpace($event)" />
              </div>
            </div>
          </div>
          <div class="row" *ngIf="showPasswordFormat">
            <div class="col-sm-12 col-md-12">
              <div class="password-rules">
                <div class="text-auth" [ngClass]="{'success': letterFlag}">
                  <img class="cross" src="../../../../assets/images/auth-error-cross.svg" />
                  <img class="tick" src="../../../../assets/images/auth-error-tick.svg" />
                  <span class="rules-info">Requiere una letra Mayuscula | Miniscula</span>
                </div>
                <div class="text-auth" [ngClass]="{'success': digitFlag}">
                  <img class="cross" src="../../../../assets/images/auth-error-cross.svg" />
                  <img class="tick" src="../../../../assets/images/auth-error-tick.svg" />
                  <span class="rules-info">Requiere un digito 0-9</span>
                </div>
                <div class="text-auth" [ngClass]="{'success': charFlag}">
                  <img class="cross" src="../../../../assets/images/auth-error-cross.svg" />
                  <img class="tick" src="../../../../assets/images/auth-error-tick.svg" />
                  <span class="rules-info">Requiere un Caracter Especial : ! @ # $ . . .</span>
                </div>
                <div class="text-auth" [ngClass]="{'success': lengthFlag}">
                  <img class="cross" src="../../../../assets/images/auth-error-cross.svg" />
                  <img class="tick" src="../../../../assets/images/auth-error-tick.svg" />
                  <span class="rules-info">Contrase&#241;a requiere por lo menos 8 caracteres</span>
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-sm-12 col-md-12">
              <div class="input-block extra">
                <input type="password" class="form-control" placeholder="Confirmar Contrase&#241;a Nueva" [(ngModel)]="confirmPassword" id="cnfPassword"
                       name="cnfPassword" maxlength="50" (keypress)="restrictSpace($event)"/>
              </div>
            </div>
          </div>
        </div>
        <div>
          <validation-summary [validationErrors]="validationMessages"></validation-summary>
        </div>
        <div class="button-block">
          <button class="btn comm-btn" (click)="updateForgotPassword()">Aplicar</button>
        </div>
      </form>
    </div>
  </div>
</div>
