import { Component, OnInit, Input } from '@angular/core';
import { Constants } from '../../../shared/constant';
import { IntegrationService } from '../../../api-services/integration.service';
import { SharedDataService } from '../../../services/sharedData.service';
import { SettingService } from '../../../api-services/setting.service';
import { iTenantStorage } from '../../../models/setting.model';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-integration',
  templateUrl: './integration.component.html',
  styleUrls: ['./integration.component.css']
})
export class IntegrationComponent implements OnInit {

  readonly azureStorage: number = Constants.storageAccount['azure'];
  readonly dropBoxStorage: number = Constants.storageAccount['dropBox'];
  readonly googleDriveStorage: number = Constants.storageAccount['googleDrive'];
  readonly oneDriveStorage: number = Constants.storageAccount['oneDrive'];
  storageRedirectURL: string;
  validationMessages: any;
  tenantId: number;
  isDropBoxAccountSetup: boolean = false;
  isDropBoxActive: boolean = false;
  tenantStorage?: iTenantStorage[] = [];

  constructor(private sharedService: SharedDataService,
    private settingService: SettingService,
    private service: IntegrationService,
    private toastr: ToastrService) { }

  ngOnInit(): void {
    this.getTenantStorage();
    setTimeout(() => {
      if (!this.isDropBoxAccountSetup)
        this.getStorageRedirectURL(this.dropBoxStorage);
    }, 1500);
  }

  // Get storage redirect URL based on storageId
  getStorageRedirectURL(storageId) {
    this.validationMessages = [];
    this.service.getStorageRedirectURL(storageId).subscribe(
      (result) => {
        if (result != null) {
          this.storageRedirectURL = result;
        }
      },
      (error) => {
        this.populateApiErrorMessages(error.message);
      }
    );
  }

  populateApiErrorMessages(message: string) {
    this.validationMessages.push(message);
  }

  // Get tenant storage details for displaying account is set
  getTenantStorage() {
    this.settingService.getTenantStorages().subscribe(
      (result) => {
        if (result != null) {
          this.tenantStorage = <iTenantStorage[]>result;
          this.tenantStorage.forEach(element => {
            if (element.storageTypeId == Constants.storageAccount["dropBox"] && element.isAccoutnActive) {
              this.isDropBoxAccountSetup = true;
              this.isDropBoxActive = element.isEnabled;
            }
          });
        }
      },
      (error) => {
        this.populateApiErrorMessages(error.message);
      }
    );
  }

  // Set storage account status
  setDropBoxAccount(status) {
    this.settingService.updateStorageAccountStatus(this.dropBoxStorage, !status).subscribe(
      (result) => {
        if (result != null) {
          if (status == false)
            this.successMsg("Cuenta activada con éxito.");
          else
            this.successMsg("Cuenta desactivada con éxito.");
        }
      },
      (error) => {
        this.populateApiErrorMessages(error.message);
      }
    );
  }

  successMsg(message) {
      this.toastr.success(message, 'Exito')
  }
}
