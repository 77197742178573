<div class="contact-form-container">
  <div class="container">
    <div class="contact-form-block">
      <div class="logo-block">
        <img src="../../../../assets/images/auth-logo.svg" />
      </div>
      <section class="thank-you-sec">
        <div class="container">
          <div class="title-desc-block text-center">
            <div class="block-title">
              <h3>Gracias!</h3>
            </div>
            <div class="block-desc">
              <p>Revise su correo electrónico para ver el enlace de verificación.</p>
            </div>
          </div>
        </div>
      </section>
    </div>
  </div>
</div>