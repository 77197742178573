<div class="authentication-container">
  <div class="container">
    <div class="auth-content register-auth">
      <div class="logo-block">
        <img src="../../../../assets/images/auth-logo.svg" />
      </div>
      <div class="auth-title">
        <span>Crear Su Cuenta</span>
      </div>
      <form ngNativeValidate #registrationForm="ngForm">
        <div class="auth-block">
          <div class="row">
            <div class="col-sm-6 col-md-6">
              <div class="input-block">
                <input type="text" class="form-control" placeholder="Primer Nombre" name="firstName" [(ngModel)]="tenantRegistration.firstName" maxlength="50" />
              </div>
            </div>
            <div class="col-sm-6 col-md-6">
              <div class="input-block">
                <input type="text" class="form-control" placeholder="Apellidos" name="lastName" [(ngModel)]="tenantRegistration.lastName" maxlength="50" />
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-sm-12 col-md-12">
              <div class="input-block">
                <input type="text" class="form-control" placeholder="Empresa" name="businessName" [(ngModel)]="tenantRegistration.businessName" maxlength="50" (keypress)="checkSpecialCharacter($event)" (paste)="onPaste($event)" />
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-sm-6 col-md-6">
              <div class="input-block next">
                <div class="only-extra-part">
                    <input type="text" class="form-control ph-number" [(ngModel)]="tenantRegistration.phoneNo" name="phoneNo" maxlength="25" minlength="8"
                           [ngClass]="{'code-one': selectedCountry.countryISDCode != undefined && selectedCountry.countryISDCode.length == 2, 'code-two': selectedCountry.countryISDCode != undefined && selectedCountry.countryISDCode.length == 3, 'code-three': selectedCountry.countryISDCode != undefined && selectedCountry.countryISDCode.length == 4, 'code-four': selectedCountry.countryISDCode != undefined && selectedCountry.countryISDCode.length == 5, 'code-six': selectedCountry.countryISDCode != undefined && selectedCountry.countryISDCode.length == 7}" placeholder="1234567890"
                           (keypress)="restrictSpecialCharacterForPhoneNumber($event)" (paste)="restrictPhoneNumberCharactersOnPaste($event)" />
                    <span class="only-code">{{selectedCountry.countryISDCode}}</span>
                </div>
                <div class="tel-dd-part">
                  <div class="dropdown">
                    <a class="dropdown-toggle" id="menu1" data-toggle="dropdown">
                      <span>{{selectedCountry.countryCode}}</span><img src="../../../../assets/images/select-arw.svg" />
                    </a>
                    <ul class="dropdown-menu" role="menu" aria-labelledby="menu1">
                      <li class="ph-menu-item" *ngFor="let data of countryList"><a (click)="selectCountry(data)"><span class="country-id">{{data.bothCountryCode}}</span></a></li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-sm-6 col-md-6">
              <div class="input-block next">
                <input type="text" class="form-control" placeholder="Correo Electr&#243;nico" [(ngModel)]="tenantRegistration.emailId" name="emailId"
                       pattern="[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,3}$" />
              </div>
            </div>
          </div>
          <div>
            <div *ngIf="validationMessages?.length > 0" class="error-block">
              <div class="error-part">
                <span class="warning-icon"><img src="../../../assets/images/error.svg" /></span>
                <div *ngFor="let error of validationMessages">
                  <span class="error-msg" [innerHTML]="error"></span>
                </div>
              </div>
            </div>
          </div>
          <div class="button-block">
            <!--<button class="btn comm-btn left" routerLink="/" routerLinkActive="active">Have account? <span>Login</span></button>-->
            <button (click)="saveTenantRegistration()" *ngIf="!hideButtons" class="btn comm-btn">Avanzar<svg xmlns="http://www.w3.org/2000/svg" width="14.488" height="9.992" viewBox="0 0 14.488 9.992"><path id="arrow" d="M-1.5,16.5H10.964L7.148,12.861a.5.5,0,0,1,.689-.724L12.2,16.289a1,1,0,0,1-.009,1.422l-4.35,4.143a.5.5,0,1,1-.689-.723L10.98,17.5H-1.5A.5.5,0,0,1-2,17a.5.5,0,0,1,.5-.5Z" transform="translate(2 -12)" fill="#fff" /></svg></button>
          </div>
          <div class="already-status">
            <span>&#191;Ya&nbsp;una&nbsp;cuenta?<a href="/login">Iniciar&nbsp;sesi&#243;n</a></span>
          </div>
        </div>
      </form>
    </div>
  </div>
</div>
