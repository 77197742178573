import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { NgForm } from '@angular/forms'
import { SharedDataService } from '../../../services/sharedData.service';
import { TenantRegistrationService } from '.././../../api-services/tenant-registration.service';
import { iForgotPassword } from '../../../models/tenant-registration.model';
import { AuthService } from '../../../services/auth.service';
import { Constants } from '../../../shared/constant';

@Component({
  selector: 'app-verification-code-confirmation',
  templateUrl: './verification-code-confirmation.component.html',
  styleUrls: ['./verification-code-confirmation.component.css']
})
export class VerificationCodeConfirmationComponent implements OnInit {

  constructor(private router: Router,
    private service: TenantRegistrationService,
    private sharedService: SharedDataService,
    private authService: AuthService) { }

  @ViewChild('activateForgotPasswordForm')
  activateForgotPasswordForm: NgForm;
  forgotPasswordData?: iForgotPassword = {};
  confirmPassword?: string;
  validationMessages: any;
  message?: string;
  letterFlag: boolean = false;
  digitFlag: boolean = false;
  lengthFlag: boolean = false;
  charFlag: boolean = false;
  showPasswordFormat: boolean = false;

  ngOnInit(): void {
  }

  //Method to update password when user forgot password
  updateForgotPassword() {
    this.validationMessages = [];

    this.populateValidateMessages();

    if (this.validationMessages.length === 0) {
      this.service.updateForgotPassword(this.forgotPasswordData).subscribe(result => {
        if (result != null) {
          this.message = <string>result;

          if (this.message != "success")
            this.populateApiErrorMessages(this.message);
          else
            this.router.navigate(['/password-changed-success']);
        }
      }, error => {
        this.populateApiErrorMessages(error.message);
      });
    }
  }

  //focus event of password text box to show all password formats
  focusFunction() {
    this.showPasswordFormat = true;
  }

  //method to check all the password formats on change event of password text box
  checkPasswordFormat() {
    if (this.isCharCheck(this.forgotPasswordData.newPassword))
      this.charFlag = true;
    else
      this.charFlag = false;

    if (this.isUpperCaseCheck(this.forgotPasswordData.newPassword) && this.isLowerCaseCheck(this.forgotPasswordData.newPassword))
      this.letterFlag = true;
    else
      this.letterFlag = false;

    if (this.forgotPasswordData.newPassword.length >= 8)
      this.lengthFlag = true;
    else
      this.lengthFlag = false;

    if (this.isDigitCheck(this.forgotPasswordData.newPassword))
      this.digitFlag = true;
    else
      this.digitFlag = false;
  }

  //Check for lower case letter
  isLowerCaseCheck(password) {
    var re = /[a-z]/;
    return re.test(password);
  }

  //Check for upper case letter
  isUpperCaseCheck(password) {
    var re = /[A-Z]/;
    return re.test(password);
  }

  //Check for digit
  isDigitCheck(password) {
    var re = /\d/;
    return re.test(password);
  }

  //Check for special character
  isCharCheck(password) {
    var re = /[!@#$%^&*()]/;
    return re.test(password);
  }

  populateValidateMessages() {
    if (this.forgotPasswordData.activationCode === undefined || this.forgotPasswordData.activationCode === null
      || this.forgotPasswordData.activationCode === '') {
      this.validationMessages.push(Constants.activationCodeRequiredMsg);
    }
    else if (this.forgotPasswordData.newPassword === undefined || this.forgotPasswordData.newPassword === null
      || this.forgotPasswordData.newPassword === '') {
      this.validationMessages.push(Constants.newPasswordRequiredMsg);
    }
    else if (this.confirmPassword === undefined || this.confirmPassword === null || this.confirmPassword === '') {
      this.validationMessages.push(Constants.confPasswordRequiredMsg);
    }
    else if (this.forgotPasswordData.newPassword != this.confirmPassword) {
      this.validationMessages.push(Constants.oldNewPasswordNotMatchMsg);
    }
    else if (!this.lengthFlag || !this.charFlag || !this.digitFlag || !this.letterFlag) {
      this.validationMessages.push(Constants.passwordRulesMsg);
    }
  }

  populateApiErrorMessages(message: string) {
    this.validationMessages.push(message);
  }
  restrictSpace(e) {
    return this.sharedService.restrictSpace(e);
  }

}
