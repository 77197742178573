import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { SharedDataService } from '../../services/sharedData.service';

@Component({
  selector: 'app-side-bar',
  templateUrl: './side-bar.component.html',
  styleUrls: ['./side-bar.component.css']
})
export class SideBarComponent implements OnInit {

  constructor(private router: Router,
    private sharedService: SharedDataService) { }

  userRole?: string;

  ngOnInit(): void {
    this.userRole = this.sharedService.getValue("userRole");
  }

}
