import { Injectable } from '@angular/core';
import { Routes, RouterModule, Router } from '@angular/router';
import { HttpClient, HttpErrorResponse, HttpHeaders, HttpRequest, HttpEvent, HttpResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, retry, map, finalize } from 'rxjs/operators';
import { SharedDataService } from './../services/sharedData.service';
import { environment } from './../../environments/environment';

@Injectable()
export class ApiClientService {
  private headerOptions: HttpHeaders = {} as HttpHeaders;

  constructor(private http: HttpClient,
    private router: Router,
    private sharedService: SharedDataService) {

    //var token = this.sharedService.getValue('token');
    //if (token) {
    //  let token = JSON.parse(this.sharedService.getValue('token'));
    //  if (token) {
    //    this.setHeader("Authorization", `Bearer ${token}`);
    //  }
    //}
  }
  
  public setHeader(key, value) {
    this.headerOptions[key] = value;
  }

  public getHeader(key, value) {
    var header: HttpHeaders = {} as HttpHeaders;
    header[key] = value;
    return header;
  }

  private getAuthoriseHeader() {
    var token = this.sharedService.getValue('token');
    if (token) {
      let token = JSON.parse(this.sharedService.getValue('token'));
      if (token) {
        return this.getHeader("Authorization", `Bearer ${token}`);
      }
      return {} as HttpHeaders;
    }
  }

  public getList<T>(url: string, header: any = undefined, params: any = undefined, showLoader: boolean = true): Observable<T[]> {
    if (showLoader) {
      this.sharedService.showLoader();
    }
    return this.http.get(this.fullUrl(url), { headers: header ? header : null, params: params ? params : null })
      .pipe(
        map(response => {
          return response as T[];
        }),
        catchError((err) => {
          return this.handleError(err);
        }),
        finalize(() => {
          if (showLoader) {
            this.sharedService.hideLoader();
          }
        }));
  }

  public getListWithoutLoader<T>(url: string, header: any = undefined, params: any = undefined, showLoader: boolean = true): Observable<T[]> {

    return this.http.get(this.fullUrl(url), { headers: header ? header : null, params: params ? params : null })
      .pipe(
        map(response => {
          return response as T[];
        }),
        catchError((err) => {
          return this.handleError(err);
        }),
        finalize(() => {

        }));
  }

  public getform<T>(url: string, header: any = undefined, params: any = undefined, showLoader: boolean = true): Observable<T[]> {
    if (showLoader) {
      this.sharedService.showLoader();
    }
    return this.http.get(this.fullUrl(url), { headers: header ? header : null, params: params ? params : null })
      .pipe(
        map(response => {
          return response as T[];
        }),
        catchError((err) => {
          return this.handleError(err);
        }),
        finalize(() => {
          if (showLoader) {
            this.sharedService.hideLoader();
          }
        }));
  }



  public getJSONList<T>(path: string): Observable<any> {
    return this.http.get(path);
  }

  public getSingle<T>(url: string, header: any = undefined, params: any = undefined, showLoader: boolean = true): Observable<T> {
    if (showLoader) {
      this.sharedService.showLoader();
    }
    return this.http.get(this.fullUrl(url), { headers: header ? header : null, params: params ? params : null })
      .pipe(
        map(response => {
          return response as T;
        }),
        catchError((err) => {
          return this.handleError(err);
        }),
        finalize(() => {
          if (showLoader) {
            this.sharedService.hideLoader();
          }
        }));
  }

  public getSingleWithoutLoader<T>(url: string, header: any = undefined, params: any = undefined, showLoader: boolean = true): Observable<T> {

    return this.http.get(this.fullUrl(url), { headers: header ? header : null, params: params ? params : null })
      .pipe(
        map(response => {
          return response as T;
        }),
        catchError((err) => {
          return this.handleError(err);
        }),
        finalize(() => {

        }));
  }

  public post<T>(url: string, data: any, header: any = undefined, showLoader: boolean = true): Observable<T> {
    if (showLoader) {
      this.sharedService.showLoader();
    }
    return this.http.post(this.fullUrl(url), data, { headers: header ? header : null })
      .pipe(
        map(response => {
          return response as T;
        }),
        catchError((err: any) => {
          return this.handleError(err);
        }),
        finalize(() => {
          if (showLoader) {
            this.sharedService.hideLoader();
          }
        }));
  }

  public postWithoutLoader<T>(url: string, data: any, header: any = undefined, showLoader: boolean = true): Observable<T> {

    return this.http.post(this.fullUrl(url), data, { headers: header ? header : this.getAuthoriseHeader() })
      .pipe(
        map(response => {
          return response as T;
        }),
        catchError((err: any) => {
          return this.handleError(err);
        }),
        finalize(() => {

        }));
  }

  public postList<T>(url: string, data: any, header: any = undefined, showLoader: boolean = true): Observable<T[]> {
    if (showLoader) {
      this.sharedService.showLoader();
    }
    return this.http.post(this.fullUrl(url), data, { headers: header ? header : null })
      .pipe(
        map(response => {
          return response as T[];
        }),
        catchError((err) => {
          return this.handleError(err);
        }),
        finalize(() => {
          if (showLoader) {
            this.sharedService.hideLoader();
          }
        }));
  }

  public put<T>(url: string, data: any, header: any = undefined, showLoader: boolean = true): Observable<T> {
    if (showLoader) {
      this.sharedService.showLoader();
    }
    return this.http.put(this.fullUrl(url), data, { headers: header ? header : null })
      .pipe(
        map(response => {
          return response as T;
        }),
        catchError((err) => {
          return this.handleError(err);
        }),
        finalize(() => {
          if (showLoader) {
            this.sharedService.hideLoader();
          }
        }));
  }

  public delete<T>(url: string, data: any, showLoader: boolean = true): Observable<T> {
    if (showLoader) {
      this.sharedService.showLoader();
    }
    return this.http.delete(this.fullUrl(url), { headers: null })
      .pipe(
        map(response => {
          return response as T;
        }),
        catchError((err) => {
          return this.handleError(err);
        }),
        finalize(() => {
          if (showLoader) {
            this.sharedService.hideLoader();
          }
        }));
  }

  public fullUrl(url: string): string {
    return environment.apiUrl + url;
  }

  public handleError(response: any) {

    if (response instanceof HttpErrorResponse) {
      if (response.error instanceof ErrorEvent) {
        console.error("Error Event");
      } else {
        console.log(`error status : ${response.status} ${response.statusText}`);        
      }
    } else {
      console.error(`Some error occurred : ${response.error}`);
    }
    return throwError(response);
  }

}
