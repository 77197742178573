<div class="all-settings-content">
  <div class="settings-container">
    <!--<div class="settings-section">
      <div class="settings-title">
        <span>Direccion Web <a class="edit" *ngIf="!isEdit" (click)="editClick()">Editar</a></span>
      </div>
      <div class="row">
        <div class="col-md-12">
          <div class="settings-blocks first">
            <div class="row">
              <div class="col-md-12">
                <div class="desc-block">
                  <div class="dom-confirm">
                    <div class="row">
                      <div class="col-md-12">
                        <div class="input-edit">
                          <input type="text" *ngIf="!isEdit" disabled placeholder="ABC" [(ngModel)]="defaultDomainName" class="form-control" maxlength="10" />
                          <input type="text" *ngIf="isEdit" placeholder="ABC" [(ngModel)]="domainName" class="form-control" (input)="checkSameDomainName()"
                                 (keypress)="checkSpecialCharacter($event)" (paste)="onPaste($event)" maxlength="10" />
                          <div class="dom-name" [ngClass]="{'move-right': isEdit == true}">
                            <span>.Adjustify.co</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div>
                    <validation-summary [validationErrors]="validationMessages"></validation-summary>
                  </div>
                  <div class="button-block">
                    <div class="row">
                      <div class="col-md-3">
                        <button class="btn comm-btn conf" *ngIf="isEdit" (click)="checkDomainAvailability()">Revisar Disponibilidad</button>
                      </div>
                      <div class="col-md-3">
                        <span class="checking" *ngIf="showCheck">
                          checking...
                        </span>
                        <span class="not-hr" *ngIf="isRight == false">
                          <img class="confirm" src="../../../../assets/images/web-wrong.svg" />
                          No disponible 
                        </span>
                        <span class="hr" *ngIf="isRight == true">
                          <img class="confirm" src="../../../../assets/images/web-right.svg" />
                          Disponible
                        </span>
                      </div>
                      <div class="col-md-2"></div>
                      <div class="col-md-2 rt-only">
                        <button class="btn comm-btn cancel" *ngIf="isEdit" (click)="cancelClick()">Cancelar</button>
                      </div>
                      <div class="col-md-2 rt-only">
                        <button class="btn comm-btn save" *ngIf="isEdit" [disabled]="!isRight || sameDomainName" (click)="saveDomainName()">Guardar</button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>-->
    <!--<div class="settings-section bottom-block">
      <div class="settings-title">
        <span>Preferences</span>
      </div>
      <div class="row">
        <div class="col-md-6">
          <div class="settings-blocks">
            <div class="desc-block">
              <span class="desc-top">Business Name</span>
              <span class="desc-btm">{{userSettingData.businessName}}</span>
            </div>
          </div>
        </div>
        <div class="col-md-6">
          <div class="settings-blocks">
            <div class="desc-block">
              <span class="desc-top">Primary Account Holder</span>
              <span class="desc-btm">{{userSettingData.fullName}}</span>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-6">
          <div class="settings-blocks">
            <div class="desc-block">
              <span class="desc-top">Primary Account Holder Email</span>
              <span class="desc-btm">{{userSettingData.emailId}}</span>
            </div>
          </div>
        </div>
        <div class="col-md-6">
          <div class="settings-blocks">
            <div class="row">
              <div class="col-md-9">
                <div class="desc-block">
                  <span class="desc-top last">Time Zone</span>
                  <div class="input-block">
                    <select type="text" class="form-control" (change)="saveTimeZone()" [(ngModel)]="userSettingData.utcId">
                      <option value="0">---------select---------</option>
                      <option *ngFor="let data of timeZoneList" [value]="data.id">{{data.name}}</option>
                    </select>
                    <div class="arrow"></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>-->
    <div class="settings-section bottom-block">
      <div class="settings-title">
        <span>Preferencias<a class="edit" *ngIf="!isEditb" (click)="editClickbtm()">Editar</a></span>
      </div>
      <div class="row">
        <div class="col-md-12">
          <div class="btm-edit">
            <div class="edit-block" [ngClass]="{'only-btm': isEditb == true}">
              <div class="row">
                <div class="col-md-6">
                  <div class="settings-blocks">
                    <div class="desc-block">
                      <span class="desc-top">Empresa</span>
                    </div>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="settings-blocks">
                    <div class="desc-block">
                      <input type="text" class="text-only" [disabled]="!isEditb" [ngClass]="{'form-control': isEditb == true}"
                             placeholder="XYZ" [(ngModel)]="userSettingData.businessName" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="edit-block" [ngClass]="{'only-btm': isEditb == true}">
              <div class="row">
                <div class="col-md-6">
                  <div class="settings-blocks">
                    <div class="desc-block">
                      <span class="desc-top">Nombre Usuario Primario</span>
                    </div>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="settings-blocks">
                    <div class="desc-block">
                      <input type="text" class="text-only half" [disabled]="!isEditb" [ngClass]="{'form-control': isEditb == true}"
                             placeholder="John" [(ngModel)]="userSettingData.firstName" />
                      <input type="text" class="text-only half" [disabled]="!isEditb" [ngClass]="{'form-control': isEditb == true}"
                             placeholder="Dante" [(ngModel)]="userSettingData.lastName" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="edit-block" [ngClass]="{'only-btm': isEditb == true}">
              <div class="row">
                <div class="col-md-6">
                  <div class="settings-blocks">
                    <div class="desc-block">
                      <span class="desc-top">Correo Electr&#243;nico de Usuario Primario</span>
                    </div>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="settings-blocks">
                    <div class="desc-block">
                      <input type="text" class="text-only" disabled [ngClass]="{'form-control': isEditb == true}"
                             placeholder="abc@gmail.com" [(ngModel)]="userSettingData.emailId"
                             pattern="[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,3}$" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="edit-block">
              <div class="row">
                <div class="col-md-6">
                  <div class="settings-blocks">
                    <div class="desc-block">
                      <span class="desc-top">CASCO API Key</span>
                    </div>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="settings-blocks">
                    <div class="desc-block">
                      <div class="ext-block" *ngIf="!showGenerateBtn">
                        <span class="key">{{tenantAPIKey}}</span>
                        <a class="copy-text" [cdkCopyToClipboard]="tenantAPIKey" title="copiar al portapapeles"></a>                        
                      </div>
                      <button *ngIf="showGenerateBtn" class="btn comm-btn" (click)="generateAdjustifyAPIKey()">Generate API Key</button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!--<div class="edit-block" [ngClass]="{'only-btm': isEditb == true}">
              <div class="row">
                <div class="col-md-6">
                  <div class="settings-blocks">
                    <div class="desc-block">
                      <span class="desc-top">Time Zone</span>
                    </div>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="settings-blocks">
                    <div class="desc-block">
                      <select type="text" class="text-only" [disabled]="!isEditb" [ngClass]="{'form-control': isEditb == true}" [(ngModel)]="userSettingData.utcId">
                        <option value="0">---------select---------</option>
                        <option *ngFor="let data of timeZoneList" [value]="data.id">{{data.name}}</option>
                      </select>
                      <div class="arrow"></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>-->
          </div>
        </div>
      </div>
      <div>
        <validation-summary [validationErrors]="validationMessagesPref"></validation-summary>
      </div>
      <div class="button-block">
        <button class="btn comm-btn" *ngIf="isEditb" (click)="updateUserSettingData()">Guardar</button>
        <button class="btn comm-btn cancel" *ngIf="isEditb" (click)="prefCancelClick()">Cancelar</button>
      </div>
    </div>
    <!--<div class="settings-section billing-add">
        <div class="settings-title">
            <span>Informacion de Cobranza<a class="edit" *ngIf="!isEditBilling" (click)="editBillingForm()">Editar</a></span>
        </div>
        <div class="btm-edit authentication-container">
            <span class="address-desc" *ngIf = "!isEditBilling">{{billingDetails.combinedAddress}}</span>
            <div class="billing-desc-add" *ngIf = "isEditBilling">
                <div class="row">
                    <div class="col-md-12">
                        <div class="input-block">
                            <label class="input-label">Direccion Linea 1*</label>
                            <input type="text" class="form-control" placeholder="Direccion Linea 1" [(ngModel)]="billingDetails.addressLine1"/>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-12">
                        <div class="input-block">
                            <label class="input-label">Direccion Linea 2</label>
                            <input type="text" class="form-control" placeholder="Direccion Linea 2" [(ngModel)]="billingDetails.addressLine2"/>
                        </div>
                    </div>
                </div>
                <div class="row" *ngIf = "!isCountryUSSelected">
                  <div class="col-md-12">
                      <div class="input-block">
                          <label class="input-label">Direccion Linea 3</label>
                          <input type="text" class="form-control" placeholder="Direccion Linea 3" [(ngModel)]="billingDetails.addressLine3"/>
                      </div>
                  </div>
                </div>
                <div class="row" *ngIf = "isCountryUSSelected">
                    <div class="col-md-6">
                        <div class="input-block">
                            <label class="input-label">Ciudad*</label>
                            <input type="text" class="form-control" placeholder="Ciudad" [(ngModel)]="billingDetails.city"/>
                        </div>
                    </div>
                    <div class="col-md-6" *ngIf = "isCountryUSSelected">
                        <div class="input-block">
                            <label class="input-label">Departamento*</label>
                            <select class="form-control" [(value)]="billingDetails.stateId" [(ngModel)]="billingDetails.stateId">
                              <option *ngFor="let state of stateList" [value]="state.stateId">{{state?.stateName}}</option>
                            </select>
                            <div class="arrow"></div>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-6" *ngIf = "isCountryUSSelected">
                        <div class="input-block">
                            <label class="input-label">Codigo Postal*</label>
                            <input type="text" class="form-control" placeholder="32836" [(ngModel)]="billingDetails.postalCode"/>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="input-block">
                            <label class="input-label">Pais*</label>
                            <select class="form-control" [(value)]="billingDetails.countryId" [(ngModel)]="billingDetails.countryId" (change) = 'getStates(billingDetails.countryId)'>                                
                                <option *ngFor="let country of countryList" [value]="country.id">{{country?.country}}</option>
                            </select>
                            <div class="arrow"></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div>
          <validation-summary [validationErrors]="validationMessagesBilling"></validation-summary>
        </div>
        <div class="button-block" *ngIf = "isEditBilling">
            <button class="btn comm-btn" (click) = "SaveBillingDetail()">Guardar</button>
            <button class="btn comm-btn cancel" (click) = "CancelBillingDetail()">Cancelar</button>
        </div>
    </div>-->
  </div>
</div>

<div class="modal fade edit-pop" id="edit-data" data-backdrop="static" aria-hidden="true" role="dialog">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header pop-title">
        <button type="button" class="close" data-dismiss="modal">
          <img src="../../../../assets/images/modal-close-img.svg" />
        </button>
        <h4 class="modal-title">
          <span>Direccion Web</span>
        </h4>
      </div>
      <div class="modal-body">
        <div class="pop-info-section">
          <div class="input-block">
            <input type="text" class="form-control" placeholder="ABCCompany" [(ngModel)]="domainName">
            <button class="btn">Adjustify.co</button>
            <img src="../../../../assets/images/email-circle-img.svg" />
            <!--<img src="../../../../assets/images/wrong-img.svg" />-->
          </div>
          <div>
            <validation-summary [validationErrors]="validationMessages"></validation-summary>
          </div>
          <div class="button-block">
            <button class="btn comm-btn" type="button" (click)="saveDomainName()">Guardar</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="modal fade edit-zone" id="edit-zone" data-backdrop="static" aria-hidden="true" role="dialog">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header pop-title">
        <button type="button" class="close" data-dismiss="modal">
          <img src="../../../../assets/images/modal-close-img.svg" />
        </button>
        <h4 class="modal-title">
          <span>Zona horaria</span>
        </h4>
      </div>
      <div class="modal-body">
        <div class="pop-info-section">
          <div class="input-block">
            <select type="text" class="form-control" [(ngModel)]="timeZoneId">
              <option value="0">---select---</option>
              <option *ngFor="let data of timeZoneList" [value]="data.id">{{data.name}}</option>
            </select>
            <img src="../../../../assets/images/email-circle-img.svg" />
          </div>
          <div>
            <validation-summary [validationErrors]="validationMessages"></validation-summary>
          </div>
          <div class="button-block">
            <button class="btn comm-btn" type="button" (click)="saveTimeZone()">Guardar</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
