import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { SharedDataService } from '../../../services/sharedData.service';
import { SettingService } from '.././../../api-services/setting.service';
import { iUserSetting, iDropDown, iUpdateSetting, iCountryList, iBillingDetails } from '../../../models/setting.model';
import { ToastrService } from 'ngx-toastr';
import { ClaimCaseService } from '../../../api-services/claim-case.service';
import { iStateList } from '../../../models/claimDetail.model';
declare var $: any;

@Component({
  selector: 'app-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.css']
})
export class SettingsComponent implements OnInit {

  constructor(private router: Router,
    private service: SettingService,
    private sharedService: SharedDataService,
    private claimService: ClaimCaseService,
    private toastr: ToastrService) { }

  userSettingData?: iUserSetting = {};
  billingDetails?: iBillingDetails = {};
  timeZoneList?: iDropDown[] = [];
  validationMessages: any;
  validationMessagesPref: any;
  validationMessagesBilling: any;
  userId?: number;
  updateSetting?: iUpdateSetting = {};
  message?: string;
  domainName?: string;
  timeZoneId?: number;
  isEdit?: boolean = false;
  isRight?: boolean;
  isEditb?: boolean = false;
  isEditBilling?: boolean = false;
  showCheck?: boolean = false;
  sameDomainName?: boolean = true;
  defaultDomainName?: string;
  showGenerateBtn?: boolean = true;
  tenantId?: number;
  tenantAPIKey?: string;
  countryList?: iCountryList[] = [];
  stateList?: iStateList[] = [];
  isCountryUSSelected: boolean = false;

  ngOnInit(): void {
    this.userId = this.sharedService.getValue("accountId");
    this.tenantId = this.sharedService.getValue("tenantId");
    this.getTimeZoneList();
    this.getUserSettingData();
    this.getAdjustifyAPIKey();
    this.getCountryList();    
  }

  refreshMethod() {
    this.validationMessages = [];
    this.validationMessagesPref = [];
    this.validationMessagesBilling = [];
    this.userId = this.sharedService.getValue("accountId");
    this.tenantId = this.sharedService.getValue("tenantId");
    this.getTimeZoneList();
    this.getUserSettingData();
    this.getAdjustifyAPIKey();
    this.getCountryList();
    this.isEditb = false;
    this.isEdit = false;
    this.isEditBilling = false;
  }

  getUserSettingData() {
    this.service.getUserSettingsData(this.userId).subscribe(result => {
      if (result != null) {
        this.userSettingData = <iUserSetting>result;
        this.domainName = this.userSettingData.domainName;
        this.timeZoneId = this.userSettingData.utcId;
        this.defaultDomainName = 'https://' + this.userSettingData.domainName + '.Adjustify.co';
      }
    }, error => {
      this.populateApiErrorMessages(error.message);
    });
  }

  getTenantBillingDetails()
  {
    this.service.getTenantBillingDetails(this.tenantId).subscribe(result => {
      if(result != null)
      {
        this.billingDetails = <iBillingDetails>result;
        if(this.billingDetails.countryId !== null)
        {                    
          this.defineAddressElements(this.billingDetails.countryId);
        }
        else {
          this.billingDetails.countryId = this.countryList.length == 0 ? 1 : this.countryList[0].id;
        }
      }
    }, error => {
      this.populateApiErrorMessages(error.message);
    });
  }

  getTimeZoneList() {
    this.service.getUTCList().subscribe(result => {
      if (result != null) {
        this.timeZoneList = <iDropDown[]>result;
      }
    }, error => {
      this.populateApiErrorMessages(error.message);
    });
  }

  saveDomainName() {
    this.validationMessages = [];

    this.domainValidateMessages();

    if (this.validationMessages.length === 0) {
      this.updateSetting.tenantId = this.userSettingData.tenantId;
      this.updateSetting.domainName = this.domainName.toLowerCase();

      this.service.updateDomainName(this.updateSetting).subscribe(result => {
        if (result != null) {
          this.message = <string>result;

          if (this.message != "success") {
            //this.domainName = this.userSettingData.domainName;
            this.populateApiErrorMessages(this.message);
          }
          else {
            this.getUserSettingData();
            this.isEdit = false;
            this.isRight = null;
            $("#edit-data").modal("hide");
          }
        }
      }, error => {
        this.populateApiErrorMessages(error.message);
      });
    }
  }

  saveTimeZone() {
    this.validationMessages = [];

    this.timezoneValidateMessages();

    if (this.validationMessages.length === 0) {
      this.updateSetting.tenantId = this.userSettingData.tenantId;
      this.updateSetting.timeZoneId = this.userSettingData.utcId;

      this.service.updateTimeZone(this.updateSetting).subscribe(result => {
        if (result != null) {
          this.getUserSettingData();
          $("#edit-zone").modal("hide");
        }
      }, error => {
        this.populateApiErrorMessages(error.message);
      });
    }
  }

  checkSameDomainName() {
    this.isRight = null;
    this.sameDomainName = true;
    if (this.userSettingData.domainName == this.domainName)
      this.sameDomainName = true;
    else
      this.sameDomainName = false;
  }

  domainValidateMessages() {

    if (this.domainName === undefined || this.domainName === null || this.domainName === '') {
      this.validationMessages.push('Domain name is required.');
    }
  }

  timezoneValidateMessages() {

    if (this.timeZoneId === undefined || this.timeZoneId === null || this.timeZoneId === 0) {
      this.validationMessages.push('Time-Zone selection is required.');
    }
  }

  populateApiErrorMessages(message: string) {
    this.validationMessages.push(message);
  }

  editClick() {
    this.isEdit = true;
  }

  editClickbtm() {
    this.isEditb = true;
  }

  editBillingForm(){
    this.isEditBilling = true;
  }

  checkSpecialCharacter(event) {
    var regex = new RegExp("^[a-zA-Z0-9]+$");
    var key = String.fromCharCode(!event.charCode ? event.which : event.charCode);
    if (!regex.test(key)) {
      event.preventDefault();
      return false;
    }
  }

  onPaste(event: ClipboardEvent) {
    var regex = new RegExp("^[a-zA-Z0-9]+$");
    if (!regex.test(event.clipboardData.getData('text'))) {
      event.preventDefault();
      return false;
    }
  }

  checkDomainAvailability() {
    this.showCheck = true;
    this.validationMessages = [];

    this.domainValidateMessages();
    if (this.validationMessages.length === 0) {
      this.service.checkDomainAvailability(this.domainName.toLocaleLowerCase(), this.userSettingData.tenantId).subscribe(result => {
        if (result != null) {
          this.checkSameDomainName();
          this.showCheck = false;
          if (result)
            this.isRight = true;
          else
            this.isRight = false;
        }
      }, error => {
        this.populateApiErrorMessages(error.message);
      });
    }
  }

  cancelClick() {
    this.isEdit = false;
    this.isRight = null;
    this.domainName = this.userSettingData.domainName;
  }

  prefCancelClick() {
    this.isEditb = false;
    this.getUserSettingData();
    this.validationMessagesPref = [];
  }

  CancelBillingDetail(){
    this.isEditBilling = false;
    this.getCountryList();
    this.getTenantBillingDetails();
    this.validationMessagesBilling = [];
  }

  isValidEmail(email) {
    var re = /[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,3}$/;
    return re.test(email);
  }

  populateValidateMessages() {

    if (this.userSettingData.businessName === undefined || this.userSettingData.businessName === null || this.userSettingData.businessName.trim() === '') {
      this.validationMessagesPref.push('Nombre de la empresa requerido.');
    }

    if (this.userSettingData.firstName === undefined || this.userSettingData.firstName === null || this.userSettingData.firstName.trim() === '') {
      this.validationMessagesPref.push('Primer nombre requerido.');
    }

    if (this.userSettingData.lastName === undefined || this.userSettingData.lastName === null || this.userSettingData.lastName.trim() === '') {
      this.validationMessagesPref.push('Apellido requerido.');
    }

    if (this.userSettingData.emailId === undefined || this.userSettingData.emailId === null || this.userSettingData.emailId.trim() === '') {
      this.validationMessagesPref.push('Correo electrónico requerido.');
    }
    else if (!this.isValidEmail(this.userSettingData.emailId)) {
      this.validationMessagesPref.push('Por favor ingrese un correo electrónico válido.');
    }

    if (this.userSettingData.utcId === undefined || this.userSettingData.utcId === null || this.userSettingData.utcId === 0) {
      this.validationMessagesPref.push('Time zone selection is required.');
    }
  }

  populateValidateMessagesForBilling(){
    if (this.billingDetails.addressLine1 === undefined || this.billingDetails.addressLine1 === null || this.billingDetails.addressLine1.trim() === '') {
      this.validationMessagesBilling.push('Address Line 1 is required.');
    }

    if (this.isCountryUSSelected) {
      if (
        this.billingDetails.city === undefined ||
        this.billingDetails.city === null ||
        this.billingDetails.city.trim() === ""
      ) {
        this.validationMessagesBilling.push("City is required.");
      }

      if (
        this.billingDetails.postalCode === undefined ||
        this.billingDetails.postalCode === null ||
        this.billingDetails.postalCode.trim() === ""
      ) {
        this.validationMessagesBilling.push("Zip code is required.");
      } else if (this.billingDetails.postalCode.trim().length > 0) {
        if (!this.sharedService.isValidZipCode(this.billingDetails.postalCode)) {
          this.validationMessagesBilling.push('Ingrese un código postal válido.');
        }
      }

      if (
        this.billingDetails.stateId === undefined ||
        this.billingDetails.stateId === null
      ) {
        this.validationMessagesBilling.push("State is required.");
      }
    }
    if (this.billingDetails.countryId === undefined || this.billingDetails.countryId === null) {
      this.validationMessagesBilling.push('Country is required.');
    }
  }
  updateUserSettingData() {
    this.validationMessagesPref = [];

    this.populateValidateMessages();

    if (this.validationMessagesPref.length === 0) {
      this.service.updateUserSettingData(this.userSettingData).subscribe(result => {
        if (result != null) {
          this.successmsg();
          this.isEditb = false
          this.getUserSettingData();
        }
      }, error => {
        this.populateApiErrorMessages(error.message);
      });
    }
  }

  SaveBillingDetail(){
    this.validationMessagesBilling = [];

    this.populateValidateMessagesForBilling();

    if (this.validationMessagesBilling.length === 0){
      if (this.isCountryUSSelected)
      {
        this.billingDetails.addressLine3 = null;
      } else {
        this.billingDetails.city = null;
        this.billingDetails.stateId = null;
        this.billingDetails.postalCode = null;
      } 
        
      this.service.updateTenantBillingAddress(this.billingDetails).subscribe(result => {
        if(result != null)
        {
          this.successmsg();
          this.isEditBilling = false;
          this.getTenantBillingDetails();
        }
      });
    }
  }

  successmsg() {
      this.toastr.success("Data updated successfully", 'Exito')
  }

  getAdjustifyAPIKey() {
    this.service.getAdjustifyAPIKey(this.tenantId).subscribe(result => {
      if (result != null) {
        this.tenantAPIKey = <string>result;

        if (this.tenantAPIKey == null || this.tenantAPIKey == undefined)
          this.showGenerateBtn = true;
        else
          this.showGenerateBtn = false;
      }
    }, error => {
      this.populateApiErrorMessages(error.message);
    });
  }

  generateAdjustifyAPIKey() {
    this.service.generateAdjustifyAPIKey(this.tenantId).subscribe(result => {
      if (result != null) {
        if (result)
          this.getAdjustifyAPIKey();
      }
    }, error => {
      this.populateApiErrorMessages(error.message);
    });
  }

  getCountryList() {
    this.service.getCountryList().subscribe(result => {
      if (result != null) {
        this.countryList = <iCountryList[]>result;
        if (this.countryList != null && this.countryList.length > 0) {          
          this.billingDetails.countryId = this.countryList[0].id;            
          this.getStates(this.billingDetails.countryId);
          this.getTenantBillingDetails();
        }
      }
    }, error => {
      this.populateApiErrorMessages(error.message);
    });
  }

  getStates(countryId: number) {
    this.defineAddressElements(countryId);
    this.claimService.getStates(countryId).subscribe(result => {
      this.stateList = <iStateList[]>result;
      if(this.stateList != null && this.stateList.length > 0)
        this.billingDetails.stateId = this.stateList[0].stateId;
      else 
        this.billingDetails.stateId = null;
    });
  }

  defineAddressElements(countryId: number)
  {      
      this.isCountryUSSelected = this.countryList.filter(c => c.id == countryId)[0].countryCode === "US";
  }
}
