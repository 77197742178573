import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'validation-summary',
  templateUrl: './validation-summary.component.html',
  styleUrls: ['./validation-summary.component.css']
})
export class ValidationSummaryComponent implements OnInit {
  @Input() validationErrors: any;

  constructor() { }

  ngOnInit() {
  }
}
