import { Component, OnInit, ViewChild, Inject } from '@angular/core';
import { Router, Data } from '@angular/router';
import { ClaimCase } from '../../models/claimCase.model';
import { ClaimCaseService } from './../../api-services/claim-case.service';
import { SettingService } from './../../api-services/setting.service';
import { SharedDataService } from '../../services/sharedData.service';
import { iStateList, iLossTypeList, iMeetingData, iClaimDetailList } from '../../models/claimDetail.model';
import { NgForm } from '@angular/forms';
import { iCountryList } from '../../models/setting.model';
import { HttpClient, HttpHeaders, HttpResponse, HttpEventType, HttpRequest } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { saveAs } from 'file-saver';
import { DOCUMENT, DatePipe } from '@angular/common';
import { PublicFormValidationMessage } from 'src/app/models/contactus-detail';
declare var $: any;

@Component({
  selector: 'app-add-claim',
  templateUrl: './add-claim.component.html',
  styleUrls: ['./add-claim.component.css']
})
export class AddClaimComponent implements OnInit {

  selectedTicket: any;
  isTicketSelected = false;
  @ViewChild('addBacklogForm')
  addBacklogForm: NgForm;
  validationMessages: any;
  claim: ClaimCase = {};
  public maxDate = new Date();
  states = [];
  lossTypes = [];
  public selectedDate = new Date();
  public startAt = new Date(2020, 4, 22, 10, 30, 30);
  // Min moment: February 12 2018, 10:30
  public minDate = new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate());
  selectedTime = 'None';
  invitationDate = new Date();
  lossDate = new Date();
  pageName?: string;
  pageType?: string;
  isEdit = false;
  caseId?: number;
  disableInvite?: boolean = false;
  sendInvitationAgain?: boolean = false;
  message: string = "";
  oldScheduledDate: string;
  oldScheduleTime: string;
  oldEmailId: string;
  typeId = 0;
  customerEmailId: string;
  countryList?: iCountryList[] = [];
  selectedCountry?: iCountryList = {};
  placeHolder: string = "";
  //str: string = "";
  showConf?: boolean = true;
  meetingData?: iMeetingData = {};
  calendarType?: string = "";
  showLossTypeDropDown?: boolean = false;
  isTypeSelected = false;
  selectedODOOCustomer: any;
  customerTikcetList: any[] = [];
  claimDetailList: iClaimDetailList[] = [];
  public myFilter = (d: Date): boolean => {
    const day = d.getDay();
    // Prevent Saturday and Sunday from being selected.
    return day !== 0 && day !== 6;
  }

  constructor(private router: Router,
    private service: ClaimCaseService,
    private sharedService: SharedDataService,
    private httpClient: HttpClient,
    @Inject(DOCUMENT) private document: Document,
    private datepipe: DatePipe,
    private settingService: SettingService) { }

  changeType() {
    this.validationMessages = [];
    this.setClaimObject();
    this.isTicketSelected = false;
    this.customerTikcetList = [];
    this.selectedTicket = null;
    this.validationMessages = [];
    if (this.typeId == 2) {
      this.isTypeSelected = false;

    } else {
      this.isTypeSelected = true;
    }
  }
  ngOnInit(): void {
    this.getAllClaimList();
    if (document.querySelector(".client_number")) {
      document.querySelector(".client_number").addEventListener("keypress", function (evt: any) {
        if (evt.which != 8 && evt.which != 0 && evt.which < 48 || evt.which > 57) {
          evt.preventDefault();
        }
      });
    }

    this.pageType = this.sharedService.getValue("pageType");
    this.getAllStates();
    this.getCountryList();
    this.getAdjusterCalendarPreference();

    if (this.pageType == "AddClaim") {
      this.getLossTypes();
      this.setClaimObject();
      this.pageName = "Agregar Nuevo Registro";
      this.placeHolder = "Phone Number";
      this.isEdit = false;
    }
    else {
      this.isEdit = true;
      this.pageName = "Editar Caso";
      this.caseId = this.sharedService.getValue("caseId");
      this.getClaimDetails();
      this.isTypeSelected = true;
      this.typeId = 1;
    }

  }

  setClaimObject() {
    this.claim = {
      claimNumber: '',
      policyNumber: '',
      uniqueNumber: null,
      firstName: '',
      lastName: '',
      claimCaseId: 0,
      tenantId: this.sharedService.getValue("tenantId"),
      phoneNumber: '',
      email: '',
      lossType: '',
      // odooCustomerId: 0,
      //odooTicketId: 0,
      createdBy: this.sharedService.getValue("accountId"),
      lossTypeId: 0,
      stateId: 0,
      lossDate: '',
      addressLine1: '',
      addressLine2: '',
      city: '',
      zipCode: '',
      sendInvitation: false,
      callScheduleTime: 'None',
      confStatusId: 0,
      inviteAgainForPendingInvitation: false,
      isdCountryId: 1,

    };
  }

  public getClaimDetails() {
    this.service.getClaimDetails(this.caseId).subscribe(result => {
      this.claim = <ClaimCase>result;
      this.lossDate = new Date(this.claim.dateLossDate);
      this.claim.createdDate = this.sharedService.ConvertUTCTimeToLocalTime(this.claim.createdDate);
      this.selectedCountry.id = this.claim.isdCountryId;
      this.selectedCountry.countryCode = this.claim.countryCode;
      this.selectedCountry.bothCountryCode = this.claim.bothCountryCode;
      this.selectedCountry.country = this.claim.country;
      this.selectedCountry.countryISDCode = this.claim.countryISDCode;
      if (this.claim.sendInvitation) {
        this.showConf = false;
      }
      else {
        this.showConf = true;
      }

      if (this.claim.lossTypeId == null || this.claim.lossTypeId == undefined) {
        this.showLossTypeDropDown = false;
      }
      else {
        this.getAllLossTypeEditClaim(this.claim.lossTypeId);
        this.showLossTypeDropDown = true;
      }

      //if (this.claim.isdCountryId == 0) {
      //  this.selectedCountry.id = 0;
      //  this.selectedCountry.countryCode = "None";
      //  this.selectedCountry.bothCountryCode = "None";
      //}
      //else {
      //  this.countryList.forEach(obj => {
      //    if (obj.id == this.claim.isdCountryId) {
      //      this.selectedCountry = obj;
      //    }
      //  });        
      //}

      //Take invitation date and time
      this.oldScheduledDate = this.sharedService.ConvertUTCTimeToLocalTime(this.claim.invitationDate).toDateString();
      this.oldScheduleTime = this.claim.callScheduleTime;
      this.oldEmailId = this.claim.email;

      if (this.claim.stateId == undefined || this.claim.stateId == null)
        this.claim.stateId = 0;

      if (this.claim.sendInvitation == true) {
        this.invitationDate = new Date(this.claim.dateInvitationDate);
        this.disableInvite = true;
      }
      else {
        this.disableInvite = false;
        this.claim.callScheduleTime = "None";
      }

    }, error => {
      this.populateApiErrorMessages(error.message);
    });
  }

  onSubmit() {
    this.validationMessages = [];

    this.populateValidateMessages();

    if (this.validationMessages.length === 0) {
      this.addClaim();
    }
  }

  populateValidateMessages() {
    if (this.claim.claimNumber && this.claim.claimNumber.trim().length === 0) {
      this.validationMessages.push('Número de Ticket.');
    }

    if (this.claim.policyNumber && this.claim.policyNumber.length > 0 && this.claim.policyNumber.trim().length === 0) {
      this.validationMessages.push('Ingrese un Número de Ticket válido.');
    }

    if ((this.claim.uniqueNumber != null || this.claim.uniqueNumber != undefined || this.claim.uniqueNumber != null) && (this.claim.uniqueNumber.toString().trim().length > 0)) {
      if (!this.isDigitCheck(this.claim.uniqueNumber)) {
        this.validationMessages.push('Ingrese un número único válido.');
      }
    }

    if (!this.showLossTypeDropDown) {
      if ((this.claim.lossType != undefined || this.claim.lossType != null) && this.claim.lossType.trim().length == 0) {
        this.validationMessages.push('Ingrese el tipo de caso.');
      }
    }
    else {
      if (this.claim.lossTypeId == 0 || this.claim.lossTypeId == null || this.claim.lossTypeId == undefined) {
        this.validationMessages.push('Por favor seleccione tipo de caso.');
      }
    }

    if (this.claim.firstName && this.claim.firstName.trim().length === 0) {
      this.validationMessages.push('Primer nombre requerido.');
    }

    if (this.claim.lastName && this.claim.lastName.trim().length === 0) {
      this.validationMessages.push('Apellido requerido.');
    }

    if (this.claim.addressLine1 && this.claim.addressLine1.length > 0 && this.claim.addressLine1.trim().length === 0) {
      this.validationMessages.push('Ingrese la primera línea de dirección válida 1.');
    }

    if (this.claim.addressLine2 && this.claim.addressLine2.length > 0 && this.claim.addressLine2.trim().length === 0) {
      this.validationMessages.push('Ingrese la segunda línea de dirección válida 2.');
    }

    if (this.claim.city && this.claim.city.length > 0 && this.claim.city.trim().length === 0) {
      this.validationMessages.push('Ingrese una ciudad válida.');
    }

    if (this.claim.zipCode && this.claim.zipCode.length > 0 && this.claim.zipCode.trim().length === 0) {
      this.validationMessages.push('Ingrese un código postal válido.');
    }
    else if (this.claim.zipCode && this.claim.zipCode.trim().length > 0) {
      if (!this.sharedService.isValidZipCode(this.claim.zipCode)) {
        this.validationMessages.push('Ingrese un código postal válido.');
      }
    }
    if (this.selectedCountry) {
      if (this.selectedCountry.id === 0 || this.selectedCountry.id === null || this.selectedCountry.id === undefined) {
        this.validationMessages.push('Por favor seleccione país.');
      }
    } else {
      this.validationMessages.push('Por favor seleccione país.');
    }


    if (this.claim.phoneNumber && this.claim.phoneNumber.length > 0 && this.claim.phoneNumber.trim().length === 0) {
      this.validationMessages.push('Ingrese un número de teléfono válido.');
    }
    else if (this.claim.phoneNumber && this.claim.phoneNumber.trim().length > 0) {
      if (!this.sharedService.isValidPhoneNo(this.claim.phoneNumber)) {
        this.validationMessages.push('Ingrese un número de teléfono válido.');
      }
    }


    if (this.claim.email && this.claim.email.trim().length === 0) {
      this.validationMessages.push('Correo electrónico requerido.');
    }
    else if (!this.isValidEmail(this.claim.email)) {
      this.validationMessages.push('Por favor ingrese un correo electrónico válido.');
    }

    if (this.lossDate == null) {
      this.validationMessages.push('Seleccione una fecha válida.');
    }
    else if (new Date(this.lossDate.getFullYear(), this.lossDate.getMonth(), this.lossDate.getDate()) > new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate())) {
      this.validationMessages.push('La fecha del caso no puede ser una fecha futura.');
    }

    if (this.claim.sendInvitation && (this.claim.conferenceId === null || this.claim.conferenceId === undefined)) {
      if (this.invitationDate == null) {
        this.validationMessages.push('Seleccione la fecha de la invitación.');
        return;
      }
      else if (this.invitationDate < new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate())) {
        this.validationMessages.push('La fecha de la invitación no puede ser en el pasado.');
        return;
      }
      else if (this.invitationDate.getDay() == 0 || this.invitationDate.getDay() == 6) {
        this.validationMessages.push('La fecha de la invitación no puede ser durante el fin de semana.');
        return;
      }

      if (this.claim.callScheduleTime && this.claim.callScheduleTime == "None") {
        this.validationMessages.push('Por favor seleccione una hora válida.');
        return;
      }

      var dateToCheck = new Date();
      var tempInviDate = this.invitationDate;
      var dHour = dateToCheck.getHours();
      var dMin = dateToCheck.getMinutes();

      var hours = Number(this.claim.callScheduleTime.split(":")[0]);
      var minutes = Number(this.claim.callScheduleTime.split(":")[1]);
      tempInviDate.setHours(hours, minutes, 0, 0);
      dateToCheck.setHours(dHour, dMin, 0, 0);

      //if (this.invitationDate <= dateToCheck && hours <= dateToCheck.getHours() && minutes <= dateToCheck.getMinutes()) {
      //  this.validationMessages.push("Lo siento, no puede seleccionar ese horario ya que no es válido. Por favor seleccione una fecha u hora futura válida");
      //}

      if (tempInviDate < dateToCheck) {
        this.validationMessages.push("Lo siento, no puede seleccionar ese horario ya que no es válido. Por favor seleccione una fecha u hora futura válida");
      }
    }
  }

  addClaim() {

    this.validationMessages = [];

    if (this.claim.sendInvitation) {
      var hours = Number(this.claim.callScheduleTime.split(":")[0]);
      var minutes = Number(this.claim.callScheduleTime.split(":")[1]);
      this.invitationDate.setHours(hours);
      this.invitationDate.setMinutes(minutes);
      this.invitationDate.setSeconds(0);
      this.claim.invitationDate = new Date(this.invitationDate.toUTCString());
      this.claim.calendarType = this.calendarType;
      //this.claim.invitationDate = this.sharedService.getDate(this.invitationDate);
    }

    this.claim.lossDate = this.sharedService.getDate(this.lossDate);

    this.claim.createdDate = new Date(new Date().toUTCString());


    if (this.selectedCountry) {
      this.claim.countryISDCode = this.selectedCountry.countryISDCode;
      this.claim.isdCountryId = this.selectedCountry.id;
    }
    if (!this.showLossTypeDropDown)
      this.claim.lossTypeId = null;
    else
      this.claim.lossType = null;

    if (!(this.claim.odooTicketId > 0 && this.claim.odooCustomerId > 0)) {
      this.claim.odooTicketId = 0;
      this.claim.odooCustomerId = 0;
    }
    this.service.insertOrUpdateClaimData(this.claim).subscribe((data: any) => {
      if (data !== null) {
        this.message = <string>data;
        if (this.message != "success") {
          this.populateApiErrorMessages(this.message);
        }
        else {
          if (this.claim.sendInvitation && (this.claim.conferenceId == undefined || this.claim.conferenceId == null)) {

            if (this.calendarType != null || this.calendarType == "adjustify") {
              this.meetingData.adjusterId = this.sharedService.getValue("accountId");
              this.meetingData.toEmailId = this.claim.email;
              this.meetingData.customerName = this.claim.firstName + " " + this.claim.lastName;
              this.meetingData.startDateTime = this.claim.invitationDate;
              this.meetingData.localeDateOffset = this.sharedService.getTimezonOffset().toString();
              this.meetingData.calendarType = this.calendarType;

              if (this.calendarType == "outlook")
                this.downloadOutlookMeetingFile(this.meetingData);
              else if (this.calendarType == "google")
                this.createGoogleCalendarMeetingLink(this.meetingData);
            }
          }
          this.router.navigate(['/backlog']);
        }
      }
    }, error => {
      this.populateApiErrorMessages(error.error.message);
    });
  }

  openPopup() {

    this.validationMessages = [];

    this.populateValidateMessages();

    var previouslySelectedDate = new Date(this.oldScheduledDate);

    if (this.validationMessages.length === 0) {
      if (this.oldScheduleTime != this.claim.callScheduleTime || this.oldEmailId != this.claim.email ||
        previouslySelectedDate.getFullYear() != this.invitationDate.getFullYear() || previouslySelectedDate.getMonth() != this.invitationDate.getMonth() || previouslySelectedDate.getDate() != this.invitationDate.getDate()) {
        $("#invite-data").modal("show");
      }
      else {
        this.addClaim();
      }
    }
  }

  saveAndInviteAgain(value) {
    if (value != null) {
      this.claim.inviteAgainForPendingInvitation = value;
      $("#invite-data").modal("hide");
      this.addClaim();
    }
  }

  isDigitCheck(uniqueNo) {
    var re = /\d/;
    return re.test(uniqueNo);
  }

  isValidEmail(email) {
    var re = /^[a-zA-Z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/;
    return re.test(email);
  }

  populateApiErrorMessages(message: string) {
    if (!this.validationMessages) {
      this.validationMessages = [];
    }
    this.validationMessages.push(message);
  }

  //Method to fetch all the claims in list
  getAllStates() {
    let countryId = 1;
    this.service.getStates(countryId).subscribe(result => {
      this.states = <iStateList[]>result;
    });
  }

  //Method to fetch all the claims in list
  getLossTypes() {
    var tenantId = this.sharedService.getValue("tenantId");
    this.service.getLossTypes(tenantId).subscribe(result => {
      this.lossTypes = <iLossTypeList[]>result;

      if (this.lossTypes.length == 0)
        this.showLossTypeDropDown = false;
      else
        this.showLossTypeDropDown = true;

    });
  }

  //Method to get all loss type including in-active if in use
  getAllLossTypeEditClaim(lossTypeId?: any) {
    var tenantId = this.sharedService.getValue("tenantId");
    this.service.getAllLossTypeEditClaim(tenantId, lossTypeId).subscribe(result => {
      this.lossTypes = <iLossTypeList[]>result;
    });
  }

  cancelClick() {
    this.router.navigateByUrl('backlog');
  }

  getCountryList() {
    this.settingService.getCountryList().subscribe(result => {
      if (result != null) {
        this.countryList = <iCountryList[]>result;
        if (this.pageType == "AddClaim") {
          this.selectedCountry = this.countryList.find(x => x.isDefaultCountry == true);
          this.claim.countryId = this.countryList.find(x => x.isDefaultCountry == true)?.id;
        }
      }
    }, error => {
      this.populateApiErrorMessages(error.message);
    });
  }

  selectCountry(data?: any) {
    //this.selectedCountry = null;
    this.selectedCountry = data;
  }

  selectCountryNew(data?: any) {
    //this.selectedCountry = null;
    this.selectedCountry = data;
  }

  focusFunction() {
    if (this.pageName == "Agregar Nuevo Registro") {
      this.placeHolder = "";
    }
  }

  restrictSpecialCharacterForPhoneNumber(event) {
    return this.sharedService.restrictSpecialCharacterForGivenRegex(event);
  }

  restrictPhoneNumberCharactersOnPaste(event: ClipboardEvent) {
    return this.sharedService.restrictGivenRegexCharactersOnPaste(event);
  }

  //private method for download
  private getHeader(key, value) {
    var header: HttpHeaders = {} as HttpHeaders;
    header[key] = value;
    return header;
  }

  //private method for download
  private getAuthoriseHeader() {
    var token = this.sharedService.getValue('token');
    if (token) {
      let token = JSON.parse(this.sharedService.getValue('token'));
      if (token) {
        return this.getHeader("Authorization", `Bearer ${token}`);
      }
      return {} as HttpHeaders;
    }
  }

  //Download outlook meeting ics file
  private downloadOutlookMeetingFile(data?: any) {
    this.validationMessages = [];
    const filename = "Adjustify Meeting.ics";
    this.httpClient.post(environment.apiUrl + 'conference/createOutlookMeeting', data, {
      headers: this.getAuthoriseHeader(),
      observe: 'response',
      responseType: 'blob'
    })
      .subscribe(
        data => {
          var contentType = data.headers.get('content-type');
          var blob = new Blob([data.body], { type: contentType });
          saveAs(blob, filename);
        },
        error => {
          this.populateApiErrorMessages(error.message);
        });
  }

  //Create link for google calendar meeting
  private createGoogleCalendarMeetingLink(data?: any) {
    this.service.createGoogleCalendarMeeting(data).subscribe((data: any) => {
      if (data != null) {
        window.open(data, "_blank");
      }
    }, error => {
      this.populateApiErrorMessages(error.message);
    });
  }

  //Method to get adjuster calendar preference
  getAdjusterCalendarPreference() {
    this.service.getAdjusterCalendarPreference(this.sharedService.getValue("accountId")).subscribe(result => {
      this.calendarType = result;
    });
  }

  changeCalendarType(calendar?: any) {
    console.log(calendar);
    this.calendarType = calendar;
  }

  getTicketFromOdoo(request: any) {
    this.validationMessages = [];
    this.getFormError(request);
    if (request.valid) {
      this.service.getTicketFromODOO(request.value.odooTicketId, request.value.odooCustomerId).subscribe(data => {
        if (data && data.error) {
          this.populateApiErrorMessages(data.error);
        }
        else if (data && data.ticketDetail) {
          this.claim.odooTicketId = request.value.odooTicketId;
          this.claim.odooCustomerId = request.value.odooCustomerId;
          let ticketDetail = JSON.parse(data.ticketDetail);
          if (ticketDetail && ticketDetail.length > 0) {
            this.isTypeSelected = true;
            ticketDetail = ticketDetail[0];
            if (data.customerDetail) {
              this.claim.city = data.customerDetail.city == "false" ? "" : data.customerDetail.city.trim();
              this.claim.email = data.customerDetail.email == "false" ? "" : data.customerDetail.email;
              this.claim.phoneNumber = data.customerDetail.mobile == "false" ? "" : data.customerDetail.mobile;
              this.claim.addressLine1 = data.customerDetail.street == "false" ? "" : data.customerDetail.street;
              this.claim.addressLine2 = data.customerDetail.street2 == "false" ? "" : data.customerDetail.street2;
              this.claim.zipCode = data.customerDetail.zip == "false" ? "" : data.customerDetail.zip;
              this.trimData(this.claim);
              if (!(ticketDetail.date_trans == "false")) {
                // this.lossDate = new Date(ticketDetail.date_trans);
              }
              if (!(data.customerDetail.mobile == "false")) {
                let phoneNumber = data.customerDetail.mobile.split("-")
                if (!(phoneNumber.length == 2)) {
                  phoneNumber = data.customerDetail.mobile.split(" ")
                }

                if (phoneNumber.length == 2) {
                  this.selectedCountry = this.countryList.find(x => x.countryISDCode == phoneNumber[0]);
                  this.claim.phoneNumber = phoneNumber.length && phoneNumber.length > 1 ? phoneNumber[1] : "";
                }
              }
              if (!(data.customerDetail.name == "false")) {
                let getFirstAndLastName = data.customerDetail.name.split(" ");
                this.claim.firstName = getFirstAndLastName.length && getFirstAndLastName.length > 0 ? getFirstAndLastName[0] : "";
                this.claim.lastName = getFirstAndLastName.length && getFirstAndLastName.length > 1 ? getFirstAndLastName[1] : "";
              }
              this.claim.countryId = data.customerDetail.country_id ? data.customerDetail.country_id[0] : 0;
            }

          } else {
            this.isTypeSelected = false;
            this.setClaimObject();
            this.claim.odooTicketId = request.value.odooTicketId;
            this.claim.odooCustomerId = request.value.odooCustomerId;
            this.populateApiErrorMessages("Datos no encontrados");
          }
        }
      })
    }
  }

  getTicketFromOdooNew(odooTicketId: any, odooCustomerId: any) {
    this.service.getTicketFromODOO(odooTicketId, odooCustomerId).subscribe(data => {
      if (data && data.error) {
        this.populateApiErrorMessages(data.error);
      }
      else if (data && data.customerDetail) {
        this.claim.odooTicketId = odooTicketId
        this.claim.odooCustomerId = odooCustomerId;
        this.isTypeSelected = true;
        if (data.customerDetail) {
          this.claim.city = data.customerDetail.city == "false" ? "" : data.customerDetail.city;
          this.claim.email = data.customerDetail.email == "false" ? "" : data.customerDetail.email;
          this.claim.phoneNumber = data.customerDetail.mobile == "false" ? "" : data.customerDetail.mobile;
          this.claim.addressLine1 = data.customerDetail.street == "false" ? "" : data.customerDetail.street;
          this.claim.addressLine2 = data.customerDetail.street2 == "false" ? "" : data.customerDetail.street2;
          this.claim.zipCode = data.customerDetail.zip == "false" ? "" : data.customerDetail.zip;
          this.trimData(this.claim);

          if (!(data.customerDetail.mobile == "false")) {
            let phoneNumber = data.customerDetail.mobile.split("-")
            if (!(phoneNumber.length == 2)) {
              phoneNumber = data.customerDetail.mobile.split(" ")
            }

            if (phoneNumber.length == 2) {
              this.selectedCountry = this.countryList.find(x => x.countryISDCode == phoneNumber[0]);
              this.claim.phoneNumber = phoneNumber.length && phoneNumber.length > 1 ? phoneNumber[1] : "";
            }
          }
          if (!(data.customerDetail.name == "false")) {
            let getFirstAndLastName = data.customerDetail.name.split(" ");
            this.claim.firstName = getFirstAndLastName.length && getFirstAndLastName.length > 0 ? getFirstAndLastName[0] : "";
            this.claim.lastName = getFirstAndLastName.length && getFirstAndLastName.length > 1 ? getFirstAndLastName[1] : "";
          }
          this.claim.countryId = data.customerDetail.country_id ? data.customerDetail.country_id[0] : 0;
        } else {
          this.isTypeSelected = false;
          this.setClaimObject();
          this.claim.odooTicketId = odooTicketId;
          this.claim.odooCustomerId = odooCustomerId;
          this.populateApiErrorMessages("Datos no encontrados");
        }
      }
    })
  }

  getFormError(request: any) {
    return Object.keys(request.controls).map(key => {
      const errors = request.controls[key].errors;
      if (errors === null) { return null; }
      if (errors.required) {
        if (!(this.validationMessages.find(x => x == 'Por favor complete todos los campos obligatorios'))) {
          this.validationMessages.push('Por favor complete todos los campos obligatorios');
        }
      }
      else if (errors.email) {
        if (!(this.validationMessages.find(x => x == 'Por favor ingrese una dirreción de correo válida.'))) {
          let message = PublicFormValidationMessage.find(x => x.validationKey == 'email').message
          this.validationMessages.push(message);
        }
      }
      else {
        this.validationMessages.push(`${key} has an unknown error`);
      }
    });
  }

  searchCustomer(searchFormGroup: any) {
    this.validationMessages = [];
    this.getFormError(searchFormGroup);
    if (searchFormGroup.valid) {
      this.isTicketSelected = false;
      this.customerTikcetList = [];
      this.selectedTicket = null;
      this.validationMessages = [];
      this.changeType();
      this.claim.email = searchFormGroup.value.email;
      this.service.getCustomerFromODOO(searchFormGroup.value.email).subscribe(data => {
        let customerExist = JSON.parse(data);
        if (data == null || (customerExist && customerExist.length == 0)) {
          this.populateApiErrorMessages("Cliente no encontrado");
        } else {
          let customer = JSON.parse(data);
          if (customer && customer.length > 0) {
           // this.selectedODOOCustomer = customer[0];
            // let customerId = 0;
            // if (this.selectedODOOCustomer && (this.selectedODOOCustomer.parent_id == false || this.selectedODOOCustomer.parent_id == 'false')) {
            //   customerId = this.selectedODOOCustomer.id;
            // } else {
            //   customerId = this.selectedODOOCustomer.parent_id[0];
            // }
            //  this.selectedODOOCustomer.id = customerId;
            this.getCustomerTicket(customer);
          }
        }
      })
    }

  }

  getCustomerTicket(customerDetail: any) {
    this.customerTikcetList = [];
    if (customerDetail  && customerDetail.length > 0) {
      this.customerTikcetList = customerDetail;
      this.customerTikcetList = this.customerTikcetList.filter((elem) => !this.claimDetailList.find((slot) => elem.ODDOTicketId === slot.odooTicketId));
    }

    if (this.customerTikcetList && this.customerTikcetList.length == 0) {
      this.populateApiErrorMessages("No se encontró ningún boleto");
    }
    // this.service.getCustomerTicketFromODOO(customerId).subscribe(data => {
    //   if (data == null) {
    //     this.populateApiErrorMessages("No se encontró ningún boleto");
    //   } else {
    //     this.customerTikcetList = JSON.parse(data);
    //     this.customerTikcetList = this.customerTikcetList.filter(x => x.helpdesk_ticket_id != false);
    //     this.customerTikcetList = this.customerTikcetList.filter((elem) => !this.claimDetailList.find((slot) => elem.helpdesk_ticket_id[0] === slot.odooTicketId));
    //     if (this.customerTikcetList && this.customerTikcetList.length == 0) {
    //       this.populateApiErrorMessages("No se encontró ningún boleto");
    //     }
    //     //   this.customerTikcetList = this.claimDetailList((elem) => !this.availableSlotList.find((slot) => elem.key == slot.scheduleData));
    //   }
    // })
  }

  selectTicket(ticketDetails) {
    this.isTicketSelected = true;
    this.selectedTicket = ticketDetails;
    this.getTicketFromOdooNew(ticketDetails.ODDOTicketId, ticketDetails.ODOOCustomerId);
  }

  cancel() {
    this.isTicketSelected = false;
    this.customerTikcetList = [];
    this.selectedTicket = null;
    this.validationMessages = [];
    this.claim.email = null;
  }

  trimData(claim: any) {
    this.claim.city = claim.city ? claim.city.trim() : '';
    this.claim.addressLine1 = claim.addressLine1 ? claim.addressLine1.trim() : '';
    this.claim.addressLine2 = claim.addressLine2 ? claim.addressLine2.trim() : '';
    this.claim.zipCode = claim.zipCode ? claim.zipCode.trim() : '';
  }

  getAllClaimList() {
    this.service.getAllClaimList().subscribe(
      (result) => {
        if (result != null) {
          this.claimDetailList = <iClaimDetailList[]>result.filter(x => x.odooTicketId > 0 && x.odooCustomerId > 0);
        }
      },
      (error) => {
        this.populateApiErrorMessages(error.message);
      }
    );
  }
}
