import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { SharedDataService } from '../../../services/sharedData.service';
import { DashboardService } from '.././../../api-services/dashboard.service';

@Component({
  selector: 'app-total-calls',
  templateUrl: './total-calls.component.html',
  styleUrls: ['./total-calls.component.css']
})
export class TotalCallsComponent implements OnInit {

  constructor(private router: Router,
    private service: DashboardService,
    private sharedService: SharedDataService) { }

  adjusterId?: number;
  completedCallsCount?: number;

  ngOnInit(): void {
    this.adjusterId = this.sharedService.getValue("accountId");
    this.getAllCompletedCallsCount();
  }

  //Method to get backlog counts
  getAllCompletedCallsCount() {
    this.service.getAllCompletedCallsCount(this.adjusterId).subscribe(result => {
      if (result != null) {
        this.completedCallsCount = <number>result;
      }
    }, error => {
    });
  }

}
