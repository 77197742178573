<div class="authentication-container">
  <div class="container">
    <div class="auth-content verification-auth">
      <div class="logo-block">
        <img src="../../../../assets/images/auth-logo.svg" />
      </div>
      <div class="auth-title">
        <span>Bienvendios a CASCO Safety</span>
      </div>
      <div class="auth-text">
        <span>Ingresar su correo electr&#243;nico y le enviaremos un c&#243;digo de activaci&#243;n.</span>
      </div>
      <form ngNativeValidate #forgotPasswordForm="ngForm">
        <div class="auth-block">
          <div class="row">
            <div class="col-sm-12 col-md-12">
              <div class="input-block extra">
                <input type="text" class="form-control" placeholder="Correo Electr&#243;nico" name="emailId" [(ngModel)]="emailId"
                       pattern="[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,3}$" />
              </div>
            </div>
          </div>
        </div>
        <div>
          <validation-summary [validationErrors]="validationMessages"></validation-summary>
        </div>
        <div class="button-block">
          <button class="btn comm-btn" (click)="sendActivationCode()">Aplicar</button>
        </div>
      </form>
      <div class="already-status">
        <span>&#191;Ya tiene una cuenta? <a href="/login">Iniciar&nbsp;sesi&#243;n</a></span>
        <!--<a href="/register" class="register-only">Registre su cuenta</a>-->
      </div>
    </div>
  </div>
</div>
