import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-weekly-stats',
  templateUrl: './weekly-stats.component.html',
  styleUrls: ['./weekly-stats.component.css']
})
export class WeeklyStatsComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
