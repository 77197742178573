<div class="widgets call-view">
    <div class="call-list-top">
        <div class="title-block">
            <div class="row">
                <div class="col-md-6">
                    <div class="title">
                        <span class="only">Hoy</span><span> {{dayViewCount.todayDate | date:"d MMM" }}</span>
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="more-part">
                        <a href="/full-calendar-view">M&#225;s<img src="../../../../assets/images/ds-more-img.svg" /></a>
                    </div>
                </div>
            </div>
        </div>
        <div class="details-part">
            <div class="row">
                <div class="col-md-6">
                    <div class="some-part">
                        <span class="img-left">
              <img src="../../../../assets/images/available-part-img.svg" />
            </span>
                        <div class="extra-shot">
                            <span class="one-part">{{dayViewCount.remainingCalls}}</span><span> Restantes</span>
                        </div>
                    </div>
                </div>
                <div class="col-md-1"></div>
                <div class="col-md-5">
                    <div class="extra-right">
                        <div class="call-info-widget">
                            <span class="outof">{{dayViewCount.completedCalls}}</span><span class="total"> /{{dayViewCount.totalCalls}}</span>
                        </div>
                        <div class="text-part">
                            <span class="only-info">Llamadas Completadas</span>
                        </div>
                    </div>
                    <div class="clear"></div>
                </div>
            </div>
        </div>
        <div class="progress-block">
            <span style="width: {{dayViewCount.completedPercentage}}%"></span>
        </div>
    </div>
    <div class="call-list-btm">
        <div class="row">
            <div class="col-md-6">
                <div class="btm-title">
                    <span>Pr&#243;ximas Llamadas</span>
                </div>
            </div>
            <div class="col-md-6">
                <div class="img-sec">
                    <span class="timer-part" [ngClass]="showCube == true ? 'active' : ''" (click)="showTimeCube()">
            <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14"><path id="Path_3322" data-name="Path 3322" d="M8.993,2A7,7,0,1,0,16,9,7,7,0,0,0,8.993,2ZM9,14.6A5.6,5.6,0,1,1,14.6,9,5.6,5.6,0,0,1,9,14.6ZM8.846,5.5H8.8a.5.5,0,0,0-.5.5v3.3a.693.693,0,0,0,.343.6l2.9,1.743a.5.5,0,1,0,.511-.861L9.35,9.182V6A.5.5,0,0,0,8.846,5.5Z" transform="translate(-2 -2)" fill="#002062" /></svg>
          </span>
                    <span class="add-part" title="Agregar Nuevo Caso" (click)="goToAddNewClaimScreen()"><img src="../../../../assets/images/addition-widget.svg" /></span>
                </div>
            </div>
        </div>
        <div class="list-part">
            <div class="assign-part" *ngIf="isSuperAdmin">
                <div class="dropdown">

                    <a class="dropdown-toggle" id="menu1" data-toggle="dropdown">
            {{selectedTeamMate.name}} <img src="../../../../assets/images/dd-arrow.svg" />
          </a>
                    <ul class="dropdown-menu" role="menu" aria-labelledby="menu1">
                        <li role="presentation"><a role="menuitem" (click)="selectMe()">Asignarme</a></li>
                        <li role="presentation"><a role="menuitem" (click)="selectAll()">Asignar a Todos</a></li>
                        <li role="presentation" *ngFor="let item of teammateList">
                            <a role="menuitem" (click)="selectTeamMate(item)">{{item.name}}</a>
                        </li>
                    </ul>
                </div>
            </div> 

            <!-- Day View Data for ME -->
            <div class="listing-only-part" *ngIf="showMe && !showCube">
                <div class="block-part" [ngClass]="i == 0 ? 'top-one' : ''" *ngFor="let item of dayViewDataForMe; let i= index">
                    <div class="time-sec">
                        <span class="time-only">{{item.time}}</span>
                        <span class="type-only">{{item.timePart}}</span>
                    </div>
                    <div class="approve-part">
                        <span class="name-part">{{item.initials}}</span>
                        <div class="details-approve">
                            <span class="approve-name">{{item.customerName}}</span>
                            <span>Estado : </span><span class="status" [ngClass]="item.callStatus == 'Pending' ? 'wait' : ''">{{item.callStatus}}</span>
                        </div>
                        <div class="clear"></div>
                    </div>
                    <a *ngIf="item.callStatus != 'Pending'" class="after-part" (click)="goToVideoCall(item.confId)">
                        <img src="../../../../assets/images/after-hover.svg" />
                    </a>
                </div>
            </div>

            <!-- Day View Data for ALL -->
            <div class="listing-only-part" *ngIf="showAll && !showCube">
                <div class="block-part no-hover" [ngClass]="{'top-one': i == 0, 'extra-part' : item.callCount > 1}" *ngFor="let item of dayViewDataForAll; let i= index">
                    <div class="time-sec">
                        <span class="time-only">{{item.time}}</span>
                        <span class="type-only">{{item.timePart}}</span>
                    </div>
                    <div class="approve-part">
                        <span class="name-part">{{item.initials}}</span>
                        <div class="details-approve">
                            <span class="approve-name">{{item.adjusterName}}</span>
                            <span class="partner-text">con {{item.customerName}}</span>
                        </div>
                        <div class="clear"></div>
                        <span *ngIf="item.callCount > 1" class="many-block" data-toggle="modal" (click)="showAllList(item)">and <span class="spl">+{{item.callCount - 1}}</span> others</span>
                    </div>
                    <a class="after-part">
                        <img src="../../../../assets/images/after-hover.svg" />
                    </a>
                </div>
            </div>

            <!-- Day View Data for Specific Team-mate -->
            <div class="listing-only-part" *ngIf="showTeamMate && !showCube">
                <div class="block-part no-hover" [ngClass]="i == 0 ? 'top-one' : ''" *ngFor="let item of dayViewDataForTeamMate; let i= index">
                    <div class="time-sec">
                        <span class="time-only">{{item.time}}</span>
                        <span class="type-only">{{item.timePart}}</span>
                    </div>
                    <div class="approve-part">
                        <span class="name-part">{{item.initials}}</span>
                        <div class="details-approve">
                            <span class="approve-name">{{item.adjusterName}}</span>
                            <span class="partner-text">con {{item.customerName}}</span>
                        </div>
                        <div class="clear"></div>
                    </div>
                    <a class="after-part">
                        <img src="../../../../assets/images/after-hover.svg" />
                    </a>
                </div>
            </div>

            <!-- Time Section for Me -->
            <div class="timings-section" *ngIf="showMeCube && showCube">
                <div class="row">
                    <div class="col-md-3" *ngFor="let item of dayViewDataForMe; let i= index">
                        <div class="timings-only">
                            <span class="top-timings">{{item.time}}</span>
                            <span class="btm-timings">{{item.timePart}}</span>
                            <div class="tooltiptext">
                                <div class="approve-part">
                                    <span class="name-part">{{item.initials}}</span>
                                    <div class="details-approve">
                                        <span class="approve-name">{{item.customerName}}</span>
                                        <div class="left-part">
                                            <span class="st-title">Estado: </span>
                                            <span class="status" [ngClass]="item.callStatus == 'Pending' ? 'wait' : ''">{{item.callStatus}}</span>
                                        </div>
                                    </div>
                                    <div class="clear"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


            <!-- Time Section for Team-mate -->
            <div class="timings-section" *ngIf="showTeamMateCube && showCube">
                <div class="row">
                    <div class="col-md-3" *ngFor="let item of dayViewDataForTeamMate; let i= index">
                        <div class="timings-only">
                            <span class="top-timings">{{item.time}}</span>
                            <span class="btm-timings">{{item.timePart}}</span>
                            <div class="tooltiptext">
                                <div class="approve-part">
                                    <span class="name-part">{{item.initials}}</span>
                                    <div class="details-approve">
                                        <span class="approve-name">{{item.adjusterName}}</span>
                                        <div class="left-part">
                                            <span class="st-title">con {{item.customerName}}</span>
                                        </div>
                                    </div>
                                    <div class="clear"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <!-- Time Section for All -->
            <div class="timings-section" *ngIf="showAllCube && showCube">
                <div class="row">
                    <div class="col-md-3" *ngFor="let item of dayViewDataForAll; let i= index">
                        <div class="timings-only">
                            <span class="top-timings">{{item.time}}</span>
                            <span class="btm-timings">{{item.timePart}}</span>
                            <div class="tooltiptext" *ngIf="item.callCount == 1">
                                <div class="approve-part">
                                    <span class="name-part">{{item.initials}}</span>
                                    <div class="details-approve">
                                        <span class="approve-name">{{item.adjusterName}}</span>
                                        <div class="left-part">
                                            <span class="st-title">con {{item.customerName}}</span>
                                        </div>
                                    </div>
                                    <div class="clear"></div>
                                </div>
                            </div>
                        </div>
                        <div class="round-part" *ngIf="item.callCount > 1">
                            <span (click)="showAllListCube(item)">{{item.callCount}}</span>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    </div>
</div>



<div class="modal fade user-pop" id="user-data" data-backdrop="static" aria-hidden="true" role="dialog">
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-header pop-title">
                <button type="button" class="close" data-dismiss="modal">
          <img src="../../../assets/images/close-pop.svg" />
        </button>
                <h4 class="modal-title">
                    <span class="only-time">Hoy<span class="times"> at</span><span class="only-time"> {{modalTime}}</span><span class="times"> {{modalTimePart}}</span></span>
                </h4>
            </div>
            <div class="modal-body">
                <div class="user-list">
                    <div class="row">
                        <div class="col-md-9">
                            <div class="search-bar">
                                <input type="text" class="form-control" placeholder="Buscar" (input)="searchName($event.target.value)" />
                            </div>
                        </div>
                        <div class="col-md-3">
                            <div class="totalling">
                                <span>TOTAL <span class="total-part">{{dayViewAllList.callCount}}</span></span>
                            </div>
                        </div>
                    </div>
                    <div class="user-block" *ngIf="!showSearchedData">
                        <div class="approve-part" *ngFor="let item of dayViewAllList.dayViewAllList; let i= index">
                            <span class="name-part">{{item.initials}}</span>
                            <div class="details-approve">
                                <span class="approve-name">{{item.adjusterName}}</span>
                                <span class="partner-text">con {{item.customerName}}</span>
                            </div>
                            <div class="clear"></div>
                        </div>
                    </div>

                    <div class="user-block" *ngIf="showSearchedData">
                        <div class="approve-part" *ngFor="let item of dayViewAllSearchList.dayViewAllList; let i= index">
                            <span class="name-part">{{item.initials}}</span>
                            <div class="details-approve">
                                <span class="approve-name">{{item.adjusterName}}</span>
                                <span class="partner-text">con {{item.customerName}}</span>
                            </div>
                            <div class="clear"></div>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    </div>
</div>

<div class="modal fade user-pop" id="user-list-data" data-backdrop="static" aria-hidden="true" role="dialog">
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-header pop-title">
                <button type="button" class="close" data-dismiss="modal">
          <img src="../../../assets/images/close-pop.svg" />
        </button>
                <h4 class="modal-title">
                    <span class="only-time">Hoy<span class="times"> at</span><span class="only-time"> {{modalTime}}</span><span class="times"> {{modalTimePart}}</span></span>
                </h4>
            </div>
            <div class="modal-body">
                <div class="user-list">
                    <div class="row">
                        <div class="col-md-9">
                            <div class="search-bar">
                                <input type="text" class="form-control" placeholder="Buscar" (input)="searchNameCube($event.target.value)" />
                            </div>
                        </div>
                        <div class="col-md-3">
                            <div class="totalling">
                                <span>TOTAL <span class="total-part">{{dayViewAllList.callCount}}</span></span>
                            </div>
                        </div>
                    </div>
                    <div class="user-block" *ngIf="!showSearchedData">
                        <div class="approve-part" *ngFor="let item of dayViewAllList.dayViewAllList; let i= index">
                            <span class="name-part">{{item.initials}}</span>
                            <div class="details-approve">
                                <span class="approve-name">{{item.adjusterName}}</span>
                                <span class="partner-text">con {{item.customerName}}</span>
                            </div>
                            <div class="clear"></div>
                        </div>
                    </div>

                    <div class="user-block" *ngIf="showSearchedData">
                        <div class="approve-part" *ngFor="let item of dayViewAllSearchList.dayViewAllList; let i= index">
                            <span class="name-part">{{item.initials}}</span>
                            <div class="details-approve">
                                <span class="approve-name">{{item.adjusterName}}</span>
                                <span class="partner-text">con {{item.customerName}}</span>
                            </div>
                            <div class="clear"></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<!--<div class="modal fade no-pop" id="dashboard-puchase-data" data-backdrop="static" aria-hidden="true" role="dialog">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header no-title">
        <button type="button" class="close" data-dismiss="modal">
          <img src="../../../../assets/images/modal-close-img.svg" />
        </button>
        <h4 class="modal-title">
          <span>Le gusta nuestros servicios?</span>
        </h4>
      </div>
      <div class="modal-body">
        <div class="pop-info-section">
          <div class="img-part">
            <img src="../../../assets/images/purchase-img.svg" />
          </div>
          <div class="desc-part">
            <span>{{freeTrialEndMessage}}</span>
          </div>
          <div class="button-block">
            <button class="btn comm-btn" type="button" data-dismiss="modal" (click)="redirect()">Let's go</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>-->

<div class="modal fade no-pop" id="puchase-data" data-backdrop="static" aria-hidden="true" role="dialog">
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-header no-title">
                <button type="button" class="close" data-dismiss="modal">
                    <img src="../../../../assets/images/modal-close-img.svg" />
                </button>
            </div>
            <div class="modal-body">
                <div class="pop-info-section">
                    <div class="row">
                        <div class="col-md-5">
                            <div class="img-part">
                                <img src="../../../assets/images/purchase-img.svg" />
                                <div class="price">
                                    <span class="price-top">$</span>
                                    <span class="price-mdl"> 12</span>
                                    <span class="price-btm">por Caso</span>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-7">
                            <div class="desc-part">
                                <span class="desc-top-part">Ha alcanzado su limite de casos gratis.</span>
                                <span class="desc-btm-part">{{freeTrialEndMessage}}</span>
                            </div>
                            <div class="button-block">
                                <button class="btn comm-btn" type="button" data-dismiss="modal" (click)="redirect()">Contact Sales</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="modal fade ex-pop" id="puchase-data-c" data-backdrop="static" aria-hidden="true" role="dialog">
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-header no-title">
                <button type="button" class="close" data-dismiss="modal">
                    <img src="../../../../assets/images/modal-close-img.svg" />
                </button>
                <h4 class="modal-title">
                    <span class="f-top">Ha alcanzado su limite de casos gratis.</span>
                    <span class="f-btm">Please Contactar Administrator </span>
                </h4>
            </div>
        </div>
    </div>
</div>