import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { ClaimCase } from '../../models/claimCase.model';
import { SharedDataService } from '../../services/sharedData.service';
import { ClaimCaseService } from './../../api-services/claim-case.service';
import { iMeetingData } from '../../models/claimDetail.model';
import { NgForm } from '@angular/forms';
import { HttpClient, HttpHeaders, HttpResponse, HttpEventType, HttpRequest } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { saveAs } from 'file-saver';

@Component({
  selector: 'app-call-schedule',
  templateUrl: './call-schedule.component.html',
  styleUrls: ['./call-schedule.component.css']
})
export class CallScheduleComponent implements OnInit {

  @ViewChild('scheduleCallForm')
  scheduleCallForm: NgForm;
  validationMessages: any;
  public claim: ClaimCase = new ClaimCase();
  public selectedDate = new Date();
  public startAt = new Date(2020, 4, 22, 10, 30, 30);
  // Min moment: February 12 2018, 10:30
  public minDate = new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate());
  selected = 'None';
  message: string = "";
  meetingData?: iMeetingData = {};
  calendarType?: string = "";

  public myFilter = (d: Date): boolean => {
    const day = d.getDay();
    // Prevent Saturday and Sunday from being selected.
    return day !== 0 && day !== 6;
  }

  constructor(private router: Router,
    private service: ClaimCaseService,
    private httpClient: HttpClient,
    private sharedDataService: SharedDataService) { this.claim = new ClaimCase(); }

  ngOnInit(): void {
    this.PopulateClaimDetails();
    this.getAdjusterCalendarPreference();
  }

  PopulateClaimDetails() {

    const caseId = this.sharedDataService.getValue("caseId");

    if (caseId) {
      this.service.getClaimDetails(caseId)
        .subscribe((data: any) => {
          if (data !== undefined) {
            this.claim = <ClaimCase>data;
          }
        });
    }
  }

  onSubmit() {

    this.validationMessages = [];

    this.populateValidateMessages();

    if (this.validationMessages.length === 0) {
      this.scheduleClaim();
    }
  }

  scheduleClaim() {
    var hours = Number(this.selected.split(":")[0]);
    var minutes = Number(this.selected.split(":")[1]);
    this.selectedDate.setHours(hours);
    this.selectedDate.setMinutes(minutes);
    this.selectedDate.setSeconds(0);
    this.selectedDate = new Date(this.selectedDate.toUTCString());

    let submitObject = {
      CaseId: this.claim.claimCaseId,
      ScheduledDate: this.selectedDate,
      ScheduledTime: this.selected,
      Duration: "30",
      ScheduledBy: this.sharedDataService.getValue("accountId"),
      CreatedDate: new Date(),
      CalendarType: this.calendarType
    };

    this.service.insertConference(submitObject)
      .subscribe((data: any) => {
        if (data !== null) {
          this.message = <string>data;
          if (this.message != "success") {
            this.populateApiErrorMessages(this.message);
          }
          else {
            if (this.calendarType != null || this.calendarType == "adjustify") {
              this.meetingData.adjusterId = this.sharedDataService.getValue("accountId");
              this.meetingData.toEmailId = this.claim.email;
              this.meetingData.customerName = this.claim.firstName + " " + this.claim.lastName;
              this.meetingData.startDateTime = this.claim.invitationDate;
              this.meetingData.localeDateOffset = this.sharedDataService.getTimezonOffset().toString();
              this.meetingData.calendarType = this.calendarType;

              if (this.calendarType == "outlook")
                this.downloadOutlookMeetingFile(this.meetingData);
              else if (this.calendarType == "google")
                this.createGoogleCalendarMeetingLink(this.meetingData);
            }

            this.router.navigate(['/backlog']);
          }
        }
      });
  }

  populateValidateMessages() {
    if (this.selected == "None") {
      this.validationMessages.push('Por favor seleccione una hora válida.');
      return;
    }

    if (this.selectedDate == null) {
      this.validationMessages.push('Seleccione una fecha válida.');
      return;
    }
    else if (this.selectedDate < new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate())) {
      this.validationMessages.push('La fecha programada de la llamada no puede ser anterior a la anterior.');
      return;
    }
    else if (this.selectedDate.getDay() == 0 || this.selectedDate.getDay() == 6) {
      this.validationMessages.push('La fecha reprogramada no puede ser durante el fin de semana.');
      return;
    }

    var dateToCheck = new Date();
    var tempInviDate = this.selectedDate;
    var dHour = dateToCheck.getHours();
    var dMin = dateToCheck.getMinutes();

    var hours = Number(this.selected.split(":")[0]);
    var minutes = Number(this.selected.split(":")[1]);
    tempInviDate.setHours(hours, minutes, 0, 0);
    dateToCheck.setHours(dHour, dMin, 0, 0);

    if (tempInviDate < dateToCheck) {
      this.validationMessages.push("Lo siento, no puede seleccionar ese horario ya que no es válido. Por favor seleccione una fecha u hora futura válida");
    }

    //if (this.selectedDate <= dateToCheck && hours <= dateToCheck.getHours() && minutes <= dateToCheck.getMinutes()) {
    //  this.validationMessages.push("Lo siento, no puede seleccionar ese horario ya que no es válido. Por favor seleccione una fecha u hora futura válida");
    //}
  }

  populateApiErrorMessages(message: string) {
    this.validationMessages.push(message);
  }

  //private method for download
  private getHeader(key, value) {
    var header: HttpHeaders = {} as HttpHeaders;
    header[key] = value;
    return header;
  }

  //private method for download
  private getAuthoriseHeader() {
    var token = this.sharedDataService.getValue('token');
    if (token) {
      let token = JSON.parse(this.sharedDataService.getValue('token'));
      if (token) {
        return this.getHeader("Authorization", `Bearer ${token}`);
      }
      return {} as HttpHeaders;
    }
  }

  //Download outlook meeting ics file
  private downloadOutlookMeetingFile(data?: any) {
    this.validationMessages = [];
    const filename = "Adjustify Meeting.ics";
    this.httpClient.post(environment.apiUrl + 'conference/createOutlookMeeting', data, {
      headers: this.getAuthoriseHeader(),
      observe: 'response',
      responseType: 'blob'
    })
      .subscribe(
        data => {
          var contentType = data.headers.get('content-type');
          var blob = new Blob([data.body], { type: contentType });
          saveAs(blob, filename);
        },
        error => {
          this.populateApiErrorMessages(error.message);
        });
  }

  //Create link for google calendar meeting
  private createGoogleCalendarMeetingLink(data?: any) {
    this.service.createGoogleCalendarMeeting(data).subscribe((data: any) => {
      if (data != null) {
        window.open(data, "_blank");
      }
    }, error => {
      this.populateApiErrorMessages(error.message);
    });
  }

  //Method to get adjuster calendar preference
  getAdjusterCalendarPreference() {
    this.service.getAdjusterCalendarPreference(this.sharedDataService.getValue("accountId")).subscribe(result => {
      this.calendarType = result;
    });
  }
}
