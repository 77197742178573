import { Component, OnInit } from '@angular/core';
import { Router, Data } from '@angular/router';
import { SettingService } from './../../../api-services/setting.service';
import { SharedDataService } from './../../../services/sharedData.service';
import { iLossTypeDetail } from '../../../models/setting.model';
import { ToastrService } from 'ngx-toastr';
declare var $: any;

@Component({
  selector: 'app-configuration',
  templateUrl: './configuration.component.html',
  styleUrls: ['./configuration.component.css']
})
export class ConfigurationComponent implements OnInit {

  constructor(private router: Router,
    private service: SettingService,
    private sharedService: SharedDataService,
    private toastr: ToastrService) { }

  lossTypeList?: iLossTypeDetail[] = [];
  newLossType?: iLossTypeDetail = {};
  activeDeactiveLossType?: iLossTypeDetail = {};
  tenantId?: number;
  showGrid?: boolean = false;
  validationMessages: any;
  validationMessagesAdd: any;
  activeMsg?: string;

  ngOnInit(): void {
    this.validationMessages = [];
    this.validationMessagesAdd = [];
    this.tenantId = this.sharedService.getValue("tenantId");
    this.getAllLossType();
  }

  refreshMethod() {
    this.validationMessages = [];
    this.validationMessagesAdd = [];
    this.tenantId = this.sharedService.getValue("tenantId");
    this.getAllLossType();
  }

  //Method to get all loss type
  getAllLossType() {
    this.service.getLossType(this.tenantId, this.sharedService.getTimezonOffset()).subscribe(result => {
      if (result != null) {
        this.lossTypeList = <iLossTypeDetail[]>result;
        if (this.lossTypeList.length == 0)
          this.showGrid = false;
        else
          this.showGrid = true;
      }
    }, error => {
      this.populateApiErrorMessages(error.message);
    });
  }

  //Method to get all loss type without loader
  getLossTypeWithoutLoader() {
    this.service.getLossTypeWithoutLoader(this.tenantId, this.sharedService.getTimezonOffset()).subscribe(result => {
      if (result != null) {
        this.lossTypeList = <iLossTypeDetail[]>result;
        if (this.lossTypeList.length == 0)
          this.showGrid = false;
        else
          this.showGrid = true;
      }
    }, error => {
      this.populateApiErrorMessages(error.message);
    });
  }

  //Method to add new loss type
  addLossType() {

    this.validationMessagesAdd = [];

    this.populateValidateMessages();

    if (this.validationMessagesAdd.length === 0) {
      this.newLossType.tenantId = this.tenantId;
      this.newLossType.createdDate = new Date(new Date().toUTCString());
      this.service.addLossType(this.newLossType).subscribe(result => {
        if (result != null) {
          if (result == "true") {
            this.newLossType = {};
            this.getAllLossType();
            $("#add-type").modal("hide");
          }
          else {
            this.populateApiErrorMessagesAdd(result);
          }
        }
      }, error => {
        this.populateApiErrorMessagesAdd(error.message);
      });
    }
  }

  //Method to close add modal pop-up
  closeAddModal() {
    this.validationMessagesAdd = [];
    this.newLossType = {};
    $("#add-type").modal("hide");
  }

  //Method to open remove modal pop-up
  openRemoveModal(item?: any) {    
    this.activeDeactiveLossType = item;
    if (!this.activeDeactiveLossType.isActive) {
      this.activeDeactiveLossType.isActive = true;
      this.activeDeActiveLossType();
    }
    else {      
      $("#remove-type").modal("show");
    }                    
  }

  //Method to close remove modal pop-up
  closeRemoveModal() {
    this.getLossTypeWithoutLoader();
    $("#remove-type").modal("hide");
    this.activeDeactiveLossType = {};
  }

  //Method to activate and de-activate loss type
  activeDeActiveLossType() {
    this.activeDeactiveLossType.tenantId = this.tenantId;
    this.activeDeactiveLossType.createdDate = new Date(new Date().toUTCString());
    this.service.activeDeActiveLossType(this.activeDeactiveLossType).subscribe(result => {
      if (result != null) {       
        if (this.activeDeactiveLossType.isActive == true)
          this.successMsg("Tipo de caso activado con éxito.");
        else {
          $("#remove-type").modal("hide");
          this.successMsg("Tipo de caso desactivado con éxito.");
        }
          
        this.getAllLossType();        
      }
    }, error => {
      this.populateApiErrorMessages(error.message);
    });
  }

  populateValidateMessages() {
    if (this.newLossType.userLossTypeId == null || this.newLossType.userLossTypeId == undefined ||
      this.newLossType.userLossTypeId.toString().trim().length == 0 || !this.isDigitCheck(this.newLossType.userLossTypeId)) {
      this.validationMessagesAdd.push('Favor ingresar tipo de caso valido.');
    }
    if (this.newLossType.lossType == null || this.newLossType.lossType == undefined || this.newLossType.lossType.trim() == '') {
      this.validationMessagesAdd.push('Introduzca una descripción válida.');
    }
  }

  populateApiErrorMessages(message: string) {
    this.validationMessages.push(message);
  }

  populateApiErrorMessagesAdd(message: string) {
    this.validationMessagesAdd.push(message);
  }

  isDigitCheck(lossTypeId) {
    var re = /\d/;
    return re.test(lossTypeId);
  }

  successMsg(msg?: string) {
      this.toastr.success(msg, 'Exito')
  }

}
