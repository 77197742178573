import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpParams, HttpHeaders } from '@angular/common/http';
import { AuthService } from './../services/auth.service';
import { FileUploadService } from './../services/file-upload.service';
import { ConferenceInstance, iUpdateNote, iConnectionLog, iPDFData, iPDFImageList } from './../models/conference-instace.model';
import { iReviewFile, iReviewData } from './../models/file-upload.model';
import { Conference } from './../models/conference.model';
import { ApiClientService } from './api-client.service';
import { ConferenceInstanceArtifacts } from './../models/conference-instace-artifacts.model';
import { SharedDataService } from '../services/sharedData.service';
import { ConferenceInstanceLog } from './../models/conference-instance-log.model';

@Injectable({
  providedIn: 'root'
})
export class VideoCallService {

  constructor(private apiClientService: ApiClientService, private authService: AuthService, private fileService: FileUploadService, private sharedService: SharedDataService) { }


  public getConferenceInfoById(conferenceId: number): Observable<Conference> {
    let params = new HttpParams();
    params = params.append('conferenceId', conferenceId.toString());
    
    return this.apiClientService.getSingle<Conference>("conference/getConferenceInfoById", undefined, params);
  }

  public startCall(conferenceInstance: ConferenceInstance): Observable<number> {
    return this.apiClientService.post<number>("conferenceInstance/startCall", conferenceInstance);
  }

  public endCall(conferenceInstance: ConferenceInstance): Observable<number> {
    return this.apiClientService.post("conferenceInstance/endCallInstance", conferenceInstance);
  }

  public saveImage(conferenceInstance: ConferenceInstance): Observable<number> {
    return this.apiClientService.post("conferenceInstance/saveImage", conferenceInstance, undefined, false);
  }

  public saveFile(claimNumber: string, conferenceInstanceId: number, userId: number, storageTypeId: number, 
    odooCustomerId:number,odooTicketId:number,    
    blobData: any): Promise<any> {
    var formData = new FormData();
    formData.append("blob", blobData);

    let params = new HttpParams();
    params = params.append('claimNumber', "test");
    params = params.append('conferenceInstanceId', conferenceInstanceId.toString());
    params = params.append('userId', userId.toString());
    params = params.append('storageTypeId', storageTypeId.toString());
    params = params.append('oddoCustomerId', odooCustomerId.toString());
    params = params.append('oddoTicketId', odooTicketId.toString());

    return this.fileService.postSync(`conferenceInstance/saveVideo`, formData, null, params);
  }

  public downloadFile(conferenceInstanceId: number): Observable<ArrayBuffer | Blob> {
    let headers: HttpHeaders = {} as HttpHeaders;
    headers['Accept'] = 'application/zip';
    headers['responseType'] = 'blob';
    headers['Authorization'] = `Bearer ${this.sharedService.getValue("token")}`;

    return this.fileService.getZip(`conferenceInstance/getConferenceFiles/${conferenceInstanceId}`, headers);
  }

  public getAllImages(conferenceId: number): Observable<ConferenceInstanceArtifacts[]> {
    let params = new HttpParams();
    params = params.append('conferenceId', conferenceId.toString());
    // params = params.append('oddoCustomerId', odooCustomerId.toString());
    // params = params.append('oddoTicketId', odooTicketId.toString());
    return this.apiClientService.getList<ConferenceInstanceArtifacts>("conferenceInstance/getConferenceFilesForReview", undefined, params);
  }

  public getReviewData(conferenceId: number): Observable<iReviewData> {
    let params = new HttpParams();
    params = params.append('conferenceId', conferenceId.toString());

    return this.apiClientService.getSingle<iReviewData>("conferenceInstance/getReviewData", undefined, params);
  }

  public getFileToDisplay(artifactId: string): Observable<iReviewFile> {
    let params = new HttpParams();
    params = params.append('artifactId', artifactId);

    return this.apiClientService.getSingle<iReviewFile>("conferenceInstance/getFileToDisplay", undefined, params);
  }

  public updateNotesForArtifact(updateNote: iUpdateNote): Observable<any> {
    return this.apiClientService.post<any>("conferenceInstance/updateNotesForArtifact", updateNote);
  }

  public updateNotesForConference(updateNote: iUpdateNote): Observable<any> {
    return this.apiClientService.post<any>("conferenceInstance/updateNotesForConference", updateNote);
  }

  public deleteArtifacts(artifactIdList: string[]): Observable<any> {
    return this.apiClientService.post<any>("conferenceInstance/deleteArtifacts", artifactIdList);
  }

  public getConnectivityLog(voipToken: string): Observable<iConnectionLog> {
    let params = new HttpParams();
    params = params.append('voipToken', voipToken);

    return this.apiClientService.getSingleWithoutLoader<iConnectionLog>("conferenceInstance/getConnectivityLog", undefined, params);
  }

  public insertConferenceInstanceLog(conferenceInstanceLogVM: ConferenceInstanceLog): Observable<any> {
    return this.apiClientService.post<any>("conferenceInstance/createConferenceInstanceLog", conferenceInstanceLogVM);
  }

  public getDataForPDF(conferenceId: number): Observable<iPDFData> {
    let params = new HttpParams();
    params = params.append('conferenceId', conferenceId.toString());

    return this.apiClientService.getSingle<iPDFData>("conferenceInstance/getDataForPDF", undefined, params);
  }

  public getImageListForPDF(conferenceId: number): Observable<iPDFImageList[]> {
    let params = new HttpParams();
    params = params.append('conferenceId', conferenceId.toString());

    return this.apiClientService.getList<iPDFImageList>("conferenceInstance/getImageListForPDF", undefined, params);
  }
}

