import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { ContactUsDetailService } from 'src/app/api-services/contactus-detail.service';
import { SettingService } from 'src/app/api-services/setting.service';
import { CustomerViewModel, PublicFormValidationMessage } from 'src/app/models/contactus-detail';
import { SharedDataService } from 'src/app/services/sharedData.service';

@Component({
  selector: 'app-getting-starded',
  templateUrl: './getting-starded.component.html',
  styleUrls: ['./getting-starded.component.css']
})
export class GettingStardedComponent implements OnInit {

  model: CustomerViewModel = new CustomerViewModel();
  validationMessages = [];
  constructor(private contactUsDetailService: ContactUsDetailService,
    private settingService: SettingService,
    private sharedService: SharedDataService,
    private toastr: ToastrService,
    private router: Router
  ) { }

  ngOnInit(): void {
  }

  verifyCustomerDetail(request: any) {
    this.getFormError(request)
    if (request.valid && this.validationMessages.length == 0) {
      this.contactUsDetailService.verifyCustomer(request.value).subscribe((data: any) => {
        if (data && data.error) {
          this.toastr.error(data.error);
        } else {
          sessionStorage.setItem('previousRoute', this.router.url);
          //this.router.navigate(["/thank-you"])
          this.router.navigate(['contact-form'], { queryParams: { token: data } })
        }
      }, error => {
        //this.toastr.error('se produce un error ', 'Aviso');
      });
    }
  }

  getFormError(request) {
    this.validationMessages = [];
    if (request.value.emailId != request.value.confirmEmailId) {
      this.validationMessages.push("El correo electrónico y el correo electrónico de confirmación deben ser iguales.")
    }
    return Object.keys(request.controls).map(key => {
      const errors = request.controls[key].errors;
      if (errors === null) { return null; }
      if (errors.required) {
        if (!(this.validationMessages.find(x => x == 'Por favor complete todos los campos obligatorios'))) {
          this.validationMessages.push('Por favor complete todos los campos obligatorios');
        }
      }
      else if (errors.email) {
        if (!(this.validationMessages.find(x => x == 'Por favor ingrese una dirreción de correo válida.'))) {
          let message = PublicFormValidationMessage.find(x => x.validationKey == 'email').message
          this.validationMessages.push(message);
        }
      }
      else {
        this.validationMessages.push(`${key} has an unknown error`);
      }
    });

  }
}
