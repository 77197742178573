import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from '../../../services/auth.service';
import { AuthModel } from './../../../models/auth.model';
import { iUserLastLoggedIn } from '../../../models/setting.model';
import { SettingService } from '../../../api-services/setting.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {

  loading = false;
  returnUrl: string;
  isLoginError: boolean = false;
  validationMessages: any;
  emailaddress: string = "";
  password: string = "";
  errorData: { errorTitle: string; errorDesc: string; };
  userLastLoggedIn?: iUserLastLoggedIn = {};
  
  constructor(
    private router: Router,
    private authService: AuthService,
    private settingService: SettingService,    
    private activateRoute: ActivatedRoute) {

    this.authService.redirectToMainPageIfUserIsLoggedIn();
  }

  ngOnInit(): void {
    this.authService.isUserLoggedIn.next(false);
    this.activateRoute.queryParams.subscribe(params => {
      if (params['key'] && params['key'] != '') {
        this.getToken(params['key']);
      }
    });
  }

  getToken(key: string) {
    var authRequest = { authKey: key, requestedBy: window.location.href } as AuthModel;
    this.authService.getToken(authRequest).subscribe((data) => {
      this.authService.redirectToMainPageIfUserIsLoggedIn();
    },
      error => {
        this.populateApiErrorMessages(error.message);
      });
  }

  onSubmit() {

    this.validationMessages = [];

    this.populateValidateMessages();

    if (this.validationMessages.length === 0) {

      this.authService.login(this.emailaddress, this.password).subscribe(
        response => {
          if (response) {            
            this.authService.redirectToMainPage(response);  
            //this.saveUserLastLoggedIn();          
          }
        },
        error => {
          this.populateApiErrorMessages(error.errorDesc);
        });
    }
  }

  populateValidateMessages() {

    if (this.emailaddress.length === 0) {
      this.validationMessages.push('Correo electrónico requerido.');
    }
    else if (!this.isValidEmail(this.emailaddress)) {
      this.validationMessages.push('Correo electrónico inválido.');
    }

    if (this.password.length === 0) {
      this.validationMessages.push('Contrasena requerida.');
    }
  }

  isValidEmail(email) {
    var re = /^[a-zA-Z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/;
    return re.test(email);
  }

  populateApiErrorMessages(message: string) {
    this.validationMessages.push(message);
  }

  logout() {
    this.authService.logout();
    this.router.navigate(['/login']);
  }  

  //Method to save user's last logged in date and time
  saveUserLastLoggedIn() {    
    this.userLastLoggedIn.currentDate = new Date(new Date().toUTCString());
    this.settingService.saveUserLastLoggedIn(this.userLastLoggedIn).subscribe(result => {
      
    });
  }
}
