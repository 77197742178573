<div class="contact-form-container" *ngIf="userData">
    <div class="container">
        <div class="contact-form-block">
            <div class="logo-block">
                <img src="../../../../assets/images/auth-logo.svg" />
            </div>
            <div class="welcome-name text-center" *ngIf="userData.oddoId > 0 && isCustomerExistInAdjusify">
                <h1>Bienvenido, {{userData.fullName}}
                </h1>
            </div>
            <form #frmCreateClient="ngForm" id="contactone" class="contact-form"
                (ngSubmit)="sendContactUsRequest(frmCreateClient);" autocomplete="off">
                <div class="contact-section">
                    <div class="contact-information" *ngIf="!(userData.oddoId > 0 && isCustomerExistInAdjusify)">
                        <div class="contact-title">
                            <span>Información del Contacto </span>
                        </div>
           
                        <div class="form-data">
                            <div class="row">
                                <div class="col-md-6">
                                    <div class="input-block">
                                        <label class="input-label">
                                            Nombre:
                                            <span class="mandatory-check" data-toggle="tooltip" data-placement="top"
                                                title="Indica campo requerido">*</span>
                                        </label>
                                        <input readonly type="text" placeholder="Nombre" id="fullName" name="fullName"
                                            [(ngModel)]="model.fullName" #fullName="ngModel" maxlength="50" required=""
                                            class="form-control">
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="input-block dd-block">
                                        <label class="input-label">
                                            Celular:
                                            <span class="mandatory-check" data-toggle="tooltip" data-placement="top"
                                                title="Indica campo requerido">*</span>
                                        </label>
                                        <div class="only-extra-part">
                                            <input (blur)="removeSpaceFromString(frmCreateClient,'mobileNumber')" required (keypress)="restrictSpecialCharacterForPhoneNumber($event)"
                                                maxlength="15" minlength="5" type="text" class="form-control ph-number"
                                                placeholder="1234567890" id="mobileNumber" name="mobileNumber"
                                                [(ngModel)]="model.mobileNumber" #mobileNumber="ngModel" />
                                            <span class="only-code">{{selectedCountry?.countryISDCode}}</span>
                                        </div>
                                        <div class="tel-dd-part">
                                            <div class="dropdown">
                                                <a class="dropdown-toggle" id="menu1" data-toggle="dropdown">
                                                    <span>{{selectedCountry?.countryCode}}</span><img
                                                        src="../../../../assets/images/select-arw.svg" />
                                                </a>
                                                <ul class="dropdown-menu" role="menu" aria-labelledby="menu1">
                                                    <!--<li class="ph-menu-item"><a><span class="country-id">None</span></a></li>-->
                                                    <li class="ph-menu-item" *ngFor="let data of countryList"   (click)="selectCountry(data)"><a
                                                            (click)="selectCountry(data)"><span   (click)="selectCountry(data)"
                                                                class="country-id">{{data.bothCountryCode}}</span></a>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-6">
                                    <div class="input-block">
                                        <label class="input-label">
                                            Pa&#237;s de Consulta:
                                            <span class="mandatory-check" data-toggle="tooltip" data-placement="top"
                                                title="Indica campo requerido">*</span>
                                        </label>
                                        <ng-multiselect-dropdown required name="countryName"
                                            [(ngModel)]="model.countryName" #countryName="ngModel"
                                            [placeholder]="'Seleccione'" [settings]="dropdownSettings"
                                            [data]="countryDropDownList" (onSelect)="onItemSelect($event)"
                                            (onSelectAll)="onSelectAll($event)" class="multi-select">
                                        </ng-multiselect-dropdown>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="input-block">
                                        <label class="input-label">
                                            Correo:
                                            <span class="mandatory-check" data-toggle="tooltip" data-placement="top"
                                                title="Indica campo requerido">*</span>
                                        </label>
                                        <input readonly type="email" email placeholder="muestra@muestra.com"
                                            id="emailaddress" name="emailAddress" (change)="searchUser(frmCreateClient)"
                                            [(ngModel)]="model.emailAddress" #emailAddress="ngModel" maxlength="50"
                                            required="" class="form-control">
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-6">
                                    <div class="input-block">
                                        <label class="input-label">
                                            Cargo:
                                            <span class="mandatory-check" data-toggle="tooltip" data-placement="top"
                                                title="Indica campo requerido">*</span>
                                        </label>
                                        <input (blur)="removeSpaceFromString(frmCreateClient,'jobTitle')" type="text" placeholder="Cargo" id="jobTitle" name="jobTitle"
                                            [(ngModel)]="model.jobTitle" #jobTitle="ngModel" maxlength="50" required=""
                                            class="form-control">
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="input-block">
                                        <label class="input-label">
                                            Empresa:
                                            <span class="mandatory-check" data-toggle="tooltip" data-placement="top"
                                                title="Indica campo requerido">*</span>
                                        </label>
                                        <input (blur)="removeSpaceFromString(frmCreateClient,'company')" type="text" placeholder="Empresa" id="company" name="company"
                                            [(ngModel)]="model.company" #company="ngModel" maxlength="50" required=""
                                            class="form-control">
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-6">
                                    <div class="input-block">
                                        <label class="input-label">
                                            # Colaboradores Expuestos:
                                            <span class="mandatory-check" data-toggle="tooltip" data-placement="top"
                                                title="Indica campo requerido">*</span> </label>
                                        <input (blur)="removeSpaceFromString(frmCreateClient,'exposedCollaborators')" type="text" placeholder="collaborators expuestos"
                                            id="exposedCollaborators" name="exposedCollaborators"
                                            [(ngModel)]="model.exposedCollaborators" #exposedCollaborators="ngModel"
                                            maxlength="50" required="" class="form-control">
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="input-block">
                                        <label class="input-label"># Colaboradores Totales:</label>
                                        <input (blur)="removeSpaceFromString(frmCreateClient,'totalCollaborators')" type="text" name="totalCollaborators"
                                            [(ngModel)]="model.totalCollaborators" #totalCollaborators="ngModel"
                                            placeholder="Colaboradores Totales" id="totalCollaborators" maxlength="50"
                                            class="form-control">
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                    <div class="consultation-information">
                        <div class="contact-title">
                            <span>ASPECTOS SOBRE LA CONSULTA</span>
                        </div>

                        <div class="form-data">
                            <div class="row">
                                <div class="col-md-12">
                                    <div class="input-block">
                                        <label class="input-label">
                                            ¿Cuenta actualmente con un Test de personalidad basada en la seguridad?
                                            <span class="mandatory-check" data-toggle="tooltip" data-placement="top"
                                                title="Indica campo requerido">*</span>
                                        </label>
                                        <div class="contact-radio">
                                            <label for="personalitytestSi" class="radio-btn">
                                                <input type="radio" id="personalitytestSi" required
                                                    name="isSafetyBasedPersonalityTest"
                                                    [(ngModel)]="model.isSafetyBasedPersonalityTest"
                                                    #isSafetyBasedPersonalityTest="ngModel" value="Si">
                                                <span class="checkmark"></span>Si
                                            </label>
                                            <label for="personalitytestNo" class="radio-btn">
                                                <input type="radio" id="personalitytestNo" required
                                                    name="isSafetyBasedPersonalityTest"
                                                    [(ngModel)]="model.isSafetyBasedPersonalityTest"
                                                    #isSafetyBasedPersonalityTest="ngModel" value="No">
                                                <span class="checkmark"></span>No
                                            </label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-12">
                                    <div class="input-block dd-block">
                                        <label class="input-label">
                                            ¿Sobre qué tipo de riesgos, servicios o productos es su consulta? 
                                            <span class="mandatory-check" data-toggle="tooltip" data-placement="top"
                                                title="Indica campo requerido">*</span>
                                        </label>
                                        <ng-multiselect-dropdown required name="riskTypes" [(ngModel)]="model.riskTypes"
                                            #riskTypes="ngModel" id="riskTypes" [placeholder]="'Seleccione'"
                                            [settings]="dropdownSettings" [data]="riskTypesDropDownList"
                                            (onSelect)="onItemSelect($event)" (onSelectAll)="onSelectAll($event)"
                                            class="multi-select">
                                        </ng-multiselect-dropdown>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-12">
                                    <div class="input-block">
                                        <label class="input-label">
                                            ¿Cual es la actividad que realiza?
                                            <span class="mandatory-check" data-toggle="tooltip" data-placement="top"
                                                title="Indica campo requerido">*</span>
                                        </label>
                                        <input (blur)="removeSpaceFromString(frmCreateClient,'activityName')" type="text" placeholder="¿Cual es la actividad que realiza?"
                                            id="activityDo" name="activityName" [(ngModel)]="model.activityName"
                                            #activityName="ngModel" maxlength="50" required="" class="form-control">
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-12">
                                    <div class="input-block">
                                        <label class="input-label">
                                            ¿Con qu&#233; frecuencia realiza esa actividad?
                                            <span class="mandatory-check" data-toggle="tooltip" data-placement="top"
                                                title="Indica campo requerido">*</span>
                                        </label>
                                        <ng-multiselect-dropdown required [placeholder]="'Seleccione'"
                                            [settings]="dropdownSettingsForActivity" [data]="activityTypeDropDownList"
                                            (onSelect)="onItemSelect($event)" (onSelectAll)="onSelectAll($event)"
                                            class="multi-select" name="activityTypes" [(ngModel)]="model.activityTypes"
                                            #activityTypes="ngModel">
                                        </ng-multiselect-dropdown>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-12">
                                    <div class="input-block">
                                        <label class="input-label">
                                            ¿Ha identificado los riesgos espec&#237;ficos de la actividad?
                                            <span class="mandatory-check" data-toggle="tooltip" data-placement="top"
                                                title="Indica campo requerido">*</span>
                                        </label>
                                        <div class="contact-radio">
                                            <label for="specificRisksSi" class="radio-btn">
                                                <input required type="radio" id="specificRisksSi"
                                                    name="isRiskIdentified" [(ngModel)]="model.isRiskIdentified"
                                                    #isRiskIdentified="ngModel" value="Si">
                                                <span class="checkmark"></span>Si
                                            </label>
                                            <label for="specificRisksNo" class="radio-btn">
                                                <input required type="radio" id="specificRisksNo"
                                                    name="isRiskIdentified" [(ngModel)]="model.isRiskIdentified"
                                                    #isRiskIdentified="ngModel" value="No">
                                                <span class="checkmark"></span>No
                                            </label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-12">
                                    <div class="input-block">
                                        <label class="input-label">
                                            ¿Cual es el número # de personas que realizan esa actividad o se exponen a ese riesgo?
                                            <span class="mandatory-check" data-toggle="tooltip" data-placement="top"
                                                title="Indica campo requerido">*</span>
                                        </label>
                                        <input (blur)="removeSpaceFromString(frmCreateClient,'employeeActivity')" type="text"
                                            placeholder="¿Cual es el número # de personas que realizan esa actividad o se exponen a ese riesgo?"
                                            id="employeeActivity" name="employeeActivity"
                                            [(ngModel)]="model.employeeActivity" #employeeActivity="ngModel"
                                            maxlength="500" required="" class="form-control">
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-12">
                                    <div class="input-block">
                                        <label class="input-label">
                                            ¿Actualmente usan EPP (Equipos de Proteccion Personal) para esta actividad?
                                            <span class="mandatory-check" data-toggle="tooltip" data-placement="top"
                                                title="Indica campo requerido">*</span>
                                        </label>
                                        <div class="contact-radio">
                                            <label for="ppeSi" class="radio-btn">
                                                <input required type="radio" id="ppeSi" name="isPPE"
                                                    [(ngModel)]="model.isPPE" #isPPE="ngModel" value="Si">
                                                <span class="checkmark"></span>Si
                                            </label>
                                            <label for="ppeNo" class="radio-btn">
                                                <input required type="radio" id="ppeNo" name="isPPE"
                                                    [(ngModel)]="model.isPPE" #isPPE="ngModel" value="No">
                                                <span class="checkmark"></span>No
                                            </label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-8">
                                    <div class="input-block">
                                        <label class="input-label">
                                            ¿Como podemos ayudarle?
                                            <span class="mandatory-check" data-toggle="tooltip" data-placement="top"
                                                title="Indica campo requerido">*</span>
                                        </label>
                                        <input (blur)="removeSpaceFromString(frmCreateClient,'comment')" type="text" placeholder="¿Como podemos ayudarle?" id="comment"
                                            name="comment" [(ngModel)]="model.comment" #comment="ngModel"
                                            maxlength="500" required="" class="form-control">
                                    </div>
                                </div>
                                <div class="col-md-4">
                                    <div class="input-block">
                                        <label class="input-label">
                                            ¿Requiere que realicemos una inspección virtual de su área durante la llamada para entender mejor sus requerimientos?
                                        </label>
                                        <div class="contact-radio">
                                            <label for="physicalActivitySi" class="radio-btn">
                                                <input type="radio" id="physicalActivitySi" name="isPhysicalVisit"
                                                    [(ngModel)]="model.isPhysicalVisit" #isPhysicalVisit="ngModel"
                                                    value="Si">
                                                <span class="checkmark"></span>Si
                                            </label>
                                            <label for="physicalActivitySiNo" class="radio-btn">
                                                <input type="radio" id="physicalActivitySiNo" name="isPhysicalVisit"
                                                    [(ngModel)]="model.isPhysicalVisit" #isPhysicalVisit="ngModel"
                                                    value="No">
                                                <span class="checkmark"></span>No
                                            </label>
                                            <label for="physicalActivitySiNoSe" class="radio-btn">
                                                <input type="radio" id="physicalActivitySiNoSe" name="isPhysicalVisit"
                                                    [(ngModel)]="model.isPhysicalVisit" #isPhysicalVisit="ngModel"
                                                    value="No Se">
                                                <span class="checkmark"></span>No Se
                                            </label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                               
                                <!-- Tenant User List -->
                                <div class="col-md-4">
                                    <div class="input-block">
                                        <label class="input-label">Seleccionar Asesores <span class="mandatory-check"
                                                data-toggle="tooltip" data-placement="top"
                                                title="Select User">*</span> </label>
                                        <select class="form-control" [(value)]="model.accountId"
                                            [(ngModel)]="model.accountId" name="account user" (ngModelChange)="onChangeUser()">
                                            <option value="0" disabled>Seleccionar Asesores</option>

                                            <option *ngFor="let item of tenantUserList" [value]="item.id">
                                                {{item.fullName}}</option>

                                            <!-- <option value="18:00">06:00 PM</option> -->

                                        </select>
                                        <div class="arrow"></div>
                                    </div>
                                </div>
   <!-- Tenant User List -->
                                <div class="col-md-4">
                                    <div class="input-block">
                                        <label class="input-label">Seleccionar Fecha <span class="mandatory-check"
                                                data-toggle="tooltip" data-placement="top"
                                                title="Seleccionar Fecha">*</span> </label>
                                        <input (ngModelChange)="getAvailableSlot($event)" name="dtSendInvitation"
                                            id="dtSendInvitation" placeholder="mm/dd/yy" 
                                            [min]="minDate"
                                            [owlDateTimeTrigger]="dtSendInvitation" [owlDateTime]="dtSendInvitation"
                                            class="form-control date-control" [(ngModel)]="invitationDate"
                                            [owlDateTimeFilter]="myFilter" required>
                                        <owl-date-time [pickerType]="'calendar'" #dtSendInvitation></owl-date-time>
                                        <div class="arrow" [owlDateTimeTrigger]="dtSendInvitation"></div>
                                    </div>
                                </div>
                                <!-- [min]="minDate" -->
                                <div class="col-md-4">
                                    <div class="input-block">
                                        <label class="input-label">Seleccionar Hora <span class="mandatory-check"
                                                data-toggle="tooltip" data-placement="top"
                                                title="Seleccionar Hora">*</span> </label>
                                        <select class="form-control" [(value)]="model.callScheduleTime"
                                            [(ngModel)]="model.callScheduleTime" name="callScheduleTime">
                                            <option value="None">Ninguno</option>

                                            <option *ngFor="let item of timeSlot" [value]="item.key">
                                                {{item.value}}</option>

                                            <!-- <option value="18:00">06:00 PM</option> -->

                                        </select>
                                        <div class="arrow"></div>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-6">
                                    <div class="input-block">
                                        <label class="input-label">Adjunte cualquier imagen o archivo que nos ayuda a
                                            comprender su consulta</label>
                                        <!--<input type="file" placeholder="" id="attachFile" name="attachFile" maxlength="50" required="" class="form-control">-->
                                        <div class="upload-block">
                                            <div class="upload-area" draggable="true">
                                                <div class="upload-img">
                                                    <img src="../../../../assets/images/upload-file.svg" />
                                                </div>
                                                <div class="text-part">
                                                    <span class="top">Arrastrar & Soltar Archivo</span>
                                                    <span class="btm">O</span>
                                                </div>
                                                <label class="select-file">
                                                    <span class="input-section">
                                                        <input (change)="handleInputChange($event)" type="file"
                                                            id="browseNew" style="display:none">
                                                        Encontrar archivo
                                                    </span>
                                                    <span class="file-name">{{fileDetail?.name}}</span>
                                                </label>
                                                <button (click)="contactUsFileDetail = null;fileDetail =null;"
                                                    *ngIf="fileDetail?.name" class="close-btn" type="submit">
                                                    <i class="fa fa-times-circle" aria-hidden="true"></i>
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div>
                                <validation-summary [validationErrors]="validationMessages"></validation-summary>
                            </div>
                            <div class="button-block">
                                <button type="button" class="btn comm-btn cancel"
                                    (click)="resetForm(frmCreateClient)">Reiniciar</button>
                                <button class="btn comm-btn add" type="submit">Guardar y Cerrar</button>
                            </div>
                        </div>
                    </div>
                </div>
            </form>
        </div>
    </div>
</div>
