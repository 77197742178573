<header class="header">
    <div class="navbar navbar-inverse navbar-fixed-top">
        <div class="row">
            <div class="navbar-header">
                <button type="button" class="navbar-toggle" data-toggle="collapse" data-target=".navbar-collapse">
                    <span class="icon-bar"></span>
                    <span class="icon-bar"></span>
                    <span class="icon-bar"></span>
                </button>
                <div class="logo">
                    <a >
                        <img src="assets/images/header-logo.svg" />
                    </a>
                </div>
            </div>
            <div class="navbar-collapse collapse">

                <div class="top-nav">
                    <ul class="nav navbar-nav">
                        <li>
                            <div class="log-out">
                                <a class="btn-secondary dropdown-toggle logout-button" role="button" id="dropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                    <span class="ico"><img src="../../../assets/images/user-prfl-new-ico.svg" /></span>
                                    <!--<span class="text">NM</span>-->
                                </a>
                                <div class="dropdown-menu" aria-labelledby="dropdownMenuLink">
                                  <div class="caret"></div>
                                  <div class="user-profile">
                                    <div class="profile-part">
                                      <div class="profile-desc-img">
                                          <!--<div [innerHtml]='msg' *ngIf="msg" style="color: red;"></div>-->
                                        <img src="../../../assets/images/user-pf-big.svg" />
                                        <!--<img [src]="url" *ngIf="url" />-->
                                        <!--<span>{{userProfileData.initials}}</span>-->
                                          <!--<label>
                                              <a class="input-section">
                                                  <input type="file" id="browseNew" style="display:none" (change)="selectFile($event)">
                                                  <img (click)="selectClick()" class="up-btn" src="../../../assets/images/edit-btn-up.svg" />
                                              </a>
                                          </label>-->
                                      </div>
                                      <div class="profile-name">
                                        <span class="name" title="{{userProfileData.adjusterName}}">{{userProfileData.adjusterName}}</span>
                                        <span class="email" title="{{userProfileData.emailId}}">{{userProfileData.emailId}}</span>
                                      </div>
                                      <div class="clear"></div>
                                    </div>
                                    <div class="button-block">
                                      <button class="btn comm-btn" (click)="signOut()">Cerrar sesi&#243;n</button>
                                    </div>
                                  </div>
                                </div>
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
            <div class="clear"></div>
        </div>
    </div>
</header>

<!--<div class="modal fade profile-pop" id="end-data" data-backdrop="static" aria-hidden="true" role="dialog">
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="profile-title">
                <button type="button" class="close" data-dismiss="modal">
                    <img src="../../../assets/images/close-pop.svg" />
                </button>
                <div class="main-profile-img">
                    <img src="../../../assets/images/profile-edit-img.svg" />
                    <label>
                        <span class="input-section">
                            <input type="file">
                            <img class="cam" src="../../../assets/images/only-edit-img-cam.svg" />
                        </span>
                    </label>
                </div>
            </div>
            <div class="modal-body">
                <div class="name-info-block">
                    <input type="text" placeholder="John Doe" />
                    <button class="btn"><img src="../../../assets/images/edit-btn.svg" />Edit</button>
                    <!--<button class="btn">Done</button>
                </div>
            </div>
        </div>
    </div>
</div>-->
