<div class="container-area">
    <div class="extra-one">
        <div class="content-area bg-content">
            <div class="main-title">
                <span>Calendario - Semana</span>
            </div>
            <div class="full-cal-view">
                <div class="cal-top-block">
                    <div class="row">
                        <div class="col-md-2">
                            <div class="today-part">
                                <span class="today">hoy </span><span>{{todayValue}}</span>
                            </div>
                        </div>
                        <div class="col-md-2">
                            <div class="user-part" *ngIf="isSuperAdmin && teammateList.length > 0">
                                <div class="dropdown">
                                    <a class="dropdown-toggle" id="menu1" data-toggle="dropdown">
                                        <div class="User-block-part">
                                            <img src="../../../assets/images/user-prfl-new-ico.svg" />
                                            <span>{{selectedTeamMate.name}}</span>
                                        </div>
                                        <div class="clear"></div>
                                        <img class="arrow-only" src="../../../../assets/images/dd-arrow.svg" />
                                    </a>
                                    <ul class="dropdown-menu" role="menu" aria-labelledby="menu1">
                                        <li>
                                            <a role="menuitem" (click)="loadCalendarForMe()">
                                                <div class="User-block-part">
                                                    <img src="../../../assets/images/user-prfl-new-ico.svg" />
                                                    <span>Me</span>
                                                </div>
                                            </a>
                                            <a role="menuitem" *ngFor="let item of teammateList" (click)="loadCalendarForTeamMember(item)">
                                                <div class="User-block-part">
                                                    <img src="../../../assets/images/user-prfl-new-ico.svg" />
                                                    <span>{{item.name}}</span>
                                                </div>
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-5">
                            <div class="option-desc">
                                <a style="cursor:pointer" (click)='moveNextORPreviousWeek(7)'>
                                    <img src="../../../assets/images/calender-left.svg" />
                                </a>
                                <span>{{weekFromDate}}</span>
                                <a style="cursor:pointer" (click)='moveNextORPreviousWeek(-7)'>
                                    <img src="../../../assets/images/calender-right.svg" />
                                </a>
                            </div>
                        </div>
                        <div class="col-md-2">
                            <!--<div class="type-part">
                <span>Week</span>
              </div>-->
                        </div>
                        <div class="col-md-1">
                            <div class="more-part">
                                <a href="/dashboard"><img src="../../../assets/images/less-img.svg" />Menos</a>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="cal-main-block">
                    <div class="top-titles">
                        <ul>
                            <li></li>
                            <li [ngClass]="{'active': isCurrentDay(day)}" *ngFor="let day of weekday">
                                {{day}}
                            </li>
                        </ul>
                        <div class="clear"></div>
                    </div>
                    <div class="cal-desc">
                        <ul>
                            <li class="first-child">
                                <div class="cal-timings">
                                    <span id="box">8 AM</span>
                                    <span>8.30 AM</span>
                                    <span>9 AM</span>
                                    <span>9.30 AM</span>
                                    <span>10 AM</span>
                                    <span>10.30 AM</span>
                                    <span>11 AM</span>
                                    <span>11.30 AM</span>
                                    <span>12 PM</span>
                                    <span>12.30 PM</span>
                                    <span>1 PM</span>
                                    <span>1.30 PM</span>
                                    <span>2 PM</span>
                                    <span>2.30 PM</span>
                                    <span>3 PM</span>
                                    <span>3.30 PM</span>
                                    <span>4 PM</span>
                                    <span>4.30 PM</span>
                                    <span>5 PM</span>
                                    <span>5.30 PM</span>
                                </div>
                            </li>

                            <span *ngFor="let originalEvent of events">
                <li [ngClass]="{'past': originalEvent.isPastDate}">
                  <div class="blank-part"></div>
                  <div class="cal-opt-section">
                    <div class="cal-block-part" [ngClass]="{'data-avl': (event.claimCaseId > 0 && event.confStatusId != 5 && event.confStatusId != 1), 'data-avl-reschedule': event.claimCaseId > 0 && (event.confStatusId == 5 || event.confStatusId == 1), 'click-part': (loggedInUser == adjusterId) && (event.confStatusId == 4 || event.confStatusId == 2)}" *ngFor="let event of originalEvent.eventDetails">
                      <div class="approve-part" *ngIf="event.claimCaseId > 0" (click)="redirectToPage(originalEvent, event)">
                        <span class="name-part">{{event.initials}}</span>
                            <div class="details-approve">
                                <span class="approve-name" title="{{event.firstName}} {{event.lastName}}">{{event.firstName}} {{event.lastName}}</span>
                                <span *ngIf="event.confStatusId == 1" class="status wait">Pendiente</span>
                                <span *ngIf="event.confStatusId == 2" class="status accept">Aceptado</span>
                                <span *ngIf="event.confStatusId == 3" class="status cancel">Cancelado</span>
                                <span *ngIf="event.confStatusId == 4" class="status done">Completado</span>
                                <span *ngIf="event.confStatusId == 5">
                            <span *ngIf="event.rescheduledConferenceId == 1" title="Pending acceptance from organizer" class="status reschedule">Reprogramado</span>
                                <span *ngIf="event.rescheduledConferenceId == 2" title="Pending acceptance from '{{event.firstName}} {{event.lastName}}'" class="status reschedule">Reprogramado</span>
                                </span>
                            </div>
                            <div class="clear"></div>
                    </div>
                </div>
            </div>
            </li>
            </span>
            </ul>
            <div class="clear"></div>
        </div>
    </div>
</div>
</div>
</div>
</div>
<div>
    <validation-summary [validationErrors]="validationMessages"></validation-summary>
</div>
<!--<div class="container-area">
  <div class="extra-one">
    <div class="content-area bg-content">
      <div class="main-title">
        <span>Calender - Week View</span>
      </div>
      <div class="full-cal-view">
        <div class="cal-top-block">
          <div class="row">
            <div class="col-md-2">
              <div class="today-part">
                <span class="today">Today </span><span>(21 Apr)</span>
              </div>
            </div>
            <div class="col-md-2">
              <div class="user-part">
                <div class="dropdown">
                  <a class="dropdown-toggle" id="menu1" data-toggle="dropdown">
                    <div class="User-block-part">
                      <img src="../../../assets/images/header-user.png" />
                      <span>John Doe</span>
                    </div>
                    <div class="clear"></div>
                    <img class="arrow-only" src="../../../../assets/images/dd-arrow.svg" />
                  </a>
                  <ul class="dropdown-menu" role="menu" aria-labelledby="menu1">
                    <li>
                      <a role="menuitem">
                        <div class="User-block-part">
                          <img src="../../../assets/images/header-user.png" />
                          <span>John Doe</span>
                        </div>
                      </a>
                    </li>
                    <li>
                      <a role="menuitem">
                        <div class="User-block-part">
                          <img src="../../../assets/images/header-user.png" />
                          <span>John Doe</span>
                        </div>
                      </a>
                    </li>
                    <li>
                      <a role="menuitem">
                        <div class="User-block-part">
                          <img src="../../../assets/images/header-user.png" />
                          <span>John Doe</span>
                        </div>
                      </a>
                    </li>
                    <li>
                      <a role="menuitem">
                        <div class="User-block-part">
                          <span class="name">JD</span>
                          <span>John Doe</span>
                        </div>
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div class="col-md-5">
              <div class="option-desc">
                <a href="">
                  <img src="../../../assets/images/calender-left.svg" />
                </a>
                <span>19 Apr-25 April,2020</span>
                <a href="">
                  <img src="../../../assets/images/calender-right.svg" />
                </a>
              </div>
            </div>
            <div class="col-md-2">
              <div class="type-part">
                <span>Week</span>
              </div>
            </div>
            <div class="col-md-1">
              <div class="more-part">
                <a href="/dashboard"><img src="../../../assets/images/less-img.svg" />Less</a>
              </div>
            </div>
          </div>
        </div>
        <div class="cal-main-block">
          <div class="top-titles">
            <ul>
              <li>EST</li>
              <li>19 Sunday</li>
              <li>20 Monday</li>
              <li class="active">21 Tuesday</li>
              <li>22 Wednesday</li>
              <li>23 Thursday</li>
              <li>24 Friday</li>
              <li>25 Friday</li>
            </ul>
            <div class="clear"></div>
          </div>
          <div class="cal-desc">
            <ul>
              <li>
                <div class="cal-timings">
                  <span>8 PM</span>
                  <span>8.30 PM</span>
                  <span>9 PM</span>
                  <span>9.30 PM</span>
                  <span>10 PM</span>
                  <span>10.30 PM</span>
                  <span>11 PM</span>
                  <span>11.30 PM</span>
                  <span>12 AM</span>
                  <span>12.30 AM</span>
                  <span>1 AM</span>
                  <span>1.30 AM</span>
                  <span>2 AM</span>
                  <span>2.30 AM</span>
                  <span>3 AM</span>
                  <span>3.30 AM</span>
                  <span>4 AM</span>
                </div>
              </li>
              <li class="past">
                <div class="blank-part"></div>
                <div class="cal-opt-section">
                  <div class="cal-block-part"></div>
                  <div class="cal-block-part"></div>
                  <div class="cal-block-part"></div>
                  <div class="cal-block-part"></div>
                  <div class="cal-block-part"></div>
                  <div class="cal-block-part"></div>
                  <div class="cal-block-part"></div>
                  <div class="cal-block-part"></div>
                  <div class="cal-block-part"></div>
                  <div class="cal-block-part"></div>
                  <div class="cal-block-part"></div>
                  <div class="cal-block-part"></div>
                  <div class="cal-block-part"></div>
                  <div class="cal-block-part"></div>
                  <div class="cal-block-part"></div>
                  <div class="cal-block-part"></div>
                </div>
              </li>
              <li class="past">
                <div class="blank-part"></div>
                <div class="cal-opt-section">
                  <div class="cal-block-part"></div>
                  <div class="cal-block-part"></div>
                  <div class="cal-block-part"></div>
                  <div class="cal-block-part"></div>
                  <div class="cal-block-part data-avl">
                    <div class="approve-part">
                      <span class="name-part">NC</span>
                      <div class="details-approve">
                        <span class="approve-name" title="Nickolas Caznberg">Nickolas Caznberg</span>
                        <span>Status: </span><span class="status done">Completed</span>
                      </div>
                      <div class="clear"></div>
                    </div>
                  </div>
                  <div class="cal-block-part"></div>
                  <div class="cal-block-part"></div>
                  <div class="cal-block-part"></div>
                  <div class="cal-block-part"></div>
                  <div class="cal-block-part"></div>
                  <div class="cal-block-part"></div>
                  <div class="cal-block-part data-avl">
                    <div class="approve-part">
                      <span class="name-part">NC</span>
                      <div class="details-approve">
                        <span class="approve-name" title="Nickolas Caznberg">Nickolas Caznberg</span>
                        <span>Status: </span><span class="status cancel">Cancelled</span>
                      </div>
                      <div class="clear"></div>
                    </div>
                  </div>
                  <div class="cal-block-part"></div>
                  <div class="cal-block-part"></div>
                  <div class="cal-block-part"></div>
                  <div class="cal-block-part"></div>
                </div>
              </li>
              <li>
                <div class="blank-part"></div>
                <div class="cal-opt-section">
                  <div class="cal-block-part"></div>
                  <div class="cal-block-part today-bar"></div>
                  <div class="cal-block-part"></div>
                  <div class="cal-block-part"></div>
                  <div class="cal-block-part data-avl">
                    <div class="approve-part">
                      <span class="name-part">NC</span>
                      <div class="details-approve">
                        <span class="approve-name" title="Nickolas Caznberg">Nickolas Caznberg</span>
                        <a href="/video-call">Llamar ahora</a>
                      </div>
                      <div class="clear"></div>
                    </div>
                  </div>
                  <div class="cal-block-part"></div>
                  <div class="cal-block-part"></div>
                  <div class="cal-block-part"></div>
                  <div class="cal-block-part data-avl">
                    <div class="approve-part">
                      <span class="name-part">NC</span>
                      <div class="details-approve">
                        <span class="approve-name" title="Nickolas Caznberg">Nickolas Caznberg</span>
                        <span>Status: </span><span class="status accept">Accepted</span>
                      </div>
                      <div class="clear"></div>
                    </div>
                  </div>
                  <div class="cal-block-part"></div>
                  <div class="cal-block-part"></div>
                  <div class="cal-block-part"></div>
                  <div class="cal-block-part"></div>
                  <div class="cal-block-part"></div>
                  <div class="cal-block-part"></div>
                  <div class="cal-block-part"></div>
                </div>
              </li>
              <li>
                <div class="blank-part"></div>
                <div class="cal-opt-section">
                  <div class="cal-block-part"></div>
                  <div class="cal-block-part"></div>
                  <div class="cal-block-part data-avl">
                    <div class="approve-part">
                      <span class="name-part">NC</span>
                      <div class="details-approve">
                        <span class="approve-name" title="Nickolas Caznberg">Nickolas Caznberg</span>
                        <span>Status: </span><span class="status accept">Accepted</span>
                      </div>
                      <div class="clear"></div>
                    </div>
                  </div>
                  <div class="cal-block-part"></div>
                  <div class="cal-block-part"></div>
                  <div class="cal-block-part"></div>
                  <div class="cal-block-part"></div>
                  <div class="cal-block-part"></div>
                  <div class="cal-block-part"></div>
                  <div class="cal-block-part"></div>
                  <div class="cal-block-part data-avl">
                    <div class="approve-part">
                      <span class="name-part">NC</span>
                      <div class="details-approve">
                        <span class="approve-name" title="Nickolas Caznberg">Nickolas Caznberg</span>
                        <span>Status: </span><span class="status wait">Pending</span>
                      </div>
                      <div class="clear"></div>
                    </div>
                  </div>
                  <div class="cal-block-part"></div>
                  <div class="cal-block-part"></div>
                  <div class="cal-block-part"></div>
                  <div class="cal-block-part"></div>
                  <div class="cal-block-part"></div>
                </div>
              </li>
              <li>
                <div class="blank-part"></div>
                <div class="cal-opt-section">
                  <div class="cal-block-part"></div>
                  <div class="cal-block-part data-avl">
                    <div class="approve-part">
                      <span class="name-part">NC</span>
                      <div class="details-approve">
                        <span class="approve-name" title="Nickolas Caznberg">Nickolas Caznberg</span>
                        <span>Status: </span><span class="status wait">Pending</span>
                      </div>
                      <div class="clear"></div>
                    </div>
                  </div>
                  <div class="cal-block-part"></div>
                  <div class="cal-block-part"></div>
                  <div class="cal-block-part"></div>
                  <div class="cal-block-part"></div>
                  <div class="cal-block-part"></div>
                  <div class="cal-block-part"></div>
                  <div class="cal-block-part"></div>
                  <div class="cal-block-part"></div>
                  <div class="cal-block-part data-avl">
                    <div class="approve-part">
                      <span class="name-part">NC</span>
                      <div class="details-approve">
                        <span class="approve-name" title="Nickolas Caznberg">Nickolas Caznberg</span>
                        <span>Status: </span><span class="status accept">Accepted</span>
                      </div>
                      <div class="clear"></div>
                    </div>
                  </div>
                  <div class="cal-block-part"></div>
                  <div class="cal-block-part"></div>
                  <div class="cal-block-part"></div>
                  <div class="cal-block-part"></div>
                  <div class="cal-block-part"></div>
                </div>
              </li>
              <li>
                <div class="blank-part"></div>
                <div class="cal-opt-section">
                  <div class="cal-block-part"></div>
                  <div class="cal-block-part"></div>
                  <div class="cal-block-part"></div>
                  <div class="cal-block-part data-avl">
                    <div class="approve-part">
                      <span class="name-part">NC</span>
                      <div class="details-approve">
                        <span class="approve-name" title="Nickolas Caznberg">Nickolas Caznberg</span>
                        <span>Status: </span><span class="status accept">Accepted</span>
                      </div>
                      <div class="clear"></div>
                    </div>
                  </div>
                  <div class="cal-block-part"></div>
                  <div class="cal-block-part"></div>
                  <div class="cal-block-part"></div>
                  <div class="cal-block-part"></div>
                  <div class="cal-block-part">
                    <div class="approve-part data-avl">
                      <span class="name-part">NC</span>
                      <div class="details-approve">
                        <span class="approve-name" title="Nickolas Caznberg">Nickolas Caznberg</span>
                        <span>Status: </span><span class="status wait">Pending</span>
                      </div>
                      <div class="clear"></div>
                    </div>
                  </div>
                  <div class="cal-block-part"></div>
                  <div class="cal-block-part"></div>
                  <div class="cal-block-part"></div>
                  <div class="cal-block-part"></div>
                  <div class="cal-block-part"></div>
                  <div class="cal-block-part"></div>
                  <div class="cal-block-part"></div>
                </div>
              </li>
              <li>
                <div class="blank-part"></div>
                <div class="cal-opt-section">
                  <div class="cal-block-part"></div>
                  <div class="cal-block-part"></div>
                  <div class="cal-block-part data-avl">
                    <div class="approve-part">
                      <span class="name-part">NC</span>
                      <div class="details-approve">
                        <span class="approve-name" title="Nickolas Caznberg">Nickolas Caznberg</span>
                        <span>Status: </span><span class="status accept">Accepted</span>
                      </div>
                      <div class="clear"></div>
                    </div>
                  </div>
                  <div class="cal-block-part"></div>
                  <div class="cal-block-part"></div>
                  <div class="cal-block-part data-avl">
                    <div class="approve-part">
                      <span class="name-part">NC</span>
                      <div class="details-approve">
                        <span class="approve-name" title="Nickolas Caznberg">Nickolas Caznberg</span>
                        <span>Status: </span><span class="status accept">Accepted</span>
                      </div>
                      <div class="clear"></div>
                    </div>
                  </div>
                  <div class="cal-block-part data-avl">
                    <div class="approve-part">
                      <span class="name-part">NC</span>
                      <div class="details-approve">
                        <span class="approve-name" title="Nickolas Caznberg">Nickolas Caznberg</span>
                        <span>Status: </span><span class="status accept">Accepted</span>
                      </div>
                      <div class="clear"></div>
                    </div>
                  </div>
                  <div class="cal-block-part data-avl">
                    <div class="approve-part">
                      <span class="name-part">NC</span>
                      <div class="details-approve">
                        <span class="approve-name" title="Nickolas Caznberg">Nickolas Caznberg</span>
                        <span>Status: </span><span class="status wait">Pending</span>
                      </div>
                      <div class="clear"></div>
                    </div>
                  </div>
                  <div class="cal-block-part"></div>
                  <div class="cal-block-part"></div>
                  <div class="cal-block-part"></div>
                  <div class="cal-block-part"></div>
                  <div class="cal-block-part"></div>
                  <div class="cal-block-part"></div>
                  <div class="cal-block-part"></div>
                  <div class="cal-block-part"></div>
                </div>
              </li>
            </ul>
            <div class="clear"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>-->

<div class="mobile-warning">
    <span><img src="../../../assets/images/error-mobile.svg" /> El portal de usuarios de CASCO Safety no es compatible con este dispositivo. Por favor, acceda a CASCO Safety en pantalla completa desde su escritorio. </span>
</div>