<div class="authentication-container">
    <div class="container">
        <div class="verification-auth">
            <div class="logo-block">
                <a href="https://adjustify.co/"><img src="../../../../assets/images/auth-logo.svg" /></a>
            </div>
            <div class="auth-title">
                <span></span>Su contraseña ha sido cambiado exitosamente!
            </div>
            <div class="already-status">
                <span>Por favor <a href="/login">Ingresar</a> para continuar...</span>
            </div>
        </div>
    </div>
</div>