<div class="container-area">
    <div class="extra-one">
        <div class="content-area bg-content authentication-container">
            <div class="add-claim">
                <div class="section-title">
                    <span>{{pageName}}</span>
                </div>
                <div *ngIf="!isEdit" class="row">
                    <div class="col-md-12">
                        <div class="input-block">
                            <label class="input-label">Seleccione tipo<span class="mandatory-check">
                                    *</span></label>
                            <select id="lossType" name="lossType" [(ngModel)]="typeId" class="form-control ex-control"
                                (change)="changeType()">
                                <option value="0" disabled>Seleccione tipo</option>
                                <option value="1">Crear nuevo ticket</option>
                                <option value="2">Obtener boleto</option>
                            </select>
                        </div>
                    </div>
                </div>
                <form *ngIf="typeId == 2" #getTicketForm="ngForm" (ngSubmit)="getTicketFromOdoo(getTicketForm);">
                    <div class="add-claim-desc">
                        <div class="row">
                            <form #frmSearchClient="ngForm" id="searchClientForm"
                                (ngSubmit)="searchCustomer(frmSearchClient);" autocomplete="off">
                                <div class="col-md-6">
                                    <div class="input-block">
                                        <input type="email" name="customerEmailId" class="form-control" email
                                            [(ngModel)]="claim.email" placeholder="Digitar correo electrónico"
                                            id="email" name="email" maxlength="50" required />
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="button-block">
                                        <button type="submit" class="btn comm-btn add">Buscar</button>
                                    </div>
                                </div>
                            </form>
                        </div>
                       
                        <div class="ticket-container"
                            *ngIf="customerTikcetList && customerTikcetList.length > 0 && !isTicketSelected">
                            <label class="input-label">Seleccionar Ticket</label>
                            <div class="ticket-block">
                                <div class="ticket-list" *ngFor="let item of customerTikcetList">
                                    <div class="row">

                                        <div class="col-md-4">
                                            <label>Nombre</label>
                                            <span class="desc">
                                                {{item?.Title == false ? '-':item.Title}}</span>
                                        </div>
                                        <div class="col-md-4">
                                            <label>Fecha</label>
                                            <span class="desc">{{item?.Date | date:'dd/MM/yyyy'}}</span>
                                        </div>
                                        <div class="col-md-4">
                                            <div class="button-block">
                                                <button (click)="selectTicket(item)" type="submit"
                                                    class="btn comm-btn">Programar Llamada</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <button type="button" (click)="cancel()" class="btn comm-btn add margin">Cancelar</button>
                            </div>
                        </div>
                        <div class="ticket-details" *ngIf="isTicketSelected">
                            <div class="row">
                                <div class="col-md-6">
                                    <label>Nombre</label>
                                    <span class="desc">{{selectedTicket?.Title == false ? '-':selectedTicket.Title}}</span>
                                </div>
                                <div class="col-md-6">
                                    <label>Fecha</label>
                                    <span class="desc">{{selectedTicket?.Date | date:'dd/MM/yyyy'}} </span>
                                </div>

                            </div>
                        </div>
                    </div>
                </form>
                <form *ngIf="isTypeSelected" ngNativeValidate #addBacklogForm="ngForm"
                    (keydown.enter)="$event.preventDefault()">
                    <div class="add-claim-desc">
                        <!-- <div class="row">
                             <div class="col-md-4">
                                <div class="input-block" *ngIf="claim.odooCustomerId > 0">
                                    <label class="input-label">N&#250;mero de Cliente</label>
                                    <input readonly type="number" class="form-control client_number"
                                        placeholder="123456" id="policyNumber" name="PolicyNumber"
                                        [(ngModel)]="claim.odooCustomerId" maxlength="50" />
                                </div>
                            </div>
                            <div class="col-md-4" *ngIf="claim.odooTicketId > 0">
                                <div class="input-block">
                                    <label class="input-label">Número de Ticket<span class="mandatory-check">
                                            *</span></label>
                                    <input readonly type="number" class="form-control" placeholder="8934994"
                                        id="claimnNumber" name="ClaimNumber" [(ngModel)]="claim.odooTicketId"
                                        maxlength="50" required />
                                </div>
                            </div>
                            <div class="col-md-4" [class.col-md-12]="!isEdit && !claim.odooTicketId">
                                <div class="input-block">
                                    <label class="input-label">N&#250;mero &#218;nico</label>
                                    <input type="text" appNumberOnly class="form-control" placeholder="001457896325"
                                        name="UniqueNumber" [disabled]="claim.isAddedFromAPI"
                                        [(ngModel)]="claim.uniqueNumber" maxlength="10" />
                                </div>
                            </div>
                        </div> -->
                        <div class="row">
                            <div class="col-md-8">
                                <div class="input-block">
                                    <label class="input-label">Tipo de Caso<span class="mandatory-check">
                                            *</span></label>
                                    <input *ngIf="!showLossTypeDropDown" type="text" class="form-control"
                                        placeholder="Property" name="TypeOfLoss" [(ngModel)]="claim.lossType"
                                        maxlength="50" required />
                                    <select *ngIf="showLossTypeDropDown" id="lossType" name="lossType"
                                        class="form-control ex-control" [(value)]="claim.lossTypeId"
                                        [(ngModel)]="claim.lossTypeId">
                                        <option value="0">Seleccionar Tipo de Casos</option>
                                        <option *ngFor="let lossType of lossTypes" [value]="lossType.id">
                                            {{lossType?.damageType}}</option>
                                    </select>
                                    <div *ngIf="showLossTypeDropDown" class="arrow"></div>
                                </div>
                            </div>
                            <div class="col-md-4">
                                <div class="input-block">
                                    <label class="input-label">Fecha de Caso</label>
                                    <input name="lossDate" id="lossDate" placeholder="mm/dd/yy" [max]="maxDate"
                                        [owlDateTimeTrigger]="dtLossDate" [owlDateTime]="dtLossDate"
                                        class="form-control date-control" [(ngModel)]="lossDate" required>
                                    <owl-date-time [pickerType]="'calendar'" #dtLossDate></owl-date-time>
                                    <div class="arrow" [owlDateTimeTrigger]="dtLossDate"></div>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-6">
                                <div class="input-block">
                                    <label class="input-label">Primer Nombre<span class="mandatory-check">
                                            *</span></label>
                                    <input type="text" class="form-control" placeholder="Johnathan" id="firstName"
                                        name="FirstName" [(ngModel)]="claim.firstName" maxlength="50" required />
                                </div>
                            </div>
                            <div class="col-md-6">
                                <div class="input-block">
                                    <label class="input-label">Apellidos<span class="mandatory-check"> *</span></label>
                                    <input type="text" class="form-control" placeholder="Doe" id="lastName"
                                        name="LastName" [(ngModel)]="claim.lastName" maxlength="50" required />
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-6">
                                <div class="input-block">
                                    <label class="input-label">Direcci&#243;n Linea 1</label>
                                    <input type="text" class="form-control" placeholder="Lake place no.1" maxlength="50"
                                        id="addressLine1" name="addressLine1" [(ngModel)]="claim.addressLine1"
                                        required />
                                </div>
                            </div>
                            <div class="col-md-6">
                                <div class="input-block">
                                    <label class="input-label">Direcci&#243;n Linea 2</label>
                                    <input type="text" class="form-control" placeholder="Down street" maxlength="50"
                                        id="addressLine2" name="addressLine2" [(ngModel)]="claim.addressLine2" />
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-4">
                                <div class="input-block">
                                    <label class="input-label">Ciudad</label>
                                    <input type="text" class="form-control" placeholder="Managua" maxlength="50"
                                        id="city" name="city" [(ngModel)]="claim.city" required />
                                </div>
                            </div>
                            <div class="col-md-4">
                                <div class="input-block">
                                    <label class="input-label">País</label>
                                    <select id="state" name="state" class="form-control" [(value)]="claim.countryId"
                                        [(ngModel)]="claim.countryId">
                                        <option *ngFor="let country of countryList" [value]="country.id">
                                            {{country?.country}}</option>
                                    </select>
                                    <div class="arrow"></div>
                                </div>
                            </div>
                            <div class="col-md-4">
                                <div class="input-block">
                                    <label class="input-label">C&#243;digo Postal</label>
                                    <input type="text" class="form-control" placeholder="34994" maxlength="50"
                                        id="zipCode" name="zipCode" [(ngModel)]="claim.zipCode" required />
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-6">
                                <div class="input-block dd-block">
                                    <label class="input-label">Tel&#233;fono</label>
                                    <div class="only-extra-part">
                                        <input type="text" class="form-control ph-number" placeholder="1234567890"
                                            id="phoneNumber" name="phoneNumber"
                                            [ngClass]="{'code-one': selectedCountry?.countryISDCode?.length == 2, 'code-two': selectedCountry?.countryISDCode?.length == 3, 'code-three': selectedCountry?.countryISDCode?.length == 4, 'code-four': selectedCountry?.countryISDCode?.length == 5, 'code-six': selectedCountry?.countryISDCode?.length == 7}"
                                            [(ngModel)]="claim.phoneNumber" maxlength="15" minlength="5"
                                            (keypress)="restrictSpecialCharacterForPhoneNumber($event)"
                                            (paste)="restrictPhoneNumberCharactersOnPaste($event)" />
                                        <span class="only-code">{{selectedCountry?.countryISDCode}}</span>
                                    </div>
                                    <div class="tel-dd-part">
                                        <div class="dropdown">
                                            <a class="dropdown-toggle" id="menu1" data-toggle="dropdown">
                                                <span>{{selectedCountry?.countryCode}} </span>
                                                <img
                                                    src="../../../../assets/images/select-arw.svg" />
                                            </a>
                                            <ul class="dropdown-menu" role="menu" aria-labelledby="menu1">
                                                <!--<li class="ph-menu-item"><a><span class="country-id">None</span></a></li>-->
                                                <li class="ph-menu-item" *ngFor="let data of countryList"   (click)="selectCountryNew(data)"><a
                                                        (click)="selectCountryNew(data)"><span
                                                            class="country-id"   (click)="selectCountryNew(data)">{{data.bothCountryCode}}</span></a></li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-6">
                                <div class="input-block">
                                    <label class="input-label">Correo Electr&#243;nico<span class="mandatory-check">
                                            *</span></label>
                                    <input type="text" class="form-control" [disabled]="claim.conferenceId != null"
                                        placeholder="sample@sample.com" id="emailaddress" name="Email address"
                                        [(ngModel)]="claim.email" maxlength="50" required />
                                </div>
                            </div>
                        </div>
                        <div *ngIf="showConf">
                            <div class="row">
                                <div class="col-md-12">
                                    <div class="toggle-part input-block">
                                        <span class="side">Programar e Invitar</span>
                                        <label class="switch">
                                            <input type="checkbox" [disabled]="disableInvite" id="sendInvitation"
                                                name="sendInvitation" [(ngModel)]="claim.sendInvitation">
                                            <span class="slider round" [ngClass]="{'not-sent': disableInvite}"></span>
                                        </label>
                                    </div>
                                </div>
                            </div>
                            <div *ngIf="claim.sendInvitation">
                                <div class="row">
                                    <div class="col-md-4">
                                        <div class="input-block">
                                            <label class="input-label">Seleccionar Fecha</label>
                                            <input name="dtSendInvitation" id="dtSendInvitation" placeholder="mm/dd/yy"
                                                [min]="minDate" [owlDateTimeTrigger]="dtSendInvitation"
                                                [owlDateTime]="dtSendInvitation" class="form-control date-control"
                                                [(ngModel)]="invitationDate" [owlDateTimeFilter]="myFilter" required>
                                            <owl-date-time [pickerType]="'calendar'" #dtSendInvitation></owl-date-time>
                                            <div class="arrow" [owlDateTimeTrigger]="dtSendInvitation"></div>
                                        </div>
                                    </div>
                                    <div class="col-md-4">
                                        <div class="input-block">
                                            <label class="input-label">Seleccionar Hora</label>
                                            <select class="form-control" [(value)]="claim.callScheduleTime"
                                                [(ngModel)]="claim.callScheduleTime" name="callScheduleTime">
                                                <option value="None">Ninguno</option>

                                                <option value="08:00">08:00 AM</option>
                                                <option value="08:30">08:30 AM</option>
                                                <option value="09:00">09:00 AM</option>
                                                <option value="09:30">09:30 AM</option>
                                                <option value="10:00">10:00 AM</option>
                                                <option value="10:30">10:30 AM</option>
                                                <option value="11:00">11:00 AM</option>
                                                <option value="11:30">11:30 AM</option>
                                                <option value="12:00">12:00 PM</option>
                                                <option value="12:30">12:30 PM</option>
                                                <option value="13:00">01:00 PM</option>
                                                <option value="13:30">01:30 PM</option>
                                                <option value="14:00">02:00 PM</option>
                                                <option value="14:30">02:30 PM</option>
                                                <option value="15:00">03:00 PM</option>
                                                <option value="15:30">03:30 PM</option>
                                                <option value="16:00">04:00 PM</option>
                                                <option value="16:30">04:30 PM</option>
                                                <option value="17:00">05:00 PM</option>
                                                <option value="17:30">05:30 PM</option>
                                                <!-- <option value="18:00">06:00 PM</option> -->

                                            </select>
                                            <div class="arrow"></div>
                                        </div>
                                    </div>
                                    <div class="col-md-4">
                                        <div class="notes-only">
                                            <span class="top-info">Nota:</span>
                                            <span class="btm-info">El correo electr&#243;nico de invitaci&#243;n se
                                                enviar&#225; autom&#225;ticamente al cliente.</span>
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-md-4">
                                        <div class="input-block">
                                            <label class="input-label">Agregar en su calendario</label>
                                        </div>
                                    </div>
                                    <div class="col-md-8">
                                        <div class="only-radios">
                                            <label class="radio-inline">
                                                <input type="radio" name="meetingOpt" value="outlook"
                                                    [(ngModel)]="calendarType">
                                                <span class="radiomark"></span>
                                                Outlook
                                            </label>
                                            <label class="radio-inline">
                                                <input type="radio" name="meetingOpt" value="google"
                                                    [(ngModel)]="calendarType">
                                                <span class="radiomark"></span>
                                                Google Calendar
                                            </label>
                                            <label class="radio-inline">
                                                <input type="radio" name="meetingOpt" value="adjustify"
                                                    [(ngModel)]="calendarType">
                                                <span class="radiomark"></span>
                                                CASCO
                                            </label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- <div>
                        <validation-summary [validationErrors]="validationMessages"></validation-summary>
                    </div> -->
                    <div class="button-block">
                        <button class="btn comm-btn cancel" (click)="cancelClick()">Cancelar</button>
                        <button class="btn comm-btn add" data-toggle="modal" (click)="onSubmit()">Guardar y
                            Cerrar</button>
                        <!--<button class="btn comm-btn add" data-toggle="modal" (click)="openPopup()" *ngIf="claim.confStatusId == 1">Save & Close</button>-->
                    </div>


                    <div class=""></div>
                </form>
                <div>
                    <validation-summary [validationErrors]="validationMessages"></validation-summary>
                </div>
            </div>
        </div>
    </div>
</div>


<div class="modal fade invite-pop" id="invite-data" data-backdrop="static" aria-hidden="true" role="dialog">
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-header invite-title">
                <button type="button" class="close" data-dismiss="modal">
                    <img src="../../../../assets/images/modal-close-img.svg" />
                </button>
                <h4 class="modal-title">
                    <span>Desea enviar una nueva invitacion a <br /><a>{{claim.email}} ?</a></span>
                </h4>
            </div>
            <div class="modal-body">
                <div class="pop-info-section">
                    <div class="button-block">
                        <button class="btn comm-btn yes" type="button" (click)="saveAndInviteAgain(true)"
                            name="saveAndInviteAgain">Yes</button>
                        <button class="btn comm-btn" type="button" (click)="saveAndInviteAgain(false)"
                            name="saveAndDoNotInviteAgain">No</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="mobile-warning">
    <span><img src="../../../assets/images/error-mobile.svg" /> El portal de usuarios de CASCO Safety no es compatible
        con este dispositivo. Por favor, acceda a CASCO Safety en pantalla completa desde su escritorio. </span>
</div>