import { Component, OnInit, Input, ViewChild, ElementRef } from '@angular/core';
import { Router } from '@angular/router';

import { Observable, Subscription, timer, pipe } from 'rxjs';
import { map } from 'rxjs/operators';
import { Conference } from './../../models/conference.model';
import { ConferenceInstance, iConnectionLog } from './../../models/conference-instace.model';
import { PushVoipRequest } from './../../models/push-voip-request.model';
import { PushVoipResponse } from './../../models/push-voip-response.model';

import { SharedDataService } from './../../services/sharedData.service';
import { VideoCallService } from './../../api-services/video-call.service';
import { PushVoipService } from './../../api-services/push-voip.service';
import { FileUploadService } from './../../services/file-upload.service';
import { DatePipe } from '@angular/common';
import { ConferenceInstanceLog } from './../../models/conference-instance-log.model';
import { ConferenceInstanceEventType } from './../../models/conference-instance-event.enum';
import { CustomData, PushAndroidPushtokenRequest, data } from '../../models/push-android-pushtoken-request.model';
import { PushAndroidPushtokenService } from '../../api-services/push-android-pushtoken.service';
import { PushAndroidPushtokenResponse } from '../../models/push-android-pushtoken-response.model';

import { ConversationDetailService } from "./../../api-services/conversation-detail.service";
import { Constants } from '../../shared/constant';

declare var log: Function;
declare var log_error: Function;
declare var setConnection: Function;
declare var setUsername: Function;
declare var handleVideoOfferMsg: Function;
declare var handleVideoAnswerMsg: Function;
declare var handleNewICECandidateMsg: Function;
declare var handleHangUpMsg: Function;
declare var hangUpCall: Function;
declare var manageFlash: Function;
declare var invite: Function;
declare var captureImage: Function;
declare var recordedBlobs: any;
declare var canvas: any;
declare var signalingServer: any;
declare var sendToServer: Function;
declare var callWaitTime: any;
declare var enableMeasurement: Function;
declare var startMeasurement: Function;
declare var stopMeasurement: Function;
declare var drawMeasurementStartOrEndPoint: Function;
declare var drawMeasurementRotateIcon: Function;
declare var isCaptureOfMeasurementAllowed: Function;
declare var sendConferenceInstanceId: Function;
declare var sendCaptureImageRequest: Function;
@Component({
  selector: 'app-video-call',
  templateUrl: './video-call.component.html',
  styleUrls: ['./video-call.component.css']
})
export class VideoCallComponent implements OnInit {

  public conference: Conference = {} as Conference;
  public conferenceId: number = 0;
  public callStart: boolean = false;
  public callStartDisable: boolean = false;
  public conferenceInstance = {} as ConferenceInstance;
  public timer = timer(1000);
  public ticks = 0;
  public callStartTime: Date;
  public callEndTime: Date;
  public subscription: Subscription;
  public connecting: boolean = false;
  public capturedImages: any[] = [] as any[];
  public selectedImage: any = {} as any;
  //Video
  public connection: any = null;
  public myHostname: string = window.location.hostname;
  public clientID: number = 0;
  public btnCamera: string = '';
  public btnFlash: string;
  public clientActionTitle: string;
  public clientActionDescription: string;
  callerName: string = 'Caller 1';
  accountId: string;
  public callRejected: boolean = false;
  public callEnded: boolean = false;
  flashOn: boolean = false;
  documentPath: string = '';
  public disableCapture: boolean = false;
  public voipResponse: PushVoipResponse = {} as PushVoipResponse;
  public androidPushTokenResponse: PushAndroidPushtokenResponse = {} as PushAndroidPushtokenResponse;
  public uploadInProgress: boolean = false;
  public percentage: number = 0;
  public videoUploadError: string = undefined;
  public userConnectedToSingnal: boolean = false;
  public data: data = {} as data;
  enabledMeasurementFeature: boolean = false;
  rotatePlusIcon: boolean = false;
  allowRotate: boolean = false;

  connectionLog?: iConnectionLog = {};
  showConnectionLog?: boolean = true;
  showConnectionLoader?: boolean = false;
  isInternet?: boolean = null;
  isServer?: boolean = null;
  isCamera?: boolean = null;
  isMicrophone?: boolean = null;
  isNotification?: boolean = null;
  isOverallCheck?: boolean = null;
  userId?: number;

  storageTypeId?: number;
  showMinSizeMsg?: boolean = true;
  storageType?: string = 'CASCO Safety';

  @ViewChild('showSummary') showSummaryEle: ElementRef;
  @ViewChild('noAction') noActionEle: ElementRef;
  @ViewChild('closeEndData') closeEndDataEle: ElementRef;


  constructor(private videoCallService: VideoCallService,
    private sharedDataService: SharedDataService,
    private router: Router, private pushService: PushVoipService,
    private pushAndroidService: PushAndroidPushtokenService,
    private fileUploadService: FileUploadService, private datepipe: DatePipe,
    private convService: ConversationDetailService) {
    if (!this.myHostname) {
      this.myHostname = "localhost";
    }
    console.log("Hostname: " + this.myHostname);
  }

  ngOnInit() {
    this.userId = this.sharedDataService.getValue("accountId");
    this.accountId = Math.random().toString(36).substring(7);
    this.connect(this.accountId);
    if (this.sharedDataService.getValue("conferenceId")) {
      this.conferenceId = +this.sharedDataService.getValue("conferenceId");
      this.getConferenceInfo();
    }

    this.fileUploadService.videoUploadMessage.subscribe(obj => {
      if (obj) {
        if (obj.percentage && obj.percentage < 100) {
          this.percentage = obj.percentage;
        }
      }
    });
  }

  getConferenceInfo() {
    this.videoCallService.getConferenceInfoById(this.conferenceId).subscribe(
      response => {
        this.conference = response;
        this.getConnectionLog();
        this.getActiveStorageType();
        this.showMeasurementTitle();
      },
      error => {

      });
  }

  //Get active storage type
  public getActiveStorageType() {
    this.convService.getActiveStorageType().subscribe(
      (result) => {
        if (result != null) {
          this.storageTypeId = result;

          if (this.storageTypeId == Constants.storageAccount['dropBox'])
            this.storageType = Constants.dropBox;
          else if (this.storageTypeId == Constants.storageAccount['googleDrive'])
            this.storageType = Constants.googleDrive;
          else if (this.storageTypeId == Constants.storageAccount['oneDrive'])
            this.storageType = Constants.oneDrive;
          else
            this.storageType = Constants.adjustify;

          if (this.storageTypeId != Constants.storageAccount['azure'])
            this.getStorageSize();
        }
      },
      (error) => {
      }
    );
  }

  //Check available size of active storage is less than 75 MB or not
  public getStorageSize() {
    this.convService.getStorageSize(this.storageTypeId).subscribe(
      (result) => {
        if (result != null) {
          this.showMinSizeMsg = result;
        }
      },
      (error) => {
      }
    );
  }

  //Method to close storage size msg
  public closeStorageSizeMsg() {
    this.showMinSizeMsg = true;
  }

  setInitials() {
    var guest = "Guest";
    if (this.conference && this.conference.fullName) {
      guest = this.conference.fullName.charAt(0);
      if (this.conference.lastName != "") {
        guest += this.conference.lastName.charAt(0);
      }
      return guest;
    }
    else {
      return guest.charAt(0);
    }
  }

  startCall() {
    //this.showConnectionLog = false;
    this.callRejected = false;
    this.callStartDisable = true;
    var currentDate = this.datepipe.transform(new Date(), 'yyyy-MM-dd HH:mm:ss');

    if (this.userConnectedToSingnal) {
      sendToServer({
        name: this.accountId,
        date: Date.now(),
        target: (this.conference.deviceType.toLowerCase() == "ios") ? this.conference.voipToken : this.conference.pushToken,
        type: "admin-call-request",
        customdata: {
          voipToken: (this.conference.deviceType.toLowerCase() == "ios") ? this.conference.voipToken : this.conference.pushToken,
          accountId: this.accountId,
          callerName: this.conference.callerFullName,
          companyName: "CASCO Safety",
          caseEmail: this.conference.email,
          fullName: this.conference.fullName,
          conferenceId: this.conferenceId,
          callWaitTime: callWaitTime.toString(),
          currentDate: currentDate.toString()
        }
      });
    }
    else {

      if (this.conference.deviceType.toLowerCase() == "ios") {
        let voipRequest: PushVoipRequest = {
          voipToken: this.conference.voipToken,
          accountId: this.accountId,
          callerName: this.conference.callerFullName,
          companyName: "CASCO Safety",
          caseEmail: this.conference.email,
          fullName: this.conference.fullName,
          conferenceId: this.conferenceId,
          callWaitTime: callWaitTime.toString(),
          currentDate: currentDate.toString()
        } as PushVoipRequest;

        this.pushService.sendVoip(voipRequest).subscribe(
          response => {
            this.voipResponse = response;
            if (this.voipResponse.statusCode != 200) {
              if (!this.callStart) {
                this.clientActionTitle = "Solicitud fallida"
                this.clientActionDescription = "Vuelve a llamar después de un tiempo.";
                this.noActionEle.nativeElement.click();
              }
            }
          }
        );
      }
      else {

        this.data.customdata = {
          voipToken: this.conference.pushToken,
          accountId: this.accountId,
          callerName: this.conference.callerFullName,
          companyName: "CASCO Safety",
          caseEmail: this.conference.email,
          fullName: this.conference.fullName,
          conferenceId: this.conferenceId,
          callWaitTime: callWaitTime.toString(),
          currentDate: currentDate.toString()
        } as CustomData;

        let request: PushAndroidPushtokenRequest = {
          to: this.conference.pushToken,
          data: this.data

        } as PushAndroidPushtokenRequest

        this.pushAndroidService.sendPushToken(request).subscribe(
          response => {
            this.androidPushTokenResponse = response;
            if (this.androidPushTokenResponse.success != 1) {
              if (!this.callStart) {
                this.clientActionTitle = "Solicitud fallida"
                this.clientActionDescription = "El cliente no contestó la llamada, por favor asegúrese que el cliente tenga habilitada las notificaciones push en su dispositivo móvil.";
                this.noActionEle.nativeElement.click();
              }
            }
          }
        );
      }
    }

    setTimeout(() => {
      this.callTimeOut();
    }, callWaitTime);

  }

  callTimeOut() {
    if (!this.callStart && !this.callRejected && this.callStartDisable) {
      console.log("Hello from setTimeout");
      this.clientActionTitle = "Sin respuesta";
      this.clientActionDescription = "Sin respuesta del cliente.";
      this.noActionEle.nativeElement.click();
    }
  }

  flashClick() {
    this.flashOn = !this.flashOn;
    manageFlash();
  }

  createConferenceInstace() {

    this.conferenceInstance = {
      conferenceId: this.conferenceId
    } as ConferenceInstance;

    this.videoCallService.startCall(this.conferenceInstance).subscribe(
      response => {
        if (response > 0) {
          this.callStart = true;
          this.conferenceInstance.conferenceInstanceId = response;
          this.conferenceInstance.conferenceId = this.conferenceId;
          this.callStartTime = new Date();
          this.timer = timer(0, 1000);
          this.subscription = this.timer.subscribe(t => this.ticks = t);

          this.conferenceInstance.claimNumber = this.conference.claimNumber;
          this.conferenceInstance.userId = this.userId;
          this.conferenceInstance.storageTypeId = this.storageTypeId;
          this.conferenceInstance.odooCustomerId = this.conference.odooCustomerId;
          this.conferenceInstance.odooTicketId = this.conference.odooTicketId;
          sendConferenceInstanceId(this.conferenceInstance);
        }
      },
      error => {

      });
  }

  saveFileAndEndCall() {
    this.sharedDataService.showLoader();
    let file = Observable.create((observer) => {
      let fr = new FileReader();
      let data = new Blob(recordedBlobs, { type: 'video/webm' });
      fr.readAsArrayBuffer(data);
      fr.onloadend = () => {
        observer.next(fr.result);
        observer.complete()
      };
      fr.onerror = (err) => {
        observer.error(err)
      }
      fr.onabort = () => {
        observer.error("aborted")
      }
    });


    file.subscribe((fileData) => {
      var res = this.videoCallService.saveFile(this.conference.odooTicketId.toString(), this.conferenceInstance.conferenceInstanceId, this.userId, this.storageTypeId, this.conference.odooCustomerId, this.conference.odooTicketId, new Blob([fileData], { type: 'video/webm' }));

      res.then(() => {
        this.sharedDataService.hideLoader();
        this.sharedDataService.setValue('conferenceId', this.conferenceId);
        this.router.navigate(['review']);

      }).catch(obj => {
        this.sharedDataService.hideLoader();
        if (obj.status == "0") {
          this.videoUploadError = "Algo salió mal. Vuelva a intentarlo después de un tiempo";
        }
        else {
          this.videoUploadError = obj.error.message;
        }
      });

    });
  }

  saveImage(imageData) {
    sendCaptureImageRequest();
    this.disableCapture = false;
    if (canvas) {
      const context = canvas.getContext('2d');
      context.clearRect(0, 0, canvas.width, canvas.height);
    }
    this.selectedImage = JSON.parse(JSON.stringify(this.capturedImages[this.capturedImages.length - 1]));
    // this.conferenceInstance.claimNumber = this.conference.claimNumber;
    // this.conferenceInstance.imageData = imageData;
    // this.conferenceInstance.userId = this.userId;
    // this.conferenceInstance.storageTypeId = this.storageTypeId;
    // this.conferenceInstance.odooCustomerId = this.conference.odooCustomerId;
    // this.conferenceInstance.odooTicketId = this.conference.odooTicketId;
    // this.videoCallService.saveImage(this.conferenceInstance).subscribe(
    //   response => {
    //     this.disableCapture = false;
    //     if (canvas) {
    //       const context = canvas.getContext('2d');
    //       context.clearRect(0, 0, canvas.width, canvas.height);
    //     }
    //     this.selectedImage = JSON.parse(JSON.stringify(this.capturedImages[this.capturedImages.length - 1]));
    //   },
    //   error => {

    //   });
  }

  endCall() {
    hangUpCall();
    this.saveCall();
  }

  saveCall() {
    this.callStart = false;
    this.callStartDisable = false;
    this.callEndTime = new Date();
    if (this.subscription)
      this.subscription.unsubscribe();
    this.saveFileAndEndCall();
    this.endCallProcess();
  }

  endCallProcess() {
    this.videoCallService.endCall(this.conferenceInstance).subscribe(
      response => {
      },
      error => {

      });
  }

  capture() {
    this.disableCapture = true;
    this.selectedImage = {} as any;
    captureImage();
    var _image = { data: canvas.toDataURL(), name: this.conference.claimNumber + '_' + this.getdatetime(new Date()) };
    this.capturedImages.push(JSON.parse(JSON.stringify(_image)));

    var imageData = canvas.toDataURL("image/png");
    imageData = imageData.replace(/^data:image\/(png|jpg);base64,/, "")
    this.saveImage(imageData);
  }

  changeSelected(imageObj: any) {
    this.selectedImage = JSON.parse(JSON.stringify(imageObj));
  }

  displayTime() {
    var hh = Math.floor(this.ticks / 3600);
    var mm = Math.floor((this.ticks % 3600) / 60);
    var ss = this.ticks % 60;

    return this.pad(hh, 2) + ":" + this.pad(mm, 2) + ":" + this.pad(ss, 2);
  }

  pad(n, width) {
    n = n + '';
    return n.length >= width ? n : new Array(width - n.length + 1).join('0') + n;
  }

  connect(myUsername: string): void {
    var that = this;
    console.log("Connected");
    console.log(`--UserName--: ${myUsername}`);
    var serverUrl;
    var scheme = "ws";

    // If this is an HTTPS connection, we have to use a secure WebSocket
    // connection too, so add another "s" to the scheme.

    if (document.location.protocol === "https:") {
      scheme += "s";
    }

    serverUrl = signalingServer;

    console.log(`Connecting to server: ${serverUrl}`);
    this.connection = new WebSocket(serverUrl, "json");
    console.log(`connection...........: ${this.connection}`);

    this.connection.onopen = function (evt) {

    };

    this.connection.onerror = function (evt) {
      console.dir(evt);
    }

    this.connection.onmessage = function (evt) {
      var chatBox = document.querySelector(".chatbox");
      var text = "";
      var msg = JSON.parse(evt.data);
      console.log("Message received: ");

      console.dir(msg);
      var time = new Date(msg.date);
      var timeStr = time.toLocaleTimeString();
      console.log("Message received msg.type: " + msg.type);

      switch (msg.type) {
        case "id":
          setConnection(that.connection, msg.id);
          setUsername(myUsername);
          break;

        case "username":
          text = "<b>Usuaria <em>" + msg.name + "</em> iniciado sesión en. " + timeStr + "</b><br>";
          break;

        case "message":
          text = "(" + timeStr + ") <b>" + msg.name + "</b>: " + msg.text + "<br>";
          break;

        case "rejectusername":
          myUsername = msg.name;
          text = "<b>Su nombre de usuario se ha establecido en <em>" + myUsername +
            "</em> porque el nombre que elegiste está en uso.</b><br>";
          break;

        case "userlist":
          // Received an updated user list
          var userFound = false;
          var token = (that.conference.deviceType.toLowerCase() == "ios") ? that.conference.voipToken : that.conference.pushToken;
          msg.users.forEach(function (username) {
            console.log("username**********: " + username);
            if (username != null && username != undefined && username == token) {
              userFound = true;
            }
          });
          that.userConnectedToSingnal = userFound ? true : false;
          console.log("userlist - userConnectedToSingnal: " + that.userConnectedToSingnal);

          break;
        case "application-foreground":
          that.userConnectedToSingnal = that.conference.voipToken == msg.fromUser ? true : that.userConnectedToSingnal;
          console.log("application-foreground - userConnectedToSingnal: " + that.userConnectedToSingnal);
          break;
        case "application-background":
          that.userConnectedToSingnal = that.conference.voipToken == msg.fromUser ? false : that.userConnectedToSingnal;
          console.log("application-background - userConnectedToSingnal: " + that.userConnectedToSingnal);
          break;
        case "video-offer":  // Invitation and offer to chat
          console.log("video-offer: " + msg);
          handleVideoOfferMsg(msg);
          break;

        case "video-answer":  // Callee has answered our offer
          console.log("video-answer: " + msg);
          handleVideoAnswerMsg(msg);
          enableMeasurement(msg);
          break;

        case "new-ice-candidate": // A new ICE candidate has been received
          handleNewICECandidateMsg(msg);
          break;

        case "IceCandidate": // A new ICE candidate has been received
          handleNewICECandidateMsg(msg);
          break;

        case "hang-up": // The other peer has hung up the call                    
          handleHangUpMsg(msg);
          that.saveCall();
          break;

        case "reject-call": // The other peer has hung up the call
          handleHangUpMsg(msg);
          alert("El receptor rechazó la llamada.");
          break;

        case "ack-camera-rear": // The other peer has hung up the call
          that.btnCamera = "Front Camera";
          break;

        case "ack-camera-front": // The other peer has hung up the call
          that.btnCamera = "Rear Camera";
          break;

        case "ack-flash-on": // The other peer has hung up the call
          that.btnFlash = "Flash Off";
          break;

        case "ack-flash-off": // The other peer has hung up the call
          that.btnFlash = "Flash On";
          break;

        case "ack-capture-image": // The other peer has hung up the call
          //showCapturedImage(msg);
          break;

        case "ack-call-accepted": // The other peer has hung up the call
          console.log("ack - call - accepted" + msg.name);
          invite(msg.name);
          that.createConferenceInstace();
          that.connecting = false;
          that.callStart = true;
          break;

        case "ack-call-rejected": // The other peer has hung up the call
          that.callRejected = true;
          that.callStartDisable = false;
          that.clientActionTitle = "Rechazado";
          that.clientActionDescription = "El cliente ha rechazado la solicitud de llamada.";
          that.noActionEle.nativeElement.click();
          break;

        case "ack-call-accepted-different-user": // The other peer has hung up the call
          that.callRejected = true;
          that.clientActionTitle = "Usuario invalido";
          that.clientActionDescription = "Usuario " + that.conference.fullName + " no está conectado a la aplicación.";
          that.noActionEle.nativeElement.click();
          break;
        case "ack-start-point-on":
          that.rotateMeasurementIcon();
          break;
        case "ack-start-point-fail":
          break;
        case "ack-end-point-on":
          that.rotateMeasurementIcon();
          break;
        case "ack-end-point-fail":
          break;
        case "ack-measurement-allowed":
          isCaptureOfMeasurementAllowed(true);
          break;
        case "ack-measurement-notallowed":
          isCaptureOfMeasurementAllowed(false);
          break;
        case "disconnectuser":
          break;
        // Unknown message; output to console for debugging.

        default:
          log_error("Unknown message received:");
          log_error(msg);
      }
    };
  }

  getdatetime(dt) {
    var res = "";
    res += this.formatdigits(dt.getMonth() + 1);
    res += this.formatdigits(dt.getDate());
    res += this.formatdigits(dt.getFullYear());
    res += this.formatdigits(dt.getHours() > 12 ? dt.getHours() - 12 : dt.getHours());
    res += this.formatdigits(dt.getMinutes());
    res += this.formatdigits(dt.getSeconds());
    return res;
  }

  formatdigits(val) {
    val = val.toString();
    return val.length == 1 ? "0" + val : val;
  }

  refreshCall() {
    handleHangUpMsg();
    this.callStartDisable = false;
    if (this.clientActionTitle == "No Answer") {
      sendToServer({
        name: this.accountId,
        date: Date.now(),
        target: this.conference.voipToken,
        type: "admin-call-cancelled"
      });
    }
  }

  downloadFile() {
    if (this.conferenceId <= 0)
      return;

    this.videoCallService.downloadFile(this.conferenceId).subscribe(
      response => {
        if (window.navigator.msSaveOrOpenBlob) {
          var blob = response;
          window.navigator.msSaveOrOpenBlob(blob, `VideoCall_${this.conferenceId}.zip`);
        }
        else {
          var url = window.URL.createObjectURL(response);
          var a = document.createElement('a');
          document.body.appendChild(a);
          a.setAttribute('style', 'display: none');
          a.href = url;
          a.download = `VideoCall_${this.conferenceId}.zip`;
          a.click();
          window.URL.revokeObjectURL(url);
          a.remove();
        }
      },
      error => {

      });
  }

  public getConnectionLog() {
    //this.showConnectionLoader = true;
    this.videoCallService.getConnectivityLog(this.conference.voipToken).subscribe(
      response => {
        //this.showConnectionLoader = false;
        this.connectionLog = <iConnectionLog>response;

        if (this.connectionLog.internetStatus == "Good")
          this.isInternet = true;
        else if (this.connectionLog.internetStatus == "Not Good")
          this.isInternet = false;

        if (this.connectionLog.serverStatus == "Good")
          this.isServer = true;
        else if (this.connectionLog.serverStatus == "Not Good")
          this.isServer = false;

        if (this.connectionLog.cameraStatus == "Allowed")
          this.isCamera = true;
        else if (this.connectionLog.cameraStatus == "Disabled")
          this.isCamera = false;

        if (this.connectionLog.microphoneStatus == "Allowed")
          this.isMicrophone = true;
        else if (this.connectionLog.microphoneStatus == "Disabled")
          this.isMicrophone = false;

        if (this.connectionLog.notificationStatus == "Allowed")
          this.isNotification = true;
        else if (this.connectionLog.notificationStatus == "Disabled")
          this.isNotification = false;

        if (this.isInternet == true && this.isServer == true && this.isCamera == true && this.isMicrophone == true && this.isNotification == true)
          this.isOverallCheck = true;
        else if (this.isInternet == false || this.isServer == false || this.isCamera == false || this.isMicrophone == false || this.isNotification == false)
          this.isOverallCheck = false;
        else
          this.isOverallCheck = null;
      },
      error => {

      });
  }

  logSession(eventTypeId: number, dataChannel: string) {
    try {
      console.log("logSession: " + this.conference.voipToken);
      let claimVideoSessionLogModel: ConferenceInstanceLog = {
        conferenceInstanceId: this.conferenceInstance.conferenceInstanceId,
        eventTypeId: eventTypeId,
        ipAddress: "",
        voipToken: this.conference.voipToken,
        dataChannelType: dataChannel,
        source: "Admin",
        signalingConnectionId: this.accountId
      } as ConferenceInstanceLog;

      this.videoCallService.insertConferenceInstanceLog(claimVideoSessionLogModel).subscribe(
        response => {
          console.log(eventTypeId + ' - logged');
        },
        error => {
          console.log('Error logging - ' + eventTypeId);
        }
      );
    }
    catch (error) {
      console.log(error.message)
    }
  }

  showMeasurementTitle() {
    if (this.conference.deviceType != undefined) {
      return "La función de medición es compatible con dispositivos compatibles con AR";
    }
  }

  startMeasurementProcess() {
    startMeasurement();
    this.enabledMeasurementFeature = true;
  }

  stopMeasurementProcess() {
    stopMeasurement();
    this.enabledMeasurementFeature = false;
  }

  rotateMeasurementIcon() {
    if (this.rotatePlusIcon) { this.rotatePlusIcon = false; }
    else { this.rotatePlusIcon = true; }
    drawMeasurementRotateIcon();
  }
}
