<!--<div class="container-area">
    <div class="content-area">
        <div class="video-call-container">
            <div class="row">
                <div class="col-md-4">
                    <div class="customer-block">
                        <div class="before-call" *ngIf="!callStart">
                            <img class="v-loader" src="../../../assets/images/video-loader.svg" />
                        </div>
                        <video id="received_video" autoplay></video>
                        <span class="timings">{{displayTime()}}</span>
                        <button class="btn snap" id="btnImg" (click)="capture()" [disabled]="!callStart && disableCapture">
                            <img src="../../../assets/images/snap-click.svg" />
                        </button>
                    </div>
                </div>
                <div class="col-md-1 pad-left">
                    <div class="buttons-block">
                        <div class="btn-part">
                            <button class="btn flash" id="btnFlash" [ngClass]="flashOn ? 'active' : ''" (click)="flashClick()" value="Flash On" disabled></button>
                            <button class="btn" id="btnCamera" onclick="manageCamera()" value="Rear Camera" disabled><img src="../../../assets/images/cam-rotation.svg" /></button>
                            <button class="btn" id="zoomout-button" onclick="zoomOut();" disabled><img src="../../../assets/images/zoom-out.svg" /></button>
                            <button class="btn" id="zoomin-button" onclick="zoomIn();" disabled><img src="../../../assets/images/zoom-in.svg" /></button>
                        </div>
                    </div>
                </div>
                <div class="col-md-7">
                    <div class="adjuster-block">
                        <div class="adjuster-top">
                            <div class="row">
                                <div class="col-md-2">
                                    <div class="adjuter-cam">
                                        <video id="local_video" autoplay [muted]="true"></video>
                                    </div>
                                </div>
                                <div class="col-md-10">
                                    <div class="customer-details">
                                        <div class="cust-info">
                                            <div class="row">
                                                <div class="col-md-4">
                                                    <div class="review-name">
                                                        <div class="name-block">
                                                            <span class="name-info">{{setInitials()}}</span>
                                                            <span class="name-part">{{conference.fullName}}
                                                            <span class="email-part">{{conference.email}}</span>
                                                            </span>
                                                            <div class="clear"></div>
                                                        </div>
                                                        <div class="clear"></div>
                                                    </div>
                                                </div>
                                                <div class="col-md-4">
                                                    <span class="top-title">{{conference.claimNumber}}</span>
                                                </div>
                                                <div class="col-md-4">
                                                    <div class="button-block">
                                                        <button class="btn comm-btn" *ngIf="!callStart" (click)="startCall()" [disabled]="callStartDisable">Start Call</button>
                                                        <button #closeEndData id="endDataButton" [hidden]="true" data-toggle="modal" data-target="#end-data"></button>
                                                        <button id="hangup-button" class="btn comm-btn" *ngIf="callStart" (click)="endCall()" >End Call</button>
                                                        <button #noAction [hidden]="true" data-toggle="modal" data-target="#no-action"></button>
                                                        <input type="text" id="name" value="{{accountId}}" [hidden]="true" />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="adjuster-btm">
                            <div class="row">
                                <div class="col-md-5">
                                    <div class="snap-section">
                                        <div class="snap-title">
                                            <img src="../../../assets/images/snap-cam.svg" />Snapshots
                                        </div>
                                        <div class="latest-snap">
                                            <img src="{{selectedImage?.data}}" *ngIf="selectedImage?.data" />
                                            <canvas id="canvas" width="285" height="435"></canvas>
                                        </div>
                                        <div class="snap-info-part">
                                            <span *ngIf="!capturedImages || capturedImages?.length <= 0">Latest Snapshot Preview</span>
                                        </div>
                                        <span *ngIf="!capturedImages || capturedImages?.length <= 0" class="text-only">Latest Snapshot will appear here</span>
                                    </div>
                                </div>
                                <div class="col-md-7">
                                    <div class="thumb-block">
                                        <div class="thumb-img">
                                            <span class="text-only" *ngIf="!capturedImages || capturedImages?.length <= 0">All Snapshots will appear here</span>
                                            <div class="thumb-section">
                                                <ul>
                                                    <li *ngFor="let imageObj of capturedImages">
                                                        <img src="{{imageObj?.data}}" (click)="changeSelected(imageObj)" />
                                                    </li>
                                                    <div class="clear"></div>
                                                </ul>
                                            </div>
                                        </div>
                                        <div class="snap-info-part right">
                                            <span>All Snapshots</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>-->
<div class="container-area">
  <div class="extra-one">
    <div class="content-area bg-content">
      <div class="video-call-container">
        <div class="row">
          <div class="col-md-3">
            <div class="customer-block">
              <div class="net-container">
                <div class="status-img">
                  <div class="st-check" [ngClass]="{'ok': isOverallCheck == true, 'not-ok' : isOverallCheck == false}"><img src="../../../assets/images/st-check-img.svg" /></div>
                  <div title="Refrescar" class="st-refresh" (click)="getConnectionLog()"><img src="../../../assets/images/n-refresh-ic.svg" /></div>
                </div>
                <div class="check-btm-desc">
                  <div class="status-details-part">
                    <span title="Red" [ngClass]="{'ok': isInternet == true, 'not-ok' : isInternet == false}"><svg id="signal_cellular_alt-24px" xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 30 30"><path id="Path_4096" data-name="Path 4096" d="M21.875,4A1.872,1.872,0,0,1,23.75,5.875v16.25a1.875,1.875,0,0,1-3.75,0V5.875A1.872,1.872,0,0,1,21.875,4Zm-15,12.5A1.872,1.872,0,0,1,8.75,18.375v3.75a1.875,1.875,0,0,1-3.75,0v-3.75A1.872,1.872,0,0,1,6.875,16.5Zm7.5-6.25a1.872,1.872,0,0,1,1.875,1.875v10a1.875,1.875,0,0,1-3.75,0v-10A1.872,1.872,0,0,1,14.375,10.25Z" transform="translate(1.25 1)" fill="#002062" /></svg></span>
                    <span title="Servidor" [ngClass]="{'ok': isServer == true, 'not-ok' : isServer == false}"><svg id="dns-24px" xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 30 30"><path id="Path_4094" data-name="Path 4094" d="M23,15.5H5.5A2.507,2.507,0,0,0,3,18v5a2.507,2.507,0,0,0,2.5,2.5H23A2.507,2.507,0,0,0,25.5,23V18A2.507,2.507,0,0,0,23,15.5ZM8,23a2.5,2.5,0,1,1,2.5-2.5A2.507,2.507,0,0,1,8,23ZM23,3H5.5A2.507,2.507,0,0,0,3,5.5v5A2.507,2.507,0,0,0,5.5,13H23a2.507,2.507,0,0,0,2.5-2.5v-5A2.507,2.507,0,0,0,23,3ZM8,10.5A2.5,2.5,0,1,1,10.5,8,2.507,2.507,0,0,1,8,10.5Z" transform="translate(0.75 0.75)" fill="#002062" /></svg></span>
                    <span title="Camara" [ngClass]="{'ok': isCamera == true, 'not-ok' : isCamera == false}"><svg id="photo_camera-24px" xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 30 30"><path id="Union_86" data-name="Union 86" d="M663.5,12916.5a2.507,2.507,0,0,1-2.5-2.5v-15a2.508,2.508,0,0,1,2.5-2.5h3.961l1.54-1.686a2.528,2.528,0,0,1,1.848-.813h5.3a2.491,2.491,0,0,1,1.838.813l1.549,1.686H683.5a2.507,2.507,0,0,1,2.5,2.5v15a2.507,2.507,0,0,1-2.5,2.5Zm3.751-10a6.25,6.25,0,1,0,6.25-6.25A6.251,6.251,0,0,0,667.251,12906.5Zm2.25,0a4,4,0,1,1,4,4A4,4,0,0,1,669.5,12906.5Z" transform="translate(-658.5 -12891.5)" fill="#002062" /></svg></span>
                    <span title="Micr&#243;fono" [ngClass]="{'ok': isMicrophone == true, 'not-ok' : isMicrophone == false}"><svg id="Group_5754" data-name="Group 5754" xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 30 30"><path id="mic-24px" d="M14.059,17.571a3.887,3.887,0,0,0,3.893-3.893V5.893a3.893,3.893,0,0,0-7.785,0v7.785A3.887,3.887,0,0,0,14.059,17.571Zm7.669-3.893a1.3,1.3,0,0,0-1.272,1.1,6.492,6.492,0,0,1-12.794,0,1.3,1.3,0,0,0-2.569.376,9.073,9.073,0,0,0,7.669,7.5v2.7a1.3,1.3,0,1,0,2.6,0v-2.7a9.073,9.073,0,0,0,7.669-7.5,1.3,1.3,0,0,0-1.3-1.479Z" transform="translate(0.861 0.423)" fill="#002062" /></svg></span>
                    <span title="Notificaci&#243;n" [ngClass]="{'ok': isNotification == true, 'not-ok' : isNotification == false}"><svg id="notifications-24px" xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 30 30"><path id="Path_4092" data-name="Path 4092" d="M13.9,26.875a2.507,2.507,0,0,0,2.5-2.5h-5A2.5,2.5,0,0,0,13.9,26.875Zm7.5-7.5v-6.25c0-3.837-2.05-7.05-5.625-7.9v-.85a1.875,1.875,0,0,0-3.75,0v.85c-3.587.85-5.625,4.05-5.625,7.9v6.25L4.784,20.988a1.25,1.25,0,0,0,.875,2.137H22.122a1.254,1.254,0,0,0,.887-2.137Z" transform="translate(1.103 0.625)" fill="#002062" /></svg></span>
                  </div>
                  <div class="check-info-tip">
                    <div class="st-check-btm" title="Verifique la fuerza de la conexi&#243;n seleccionando el men&#250; 'Diagn&#243;stico de video' en la aplicaci&#243;n m&#243;vil CASCO"><img src="../../../assets/images/st-check-img.svg" /></div>
                  </div>
                </div>
              </div>
              <div class="cust-video-block">
                <div class="before-call" *ngIf="!callStart">
                  <img src="../../../assets/images/custome-bc-loader.svg" />
                </div>
                <video id="received_video" autoplay></video>
              </div>
              <!--<div class="option-block">
                <div class="button-block">
                  <button class="btn measure-plus close-only"><img src="../../../assets/images/measurement-plus.svg" /></button>
                  <button class="btn snap-click" id="btnImg" (click)="capture()" [disabled]="!callStart && disableCapture"><img src="../../../assets/images/snap-click-img.svg" /></button>
                  <div class="ext-btn-part">
                    <button class="btn undo">Undo</button>
                    <button class="btn clear-only">Clear</button>
                  </div>
                  <div class="btm-part">
                    <button class="btn cam-rot" id="btnCamera" onclick="manageCamera()" value="Rear Camera" disabled></button>
                    <button class="btn flash" id="btnFlash" [ngClass]="flashOn ? 'active' : ''" (click)="flashClick()" value="Flash On" disabled></button>
                    <button class="btn z-out" id="zoomout-button" onclick="zoomOut();" disabled></button>
                    <button class="btn z-in" id="zoomin-button" onclick="zoomIn();" disabled></button>
                    <div class="measurement-block show-part">
                      <button class="btn m-mode show-only"></button>
                      <div class=measurement-info>
                        <img class="only-measure" src="../../../assets/images/measurement-white.svg" />
                        <span class="text-desc">Measure mode is ON</span>
                        <span class="close-btn-part"><img src="../../../assets/images/close-measure-mode.svg" /></span>
                      </div>
                    </div>
                  </div>
                </div>-->
              <div class="option-block">
                <div class="button-block">
                  <button class="btn measure-plus" id="btnStartORStopMeasurement" onclick="drawMeasurementStartOrEndPoint()" value="start-point" *ngIf="enabledMeasurementFeature" [ngClass]="rotatePlusIcon ? 'close-only' : ''">
                    <img src="../../../assets/images/measurement-plus.svg" />
                  </button>
                  <button class="btn snap-click" id="btnImg" (click)="capture()" [disabled]="!callStart && disableCapture"><img src="../../../assets/images/snap-click-img.svg" /></button>
                  <div class="ext-btn-part" *ngIf="enabledMeasurementFeature">
                    <button class="btn undo" id="btnUndo" onclick="undoMeasurement()">Deshacer</button>
                    <button class="btn clear-only" id="btnClear" onclick="clearMeasurement()">Limpiar</button>
                    <button *ngIf="conference.deviceType == 'android'" class="btn android-measure">
                      <img src="../../../assets/images/android-measure.svg" />
                    </button>
                  </div>
                  <div class="btm-part">
                    <button class="btn cam-rot" id="btnCamera" onclick="manageCamera()" value="Rear Camera" disabled></button>
                    <button class="btn flash" id="btnFlash" [ngClass]="flashOn ? 'active' : ''" (click)="flashClick()" value="Flash On" disabled></button>
                    <button class="btn z-out" id="zoomout-button" onclick="zoomOut();" disabled></button>
                    <button class="btn z-in" id="zoomin-button" onclick="zoomIn();" disabled></button>
                    <div class="measurement-block" [ngClass]="enabledMeasurementFeature == true ? 'show-part' : ''">
                      <div title={{showMeasurementTitle()}}>
                        <button id="btnMeasurement" class="btn m-mode show-only" (click)="startMeasurementProcess()" disabled></button>
                      </div>
                      <div class="measurement-info">
                        <img class="only-measure" src="../../../assets/images/measurement-white.svg" />
                        <span class="text-desc">El modo de medici&#243;n est&#225; activado</span>
                        <span class="close-btn-part" [ngClass]="enabledMeasurementFeature == false ? '' : 'show-part'"><img src="../../../assets/images/close-measure-mode.svg" (click)="stopMeasurementProcess();" /></span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-9">
            <div class="adjuster-block">
              <div class="adjuster-top">
                <div class="row">
                  <div class="col-md-4 right-border">
                    <div class="name-block">
                      <span class="name-info">{{setInitials()}}</span>
                      <span class="name-part">
                        {{conference.fullName}}
                        <span class="email-part">
                          <span class="mail-only">{{conference.email}}</span>
                          <a class="copy-text" title="copiar al portapapeles" [cdkCopyToClipboard]="conference.email"></a>
                        </span>
                      </span>
                      <div class="clear"></div>
                    </div>
                  </div>
                  <div class="col-md-4">
                    <div class="name-block">
                      <span class="email-part">
                        Número de Ticket
                        <span class="name-part">
                          <span class="mail-only max-only">{{conference.odooTicketId}}</span>
                          <a class="copy-text bottom" title="copiar al portapapeles" [cdkCopyToClipboard]="conference.odooTicketId"></a>
                        </span>
                      </span>
                      <div class="clear"></div>
                    </div>
                  </div>
                  <div class="col-md-4" *ngIf="!showMinSizeMsg">
                    <div class="size-text">
                      <span>
                          Minimo 75MB debiera estar disponible en su almacenamiento antes de iniciar llamada.
                      </span>
                      <a (click)="closeStorageSizeMsg()">
                        <img src="../../../assets/images/close-text-img.svg" />
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              <div class="adjuster-middle">
                <!--<div class="connection-content" *ngIf="showConnectionLog">
                  <div class="connection-top">
                    <div class="row">
                      <div class="col-md-4">
                        <img src="../../../assets/images/coonection-img.svg" />
                        <span class="cnt-text">Connection Strength</span>
                        <span class="tooltip">
                          <img class="hover-img" src="../../../assets/images/hover-img.svg" />
                          <span class="tooltiptext">Check connection strength by selecting the 'Video Diagnostics' menu in the Adjustify Mobile App</span>
                        </span>
                      </div>
                      <div class="col-md-2">
                        <a (click)="getConnectionLog()">
                          <img class="img-only" title="Refresh" src="../../../assets/images/refresh-icon.svg" />
                        </a>
                      </div>
                    </div>
                  </div>
                  <div class="connection-btm">
                      <div class="overlay" *ngIf="showConnectionLoader">
                          <div class="loader-part">
                              <div class="sh1"></div>
                              <div class="sh2"></div>
                              <h4 class="lt">Cargando</h4>
                          </div>
                      </div>
                    <div class="row">
                      <div class="col-md-3">
                        <div class="status-title">
                          <img src="../../../assets/images/internet-stats.svg" />
                          <span>Internet</span>
                        </div>
                        <div class="status-title">
                          <img src="../../../assets/images/server-stats.svg" />
                          <span>Server</span>
                        </div>
                        <div class="status-title">
                          <img src="../../../assets/images/cam-stats.svg" />
                          <span>Camera</span>
                        </div>
                        <div class="status-title">
                          <img src="../../../assets/images/microphone-stats.svg" />
                          <span>Microphone</span>
                        </div>
                        <div class="status-title">
                          <img src="../../../assets/images/notification-stats.svg" />
                          <span>Notification</span>
                        </div>
                      </div>
                      <div class="col-md-2">
                        <div class="status-desc">
                          <span [ngClass]="{'ok': isInternet == true, 'not-ok' : isInternet == false}">{{connectionLog.internetStatus}}</span>
                          <span [ngClass]="{'ok': isServer == true, 'not-ok' : isServer == false}">{{connectionLog.serverStatus}}</span>
                          <span>{{connectionLog.cameraStatus}}</span>
                          <span>{{connectionLog.microphoneStatus}}</span>
                          <span>{{connectionLog.notificationStatus}}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>-->
                <div class="row">
                  <div class="col-md-3">
                    <div class="snap-section">
                      <div class="snap-title">
                        <img src="../../../assets/images/cam-top.svg" />&#218;ltima Foto
                      </div>
                      <div class="latest-snap">
                        <div class="before-new" *ngIf="!capturedImages || capturedImages?.length <= 0">
                          <img src="../../../assets/images/latest-no-img.svg" />
                          <span>No Foto</span>
                        </div>
                        <img src="{{selectedImage?.data}}" *ngIf="selectedImage?.data" />
                        <canvas id="canvas" width="197" height="320"></canvas>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-9">
                    <div class="thumb-section">
                      <div class="row">
                        <div class="col-md-8">
                          <div class="snap-title">
                              Todas Fotos ({{capturedImages.length}})
                          </div>
                        </div>
                        <div class="col-md-4">
                          <div class="number">
                            <span> <span class="out"></span></span>
                          </div>
                        </div>
                      </div>
                      <div class="before-bg" *ngIf="!capturedImages || capturedImages?.length <= 0">
                        <div class="before-new-block">
                          <img src="../../../assets/images/thumb-no-img.svg" />
                          <span>No Fotos</span>
                        </div>
                      </div>
                      <div class="thumb-part">
                        <ul>
                          <li *ngFor="let imageObj of capturedImages">
                            <img src="{{imageObj?.data}}" (click)="changeSelected(imageObj)" />
                          </li>
                        </ul>
                        <div class="clear"></div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="adjuster-bottom">
                <div class="row">
                  <div class="col-md-7">
                    <div class="adjuster-cam-block">
                      <div class="row">
                        <div class="col-md-6 right-border">
                          <div class="adjuter-cam">
                            <video id="local_video" autoplay [muted]="true"></video>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="call-logo">
                            <img src="../../../assets/images/header-logo.svg" />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-5">
                    <div class="call-section" [class.call-end]="callStart">
                      <!--call-end-->
                      <div class="call-block">
                        <div class="call-top">
                          <!--<span>Start the call</span>-->
                          <!--<span>Calling</span>-->
                          <!--<span>Ringing</span>-->
                          <span>{{displayTime()}}</span>
                        </div>
                        <div class="border"></div>
                        <div class="call-option">
                          <div class="call-info">
                            <button class="btn" *ngIf="!callStart" (click)="startCall()" [disabled]="callStartDisable">
                              <img src="../../../assets/images/start-call-btn.svg" />
                            </button>
                            <button #closeEndData id="endDataButton" [hidden]="true" data-toggle="modal" data-target="#end-data"></button>
                            <button class="btn end" id="hangup-button" *ngIf="callStart" (click)="endCall()">
                              <img src="../../../assets/images/call-end.svg" />
                            </button>
                            <button #noAction [hidden]="true" data-toggle="modal" data-target="#no-action"></button>
                            <input type="text" id="name" value="{{accountId}}" [hidden]="true" />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="info-type">
          <span>
            <!--<img src="../../../assets/images/adjustify-small.svg" *ngIf="storageTypeId == 1" />
            <img src="../../../assets/images/dropbox-small.svg" *ngIf="storageTypeId == 2" />-->
              <!-- Su data de llamada sera guardado en {{storageType}}. -->
          </span>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="modal fade summary-pop" id="end-data" data-backdrop="static" aria-hidden="true" role="dialog">
  <div class="modal-dialog">

    <div class="modal-content">
      <div class="modal-header pop-title">
        <button type="button" class="close" data-dismiss="modal">
          <img src="../../../../assets/images/modal-close-img.svg" />
        </button>
        <h4 class="modal-title">
          <span>Llamada desconectada</span>
        </h4>
      </div>
      <div class="modal-body">
        <div class="video-upload-info">
          <span>Se ha perdido la conexión de la llamada debido a una conexión débil de internet. Por favor inténtelo nuevamente.</span>
          <div class="button-block">
            <button type="button" data-dismiss="modal" (click)="endCall()" class="btn comm-btn">Entendido</button>
          </div>
        </div>
      </div>
    </div>
  </div>

</div>

<div id="no-action" class="modal fade not-rcv" data-backdrop="static" aria-hidden="true" role="dialog">
  <div class="modal-dialog">
    <div class="modal-content view-modal">
      <div class="modal-header pop-title">
        <button type="button" class="close" data-dismiss="modal">
          <img src="../../../../assets/images/modal-close-img.svg" />
        </button>
        <h4 class="modal-title">
          <span>{{clientActionTitle}}</span>
        </h4>
      </div>
      <div class="modal-body">
        <p class="text-block">{{clientActionDescription}}</p>
        <div class="button-block">
          <button type="submit" data-dismiss="modal" class="btn comm-btn" (click)="refreshCall()">Ok</button>
        </div>
      </div>
    </div>
  </div>
</div>

