import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { iClaimDetailList } from "../../models/claimDetail.model";
import { iTenant } from "../../models/tenant-registration.model";
import { AuthService } from "../../services/auth.service";
import { SharedDataService } from "../../services/sharedData.service";
import { error } from "@angular/compiler/src/util";
import { ClaimCaseService } from "./../../api-services/claim-case.service";
import { TenantRegistrationService } from "./../../api-services/tenant-registration.service";
import { VideoCallService } from './../../api-services/video-call.service';
import { Conference, ConferenceHistoryResponse } from './../../models/conference.model';
import { PushAndroidPushtokenService } from '../../api-services/push-android-pushtoken.service';
import { PushNotificationRequest, NotificationData } from '../../models/push-android-pushtoken-request.model';

import {
  iWebRescheduleRequestList,
  iRescheduleAcceptRequestWeb,
} from "../../models/dashboard.model";
import { DashboardService } from "./../../api-services/dashboard.service";
declare var $: any;
import { DatePipe } from "@angular/common";
import { ToastrService } from "ngx-toastr";
import { Constants } from "../../shared/constant";
import { LocalizedDateTimePipe } from "src/app/pipes/localized-date-time.pipe";

@Component({
  selector: "app-backlog",
  templateUrl: "./backlog.component.html",
  styleUrls: ["./backlog.component.css"],
})
export class BacklogComponent implements OnInit {
  constructor(
    private router: Router,
    private authService: AuthService,
    private service: ClaimCaseService,
    private sharedService: SharedDataService,
    private tenantService: TenantRegistrationService,
    private dashboardService: DashboardService,
    private datepipe: DatePipe,
    private toastr: ToastrService,
    private videoCallService: VideoCallService,
    private pushAndroidService: PushAndroidPushtokenService,
    private localizedDateTime: LocalizedDateTimePipe
  ) { }

  adjusterId: number = this.sharedService.getValue("accountId");
  tenantId: number = this.sharedService.getValue("tenantId");
  claimDetailList: iClaimDetailList[] = [];
  claimCount?: number;
  tenantData?: iTenant = {};
  validationMessages: any;
  freeTrialEndMessage: string = Constants.freeTrialEndMessage;

  rescheduleModalData?: iWebRescheduleRequestList = {};
  showSlotMsg?: boolean = false;
  public maxDate = new Date();
  public minDate = new Date(
    new Date().getFullYear(),
    new Date().getMonth(),
    new Date().getDate()
  );
  rescheduleDate = new Date(
    new Date().getFullYear(),
    new Date().getMonth(),
    new Date().getDate()
  );
  selected = "None";
  rescheduleData?: iRescheduleAcceptRequestWeb = {};
  public conference: Conference = {} as Conference;
  public conferenceHistoryResponse: ConferenceHistoryResponse = {} as ConferenceHistoryResponse;

  public myFilter = (d: Date): boolean => {
    const day = d.getDay();
    // Prevent Saturday and Sunday from being selected.
    return day !== 0 && day !== 6;
  };

  ngOnInit(): void {
    this.validationMessages = [];
    this.minDate = new Date(
      new Date().getFullYear(),
      new Date().getMonth(),
      new Date().getDate()
    );
    this.rescheduleDate = new Date(
      new Date().getFullYear(),
      new Date().getMonth(),
      new Date().getDate()
    );
    this.selected = "None";

    this.getTenantById();
    this.getAllClaimList();
  }

  //Method to fetch all the claims in list
  getAllClaimList() {
    this.service.getAllClaims(this.adjusterId).subscribe(
      (result) => {
        if (result != null) this.claimDetailList = <iClaimDetailList[]>result;
        //  this.claimDetailList.forEach((event: iClaimDetailList, eventIndex: number) => {
        //  event.confDateTime = this.datepipe.transform(this.sharedService.ConvertUTCTimeToLocalTime(event.confDateTime), 'MM/dd/yy h:mm a');
        //  event.createdDate = this.datepipe.transform(this.sharedService.ConvertUTCTimeToLocalTime(event.createdDate), 'MM/dd/yy h:mm a');
        //});
      },
      (error) => {
        this.populateApiErrorMessages(error.message);
      }
    );
  }

  //Method to redirect to call schedule screen
  scheduleCall(caseId?: number) {
    this.sharedService.setValue("caseId", caseId);
    this.router.navigateByUrl("call-schedule");
  }

  //Method to redirect to add new claim screen
  addNewClaim() {
    this.getClaimCount();
  }

  getClaimCount() {
    this.service.getClaimCount(this.adjusterId).subscribe(
      (result) => {
        if (result != null) {
          if (result) $("#puchase-data").modal("show");
          else {
            this.sharedService.setValue("pageType", "AddClaim");
            this.router.navigateByUrl("claim");
          }
        }
      },
      (error) => {
        this.populateApiErrorMessages(error.message);
      }
    );
  }

  //Method to redirect to calling screen
  goToVideoCallScreen(confId?: number) {
    this.sharedService.setValue("conferenceId", confId);
    this.router.navigateByUrl("video-call");
  }

  public goToImportScreen() {
    this.router.navigateByUrl("import-batch");
  }

  refreshClick() {
    this.service.getAllClaimsRefresh(this.adjusterId).subscribe(
      (result) => {
        if (result != null) this.claimDetailList = <iClaimDetailList[]>result;
      },
      (error) => {
        this.populateApiErrorMessages(error.message);
      }
    );
  }

  public editClick(caseId?: number) {
    this.sharedService.setValue("caseId", caseId);
    this.sharedService.setValue("pageType", "EditClaim");
    this.router.navigateByUrl("claim");
  }

  resendInvite(caseId: number) {
    this.service.resendInvite(caseId).subscribe(
      (result) => {
        if (result != null) {
          if (result == "success") {
            this.successMessage("Invitación enviada con éxito.");
          } else {
            this.errorsMessage(result);
          }
        }
      },
      (error) => {
        this.errorsMessage(error.message);
      }
    );
  }

  successMessage(message) {
    this.toastr.success(message, "Exito");
  }

  errorsMessage(message) {
    this.toastr.error(message, "Error");
  }

  getTenantById() {
    this.tenantService.getTenantById(this.tenantId).subscribe(
      (result) => {
        if (result != null) this.tenantData = <iTenant>result;
      },
      (error) => { }
    );
  }

  //Method to open reschedule modal pop-up
  openRescheduleModal(confId?: any) {
    this.validationMessages = [];
    this.rescheduleDate = new Date(
      new Date().getFullYear(),
      new Date().getMonth(),
      new Date().getDate()
    );
    this.selected = "None";
    this.getRescheduleData(confId);
  }

  getToConversationScreen(confId?: any) {
    this.sharedService.setValue("conversationConfId", confId);
    this.router.navigateByUrl("chat");
  }

  //Method to get reschedule data for modal pop-up
  getRescheduleData(confId?: any) {
    this.service.getRescheduleData(confId).subscribe(
      (result) => {
        if (result != null) {
          this.rescheduleModalData = <iWebRescheduleRequestList>result;
          this.rescheduleDate = new Date(this.rescheduleModalData.dateNewScheduleDate);
          let date = this.rescheduleModalData.dateNewScheduleDate.toLocaleString();

          if (this.rescheduleDate) {
            var utcTime = new Date(date);
            this.selected = this.datepipe.transform(new Date(utcTime.getTime() - (utcTime.getTimezoneOffset() * 60000)), 'hh:mm a')
            this.selected = this.getTimeIn24Format(this.selected)
          }
          $("#backlogResheduler").modal("show");
        }
      },
      (error) => {
        this.populateApiErrorMessages(error.message);
      }
    );
  }

  //Method to reschedule conference
  rescheduleConf() {
    this.validationMessages = [];

    this.populateValidateMessages();

    if (this.validationMessages.length === 0) {
      this.rescheduleData.confId = this.rescheduleModalData.confId;
      this.rescheduleData.adjusterId = this.adjusterId;
      this.rescheduleData.updateDate = new Date();

      var hours = Number(this.selected.split(":")[0]);
      var minutes = Number(this.selected.split(":")[1]);
      this.rescheduleDate.setHours(hours);
      this.rescheduleDate.setMinutes(minutes);
      this.rescheduleDate.setSeconds(0);
      this.rescheduleDate = new Date(this.rescheduleDate.toUTCString());

      this.rescheduleData.proposedDate = this.rescheduleDate;

      this.dashboardService
        .rescheduleConferenceWeb(this.rescheduleData)
        .subscribe(
          (data: any) => {
            if (data !== null) {
              if (data != "success")
                this.validationMessages.push(
                  "El intervalo de tiempo seleccionado no está disponible, seleccione otro."
                );
              else {
                $("#backlogResheduler").modal("hide");
                this.rescheduleDate = new Date(
                  new Date().getFullYear(),
                  new Date().getMonth(),
                  new Date().getDate()
                );
                this.selected = "None";
                this.successMessage("Conferencia reprogramada correctamente.");
                this.getAllClaimList();
                this.sendPushNotificationForReschedule();
              }
            }
          },
          (error) => {
            this.populateApiErrorMessages(error.message);
          }
        );
    }
  }
  sendPushNotificationForReschedule() {
    this.videoCallService.getConferenceInfoById(this.rescheduleModalData.confId).subscribe(
      response => {
        //If Invitation is not accepted then response will be null
        if (response != null) {
          this.conference = response;
          if (this.conference.pushToken != null) {
            this.getConferenceHistory();
          }
        }
      },
      error => {
      });
  }
  getConferenceHistory() {
    this.dashboardService.getConferenceHistoryForWeb(this.rescheduleModalData.confId, this.conference.voipToken).subscribe(
      response => {
        if (response != null) {
          this.conferenceHistoryResponse = response;
          this.conferenceHistoryResponse.callList[0].notificationType = "reschedule";
          this.conferenceHistoryResponse.callList[0].title = this.conferenceHistoryResponse.callList[0].companyName;
          this.conferenceHistoryResponse.callList[0].body = Constants.pushNotificationRescheduleBody;
          this.conferenceHistoryResponse.callList[0].successCode = this.conferenceHistoryResponse.successCode;
          this.conferenceHistoryResponse.callList[0].message = this.conferenceHistoryResponse.message;
          if (this.conference.deviceType.toLowerCase() == "ios") {
            let notification: NotificationData = {
              title: this.conferenceHistoryResponse.callList[0].companyName,
              body: Constants.pushNotificationRescheduleBody
            } as NotificationData;
            let request: PushNotificationRequest = {
              to: this.conference.pushToken,
              data: this.conferenceHistoryResponse.callList[0],
              notification: notification
            } as PushNotificationRequest
            this.pushAndroidService.sendPushNotificaton(request).subscribe(
              response => {
              }
            );
          }
          else {
            let request: PushNotificationRequest = {
              to: this.conference.pushToken,
              data: this.conferenceHistoryResponse.callList[0]
            } as PushNotificationRequest
            this.pushAndroidService.sendPushNotificaton(request).subscribe(
              response => {
              }
            );
          }
        }
      },
      error => {
      });
  }

  populateValidateMessages() {
    if (this.selected == "None") {
      this.validationMessages.push("Por favor seleccione una hora válida.");
      return;
    }
    if (this.rescheduleDate == null) {
      this.validationMessages.push("Seleccione la fecha de reprogramación.");
      return;
    }
    else if (this.rescheduleDate < new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate())) {
      this.validationMessages.push("La fecha de reprogramación no puede ser en el pasado.");
      return;
    }
    else if (this.rescheduleDate.getDay() == 0 || this.rescheduleDate.getDay() == 6) {
      this.validationMessages.push('La fecha reprogramada no puede ser durante el fin de semana.');
      return;
    }

    var dateToCheck = new Date();
    var rescheduleDateToCheck = this.rescheduleDate;
    var hours = Number(this.selected.split(":")[0]);
    var minutes = Number(this.selected.split(":")[1]);
    rescheduleDateToCheck.setHours(hours);
    rescheduleDateToCheck.setMinutes(minutes);

    if (rescheduleDateToCheck <= dateToCheck) {
      this.validationMessages.push(
        "Lo siento, no puede seleccionar este horario. Por favor seleccionar un horario futuro."
      );
    }
  }

  populateApiErrorMessages(message: string) {
    this.validationMessages.push(message);
  }

  redirect() {
    this.sendClaimLimitReachNotification();
    window.open(Constants.meetingURL);
  }

  sendClaimLimitReachNotification() {
    this.service.sendClaimLimitReachNotification(this.adjusterId).subscribe(
      (result) => {

      },
      (error) => {
        this.populateApiErrorMessages(error.message);
      }
    );
  }

  getTimeIn24Format(data): string {
    const [time, modifier] = data.split(' ');

    let [hours, minutes] = time.split(':');

    if (hours === '12') {
      hours = '00';
    }

    if (modifier == 'p. m.') {
      hours = parseInt(hours, 10) + 12;
    }

    return `${hours}:${minutes}`;
  }

  copyMessage(val: string) {
    const selBox = document.createElement('textarea');
    selBox.style.position = 'fixed';
    selBox.style.left = '0';
    selBox.style.top = '0';
    selBox.style.opacity = '0';
    selBox.value = val;
    document.body.appendChild(selBox);
    selBox.focus();
    selBox.select();
    document.execCommand('copy');
    document.body.removeChild(selBox);
    this.toastr.success("Activation code is copied to the clipboard.",)
  }

  getValue(code:string) {
    return code.slice(0,3) + ' ' + code.slice(3,6)
  }
}
