import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient, HttpErrorResponse, HttpHeaders, HttpParams } from '@angular/common/http';
import { PushVoipResponse } from '../models/push-voip-response.model';
import { PushVoipRequest } from '../models/push-voip-request.model';
import { environment } from './../../environments/environment'
import { catchError, retry, map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class PushVoipService {

    constructor(private http: HttpClient) { }
    private headerOptions: HttpHeaders = {} as HttpHeaders;

    public sendVoip(voipRequestParams: PushVoipRequest): Observable<PushVoipResponse> {
        return this.post<PushVoipResponse>("PushVoip", voipRequestParams, undefined, true);
    }

    private fullUrl(url: string): string {
        return environment.voipApiUrl + url;
    }

    public post<T>(url: string, data: any, header: any = undefined, showLoading: boolean = true) {
        return this.http.post<T>(this.fullUrl(url), data, { headers: header ? header : this.headerOptions })
            .pipe(
                map(response => {
                    return response;
                }),
                catchError((err) => { return this.handleError(err); })
            );
    }

    private handleError(response: any) {
        var error = {
            status: response.status,
            message: ''
        };
        if (response.error) {
            if (response.error.status) {
                error.status = response.error.status;
            }

            if (typeof "" == typeof response.error) {
                error.message = response.error;
            }
            else {
                error.message = response.error.message ? response.error.message : response.error.error_description;
            }

        }
        else {
            error.message = response.message;
        }
        return Observable.throw(error);
    };

}
