import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { SettingService } from '../../api-services/setting.service';
import { iInvoiceReceipt } from '../../models/tenant-payment-info.model';
import { Constants } from '../../shared/constant';

@Component({
  selector: 'app-payment-successful',
  templateUrl: './payment-successful.component.html',
  styleUrls: ['./payment-successful.component.css']
})
export class PaymentSuccessfulComponent implements OnInit {

  constructor(private activatedRoute: ActivatedRoute,private service: SettingService) { }
  
  paymentRefId: string;
  invoiceReceipt?: iInvoiceReceipt = {};
  validationMessages: any;
  
  ngOnInit(): void {
    this.activatedRoute.queryParams.subscribe(prms => {
      this.paymentRefId = prms[Constants.intentId];
      this.getInvoiceReceiptData();
  });    
  }

  getInvoiceReceiptData()
  {
    this.service.getInvoiceReceipt(this.paymentRefId).subscribe(result => {
      if (result != null) {
        this.invoiceReceipt = <iInvoiceReceipt>result;    
      }
    }, error => {
      this.populateApiErrorMessages(error.message);
    });
  }
  populateApiErrorMessages(message: string) {
    this.validationMessages.push(message);
  }
}
