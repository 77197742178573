import { Injectable } from '@angular/core';
import { ApiClientService } from './api-client.service';
import { Observable } from 'rxjs';
import { HttpParams } from '@angular/common/http';
import { iTextMsg, iFinalMessageList, iMediaFileDetailsVM, iCopyRequest } from './../models/conversationDetail.model';

@Injectable({
  providedIn: 'root'
})
export class ConversationDetailService {
  constructor(private apiClientService: ApiClientService) { }

  // Get details of conference in Conversation detail tab
  public getConversationDetails(conferanceId: number, localeDateOffset: number): Observable<any> {
    let params = new HttpParams();
    params = params.append("confId", conferanceId.toString());
    params = params.append('localeDateOffset', localeDateOffset.toString());

    return this.apiClientService.getSingle<any>("conversation/getConversationDetails", undefined, params);
  }

  // Get active storage type
  public getActiveStorageType(): Observable<any> {
    let params = new HttpParams();
    return this.apiClientService.getSingle<any>("conversation/getActiveStorageType", undefined, params);
  }

  // Check available size of active storage is less than 75 MB or not
  public getStorageSize(storageTypeId: number): Observable<any> {
    let params = new HttpParams();
    params = params.append("storageTypeId", storageTypeId.toString());
    return this.apiClientService.getSingleWithoutLoader<any>("storageIntegration/getStorageSize", undefined, params);
  }

  // Get call log history
  public getCallLogs(conferanceId: number, localeDateOffset: number): Observable<any> {
    let params = new HttpParams();
    params = params.append("confId", conferanceId.toString());
    params = params.append('localeDateOffset', localeDateOffset.toString());

    return this.apiClientService.getList<any>("conversation/getCallLogs", undefined, params);
  }

  //Method to save conversation
  public saveTextMsg(textMsg: iTextMsg): Observable<any> {
    return this.apiClientService.postWithoutLoader<any>("conversation/saveTextMsg", textMsg);
  }

  //Method to get all conversation
  public getAllConversation(confId: number, dateOffset: number): Observable<iFinalMessageList> {
    let params = new HttpParams();
    params = params.append('confId', confId.toString());
    params = params.append('localeDateOffset', dateOffset.toString());

    return this.apiClientService.getSingle<iFinalMessageList>("conversation/getAllConversation", undefined, params);
  }

  //Method to get all conversation without loader
  public getAllConversationWithoutLoader(confId: number, dateOffset: number): Observable<iFinalMessageList> {
    let params = new HttpParams();
    params = params.append('confId', confId.toString());
    params = params.append('localeDateOffset', dateOffset.toString());

    return this.apiClientService.getSingleWithoutLoader<iFinalMessageList>("conversation/getAllConversation", undefined, params);
  }

  // Get media file details in conversation screen
  public getMediaFileDetails(conferanceId: number, localeDateOffset: number, skip: number, take: number): Observable<iMediaFileDetailsVM[]> {
    let params = new HttpParams();
    params = params.append("confId", conferanceId.toString());
    params = params.append('localeDateOffset', localeDateOffset.toString());
    params = params.append("skip", skip.toString());
    params = params.append("take", take.toString());

    return this.apiClientService.getListWithoutLoader<iMediaFileDetailsVM>("conversation/getMediaFile", undefined, params);
  }

  //Method to delete files
  public deleteFiles(messageIdList: number[]): Observable<any> {
    return this.apiClientService.postWithoutLoader<any>("conversation/deleteFiles", messageIdList);
  }

  //Method to copy files to review
  public copyFilesToReview(copyRequest: iCopyRequest): Observable<any> {
    return this.apiClientService.postWithoutLoader<any>("conversation/copyFilesToReview", copyRequest);
  }
}
