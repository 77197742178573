<div class="container-area">
    <div class="extra-one">
        <div class="content-area bg-content">
            <div class="conversation-main-container">
                <div class="row">
                    <div class="col-md-8">
                        <div class="convo-left">
                            <div class="convo-left-top">
                                <div class="row">
                                    <div class="col-md-1">
                                    </div>
                                    <div class="col-md-4 right-border">
                                        <div class="name-block">
                                            <span class="name-info">{{converationDetails.initials}}</span>
                                            <span class="name-part">
                                                {{converationDetails.customerName}}
                                                <span class="email-part">
                                                    <span class="mail-only">{{converationDetails.email}}</span>
                                                    <a class="copy-text" [cdkCopyToClipboard]="converationDetails.email"
                                                        title="Copiar al portapapeles"></a>
                                                </span>
                                            </span>
                                            <div class="clear"></div>
                                        </div>
                                    </div>
                                    <div class="col-md-3">
                                        <div class="name-block">
                                            <span class="email-part">
                                                Número de Ticket
                                                <span class="name-part">
                                                    <span
                                                        class="mail-only max-only">{{converationDetails.odooTicketId}}</span>
                                                    <a class="copy-text bottom"
                                                        [cdkCopyToClipboard]="converationDetails.odooTicketId"
                                                        title="Copiar al portapapeles"></a>
                                                </span>
                                            </span>
                                            <div class="clear"></div>
                                        </div>
                                    </div>
                                    <div class="col-md-4" *ngIf="!showMinSizeMsg">
                                        <div class="size-text">
                                            <span>
                                                Minimo 75MB debiera estar disponible en su almacenamiento
                                            </span>
                                            <a (click)="closeStorageSizeMsg()">
                                                <img src="../../../assets/images/close-text-img.svg" />
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="convo-content" #conversationDiv>
                                <div class="blank-sheet" *ngIf="showChatIllustration">
                                    <img src="../../../assets/images/blank-chat-img.svg" />
                                    <span>Solo di Hola e inicia el chat con su cliente.</span>
                                </div>
                                <div *ngIf="!showChatIllustration">
                                    <div *ngFor="let item of conversationList.groupMessageList; let i= index">
                                        <div class="dt-info">
                                            <span>{{item.messageDateString | date:"EEE d MMM" }}</span>
                                        </div>
                                        <div class="chat-content"
                                            *ngFor="let msgItem of item.messageList; let i= index">
                                            <div class="chat-content-left"
                                                *ngIf="msgItem.messageOrigin && msgItem.messageType == textMsgType">
                                                <div class="chat-left" [ngClass]="{'first': msgItem.isFirst}">
                                                    {{msgItem.message}}<span
                                                        class="time-block">{{msgItem.messageTime}}</span></div>
                                                <div class="clear"></div>
                                            </div>
                                            <div class="chat-content-right"
                                                *ngIf="!msgItem.messageOrigin && msgItem.messageType == textMsgType">
                                                <div class="chat-right" [ngClass]="{'first': msgItem.isFirst}">
                                                    {{msgItem.message}}<span
                                                        class="time-block">{{msgItem.messageTime}}</span></div>
                                                <div class="clear"></div>
                                            </div>
                                            <div class="chat-content-left"
                                                *ngIf="msgItem.messageOrigin && msgItem.messageType != textMsgType">
                                                <a class="only-file">
                                                    <div class="chat-left fixed-wid"
                                                        [ngClass]="{'first': msgItem.isFirst}">
                                                        <span class="file-type">
                                                            <span class="file-opt">
                                                                <img *ngIf="msgItem.mimeType == '.pdf'"
                                                                    class="view-file"
                                                                    src="../../../assets/images/pdf-img.svg" />
                                                                <img *ngIf="msgItem.mimeType != '.pdf' && msgItem.messageType != 2 && msgItem.messageType != 3"
                                                                    class="view-file"
                                                                    src="../../../assets/images/diff-doc-icon.svg" />
                                                                <img *ngIf="msgItem.messageType == imageFileType || msgItem.messageType == videoFileType"
                                                                    class="view-file"
                                                                    src="../../../assets/images/img-vd-ico.svg" />
                                                            </span>
                                                            <span class="file-desc"
                                                                title="{{msgItem.mediaFileName}}">{{msgItem.mediaFileName}}</span><span>{{msgItem.mimeType}}</span>
                                                            <span class="download-opt"
                                                                (click)="downloadFile(msgItem)"><img
                                                                    src="../../../assets/images/download-icon.svg" />Descargar</span>
                                                        </span>
                                                        <div class="clear"></div>
                                                        <span class="time-block">{{msgItem.messageTime}}</span>
                                                    </div>
                                                </a>
                                            </div>
                                            <div class="clear"></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="chat-box">
                                <div class="row">
                                    <div class="col-md-11 pad-right">
                                        <textarea placeholder="Escribir su mensaje aqu&#237;..." [(ngModel)]="message"
                                            (ngModelChange)="onTextChange($event)" maxlength="256"></textarea>
                                        <span class="text-count">{{ messageLength }}/256</span>
                                    </div>
                                    <div class="col-md-1 pad">
                                        <div class="chat-sent">
                                            <button class="btn" (click)="saveTextMsg()"><img
                                                    src="../../../assets/images/chat-sent-img.svg" /></button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-4">
                        <div class="convo-right">
                            <div class="convo-desc">
                                <ul class="nav nav-tabs">
                                    <li [ngClass]="{'active': isDetailTabActive}" (click)="setDetailsTabData()"><a
                                            data-toggle="tab" href="#details-only">Detalles</a></li>
                                    <li [ngClass]="{'active': isFileTabActive}" (click)="setFilesTabData()"><a
                                            data-toggle="tab" href="#files-only">Archivos</a></li>
                                    <li [ngClass]="{'active': isCallLogTabActive}" (click)="setCallLogsTabData()"><a
                                            data-toggle="tab" href="#logs-only">Registro de Llamadas</a></li>
                                </ul>
                                <div class="tab-content">
                                    <div id="details-only" class="tab-pane fade"
                                        [ngClass]="{'active in': isDetailTabActive}">
                                        <div class="details-block">
                                            <div class="details-content">
                                                <div class="row">
                                                    <div class="col-md-6">
                                                        <span class="block-top">Nombre</span>
                                                        <span class="block-bottom"
                                                            title="{{converationDetails.customerName}}">{{converationDetails.customerName}}</span>
                                                    </div>
                                                    <div class="col-md-6">
                                                        <span class="block-top">Tipo de Caso</span>
                                                        <span class="block-bottom"
                                                            title="{{converationDetails.lossType}}">{{converationDetails.lossType}}</span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="details-content">
                                                <div class="row">
                                                    <div class="col-md-6">
                                                        <span class="block-top">Número de Ticket</span>
                                                        <span class="block-bottom"
                                                            title="{{converationDetails.odooTicketId}}">{{converationDetails.odooTicketId}}</span>
                                                    </div>
                                                    <div class="col-md-6">
                                                        <span class="block-top">N&#250;mero Cliente</span>
                                                        <span class="block-bottom"
                                                            title="{{converationDetails.odooCustomerId}}">{{converationDetails.odooCustomerId}}</span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="details-content">
                                                <div class="row">
                                                    <div class="col-md-6">
                                                        <span class="block-top">Fecha de Caso</span>
                                                        <span class="block-bottom">{{converationDetails.lossDateString |
                                                            date :'dd/MM/yy'}}</span>
                                                    </div>
                                                    <div class="col-md-6">
                                                        <span class="block-top">Llamada Programada</span>
                                                        <span
                                                            class="block-bottom">{{converationDetails.scheduleDateString
                                                            | date :'dd/MM/yy'}}</span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="details-content">
                                                <div class="row">
                                                    <div class="col-md-12">
                                                        <span class="block-top">N&#250;mero de Tel&#233;fono</span>
                                                        <span
                                                            class="block-bottom">{{converationDetails.phoneNumber}}</span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="details-content">
                                                <div class="row">
                                                    <div class="col-md-12">
                                                        <span class="block-top">Email</span>
                                                        <span class="block-bottom"
                                                            title="{{converationDetails.email}}">{{converationDetails.email}}</span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="details-content">
                                                <div class="row">
                                                    <div class="col-md-12">
                                                        <span class="block-top">Direcci&#243;n</span>
                                                        <span class="block-bottom"
                                                            title="{{converationDetails.address}}">{{converationDetails.address}}</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div id="files-only" class="tab-pane fade"
                                        [ngClass]="{'active in': isFileTabActive}">
                                        <div class="files-block">
                                            <div class="blank-desc" *ngIf="showFileIllustration">
                                                <img src="../../../assets/images/blank-file-img.svg" />
                                                <span>
                                                    Su cliente no ha subido ning&#250;n archivo en este momento.
                                                </span>
                                                <!--<span>Your File folder is Empty.<br /> You can only see the files when the<br />Policy Holder starts to upload files.</span>-->
                                            </div>
                                            <div class="overley" *ngIf="showLoader">
                                                <div class="loader-part">
                                                    <div class="sh1"></div>
                                                    <div class="sh2"></div>
                                                    <h4 class="lt">{{loaderMessage}}</h4>
                                                </div>
                                            </div>
                                            <div class="files-top" *ngIf="!showFileIllustration">
                                                <div class="selection" *ngIf="isSelect">
                                                    <span class="dlt-part" (click)="deleteFiles()"><img
                                                            src="../../../assets/images/dlt-icon.svg" />Borrar</span>
                                                </div>
                                                <div class="selection-right">
                                                    <span class="select-part">
                                                        <img src="../../../assets/images/selection-mark.svg"
                                                            *ngIf="!isSelect" />
                                                        <span class="only-text" (click)="selectFiles()"
                                                            *ngIf="!isSelect">Seleccionar</span>
                                                        <span class="only-text" (click)="cancelClick()"
                                                            *ngIf="isSelect">Cancelar</span>
                                                    </span>
                                                    <span class="grid-change" [ngClass]="{'active': isListViewEnable}"
                                                        (click)="setFileDisplayView()"></span>
                                                </div>
                                                <div class="clear"></div>
                                            </div>
                                            <div class="files-btm" *ngIf="!showFileIllustration">
                                                <div class="img-section" *ngIf="!isListViewEnable" #scrollMe
                                                    (scroll)="appendData()">
                                                    <ul class="whole-view">
                                                        <li *ngFor="let item of mediaFileDetails; let i= index; let last = last"
                                                            [ngClass]="{'clickable': isSelect, 'last-only': last}">
                                                            <a>
                                                                <img src="{{item.mediaFileData}}"
                                                                    *ngIf="item.messageType == imageFileType"
                                                                    (click)="selectFilesForDeleteOrCopy(item)" />
                                                                <video id="received_video" controls="controls"
                                                                    *ngIf="item.messageType == videoFileType"
                                                                    (click)="selectFilesForDeleteOrCopy(item)">
                                                                    <source src="" type="video/webm">
                                                                </video>
                                                                <span class="long-doc"
                                                                    *ngIf="item.messageType == documentFileType"
                                                                    (click)="selectFilesForDeleteOrCopy(item)"></span>
                                                            </a>
                                                            <div [ngClass]="{'overlay': item.assignClass == false}"
                                                                (click)="selectFilesForDeleteOrCopy(item)"
                                                                *ngIf="item.assignClass == false">
                                                                <span><img class="only-tick"
                                                                        src="../../../assets/images/tick-mark-new.svg" /></span>
                                                            </div>
                                                            <span class="play-btn" data-click="0"
                                                                *ngIf="item.messageType == videoFileType"
                                                                (click)="selectFilesForDeleteOrCopy(item)"></span>
                                                            <img class="docx"
                                                                *ngIf="item.messageType == documentFileType"
                                                                src="{{item.mediaFileData}}" />
                                                            <span class="info"
                                                                title="{{item.fileName}} {{item.fileSize}}"><img
                                                                    src="../../../assets/images/info-only.svg" />
                                                            </span>
                                                        </li>
                                                        <div class="clear"></div>
                                                    </ul>
                                                </div>
                                                <div class="small-img-section" #scrollMe (scroll)="appendData()"
                                                    *ngIf="isListViewEnable">
                                                    <ul>
                                                        <li *ngFor="let item of mediaFileDetails; let i= index; let last = last"
                                                            [ngClass]="{'clickable': isSelect, 'last-only': last}">
                                                            <div class="info-desc">
                                                                <span class="small-img">
                                                                    <img class="only-small" src="{{item.mediaFileData}}"
                                                                        *ngIf="item.messageType == imageFileType"
                                                                        (click)="selectFilesForDeleteOrCopy(item)" />
                                                                    <video id="received_video" controls="controls"
                                                                        *ngIf="item.messageType == videoFileType"
                                                                        (click)="selectFilesForDeleteOrCopy(item)">
                                                                        <source src="" type="video/webm">
                                                                    </video>
                                                                    <span class="play-btn" data-click="0"
                                                                        *ngIf="item.messageType == videoFileType"
                                                                        (click)="selectFilesForDeleteOrCopy(item)"></span>
                                                                    <span class="only-small docx-small"
                                                                        *ngIf="item.messageType == documentFileType"
                                                                        (click)="selectFilesForDeleteOrCopy(item)"></span>
                                                                    <img class="docx" src="{{item.mediaFileData}}"
                                                                        *ngIf="item.messageType == documentFileType"
                                                                        (click)="selectFilesForDeleteOrCopy(item)" />
                                                                    <div [ngClass]="{'overlay': item.assignClass == false}"
                                                                        (click)="selectFilesForDeleteOrCopy(item)"
                                                                        *ngIf="item.assignClass == false">
                                                                        <span><img class="only-tick"
                                                                                src="../../../assets/images/tick-mark-new.svg" /></span>
                                                                    </div>
                                                                </span>
                                                                <span class="img-desc">
                                                                    <span class="img-desc-top"
                                                                        (click)="selectFilesForDeleteOrCopy(item)">{{item.fileName}}</span>
                                                                    <span class="img-desc-btm"
                                                                        (click)="selectFilesForDeleteOrCopy(item)">Date:
                                                                        {{item.createdDateString}}</span>
                                                                </span>
                                                                <div class="clear"></div>
                                                            </div>
                                                            <div class="size-desc">
                                                                <span
                                                                    (click)="selectFilesForDeleteOrCopy(item)">{{item.fileSize}}</span>
                                                            </div>
                                                            <div class="clear"></div>
                                                        </li>
                                                    </ul>
                                                </div>
                                                <div class="button-type" *ngIf="isSelect">
                                                    <button class="btn comm-btn" (click)="getStorageSize()">Copiar a
                                                        Resumen & Notas</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div id="logs-only" class="tab-pane fade"
                                        [ngClass]="{'active in': isCallLogTabActive}">
                                        <div class="log-block">
                                            <div class="blank-desc" *ngIf="showCallLogIllustration">
                                                <img src="../../../assets/images/blank-log-img.svg" />
                                                <span>Su registro de llamadas esta vacio,<br /> iniciara cuando inicie
                                                    sus llamadas.</span>
                                            </div>
                                            <div *ngIf="!showCallLogIllustration">
                                                <div class="log-content" *ngFor="let item of callLogs; let i= index">
                                                    <span class="log-top">{{item.createdDateString}}</span>
                                                    <div class="log-desc">
                                                        <span class="log-time">{{item.createdDateTime |
                                                            date:'shortTime'}}</span>
                                                        <span class="log-duration">{{item.callDiff}}</span>
                                                    </div>
                                                    <div class="clear"></div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="button-block">
                                <button class="btn comm-btn" (click)="goToReviewScreen()">Resumen & Notas</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div>
                <validation-summary [validationErrors]="validationMessages"></validation-summary>
            </div>
        </div>
    </div>
</div>