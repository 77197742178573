<div id="wrapper">
    <div class="main-container">
        <div class="page-banner animatedParent animateOnce" data-sequence="500">
            <div class="page-title animated fadeInLeft go">
                <h1>Preguntas Frecuentes</h1>
            </div>
        </div>
        <div class="faq-container">
            <div class="faq-main">
                <!------- FAQ Content Start here ----->
                <div class="faq-content animatedParent animateOnce">
                    <div class="container">
                        <div class="faq-accordion-section animated fadeInUp">
                            <div id="accordion1" class="panel-group" role="tablist" aria-multiselectable="false">
                                <div class="panel panel-default">
                                    <div class="panel-heading" role="button" data-toggle="collapse"
                                        data-parent="#accordion1" href="#faq1" aria-expanded="false">
                                        <h4 class="panel-title"><a>&iquest;Qu&eacute; es CASCO Safety?</a></h4>
                                    </div>
                                    <div id="faq1" class="panel-collapse collapse in" role="tabpanel"
                                        aria-labelledby="que1">
                                        <div class="panel-body">
                                            <p>CASCO Safety es un l&iacute;der en soluciones completas de
                                                protecci&oacute;n personal ante riesgos profesionales desde 1998.</p>
                                            <p>CASCO Safety ha servido con excelencia a los clientes industriales
                                                m&aacute;s exigentes de la regi&oacute;n fomentando la cultura de
                                                producir con seguridad manteniendo su posici&oacute;n de liderazgo en
                                                Centroam&eacute;rica.</p>
                                            <p>Ofrecemos equipos de protecci&oacute;n personal, especializados, rescate
                                                y sistemas de ingenier&iacute;a en alturas a la medida.&nbsp;<a
                                                    href="https://www.cascosafety.com" target="_blank"
                                                    rel="noopener">Visitanos aqui.</a></p>
                                        </div>
                                    </div>
                                </div>
                                <div class="panel panel-default">
                                    <div class="panel-heading" role="button" data-toggle="collapse"
                                        data-parent="#accordion1" href="#faq2" aria-expanded="false">
                                        <h4 class="panel-title"><a>&iquest;Qu&eacute; es CASCO Safety App?</a></h4>
                                    </div>
                                    <div id="faq2" class="panel-collapse collapse" role="tabpanel"
                                        aria-labelledby="que1">
                                        <div class="panel-body">
                                            <p>CASCO Safety App es una herramienta de productividad que permite a todos
                                                los clientes de CASCO Safety la oportunidad de tener una mejor y casi
                                                inmediata interacci&oacute;n con nuestros Asesores Comerciales a
                                                trav&eacute;s de videoconferencias, con habilidad para
                                                planificaci&oacute;n de m&uacute;ltiples llamadas seg&uacute;n el tipo
                                                de necesidad que desee solventar, aportando mediciones de alta
                                                precisi&oacute;n con el uso de realidad aumentada. <a
                                                    href="https://www.cascosafetyglobal.com/cascoapp" target="_blank"
                                                    rel="noopener">Conozca mas aqu&iacute;.</a></p>
                                        </div>
                                    </div>
                                </div>
                                <div class="panel panel-default">
                                    <div class="panel-heading" role="button" data-toggle="collapse"
                                        data-parent="#accordion1" href="#faq3" aria-expanded="false">
                                        <h4 class="panel-title"><a>&iquest;C&oacute;mo consigo el c&oacute;digo de 6
                                                d&iacute;gitos?</a></h4>
                                    </div>
                                    <div id="faq3" class="panel-collapse collapse" role="tabpanel"
                                        aria-labelledby="que1">
                                        <div class="panel-body">
                                            <p>El c&oacute;digo de 6 d&iacute;gitos que solicita la app le llegara a su
                                                correo electr&oacute;nico cuando agende una llamada. Recibir&aacute; un
                                                c&oacute;digo de 6 d&iacute;gitos por cada llamada que agende. <a
                                                    href="https://app.cascosafety.com/ticket" target="_blank"
                                                    rel="noopener">Puede agendar una llamada haciendo clic
                                                    aqu&iacute;.</a></p>
                                        </div>
                                    </div>
                                </div>
                                <div class="panel panel-default">
                                    <div class="panel-heading" role="button" data-toggle="collapse"
                                        data-parent="#accordion1" href="#faq4" aria-expanded="false">
                                        <h4 class="panel-title"><a>Aun no recibo el c&oacute;digo de 6 d&iacute;gitos.
                                                &iquest;Qu&eacute; hago?</a></h4>
                                    </div>
                                    <div id="faq4" class="panel-collapse collapse" role="tabpanel"
                                        aria-labelledby="que1">
                                        <div class="panel-body">
                                            <p>El c&oacute;digo tarda aproximadamente 2 minutos en llegar a su correo.
                                                Si pasado este tiempo aun no lo recibe puede revisar su bandeja de Spam
                                                en su correo electr&oacute;nico.</p>
                                        </div>
                                    </div>
                                </div>
                                <div class="panel panel-default">
                                    <div class="panel-heading" role="button" data-toggle="collapse"
                                        data-parent="#accordion1" href="#faq6" aria-expanded="false">
                                        <h4 class="panel-title"><a>&iquest;C&oacute;mo agendo una llamada?</a></h4>
                                    </div>
                                    <div id="faq6" class="panel-collapse collapse" role="tabpanel"
                                        aria-labelledby="que1">
                                        <div class="panel-body">
                                            <p>Puede agendar una llamada en cualquier momento <a
                                                    href="https://app.cascosafety.com/ticket" target="_blank"
                                                    rel="noopener">haciendo clic aqu&iacute;.</a></p>
                                            <p>Nuestros Asesores se pondr&aacute;n en contacto al recibir su cita para
                                                confirmar detalles.</p>
                                        </div>
                                    </div>
                                </div>
                                <div class="panel panel-default">
                                    <div class="panel-heading" role="button" data-toggle="collapse"
                                        data-parent="#accordion1" href="#faq7" aria-expanded="false">
                                        <h4 class="panel-title"><a>&iquest;Estas llamadas tienen alg&uacute;n costo?</a>
                                        </h4>
                                    </div>
                                    <div id="faq7" class="panel-collapse collapse" role="tabpanel"
                                        aria-labelledby="que1">
                                        <div class="panel-body">
                                            <p>No. CASCO Safety est&aacute; comprometido en brindar a sus clientes
                                                herramientas que faciliten la comunicaci&oacute;n de sus necesidades
                                                para lograr soluciones r&aacute;pidas de una forma gratuita para el
                                                cliente. <a href="https://www.cascosafetyglobal.com/cascoapp"
                                                    target="_blank" rel="noopener">Conozca mas aqu&iacute;.</a></p>
                                        </div>
                                    </div>
                                </div>
                                <div class="panel panel-default">
                                    <div class="panel-heading" role="button" data-toggle="collapse"
                                        data-parent="#accordion1" href="#faq8" aria-expanded="false">
                                        <h4 class="panel-title"><a>&iquest;Puedo hacer m&uacute;ltiples llamadas a CASCO
                                                Safety?</a></h4>
                                    </div>
                                    <div id="faq8" class="panel-collapse collapse" role="tabpanel"
                                        aria-labelledby="que1">
                                        <div class="panel-body">
                                            <p>Si. Puede agendar todas las llamadas que usted necesite; nuestro equipo
                                                estar&aacute; feliz de apoyarle. <a
                                                    href="https://www.cascosafetyglobal.com/cascoapp" target="_blank"
                                                    rel="noopener">Conozca mas aqu&iacute;.</a></p>
                                        </div>
                                    </div>
                                </div>
                                <div class="panel panel-default">
                                    <div class="panel-heading" role="button" data-toggle="collapse"
                                        data-parent="#accordion1" href="#faq9" aria-expanded="false">
                                        <h4 class="panel-title"><a>La llamada se cort&oacute;. &iquest;Qu&eacute; debo
                                                hacer?</a></h4>
                                    </div>
                                    <div id="faq9" class="panel-collapse collapse" role="tabpanel"
                                        aria-labelledby="que1">
                                        <div class="panel-body">
                                            <p>La llamada se puede cortar por varias razones, tales como mala
                                                conexi&oacute;n de internet, el cliente abandono la
                                                llamada/tel&eacute;fono por mucho tiempo, el asesor no pod&iacute;a
                                                escucharlo, etc.</p>
                                            <p>Al cortarse la llamada el asesor intentara volver a llamarlo
                                                inmediatamente 3 veces seguidas, si usted no logra contestar la llamada
                                                en esos 3 intentos, puede contactarnos directamente a trav&eacute;s de
                                                <a href="mailto:info@cascosafety.com" target="_blank"
                                                    rel="noopener">info@cascosafety.com</a>, en nuestro sitio web <a
                                                    href="https://www.cascosafety.com" target="_blank"
                                                    rel="noopener">www.cascosafety.com</a>, o en nuestro WhatsApp <a
                                                    href="https://www.cascosafetyglobal.com" target="_blank"
                                                    rel="noopener">haciendo clic aqu&iacute;.</a>
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div class="panel panel-default">
                                    <div class="panel-heading" role="button" data-toggle="collapse"
                                        data-parent="#accordion1" href="#faq10" aria-expanded="false">
                                        <h4 class="panel-title"><a>&iquest;Puedo comunicarme v&iacute;a chat con un
                                                asesor?</a></h4>
                                    </div>
                                    <div id="faq10" class="panel-collapse collapse" role="tabpanel"
                                        aria-labelledby="que1">
                                        <div class="panel-body">
                                            <p>Si. Puede contactarnos directamente a trav&eacute;s de <a
                                                    href="mailto:info@cascosafety.com" target="_blank"
                                                    rel="noopener">info@cascosafety.com</a>, en nuestro sitio web <a
                                                    href="https://www.cascosafety.com" target="_blank"
                                                    rel="noopener">www.cascosafety.com</a>, o en nuestro WhatsApp <a
                                                    href="https://www.cascosafety.com" target="_blank"
                                                    rel="noopener">haciendo clic aqu&iacute;.</a></p>
                                        </div>
                                    </div>
                                </div>
                                <div class="panel panel-default">
                                    <div class="panel-heading" role="button" data-toggle="collapse"
                                        data-parent="#accordion1" href="#faq11" aria-expanded="false">
                                        <h4 class="panel-title"><a>&iquest;Puedo comunicarme con una persona de
                                                atenci&oacute;n al cliente?</a></h4>
                                    </div>
                                    <div id="faq11" class="panel-collapse collapse" role="tabpanel"
                                        aria-labelledby="que1">
                                        <div class="panel-body">
                                            <p>Si. Puede contactarnos directamente a trav&eacute;s de <a
                                                    href="mailto:info@cascosafety.com" target="_blank"
                                                    rel="noopener">info@cascosafety.com</a>, en nuestro sitio web <a
                                                    href="https://www.cascosafety.com" target="_blank"
                                                    rel="noopener">www.cascosafety.com</a>, o en nuestro WhatsApp <a
                                                    href="https://www.cascosafetyglobal.com" target="_blank"
                                                    rel="noopener">haciendo clic aqu&iacute;.</a></p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <!------- FAQ Content End here ----->
            </div>
        </div>
    </div>

</div>