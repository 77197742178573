<div class="all-settings-content">
  <div class="m-user-container">
    <div class="m-user-top">
      <div class="button-block">
        <button class="btn comm-btn" *ngIf="!showInvite" (click)="addMember()"><svg xmlns="http://www.w3.org/2000/svg" width="9.999" height="9.999" viewBox="0 0 9.999 9.999"><path id="Union_67" data-name="Union 67" d="M-6854.166-5336.667v-3.668h-3.668a.667.667,0,0,1-.667-.666.667.667,0,0,1,.667-.666h3.668v-3.667a.666.666,0,0,1,.666-.666.666.666,0,0,1,.666.666v3.667h3.667a.666.666,0,0,1,.666.666.665.665,0,0,1-.666.666h-3.667v3.668a.667.667,0,0,1-.666.666A.667.667,0,0,1-6854.166-5336.667Z" transform="translate(6858.5 5346)" fill="#fff" /></svg>Agregar Usuario</button>
        <div class="block-section" *ngIf="showInvite">
          <div class="input-block">
            <input type="text" class="form-control" placeholder="abc@gmail.com" (focus)="focusFunction()"
                   [(ngModel)]="addUser.emailId" pattern="[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,3}$">
            <button class="btn" (click)="sentInvitation()" [disabled]="disbaleInvite">Invitar</button>
          </div>
        </div>
      </div>
    </div>
    <div>
      <validation-summary [validationErrors]="validationMessages"></validation-summary>
      <div id="resendLink" *ngIf="resendEmail" class="error-block">
        <div class="error-part">
          <span class="warning-icon"><img src="../../../assets/images/error.svg" /></span>
          <div class="error-msg">
              Ya ha agregado {{addUser.emailId}} un usuario. Haga <a style="cursor:pointer" (click)='resendInvitation()'><strong>clic</strong></a> aqu&#237; para enviar la invitaci&#243;n nuevamente.
          </div>
        </div>
      </div>
    </div>
    <div class="m-user-section">
      <div class="no-m-user" *ngIf="count <= 2">
        <img src="../../../../assets/images/no-m-user.svg" />
      </div>
      <div class="user-block">
        <div class="row">
          <div class="col-md-6" *ngFor="let item of userList; let i= index">
            <div class="m-name-block" *ngIf="!item.isPending">
              <span class="name-info">{{item.initials}}</span>
              <span class="name-part" title="{{item.fullName}}">{{item.fullName}}</span>
              <span class="email-part" title="{{item.emailId}}">{{item.emailId}}</span>
              <div class="m-user-dlt" data-toggle="modal" (click)="openDeleteModal(item)">
                  <svg xmlns="http://www.w3.org/2000/svg" width="10" height="2" viewBox="0 0 10 2"><g id="Group_5465" data-name="Group 5465" transform="translate(-0.127 -6)"><rect id="Rectangle_5490" data-name="Rectangle 5490" width="10" height="2" rx="1" transform="translate(0.127 6)" fill="#5E7EBD" /></g></svg>
              </div>
              <div class="clear"></div>
            </div>
            <!-- Pending User -->
            <div class="m-pending-block" *ngIf="item.isPending">
              <span class="pending-info"><img src="../../../../assets/images/pending-user.svg" /></span>
              <span class="email-part" title="{{item.emailId}}">{{item.emailId}}</span>
              <span class="status">Estado: <span class="pending">Pendiente</span></span>
              <div class="m-user-dlt" data-toggle="modal" (click)="openDeleteModal(item)">
                <svg xmlns="http://www.w3.org/2000/svg" width="10" height="2" viewBox="0 0 10 2"><g id="Group_5465" data-name="Group 5465" transform="translate(-0.127 -6)"><rect id="Rectangle_5490" data-name="Rectangle 5490" width="10" height="2" rx="1" transform="translate(0.127 6)" fill="#5E7EBD"/></g></svg>
              </div>
              <div class="clear"></div>
            </div>
          </div>
        </div>
      </div>      
    </div>
  </div>
</div>


<div class="modal fade dlt-pop" id="dlt-data" data-backdrop="static" aria-hidden="true" role="dialog">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header dlt-title">
        <!--<button type="button" class="close" data-dismiss="modal">
          <img src="../../../../assets/images/modal-close-img.svg" />
        </button>-->
        <h4 class="modal-title">
          <span>Seguro que quiere borrar este usuario?</span>
        </h4>
      </div>
      <div class="modal-body">
        <div class="pop-info-section">
          <div class="dlt-name-block">
            <span class="name-info" *ngIf="!userData.isPending">{{userData.initials}}</span>
            <span class="name-part" *ngIf="!userData.isPending">
              {{userData.fullName}}
              <span class="email-part">{{userData.emailId}}</span>
            </span>
            <span class="pending-info" *ngIf="userData.isPending"><img src="../../../../assets/images/pending-user.svg" /></span>
            <span class="email-part pend" *ngIf="userData.isPending" title="{{userData.emailId}}">{{userData.emailId}}</span>
            <div class="clear"></div>
          </div>
          <div class="button-block">
            <button class="btn comm-btn" type="button" (click)="deleteUser()">Si, Borrar.</button>
            <button class="btn comm-btn cancel" type="button" data-dismiss="modal">Cancelar</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
