import { Router, ActivatedRoute } from '@angular/router';
import { iPassword } from '../../../models/tenant-registration.model';
import { SharedDataService } from '../../../services/sharedData.service';
import { TenantRegistrationService } from '../../../api-services/tenant-registration.service';
import { AuthService } from '../../../services/auth.service';
import { AuthModel } from '../../../models/auth.model';
import { Component, OnInit } from '@angular/core';
import { Constants } from '../../../shared/constant';

@Component({
  selector: 'app-confirm-password',
  templateUrl: './confirm-password.component.html',
  styleUrls: ['./confirm-password.component.css']
})
export class ConfirmPasswordComponent implements OnInit {

  constructor(private router: Router,
    private service: TenantRegistrationService,
    private sharedService: SharedDataService,
    private authService: AuthService,
    private activateRoute: ActivatedRoute) { this.authService.redirectToMainPageIfUserIsLoggedIn(); }

  passwordData: iPassword = {};
  validationMessages: any;
  letterFlag: boolean = false;
  digitFlag: boolean = false;
  lengthFlag: boolean = false;
  charFlag: boolean = false;
  showPasswordFormat: boolean = false;
  confirmPassword?: string;
  emailId?: string;

  ngOnInit(): void {
    this.validationMessages = [];
    this.passwordData.accountId = this.sharedService.getValue("accountId");
    this.getEmailId();
    this.activateRoute.queryParams.subscribe(params => {
      if (params['key'] && params['key'] != '') {
        this.getToken(params['key']);
      }
    });
  }

  getEmailId() {
    this.service.getEmailId(this.passwordData.accountId).subscribe(result => {
      if (result != null) {
        this.emailId = <string>result;
      }
    }, error => {
      this.populateApiErrorMessages(error.message);
    });
  }

  getToken(key: string) {
    var authRequest = { authKey: key, requestedBy: window.location.href } as AuthModel;
    this.authService.getToken(authRequest).subscribe((data) => {
      this.authService.redirectToMainPageIfUserIsLoggedIn();
    },
      error => {
        this.populateApiErrorMessages(error.message);
      });
  }

  updatePassword() {
    this.validationMessages = [];

    this.populateValidateMessages();

    if (this.validationMessages.length === 0) {
      this.service.updatePassword(this.passwordData).subscribe(result => {
        if (result != null) {
          if (result) {
            this.authService.login(this.emailId, this.passwordData.password).subscribe((response) => {
              if (response) {
                this.authService.redirectToMainPage(response);
              }
            },
              error => {
                this.populateApiErrorMessages(error.message);
              });
          }
        }
      }, error => {
        this.populateApiErrorMessages(error.message);
      });
    }
  }

  //focus event of password text box to show all password formats
  focusFunction() {
    this.showPasswordFormat = true;
  }

  //focus event of confirm password text box to hide all password formats
  focusRepeatPasswordFunction() {
    //this.showPasswordFormat = false;
  }

  //method to check all the password formats on change event of password text box
  checkPasswordFormat() {
    if (this.isCharCheck(this.passwordData.password))
      this.charFlag = true;
    else
      this.charFlag = false;

    if (this.isUpperCaseCheck(this.passwordData.password) && this.isLowerCaseCheck(this.passwordData.password))
      this.letterFlag = true;
    else
      this.letterFlag = false;

    if (this.passwordData.password.length >= 8)
      this.lengthFlag = true;
    else
      this.lengthFlag = false;

    if (this.isDigitCheck(this.passwordData.password))
      this.digitFlag = true;
    else
      this.digitFlag = false;
  }

  //Check for lower case letter
  isLowerCaseCheck(password) {
    var re = /[a-z]/;
    return re.test(password);
  }

  //Check for upper case letter
  isUpperCaseCheck(password) {
    var re = /[A-Z]/;
    return re.test(password);
  }

  //Check for digit
  isDigitCheck(password) {
    var re = /\d/;
    return re.test(password);
  }

  //Check for special character
  isCharCheck(password) {
    var re = /[!@#$%^&*()]/;
    return re.test(password);
  }

  populateValidateMessages() {
    if (this.passwordData.password === undefined || this.passwordData.password === null || this.passwordData.password === '') {
      this.validationMessages.push('Contrasena requerida.');
    }
    else if (this.confirmPassword === undefined || this.confirmPassword === null || this.confirmPassword === '') {
      this.validationMessages.push(Constants.confPasswordRequiredMsg);
    }
    else if (this.passwordData.password != this.confirmPassword) {
      this.validationMessages.push(Constants.oldNewPasswordNotMatchMsg);
    }
    else if (!this.lengthFlag || !this.charFlag || !this.digitFlag || !this.letterFlag) {
      this.validationMessages.push(Constants.passwordRulesMsg);
    }
  }

  populateApiErrorMessages(message: string) {
    this.validationMessages.push(message);
  }

  focusConfirmPasswordFunction() {
    this.letterFlag = false;
    this.charFlag = false;
    this.digitFlag = false;
    this.lengthFlag = false;
  }

  checkConfirmPasswordFormat() {
    if (this.isCharCheck(this.confirmPassword))
      this.charFlag = true;
    else
      this.charFlag = false;

    if (this.isUpperCaseCheck(this.confirmPassword) && this.isLowerCaseCheck(this.confirmPassword))
      this.letterFlag = true;
    else
      this.letterFlag = false;

    if (this.confirmPassword.length >= 8)
      this.lengthFlag = true;
    else
      this.lengthFlag = false;

    if (this.isDigitCheck(this.confirmPassword))
      this.digitFlag = true;
    else
      this.digitFlag = false;
  }

  checkPassword() {
    this.validationMessages = [];
    if (this.passwordData.password != this.confirmPassword) {
      this.validationMessages.push('La contraseña y la contraseña de confirmación no coinciden.');
    }
  }
  restrictSpace(e) {
    return this.sharedService.restrictSpace(e);
  }
}
