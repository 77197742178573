import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { SharedDataService } from '../../../../services/sharedData.service';
import { TenantRegistrationService } from '../.././../../api-services/tenant-registration.service';
import { iActivationCode } from '../../../../models/tenant-registration.model';

@Component({
  selector: 'app-activate-account',
  templateUrl: './activate-account.component.html',
  styleUrls: ['./activate-account.component.css']
})
export class ActivateAccountComponent implements OnInit {

  constructor(private router: Router,
    private service: TenantRegistrationService,
    private sharedService: SharedDataService) { }

  activateUser: iActivationCode = {};
  validationMessages: any;
  activationCode?: string;

  ngOnInit(): void {
  }

  confirmActivationCode() {
    this.validationMessages = [];

    this.populateValidateMessages();

    if (this.validationMessages.length === 0) {
      this.service.confirmUserActivation(this.activationCode).subscribe(result => {
        if (result != null) {
          this.activateUser = <iActivationCode>result;
          if (this.activateUser.message == "success") {
            this.sharedService.setValue('accountId', this.activateUser.accountId);
            this.router.navigate(['/confirm-password']);
          }            
          else
            this.validationMessages.push(this.activateUser.message);
        }
      }, error => {
          if (error.errorTitle != '')
            this.populateApiErrorMessages(error.errorTitle);
          else
            this.populateApiErrorMessages(error.message);
      });
    }
  }

  populateValidateMessages() {

    if (this.activationCode === undefined || this.activationCode === null || this.activationCode === '') {
      this.validationMessages.push('Activation code is required.');
    }
  }

  populateApiErrorMessages(message: string) {
    this.validationMessages.push(message);
  }
}
