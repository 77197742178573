import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { NgForm } from '@angular/forms'
import { SharedDataService } from '../../../../services/sharedData.service';
import { TenantRegistrationService } from '../.././../../api-services/tenant-registration.service';
import { iActivationCode } from '../../../../models/tenant-registration.model';

@Component({
  selector: 'app-code-request',
  templateUrl: './code-request.component.html',
  styleUrls: ['./code-request.component.css']
})
export class CodeRequestComponent implements OnInit {

  constructor(private router: Router,
    private service: TenantRegistrationService,
    private sharedService: SharedDataService) { }

  @ViewChild('codeResendForm')
  codeResendForm: NgForm;
  message?: string;
  validationMessages: any;
  emailId?: string;

  ngOnInit(): void {
  }

  resendActivationCode() {
    this.validationMessages = [];

    this.populateValidateMessages();

    if (this.validationMessages.length === 0) {
      this.service.resendActivationCode(this.emailId).subscribe(result => {
        if (result != null) {
          this.message = <string>result;
          if (this.message == "true") {            
            this.router.navigate(['/activate-account']);
          }
          else
            this.validationMessages.push(this.message);
        }
      }, error => {
        this.populateApiErrorMessages(error.message);
      });
    }
  }

  populateValidateMessages() {
    if (this.emailId === undefined || this.emailId === null || this.emailId === '') {
      this.validationMessages.push('Correo electrónico requerido.');
    }
    else if (!this.isValidEmail(this.emailId)) {
      this.validationMessages.push('Please enter proper email address.');
    }
  }

  isValidEmail(email) {
    var re = /[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,3}$/;
    return re.test(email);
  }

  populateApiErrorMessages(message: string) {
    this.validationMessages.push(message);
  }

}
