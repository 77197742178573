<div class="container-area">
    <div class="content-area">
        <div class="main-title">
            <span>Tablero (Admin)</span>
        </div>
        <div class="row">
            <div class="col-md-4">
                <app-calendar-view></app-calendar-view>
            </div>
            <div class="col-md-8">
                <div class="row">
                    <div class="col-md-7">
                        <app-week-data></app-week-data>

                        <app-reschedule></app-reschedule>
                    </div>
                    <div class="col-md-5">
                        <app-conversation></app-conversation>
                    </div>
                </div>
                <!--<div class="cs-part">
                    <app-your-team *ngIf="userRole == 'Super Admin'"></app-your-team>
                </div>-->
            </div>
        </div>
        <!--<div class="row">
            <div class="col-md-12">
                <app-weekly-stats></app-weekly-stats>
            </div>
        </div>-->
    </div>
</div>

<div class="mobile-warning">
  <span><img src="../../../assets/images/error-mobile.svg" /> El portal de usuarios de CASCO Safety no es compatible con este dispositivo. Por favor, acceda a CASCO Safety en pantalla completa desde su escritorio. </span>
</div>
