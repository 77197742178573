<div class="privacy-container">
    <div class="container">
        <div class="block-title">
            <span>Política de Privacidad</span>
        </div>
        <div class="privacy-content">
            <div class="details-block">
                <p>
                    Esta Políticas de Privacidad describe cómo tratamos la información que recopilamos cuando usted
                    visita y/o utiliza los Servicios de CASCO Safety (“CASCO”) listados en nuestros Términos y
                    Condiciones. Toto inicia cuando crea una cuenta de CASCO, inicia sesión, o usa uno de los Servicios,
                    acepta la siguiente política de privacidad.
                </p>
            </div>
            <div class="details-listing">
                <div class="details-listing-info">
                    <span>1. Información que recopilamos</span>
                    <ul>
                        <li>1.1. Información que obtenemos directamente sobre usted</li>
                        <li>1.2. Información que obtenemos por medios automatizados</li>
                        <li>1.3. Cookies</li>
                    </ul>
                    <span>2. Cómo utilizamos la información que recopilamos</span>
                    <ul>
                        <li>2.1. Servicios de análisis de terceros</li>
                        <li>2.2. Seguimiento en línea y publicidad basada en intereses</li>
                    </ul>
                    <span>3. Intercambio de información</span>
                    <span>4. Cómo protegemos la información personal</span>
                    <ul>
                        <li>4.1. Políticas para niños</li>

                    </ul>
                    <span>5. Derechos sobre su información personal</span>
                    <span>6. Actualizaciones de nuestro Aviso de privacidad</span>
                    <span>7. Política y directrices de almacenamiento de datos</span>
                    <ul>
                        <li>7.1. Introducción</li>
                        <li>7.2. Objetivo</li>
                        <li>7.3. Política</li>
                        <li>7.4. Pautas de almacenamiento</li>
                        <li>7.5. Recuperación de almacenamiento</li>
                    </ul>
                    <span>8. Cómo contactarnos</span>

                </div>
            </div>
            <div class="deatils-part">
                <div class="title-top">
                    <span>1. INFORMACIÓN QUE RECOPILAMOS</span>
                </div>
                <span class="title-btm">1.1 Información que obtenemos sobre usted</span>
                <p>
                    Recibimos y almacenamos cualquier información que ingrese en los servicios o que nos proporcione de
                    cualquier otra manera. Puede optar por no proporcionarnos cierta información, aunque de hacer esto
                    es posible que no pueda aprovechar muchas de las funciones en determinado servicio.
                    <br /><br />
                    La información personal que proporcione se puede utilizar para fines tales como responder a sus
                    preguntas o solicitudes, personalizar el contenido que visualiza, mejorar la calidad de la asesoría
                    y servicios que le brindamos, así como para comunicarnos con usted.
                    <br /><br />
                    Si nos envía correspondencia personal, como correos electrónicos o cartas, o si otros usuarios o
                    terceros nos envían correspondencia sobre sus actividades o publicaciones en los Servicios, podemos
                    recopilar dicha información en un archivo específico para usted.
                    <br /><br />
                    Por tal razón, usted acepta que podemos usar su información personal para comunicarnos con usted
                    como se establece en esta política de privacidad.
                    <br /><br />
                    Los Servicios, registrarán toda información que recibimos directamente de usted, incluidos, entre
                    otros, los siguientes:
                </p>
                <ul>
                    <li>Información de contacto, como nombre, número de teléfono y dirección de correo electrónico y
                        número postal;</li>
                    <li>Información de la cuenta, como contraseña en línea y otros detalles de inicio de sesión
                        utilizados para acceder a los productos y servicios ofrecidos en las plataformas digitales de
                        CASCO;</li>
                    <li>Información de pago, como nombre, dirección de facturación y detalles de la tarjeta de pago,
                        incluido el número de tarjeta, la fecha de vencimiento y el código de seguridad, que nuestro
                        procesador de pagos externo recopila y almacena en nuestro nombre.</li>
                    <li>La geolocalización de su dispositivo móvil si acepta la recopilación de estos datos;</li>
                    <li>Otra información personal contenida en el contenido que nos envía, como a través de nuestra
                        función "Contáctenos" o herramientas de soporte al cliente en nuestros sitios web o aplicaciones
                        móviles.</li>
                </ul>
                <span class="title-btm">1.2 Información que obtenemos por medios automatizados</span>
                <p>
                    Los Servicios en ocasiones no requieren que se registre o acceda a su cuenta para proporcionarnos
                    información. En general, CASCO recopilará información genérica automáticamente. Esta información
                    genérica puede incluir su ubicación, las paginas que visualiza, los productos de su interés, el
                    número y la frecuencia de visitas en los Servicios, la página que lo redirigió a nuestras páginas
                    web (por ejemplo, “Google.com”, o “Bing.com”), otras páginas de navegación en nuestro sitio, la
                    información del navegador de su computadora, entre otros.
                    <br /><br />
                    Es importante saber, que la información genérica no revela la identidad del visitante. Este tipo de
                    datos agregados puede permitirnos determinar la frecuencia con la que los usuarios usan partes del
                    servicio, ayudarnos a determinar el servicio al cliente y las necesidades del Sitio, y hacer una
                    investigación interna sobre la demografía, los intereses y el comportamiento de nuestros usuarios
                    para comprender, proteger y servir mejor a usted y nuestra comunidad.
                    <br /><br />
                    CASCO, puede usar estos datos en forma agregada, es decir, como una medida estadística, pero no de
                    una manera que lo identifique personalmente. Como parte de este uso de la información, podemos
                    proporcionar información agregada, no información sobre usted personalmente, a nuestros socios sobre
                    cómo nuestros usuarios, colectivamente, usan nuestro Servicio.
                    <br /><br />
                    Asi mismo, usamos otras herramientas para ayudar a analizar cómo nuestros usuarios usan los
                    Servicios. Herramientas de análisis de tráfico (como Google Analytics) utilizan meta-data para
                    recopilar información que nos permite analizar y mejorar nuestros Servicios para nuestros usuarios,
                    como la frecuencia con la que visitan los Servicios, las páginas que visitan, el tiempo de carga, y
                    los otros sitios que utilizaron antes de acceder a los Servicios.

                </p>

                <span class="title-btm">1.3 Cookies</span>
                <p>Cuando usted visita nuestros sitios web, podemos utilizar cookies. Una cookie es un pequeño archivo
                    que contiene una cadena de información genérica que un sitio web almacena en la computadora de un
                    visitante y que el navegador del visitante proporciona al sitio web cada vez que el visitante
                    regresa. Cualquier uso de cookies por nuestra parte será únicamente con el propósito de mejorar la
                    calidad del acceso a nuestros sitios mediante el almacenamiento de las preferencias del usuario y el
                    seguimiento de las tendencias del usuario y necesidades del usuario.
                    <br /><br />
                    Los visitantes que no deseen que se coloquen cookies en sus computadoras deben configurar sus
                    navegadores para rechazar las cookies antes de usar nuestros Servicios ya que la mayoría de los
                    navegadores están configurados inicialmente para aceptar cookies. CASCO no controla el uso de
                    cookies por parte de terceros.
                </p>
                <div class="title-top">
                    <span>2. CÓMO UTILIZAMOS LA INFORMACIÓN QUE RECOPILAMOS</span>
                </div>
                <p>En CASCO procesamos datos personales para operar, mejorar, comprender y personalizar nuestro
                    Servicio. Por ejemplo, utilizamos datos personales para:</p>
                <ul>
                    <li>Darle a conocer nuestros productos y servicios</li>
                    <li>Establecer y administrar su cuenta y perfil en CASCO</li>
                    <li>Procesar compras y pedidos</li>
                    <li>Comunicar a usted nuestros anuncios, actualizaciones u ofertas de servicios.</li>
                    <li>Brindar soporte y asistencia sobre nuestros Servicios.</li>
                    <li>Personalizar el contenido y las comunicaciones del sitio web según sus preferencias</li>
                    <li>Cumplir contrato u obligaciones legales</li>
                    <li>Responder a las consultas de los usuarios</li>
                    <li>Cumplir con las solicitudes de los usuarios</li>
                    <li>Cumplir con nuestras obligaciones legales o contractuales</li>
                    <li>Resolver objeciones</li>
                    <li>Proteger o disuadir de acciones fraudulentas, ilegales o dañinas.</li>
                    <li>Hacer cumplir nuestros Términos de servicio</li>
                </ul>
                <p>
                    Solo procesaremos sus datos personales si tenemos una base legal para hacerlo. Las bases legales
                    para el procesamiento incluyen el consentimiento, la necesidad contractual y nuestros "intereses
                    legítimos" o el interés legítimo de otros, como se describe con más detalle a continuación.
                </p>
                <p>
                    Necesidad contractual: Procesamos las siguientes categorías de datos personales como una cuestión de
                    "necesidad contractual", lo que significa que necesitamos procesar los datos para cumplir con
                    nuestros Términos y Condiciones con usted, lo que nos permite brindarle el servicio. Cuando
                    procesamos datos debido a una necesidad contractual, la falta de proporcionar dichos datos resultará
                    en su incapacidad para utilizar algunas o todas las partes del servicio que requieran dichos datos.
                </p>
                <p>
                    Interés legítimo: procesamos las siguientes categorías de datos personales cuando creemos que
                    promueven nuestro interés legítimo o el de terceros. Ejemplos de estos intereses legítimos incluyen:
                </p>
                <ul>
                    <li>Operación y mejora de nuestro negocio, productos y servicios</li>
                    <li>Comercialización de nuestros productos y servicios</li>
                    <li>Requerimiento de asistencia al cliente</li>
                    <li>Protección contra fraudes o amenazas a la seguridad</li>
                    <li>Cumplimiento de obligaciones legales</li>
                    <li>Finalización de transacciones comerciales</li>
                </ul>
                <p>Consentimiento: En algunos casos, procesamos Datos Personales en función del consentimiento que nos
                    otorga expresamente en el momento en que de forma voluntaria obtenemos datos. Cuando procesamos
                    Datos Personales en base a su consentimiento, se le indicará expresamente en el momento y momento de
                    la recopilación.</p>
                <span class="title-btm">2.1 Servicios de análisis de terceros</span>
                <p>
                    CASCO utiliza servicios de análisis web de terceros para los sitios web y aplicaciones móviles. Los
                    proveedores de servicios que administran estos servicios utilizan tecnologías automatizadas para
                    recopilar datos (como direcciones de correo electrónico e IP) para evaluar el uso y comportamiento
                    de nuestros Servicios.
                </p>
                <span class="title-btm">2.2 Seguimiento en línea y publicidad basada en intereses</span>
                <p>
                    Esta sección de nuestro aviso de privacidad proporciona detalles y explica cómo ejercer sus
                    opciones. Puede ver nuestros anuncios en otros sitios web o aplicaciones móviles porque participamos
                    en redes publicitarias proporcionadas por las plataformas de terceros.
                    Las redes publicitarias nos permiten orientar nuestros mensajes a los usuarios teniendo en cuenta
                    los datos demográficos, los intereses inferidos de los usuarios y el contexto de navegación.
                    <br /><br />
                    Estas redes rastrean las actividades en línea de los usuarios a lo largo del tiempo mediante la
                    recopilación de información a través de medios automatizados, incluso mediante el uso de cookies,
                    registros del servidor web y otras tecnologías similares. Las redes utilizan esta información para
                    mostrar anuncios que pueden adaptarse a los intereses de las personas, realizar un seguimiento de
                    los navegadores o dispositivos de los usuarios en varios sitios web y crear un perfil de las
                    actividades de navegación en línea de los usuarios.
                    <br /><br />
                    Esta recopilación de datos tiene lugar tanto en nuestros sitios web como en sitios web de terceros
                    que participan en las redes publicitarias. Este proceso también nos ayuda a rastrear la efectividad
                    de nuestros esfuerzos de Marketing.
                </p>
                <div class="title-top">
                    <span>3. INTERCAMBIO DE INFORMACIÓN</span>
                </div>
                <p>CASCO no comparte datos personales de sus usuarios con proveedores o terceros ajenos a nuestra
                    gestion de negocio. No obstante, CASCO con estrictas medidas de control, puede facilitar información
                    de sus usuarios con proveedores de servicios externos y agentes que trabajan en nuestro nombre y nos
                    brindan servicios relacionados con los fines descritos en esta Política de Privacidad, o en nuestros
                    términos de servicio que incluyen:</p>
                <ul>
                    <li>Procesadores de pago</li>
                    <li>Proveedores de servicios de prevención de fraude</li>
                    <li>Agencias publicitárias</li>
                    <li>Proveedores de servicios de análisis</li>
                </ul>
                <p>Además de las establecidas anteriormente, estas partes también incluyen:</p>
                <ul>
                    <li>Acciones propias autorizadas por el usuario</li>
                    <li>Cuando creemos que es necesario para cumplir con la ley aplicable o responder a un proceso legal
                        válido, incluso de las fuerzas del orden u otras agencias gubernamentales.</li>
                    <li>También compartimos información con terceros cuando nos da su consentimiento para hacerlo.</li>
                    <li>Por último, compartimos datos personales con nuestras afiliadas u otros miembros de nuestra
                        familia corporativa. </li>
                </ul>
                <p>Usted acepta y reconoce que al usar nuestros Servicios, un asesor tendrá la capacidad de comunicarse
                    con usted y acceder a ciertas funcionalidades de información proporcionada por usted a través de los
                    Servicios, que incluyen, información personal y/o corporativa; así como tomar control de la cámara
                    de su dispositivo para tomar fotografías, hacer uso del flash, grabar videos, realizar video
                    llamadas, y conservar una copia de estos archivos multimedia, entre otros, siempre y cuando la app
                    este en uso, esto con el objetivo de poder brindar soluciones acertadas a sus necesidades
                    específicas y mejorar nuestro Servicios para todos nuestros clientes.
                    <br /><br />
                    CASCO en ningún momento tendrá la capacidad y/o intención de hacer uso de estas funcionalidades de
                    asesoría cuando la app no se este utilizando, ni sin su conocimiento.
                </p>

                <div class="title-top">
                    <span>4. Cómo protegemos la información personal</span>
                </div>
                <p>
                    CASCO, maneja los datos como un activo que debe protegerse contra la pérdida y el acceso no
                    autorizado. Toda la información personal recopilada de otros Servicios de la plataforma que usted
                    proporciona a través del uso del Servicio se almacena de forma segura. Solo los empleados que
                    necesitan información personal para realizar un trabajo específico (por ejemplo, un asesor de ventas
                    o de servicio al cliente) tienen acceso a ella.
                    <br /><br />
                    Todos nuestros empleados se mantienen actualizados sobre nuestras prácticas de privacidad y
                    seguridad.
                    <br /><br />
                    Debido al entorno regulatorio existente, no podemos garantizar que todas sus comunicaciones privadas
                    y otra información personal nunca se divulguen de una manera que no se describa de otra manera en
                    esta Política de Privacidad.
                    A modo de ejemplo, podemos vernos obligados a divulgar información personal al gobierno o terceros
                    bajo ciertas circunstancias siempre y cuando la ley nos obligue a hacerlo.

                </p>
                <span class="title-btm">4.1 Políticas para niños</span>
                <p>No recopilamos ni solicitamos datos personales de ninguna persona menor de 18 años. Si es menor de 18
                    años, no intente registrarse en los Servicios ni enviarnos datos personales sobre usted. Si nos
                    enteramos de que hemos recopilado datos personales de una persona menor a 18 años, eliminaremos esa
                    información lo antes posible. Si cree que una persona menor de 18 años puede habernos proporcionado
                    datos personales, por favor comuníquese con nosotros a <a
                        href="mailto:info@cascosafety.com">info@cascosafety.com</a></p>
                <div class="title-top">
                    <span>5. Derechos sobre su información personal</span>
                </div>
                <p>
                    Usted tiene derechos con respecto a sus datos personales, incluidos los que se establecen a
                    continuación. Para obtener más información sobre estos derechos o para enviar una solicitud, envíe
                    un correo electrónico a <a href="mailto:info@cascosafety.com">info@cascosafety.com</a>.
                    <br /><br />
                    Tenga en cuenta que, en algunas circunstancias, es posible que no podamos cumplir plenamente con su
                    solicitud, por ejemplo, si es frívola o extremadamente poco práctica, si pone en peligro los
                    derechos de otros o si no lo exige la ley, pero en esas circunstancias, aún responderemos para
                    notificarle dicha decisión.
                    <br /><br />
                    En algunos casos, es posible que también necesitemos que nos brinde información adicional, que puede
                    incluir datos personales, si es necesario, para verificar su identidad y la naturaleza de su
                    solicitud.
                    <br /><br />
                    Acceso: Puede solicitar más información sobre los datos personales que tenemos sobre usted y
                    solicitar una copia de dichos Datos personales.
                    <br /><br />
                    Rectificación: si cree que algún dato personal que tenemos sobre usted es incorrecto o está
                    incompleto, puede solicitar que corrijamos o complementemos dichos datos.
                    <br /><br />
                    Retiro del consentimiento: si estamos procesando sus datos personales en función de su
                    consentimiento (como se indica en el momento de la recopilación de dichos datos), tiene derecho a
                    retirar su consentimiento en cualquier momento. Sin embargo, tenga en cuenta que, si ejerce este
                    derecho, es posible que deba proporcionar su consentimiento expreso caso por caso para el uso o la
                    divulgación de algunos de sus datos personales, si dicho uso o divulgación es necesario para
                    permitirle para utilizar parte o la totalidad de nuestro Servicio.
                    <br /><br />
                    Portabilidad: también puede solicitar que transmitamos los datos a otro responsable del tratamiento
                    cuando sea técnicamente posible.
                    <br /><br />
                    Objeción: puede ponerse en contacto con nosotros para informarnos que se opone al uso o divulgación
                    posterior de sus datos personales para determinados fines, como los de marketing directo.
                    <br /><br />
                    Restricción del procesamiento: puede solicitarnos que restrinjamos el procesamiento adicional de sus
                    datos personales.
                    <br /><br />
                    Derecho a presentar una queja: tiene derecho a presentar una queja sobre las prácticas de CASCO con
                    respecto a sus datos personales ante la autoridad competente si lo considera necesario
                </p>
                <div class="title-top">
                    <span>6. Política y directrices de almacenamiento de datos</span>
                </div>

                <span class="title-btm">6.1 Introducción</span>
                <p>
                    CASCO ("Nosotros", "Nos", "Nuestro") a través de nuestros Servicios puede proporcionar a la persona
                    u organización (“Usted”) un recurso de almacenamiento individual designado temporalmente para
                    almacenar datos y archivos directamente relacionados con su uso de los Servicios ("Base de datos")
                    de acuerdo con nuestra Política de Privacidad y nuestros Términos y Condiciones.
                    <br /><br />
                    Al acceder y/o utilizar nuestros Servicios, es posible que se le proporcione un almacenamiento
                    temporal designado para administrar (almacenar, actualizar, eliminar) datos y archivos que deben
                    compartirse entre usted y otros usuarios a los que proporcionamos acceso. Usted es responsable de
                    administrar sus propios datos de acuerdo con sus procedimientos establecidos.
                </p>
                <span class="title-btm">6.2 Propósito</span>
                <p>El propósito de esta politica y sus pautas, es establecer y comunicar los recursos de almacenamiento
                    de datos dentro de nuestros Servicios, así como definir el uso aceptable del almacenamiento. La
                    infraestructura de datos de CASCO está configurada para soportar de manera óptima sus requisitos
                    tecnológicos. La gestión eficaz de los datos por nuestra parte permitirá a CASCO gestionar sus
                    recursos de almacenamiento de forma más eficiente.</p>
                <span class="title-btm">6.3 Política</span>
                <p>
                    Al acceder al sitio web u otra de nuestras plataformas digitales, se le otorgará acceso temporal
                    para el almacenamiento necesaria de su información personal y de sus intereses en relación a los
                    servicios. Sin perjuicio de los Términos de uso y la Política de privacidad de nuestro sitio web,
                    siempre evitaremos que realice una copia de seguridad de sus datos almacenados.
                    <br /><br />
                    Sujeto y de acuerdo con los Términos y Condiciones de nuestros Servicios y la Política de
                    Privacidad, nunca veremos, copiaremos, buscaremos o distribuiremos intencionalmente sus datos y por
                    consiguiente guardaremos total privacidad y confidencialidad con su información.
                </p>
                <span class="title-btm">6.4 Pautas de almacenamiento</span>
                <p>
                    Para cumplir con los Términos y Condiciones de nuestros Servicios, así como para administrar sus
                    datos almacenados de manera más efectiva:
                </p>
                <ul>
                    <li>Solo se pueden almacenar en la infraestructura los datos de CASCO, así como los datos y archivos
                        directamente relacionados con su uso del sitio web de acuerdo con los términos de uso de nuestro
                        sitio web y nuestra política de privacidad. </li>
                    <li>No asumimos ninguna responsabilidad por la pérdida, protección o restauración de datos
                        personales.</li>
                    <li>Sus datos almacenados relacionados con usted o alguien no pueden contener números de seguro
                        social, números de tarjetas de crédito cuando no se trate de compras en linea, información
                        confidencial como Seguros de Salud o cualquier información con derechos de autor o marca
                        registrada que no posea ni tenga licencia de usted o cualquier otra información similar que
                        pudiera de lo contrario, violará nuestra Política de privacidad o los Términos de uso de nuestro
                        sitio web y aplicaciones móviles.</li>
                </ul>
                <p>En CASCO el almacenamiento de datos es un recurso finito que debe administrarse de manera proactiva y
                    tiene un impacto perjudicial en nuestras operaciones e infraestructura comerciales si no se
                    supervisa.</p>
                <span class="title-btm">6.5 Recuperación de almacenamiento</span>
                <p>
                    CASCO realizará una revisión periódica y la recuperación de los recursos de almacenamiento de la
                    siguiente manera:
                </p>
                <p>
                    Podemos eliminar sus datos almacenados que consideremos que no cumplen o infringen los Términos y
                    Condiciones de nuestros Servicios, pero le notificaremos qué datos almacenados eliminaremos.
                </p>
                <p>
                    Sus datos almacenados a los que no se haya accedido durante más de dos (2) años también es posible
                    que se archiven fuera de línea.
                </p>
                <div class="title-top">
                    <span>7. Cambios a esta política </span>
                </div>
                <p>
                    Es posible que modifiquemos nuestros Servicios y/o las políticas, y que debamos actualizar estas
                    Políticas de modo que reflejen nuestros Servicios y las normas con precisión. A menos que la ley
                    disponga lo contrario, te enviaremos una notificación (por ejemplo, mediante nuestros Servicios o
                    correo electrónico) antes de modificar estas Condiciones, dando así la oportunidad de revisar los
                    cambios antes de que entren en vigor. Si sigues usando el Servicio, quedarás sujeto a las
                    Condiciones actualizadas. Si no quieres aceptar estas ni otras Condiciones actualizadas, puedes
                    solicitar eliminar tu contenido y/o tu cuenta por medio de nuestros Servicios o correo electrónico.
                    <br /><br />
                    En caso de tener dudas o solicitar mayor información estaremos felices de asistirte y puedes
                    contactarte con nosotros por correo electrónico, el tiempo de respuesta variara según la fecha en la
                    que nos contactes y la disponibilidad en el momento del personal de atención al cliente.
                    <br />
                    <a href="mailto:info@cascosafety.com.">info@cascosafety.com</a>.
                </p>

                <div class="title-top">
                    <span>8. CÓMO CONTACTARNOS</span>
                </div>
                <p>
                    Si tiene alguna pregunta sobre esta sección o nuestras prácticas de datos en general, comuníquese
                    con nosotros por correo electrónico a <a href="mailto:info@cascosafety.com">info@cascosafety.com</a>
                    y brindaremos de forma oportuna la respuesta a su consulta.
                    <br /><br />
                    Fecha de la última revisión: 04 de Enero 2022
                </p>
            </div>
        </div>
    </div>
</div>