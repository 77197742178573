import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { SharedDataService } from '../../../../services/sharedData.service';
import { TenantRegistrationService } from '../.././../../api-services/tenant-registration.service';
import { iConfirmActivation, iActivationCode } from '../../../../models/tenant-registration.model';
import { AuthService } from '../../../../services/auth.service';

@Component({
  selector: 'app-activate',
  templateUrl: './activate.component.html',
  styleUrls: ['./activate.component.css']
})
export class ActivateComponent implements OnInit {

  constructor(private router: Router,
    private service: TenantRegistrationService,
    private sharedService: SharedDataService,
    private authService: AuthService) { this.authService.redirectToMainPageIfUserIsLoggedIn(); }

  confirmActivation: iConfirmActivation = {};
  validationMessages: any;
  activateUser: iActivationCode = {};

  ngOnInit(): void {
    this.confirmActivation.tenantId = this.sharedService.getValue("tenantId");
  }

  confirmActivationCode() {
    this.validationMessages = [];

    this.populateValidateMessages();

    if (this.validationMessages.length === 0) {
      this.service.confirmUserActivation(this.confirmActivation.activationCode).subscribe(result => {
        if (result != null) {
          this.activateUser = <iActivationCode>result;
          if (this.activateUser.message == "success") {
            this.sharedService.setValue('accountId', this.activateUser.accountId);
            this.router.navigate(['/confirm-password']);
          }
          else
            this.validationMessages.push(this.activateUser.message);
        }
      }, error => {
        this.populateApiErrorMessages(error.message);
      });
    }
  }

  populateValidateMessages() {

    if (this.confirmActivation.activationCode === undefined || this.confirmActivation.activationCode === null
      || this.confirmActivation.activationCode === '') {
      this.validationMessages.push('Activation code is required.');
    }
  }

  populateApiErrorMessages(message: string) {
    this.validationMessages.push(message);
  }

}
