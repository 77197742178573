<div class="authentication-container">
  <div class="container">
    <div class="auth-content activate-auth">
      <div class="logo-block">
        <img src="../../../../assets/images/auth-logo.svg" />
      </div>
      <div class="auth-title">
        <span>Activar Su Cuenta</span>
      </div>
      <span class="text-line">Revisar su correo electronico e ingrese el codigo de activacion recibido</span>
      <div class="img-block">
        <img src="../../../../../assets/images/thanks-img-part.svg" />
      </div>
      <div class="row">
        <div class="col-sm-12 col-md-12">
          <div class="input-block extra">
            <input type="text" class="form-control" placeholder="Codigo de Activacion" [(ngModel)]="activationCode" maxlength="50" />
          </div>
        </div>
      </div>
      <div>
        <validation-summary [validationErrors]="validationMessages"></validation-summary>
      </div>
      <div class="button-block">
        <button class="btn comm-btn" (click)="confirmActivationCode()">Avanzar</button>
      </div>
      <div class="code-text">
        <a href="/code-request">Solicitar Codigo de Activacion</a>
      </div>
    </div>
  </div>
</div>
