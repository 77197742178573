<div class="container-area">
    <div class="extra-one">
        <div class="content-area bg-content">
            <div class="import-batch-list">
                <div class="only-title">
                    <span>Importar Lote</span>
                </div>
                <div class="list-desc">
                    <div class="list-top">
                        <div class="row">
                            <div class="col-md-1">
                                <span class="list-title">Número de Ticket</span>
                            </div>
                            <div class="col-md-1">
                                <span class="list-title">N&#250;mero de Cliente</span>
                            </div>
                            <div class="col-md-1">
                                <span class="list-title">N&#250;mero Unico</span>
                            </div>
                            <div class="col-md-2">
                                <span class="list-title">Tipo de Caso</span>
                            </div>
                            <div class="col-md-1">
                                <span class="list-title">Fecha de Caso</span>
                            </div>
                            <div class="col-md-1">
                                <span class="list-title">Nombre</span>
                            </div>
                            <div class="col-md-2">
                                <span class="list-title">Direcci&#243;n</span>
                            </div>
                            <div class="col-md-2">
                                <span class="list-title">Correo Electr&#243;nico</span>
                            </div>
                            <div class="col-md-1">
                                <span class="list-title">Tel&#233;fono</span>
                            </div>
                        </div>
                    </div>
                    <div class="info-section">
                        <div class="list-btm" *ngFor="let item of importData.importList; let i= index">
                            <div class="row">
                                <div class="col-md-1">
                                    <span class="list-info" [ngClass]="{'warning': !item.isValidClaimNo}" title="{{item.claimNo}}">{{item.claimNo}}</span>
                                </div>
                                <div class="col-md-1">
                                    <span class="list-info" [ngClass]="{'warning': !item.isValidPolicyNo}" title="{{item.policyNo}}">{{item.policyNo}}</span>
                                </div>
                                <div class="col-md-1">
                                    <span class="list-info" [ngClass]="{'warning': !item.isValidUniqueNo}" title="{{item.uniqueNo}}">{{item.uniqueNo}}</span>
                                </div>
                                <div class="col-md-2">
                                    <span class="list-info" [ngClass]="{'warning': !item.isValidLossType}" title="{{item.lossType}}">{{item.lossType}}</span>
                                </div>
                                <div class="col-md-1">
                                    <span class="list-info" [ngClass]="{'warning': !item.isValidLossDate}" title="{{item.lossDate}}">{{item.lossDate}}</span>
                                </div>
                                <div class="col-md-1">
                                    <span class="list-info" [ngClass]="{'warning': !item.isValidFullName}" title="{{item.fullName}}">{{item.fullName}}</span>
                                </div>
                                <div class="col-md-2">
                                    <span class="list-info" [ngClass]="{'warning': !item.isValidAddress}" title="{{item.fullAddress}}">{{item.fullAddress}}</span>
                                </div>
                                <div class="col-md-2">
                                    <span class="list-info" [ngClass]="{'warning': !item.isValidEmailId}" title="{{item.emailId}}">{{item.emailId}}</span>
                                </div>
                                <div class="col-md-1">
                                    <span class="list-info" [ngClass]="{'warning': !item.isValidFullNumber}" title="{{item.fullPhoneNumber}}">{{item.fullPhoneNumber}}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="option-part">
                        <div class="row">
                            <div class="col-md-2">
                                <span class="recodrs">{{importData.totalCount}} Registro(s)</span>
                            </div>
                            <div class="col-md-2">
                                <span class="text-warn">{{importData.invalidDataCount}} aviso(s)</span>
                            </div>
                            <div class="col-md-3">
                                <span class="text-warn">*** (Campos Obligatorios)</span>
                            </div>
                            <div class="col-md-1"></div>
                            <div class="col-md-2">
                                <div class="button-block">
                                    <button class="btn comm-btn" *ngIf="showSave" (click)="getStorageSize()">Importar Lote</button>
                                </div>
                            </div>
                            <div class="col-md-2">
                                <div class="button-block">
                                    <button class="btn comm-btn cancel" (click)="cancelClick()">Cancelar</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="error-only-msg" *ngFor="let error of validationMessages">
                <span>{{ error }} </span>
            </div>
        </div>
    </div>
</div>