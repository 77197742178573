<div class="container-area">
    <div class="content-area">
        <div class="main-title">
            <span>Historial de Llamadas</span>
        </div>
        <div class="backlog-desc-part">
            <div class="backlog-title-block">
                <div class="row">
                    <div class="col-md-1">
                        <div class="title-part first">
                            <span>Número de Ticket</span>
                        </div>
                    </div>
                    <div class="col-md-1">
                        <div class="title-part">
                            <span>N&#250;mero de Cliente</span>
                        </div>
                    </div>
                    <div class="col-md-2">
                        <div class="title-part">
                            <span>Nombre</span>
                        </div>
                    </div>
                    <div class="col-md-2">
                        <div class="title-part">
                            <span>Correo Electr&#243;nico</span>
                        </div>
                    </div>
                    <div class="col-md-2">
                        <div class="title-part">
                            <span>Tel&#233;fono</span>
                        </div>
                    </div>
                    <div class="col-md-2">
                        <div class="title-part">
                            <span>Fecha&nbsp;y Hora de la&nbsp;Conferencia</span>
                        </div>
                    </div>
                    <div class="col-md-2">
                        <div class="title-part">
                            <span>Registro de llamadas</span>
                        </div>
                    </div>
                </div>
            </div>
            <div class="backlog-desc-block" *ngFor="let item of callHistoryList; let i= index">
                <div class="row">
                    <div class="col-md-1">
                        <span class="info-desc-part first" title="{{item.odooTicketId}}">{{item.odooTicketId}}</span>
                    </div>
                    <div class="col-md-1">
                        <span class="info-desc-part" title="{{item.odooCustomerId}}">{{item.odooCustomerId}}</span>
                    </div>
                    <div class="col-md-2">
                        <span class="info-desc-part" title="{{item.name}}">{{item.name}}</span>
                    </div>
                    <div class="col-md-2">
                        <span class="info-desc-part" title="{{item.emailId}}">{{item.emailId}}</span>
                    </div>
                    <div class="col-md-2">
                        <span class="info-desc-part" title="{{item.phoneNo}}">{{item.phoneNo}}</span>
                    </div>
                    <div class="col-md-2">
                        <span class="info-desc-part" title="{{item.confDateTime | localizedDateTime}}">{{item.confDateTime | localizedDateTime}}</span>
                    </div>
                    <div class="col-md-2">
                        <div class="info-desc-part button-block">
                            <a class="cl-btn" (click)="callBack(item.conferenceId)"><img title="Registro de llamadas" src="../../../assets/images/call-bk-img.svg" /></a>
                            <a class="view-btn" (click)="viewDetails(item.conferenceId)"><img title="Ver registros" src="../../../assets/images/view-bklog.svg" /></a>
                            <a class="dl-btn" (click)="downloadArtifact(item.conferenceId)"><img title="Descargar todos los registros" src="../../../assets/images/download-bl-img.svg" /></a>
                            <a class="chat-btn" (click)="goToConversationScreen(item.conferenceId)"><img title="Chat de nuevo" src="../../../assets/images/chat-icon.svg" /></a>
                        </div>
                    </div>
                </div>
            </div>

            <!--<div class="backlog-desc-block">
      <div class="row">
        <div class="col-md-1">
          <span class="info-desc-part first" title="FL02-111456">FL02-111456</span>
        </div>
        <div class="col-md-1">
          <span class="info-desc-part" title="ABCD123444">ABCD123444</span>
        </div>
        <div class="col-md-2">
          <span class="info-desc-part" title="Jay Smith">Jay Smith</span>
        </div>
        <div class="col-md-2">
          <span class="info-desc-part" title="jaysmith07@rdg.com">jaysmith07@rdg.com</span>
        </div>
        <div class="col-md-2">
          <span class="info-desc-part" title="+1 234-456-456">+1 234-456-456</span>
        </div>
        <div class="col-md-2">
          <span class="info-desc-part" title="+1 234-456-456">+1 234-456-456</span>
        </div>
        <div class="col-md-2">
          <span class="info-desc-part" title="www.adjustify.co/users/">
            <a href="#">www.adjustify.co/users/</a>
          </span>
        </div>
      </div>
    </div>-->
            <!--<div class="more-block">
        <a href="">See More<i class="fa fa-angle-down" aria-hidden="true"></i></a>
    </div>-->
        </div>
        <div class="error-only-msg" *ngFor="let error of validationMessages">
            <span>{{ error }} </span>
        </div>
    </div>
</div>

<div class="mobile-warning">
    <span><img src="../../../assets/images/error-mobile.svg" /> El portal de usuarios de CASCO Safety no es compatible con este dispositivo. Por favor, acceda a CASCO Safety en pantalla completa desde su escritorio. </span>
</div>