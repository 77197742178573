import { Component, OnInit, ViewChild } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { NgForm } from '@angular/forms'
import { SharedDataService } from '../../../../services/sharedData.service';
import { SettingService } from '../.././../../api-services/setting.service';
import { iUserDetails, iCountryList } from '../../../../models/setting.model';
import { AuthService } from '../../../../services/auth.service';
import { AuthModel } from '../../../../models/auth.model';

@Component({
  selector: 'app-invite-user',
  templateUrl: './invite-user.component.html',
  styleUrls: ['./invite-user.component.css']
})
export class InviteUserComponent implements OnInit {

  constructor(private router: Router,
    private service: SettingService,
    private sharedService: SharedDataService,
    private authService: AuthService,
    private activateRoute: ActivatedRoute) { this.authService.redirectToMainPageIfUserIsLoggedIn(); }

  @ViewChild('inviteUserForm')
  inviteUserForm: NgForm;
  message?: string;
  validationMessages: any;
  userDetails?: iUserDetails = {};
  cnfPassword?: string;
  letterFlag: boolean = false;
  digitFlag: boolean = false;
  lengthFlag: boolean = false;
  charFlag: boolean = false;
  countryList?: iCountryList[] = [];
  selectedCountry?: iCountryList = {};
  placeHolder: string = "Phone Number";

  ngOnInit(): void {
    this.getCountryList();
    this.userDetails.isdCountryId = 1;
    this.validationMessages = [];
    this.userDetails.emailId = this.getQueryParamFromMalformedURL("emailId").toString();
    this.activateRoute.queryParams.subscribe(params => {
      if (params['key'] && params['key'] != '') {
        this.getToken(params['key']);
      }
    });
  }

  removeSpaceFromString(request: any,key:string){
    let newValue = request.form.controls[key].value; 
    if(newValue){
      request.form.controls[key].patchValue(newValue.trim())
    }
  }

  getQueryParamFromMalformedURL(name) {
    const results = new RegExp('[\\?&]' + name + '=([^&#]*)').exec(decodeURIComponent(this.router.url)); // or window.location.href
    if (!results) {
      return 0;
    }
    return results[1] || 0;
  }

  getToken(key: string) {
    var authRequest = { authKey: key, requestedBy: window.location.href } as AuthModel;
    this.authService.getToken(authRequest).subscribe((data) => {
      this.authService.redirectToMainPageIfUserIsLoggedIn();
    },
      error => {
        this.populateApiErrorMessages(error.message);
      });
  }

  saveUserData() {
    this.validationMessages = [];
    this.populateValidateMessages();

    if (this.validationMessages.length === 0) {

      this.userDetails.isdCountryId = this.selectedCountry.id;

      this.service.addUserDetails(this.userDetails).subscribe(result => {
        if (result != null) {
          if (result) {
            this.authService.login(this.userDetails.emailId, this.userDetails.password).subscribe((response) => {
              if (response) {
                this.authService.redirectToMainPage(response);
              }
            },
              error => {
                if (error.errorTitle != '')
                  this.populateApiErrorMessages(error.errorTitle);
                else
                  this.populateApiErrorMessages(error.message);
              });
          }
        }
      }, error => {
        this.populateApiErrorMessages(error.message);
      });
    }
  }

  //method to check all the password formats on change event of password text box
  checkPasswordFormat() {
    if (this.isCharCheck(this.userDetails.password))
      this.charFlag = true;
    else
      this.charFlag = false;

    if (this.isUpperCaseCheck(this.userDetails.password) && this.isLowerCaseCheck(this.userDetails.password))
      this.letterFlag = true;
    else
      this.letterFlag = false;

    if (this.userDetails.password.length >= 8)
      this.lengthFlag = true;
    else
      this.lengthFlag = false;

    if (this.isDigitCheck(this.userDetails.password))
      this.digitFlag = true;
    else
      this.digitFlag = false;
  }

  //Check for lower case letter
  isLowerCaseCheck(password) {
    var re = /[a-z]/;
    return re.test(password);
  }

  //Check for upper case letter
  isUpperCaseCheck(password) {
    var re = /[A-Z]/;
    return re.test(password);
  }

  //Check for digit
  isDigitCheck(password) {
    var re = /\d/;
    return re.test(password);
  }

  //Check for special character
  isCharCheck(password) {
    var re = /[!@#$%^&*()]/;
    return re.test(password);
  }

  populateValidateMessages() {

    if (this.userDetails.firstName === undefined || this.userDetails.firstName === null || this.userDetails.firstName === "" ||
      this.userDetails.lastName === undefined || this.userDetails.lastName === null || this.userDetails.lastName === "" ||
      this.userDetails.password === undefined || this.userDetails.password === null || this.userDetails.password === "" ||
      this.userDetails.phoneNo === undefined || this.userDetails.phoneNo === null || this.userDetails.phoneNo === "") {
      this.validationMessages.push('Ingrese los datos adecuados.');
    }
    else if (!this.sharedService.isValidPhoneNo(this.userDetails.phoneNo)) {
      this.validationMessages.push('Ingrese un número de teléfono válido.');
    }
    else if (this.userDetails.password != this.cnfPassword) {
      this.validationMessages.push('Password and confirm password are not matching.');
    }
    else if (!this.lengthFlag || !this.charFlag || !this.digitFlag || !this.letterFlag) {
      this.validationMessages.push('Please enter password which follow all the required rules.');
    }
  }

  populateApiErrorMessages(message: string) {
    this.validationMessages.push(message);
  }

  checkConfirmPasswordFormat() {
    if (this.isCharCheck(this.cnfPassword))
      this.charFlag = true;
    else
      this.charFlag = false;

    if (this.isUpperCaseCheck(this.cnfPassword) && this.isLowerCaseCheck(this.cnfPassword))
      this.letterFlag = true;
    else
      this.letterFlag = false;

    if (this.cnfPassword.length >= 8)
      this.lengthFlag = true;
    else
      this.lengthFlag = false;

    if (this.isDigitCheck(this.cnfPassword))
      this.digitFlag = true;
    else
      this.digitFlag = false;
  }

  focusConfirmPasswordFunction() {
    this.letterFlag = false;
    this.charFlag = false;
    this.digitFlag = false;
    this.lengthFlag = false;
  }

  checkPassword() {
    this.validationMessages = [];
    if (this.userDetails.password != this.cnfPassword) {
      this.validationMessages.push('Password and confirm password are not matching.');
    }
  }

  getCountryList() {
    this.service.getCountryList().subscribe(result => {
      if (result != null) {
        this.countryList = <iCountryList[]>result;
        this.selectedCountry = this.countryList[0];
      }
    }, error => {
      this.populateApiErrorMessages(error.message);
    });
  }

  selectCountry(data?: any) {
    this.selectedCountry = {};
    this.selectedCountry = data;
  }
  focusFunction() {
      this.placeHolder = "";
  }

  restrictSpecialCharacterForPhoneNumber(event) {
    return this.sharedService.restrictSpecialCharacterForGivenRegex(event);
  }

  restrictPhoneNumberCharactersOnPaste(event: ClipboardEvent) {
    return this.sharedService.restrictGivenRegexCharactersOnPaste(event);
  }

  restrictSpace(e) {
    return this.sharedService.restrictSpace(e);
  }
}
