import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpParams, HttpHeaders } from '@angular/common/http';
import { ClaimCase } from './../models/claimCase.model';
import { Conference, iCallHistoryList } from './../models/conference.model';
import { ApiClientService } from './api-client.service';
import { FileUploadService } from './../services/file-upload.service';
import { iSaveImport } from './../models/import-batch.model';

@Injectable({
  providedIn: 'root'
})

export class ImportBatchService {

  constructor(private apiClientService: ApiClientService, private fileService: FileUploadService) { }

  public saveDataFromFile(importList: iSaveImport): Observable<any> {
    return this.apiClientService.post<any>("importBatch/saveDataFromFile", importList);
  }

  public deleteUploadedFile(userId: number): Observable<any> {
    let params = new HttpParams();
    params = params.append('userId', userId.toString());

    return this.apiClientService.getSingle<any>("importBatch/deleteUploadedFile", undefined, params);
  }

  public uploadFile(formData: FormData, userId: number): Observable<any> {
    let params = new HttpParams();
    params = params.append('userId', userId.toString());

    return this.fileService.post(`importBatch/uploadFile`, formData, null, params);
  }
}
