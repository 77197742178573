import { Injectable } from '@angular/core';
import { ApiClientService } from './api-client.service';
import { Observable } from 'rxjs';
import { HttpParams } from '@angular/common/http';
import { iTermsAndConditionVM } from '../models/terms-and-condition.model';

@Injectable({
  providedIn: 'root'
})
export class TermsAndConditionService {

  constructor(private apiClientService: ApiClientService) { }

  // Get Tenant Terms and Condition active/deactivate flag
  public isTenantTermsActive(tenantId: number): Observable<boolean> {
    let params = new HttpParams();
    params = params.append("tenantId", tenantId.toString());

    return this.apiClientService.getSingle<boolean>("setting/isTenantTermsActive", undefined, params);
  }

  // Save/Update Terms and Conditions
  public saveUpdateTermsAndCondition(termsAndConditionVM: iTermsAndConditionVM): Observable<boolean> {

    return this.apiClientService.post<boolean>("setting/saveUpdateTermsAndCondition", termsAndConditionVM);
  }

  // Get Tenant latest Terms and Conditions
  public getTermsAndCondition(tenantId: number): Observable<iTermsAndConditionVM> {
    let params = new HttpParams();
    params = params.append("tenantId", tenantId.toString());

    return this.apiClientService.getSingle<iTermsAndConditionVM>("setting/getTermsAndCondition", undefined, params);
  }

  // Removed Tenant Terms and Condition feature
  public removedTenantTerms(tenantId: number): Observable<boolean> {
    let params = new HttpParams();
    params = params.append("tenantId", tenantId.toString());

    return this.apiClientService.getSingleWithoutLoader<boolean>("setting/removedTenantTerms", undefined, params);
  }
}
