<div class="all-settings-content">
  <div class="my-payment-content">
    <div class="due-container">
      <div class="due-top" *ngIf="showPending">
        <div class="top-title-part">
          <span class="title-top-in">Pagos Pendientes</span>
          <button class="btn" [disabled]="selectedInvoiceId.length == 0" (click) = "configureSessionWithInvoices()">Hacer un Pago</button>
          <div class="clear"></div>
        </div>
        <div class="due-desc">
          <div class="due-desc-top">
            <div class="row">
              <div class="col-md-1">
                <span class="check-due-part">
                  <label class="container-dot">
                    <input type="checkbox" (change)="selectAllPendingInvoicesMethod($event)" [(ngModel)]="selectAllPendingInvoices">
                    <span class="checkmark"></span>
                  </label>
                </span>
              </div>
              <div class="col-md-2">
                <span class="due-sub-tt">Numero Factura</span>
              </div>
              <div class="col-md-3">
                <span class="due-sub-tt">Fecha Factura</span>
              </div>
              <div class="col-md-3">
                <span class="due-sub-tt">Fecha Vencimiento</span>
              </div>
              <div class="col-md-2">
                <span class="due-sub-tt">Monto</span>
              </div>              
              <div class="col-md-1">
                <span class="due-sub-tt">Estado</span>
              </div>
            </div>
          </div>
          <div class="due-btm-info extra">
            <div class="due-desc-btm" *ngFor="let item of pendingInvoiceList; let i = index">
              <div class="row">
                <div class="col-md-1">
                  <span class="check-due-part">
                    <label class="container-dot">
                      <input type="checkbox" (click)="addRemoveInvoiceId($event, item.invoiceNumber, item.totalAmount)" [(ngModel)]="item.isInvoiceSelected">
                      <span class="checkmark"></span>
                    </label>
                  </span>
                </div>
                <div class="col-md-2">
                  <span class="only-sub-tt"><a (click) = "getInvoiceDetail(item.invoiceId)">{{item.invoiceNumber}}</a></span>
                </div>
                <div class="col-md-3">
                  <span class="only-sub-tt">{{item.invoiceDateString}}</span>
                </div>
                <div class="col-md-3">
                  <span class="only-sub-tt">{{item.dueDateString}}</span>
                </div>
                <div class="col-md-2">
                  <span class="only-sub-tt">${{item.totalAmount}}</span>
                </div>                
                <div class="col-md-1">
                  <span class="only-sub-tt p-part">Pendiente</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="invoice-btm">
        <div class="top-title-part">
          <span class="title-top-in">Facturas</span>
          <div class="dropdown dd-blk">
            <a class="dropdown-toggle" id="menu1" data-toggle="dropdown">
              <div class="top-block-part">
                <span>{{selectedYear.name}}</span>
                <img class="arrow-only" src="../../../../assets/images/inv-arw.svg" />
              </div>
            </a>
            <ul class="dropdown-menu" role="menu" aria-labelledby="menu1">
              <li role="presentation"><a role="menuitem" (click)="selectRecent()">Reciente</a></li>
              <li *ngFor="let item of yearList">
                <a role="menuitem" (click)="selectYear(item)">{{item.name}}</a>
              </li>
            </ul>
          </div>
          <div class="clear"></div>
        </div>
        <div class="due-desc">
          <div class="due-desc-top">
            <div class="row">
              <div class="col-md-3">
                <span class="due-sub-tt first">Numero Factura</span>
              </div>
              <div class="col-md-3">
                <span class="due-sub-tt">Fecha Factura</span>
              </div>
              <div class="col-md-3">
                <span class="due-sub-tt">Fecha Vencimiento</span>
              </div>
              <div class="col-md-2">
                <span class="due-sub-tt">Monto</span>
              </div>
              <div class="col-md-1">
                <span class="due-sub-tt">Estado</span>
              </div>
            </div>
          </div>
          <div class="due-btm-info">
            <div class="due-desc-btm" *ngFor="let item of paidInvoiceList; let i = index">
              <div class="row">
                <div class="col-md-3">
                  <span class="only-sub-tt first"><a (click) = "getInvoiceDetail(item.invoiceId)">{{item.invoiceNumber}}</a></span>
                </div>
                <div class="col-md-3">
                  <span class="only-sub-tt">{{item.invoiceDateString}}</span>
                </div>
                <div class="col-md-3">
                  <span class="only-sub-tt">{{item.dueDateString}}</span>
                </div>
                <div class="col-md-2">
                  <span class="only-sub-tt">${{item.totalAmount}}</span>
                </div>
                <div class="col-md-1">
                  <span class="only-sub-tt d-part">Pagado</span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="load-more" *ngIf="showLoadMore">
          <button class="btn comm-btn" (click)="getPaidInvoices()">Load More</button>
        </div>
      </div>
    </div>
  </div>
</div>
