<div class="widgets week-data">
    <!--<div class="more-cs-part">
        <span>Coming Soon</span>
    </div>-->
    <div class="title-block">
        <div class="row">
            <div class="col-md-6">
                <div class="title">
                    <span>Este Mes</span>
                </div>
            </div>
            <div class="col-md-6">
                <!--<div class="more-part">
                    <a href="/full-calender-view">More<img src="../../../../assets/images/ds-more-img.svg" /></a>
                </div>-->
            </div>
        </div>
    </div>
    <div class="details-part-mdl">
        <div class="row">
            <div class="col-md-6">
                <div class="some-part">
                    <span class="img-left">
                        <img src="../../../../assets/images/week-dataimg.svg" />
                    </span>
                    <div class="extra-shot">
                      <span class="one-part">{{monthlyCount.remainingCalls}}</span><span> Restantes</span>
                    </div>
                </div>
            </div>
            <div class="col-md-6">
                <div class="text-details">
                    <div class="call-info-widget">
                      <span class="outof">{{monthlyCount.completedCalls}}</span><span class="total">/ {{monthlyCount.totalCalls}}</span>
                    </div>
                    <div class="text-part">
                        <span class="only-info">Llamadas Completadas</span>
                    </div>
                </div>
                <div class="clear"></div>
            </div>
        </div>
    </div>
    <div class="btm-desc">
        <div class="row">
            <div class="col-md-12">
                <div class="progress-block">
                    <span style="width: {{monthlyCount.completedPercentage}}%"></span>
                </div>
            </div>
        </div>
    </div>
</div>
