<div class="widgets reschedule">
    <!--<div class="more-part">
    <span>Coming Soon</span>
  </div>-->
    <div class="reschedule-top">
        <div class="row">
            <div class="col-md-6">
                <div class="btm-title">
                    <span>Reprogramaci&#243;n</span>
                </div>
            </div>
            <div class="col-md-6">
                <span class="only-no">({{confRescheduleList.totalCount}})</span>
            </div>
        </div>
    </div>
    <div class="no-data" *ngIf="!showData">
        <img src="../../../../assets/images/no-rescheduling-request.svg" />
        <span>Sin registros, solicitud de conferencia esperando su actividad.</span>
    </div>
    <div class="reschedule-btm" *ngIf="showData">
        <div id="myCarousel" class="carousel slide" data-interval="false">
            <!-- Indicators -->
            <ol class="carousel-indicators" *ngIf="confRescheduleList.totalCount <= 8">
                <li *ngFor="let item of confRescheduleList.rescheduleList; let i= index; first as isFirst" data-target="#myCarousel" attr.data-slide-to="{{i}}" [ngClass]="{active:isFirst}"></li>
            </ol>

            <!-- Wrapper for slides -->
            <div class="carousel-inner">

                <div class="item" [ngClass]="{active:isFirst}" *ngFor="let item of confRescheduleList.rescheduleList; let i= index; first as isFirst">
                    <div class="reshedule-slide">
                        <div class="row">
                            <div class="col-md-10">
                                <div class="approve-part">
                                    <span class="name-part">{{item.initials}}</span>
                                    <div class="details-approve">
                                        <span class="approve-name">{{item.fullName}}</span>
                                        <span class="partner-text">{{item.emailId}}</span>
                                    </div>
                                    <div class="clear"></div>
                                </div>
                            </div>
                            <div class="col-md-2">
                                <div class="tooltip">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="23.999" viewBox="0 0 24 23.999">
                    <g id="Group_5896" data-name="Group 5896" transform="translate(-850 -388.001)">
                      <path id="Subtraction_18" data-name="Subtraction 18" d="M941-2294a11.923,11.923,0,0,1-8.486-3.514A11.921,11.921,0,0,1,929-2306a11.922,11.922,0,0,1,3.515-8.485A11.923,11.923,0,0,1,941-2318a11.921,11.921,0,0,1,8.485,3.514A11.921,11.921,0,0,1,953-2306a11.921,11.921,0,0,1-3.515,8.485A11.921,11.921,0,0,1,941-2294Zm0-23a11.013,11.013,0,0,0-11,11,11.013,11.013,0,0,0,11,11,11.012,11.012,0,0,0,11-11A11.012,11.012,0,0,0,941-2317Z" transform="translate(-79 2706)" fill="#B6CAF2" />
                      <text id="i" transform="translate(862 405)" fill="#B6CAF2" font-size="12" font-family="Muli-ExtraBold, Muli" font-weight="800"><tspan x="-1.644" y="0">i</tspan></text>
                    </g>
                  </svg>
                                    <div class="tooltiptext">
                                        <ul>
                                            <li>
                                                <span class="hovr-top">Fecha de Caso</span>
                                                <span class="hovr-btm">{{item.lossDate | date :'dd/MM/yy'}}</span>
                                            </li>
                                            <!--<li>
                        <span class="hovr-top">Policy Number</span>
                        <span class="hovr-btm">{{item.policyNo}}</span>
                      </li>-->
                                            <li>
                                                <span class="hovr-top">Numero de Caso:</span>
                                                <span class="hovr-btm">{{item.claimNo}}</span>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="slide-btm">
                            <div class="row">
                                <div class="col-md-5">
                                    <div class="old-slot">
                                        <span class="left-top">{{item.oldScheduleDate}}</span>
                                        <span class="left-btm">{{item.oldScheduleTime}}</span>
                                    </div>
                                </div>
                                <div class="col-md-2">
                                    <img src="../../../../assets/images/old-to-new.svg" />
                                </div>
                                <div class="col-md-5">
                                    <div class="new-slot">
                                        <span class="right-top">{{item.newScheduleDate}}</span>
                                        <span class="right-btm">{{item.newScheduleTime}}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="button-block">
                            <div class="row">
                                <div class="col-md-6">
                                    <button class="btn comm-btn" (click)="acceptRescheduleConf(item.confId)">Aceptar</button>
                                </div>
                                <div class="col-md-6">
                                    <button class="btn comm-btn" data-toggle="modal" (click)="openRescheduleModal(item)">Reprogramar</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <!-- Left and right controls -->
            <span class="left carousel-control" href="#myCarousel" data-slide="prev">
        <svg xmlns="http://www.w3.org/2000/svg" width="5.207" height="9.414" viewBox="0 0 5.207 9.414">
          <path id="Path_3277" data-name="Path 3277" d="M-10941.69-2260.811l-4-4-4,4" transform="translate(2265.311 -10940.983) rotate(-90)" fill="none" stroke="#002062" stroke-linecap="round" stroke-linejoin="round" stroke-width="1" />
        </svg>
      </span>
            <span class="right carousel-control" href="#myCarousel" data-slide="next">
        <svg xmlns="http://www.w3.org/2000/svg" width="5.207" height="9.414" viewBox="0 0 5.207 9.414">
          <path id="Path_3277" data-name="Path 3277" d="M-10941.69-2260.811l-4-4-4,4" transform="translate(-2260.104 10950.397) rotate(90)" fill="none" stroke="#002062" stroke-linecap="round" stroke-linejoin="round" stroke-width="1" />
        </svg>
      </span>
        </div>
    </div>
</div>

<div class="modal fade reshedule-pop" id="resheduler" data-backdrop="static" aria-hidden="true" role="dialog">
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-header pop-title">
                <button type="button" class="close" data-dismiss="modal">
          <img src="../../../assets/images/close-pop.svg" />
        </button>
                <h4 class="modal-title">
                    <span class="only-time">Escoger Fecha y Horario</span>
                </h4>
            </div>
            <div class="modal-body">
                <div class="approve-part">
                    <span class="name-part">{{rescheduleModalData.initials}}</span>
                    <div class="only-approve">
                        <span class="approve-name">{{rescheduleModalData.fullName}}</span>
                        <span class="partner-text">ha sugerido <span class="bold-block">{{rescheduleModalData.newScheduleDate}}</span> at <span class="bold-block">{{rescheduleModalData.newScheduleTime}} - {{rescheduleModalData.newScheduleEndTime}}</span></span>
                    </div>
                    <div class="clear"></div>
                </div>
                <div class="schedule-opt">
                    <div class="row">
                        <div class="col-md-7">
                            <div class="row">
                                <div class="col-md-6">
                                    <div class="input-block">
                                        <!--<input type="text" class="form-control" />-->
                                        <input name="dtRescheduleDate" id="dtRescheduleDate" placeholder="mm/dd/yy" [min]="minDate" [owlDateTimeTrigger]="dtRescheduleDate" [owlDateTime]="dtRescheduleDate" [owlDateTimeFilter]="myFilter" class="form-control date-control" [(ngModel)]="rescheduleDate"
                                            required>
                                        <owl-date-time [pickerType]="'calendar'" #dtRescheduleDate></owl-date-time>
                                        <div [owlDateTimeTrigger]="dtRescheduleDate"></div>
                                        <div class="arrow"></div>
                                    </div>
                                </div>
                                <div class="col-md-6 pad-only">
                                    <div class="input-block">
                                        <select class="form-control" [(value)]="selected" [(ngModel)]="selected" name="scheduleTime">
                      <option value="None">Ninguno</option>
                      <!-- <option value="00:00">12:00 AM</option>
                      <option value="00:30">12:30 AM</option>
                      <option value="01:00">01:00 AM</option>
                      <option value="01:30">01:30 AM</option>
                      <option value="02:00">02:00 AM</option>
                      <option value="02:30">02:30 AM</option>
                      <option value="03:00">03:00 AM</option>
                      <option value="03:30">03:30 AM</option>
                      <option value="04:00">04:00 AM</option>
                      <option value="04:30">04:30 AM</option>
                      <option value="05:00">05:00 AM</option>
                      <option value="05:30">05:30 AM</option>
                      <option value="06:00">06:00 AM</option>
                      <option value="06:30">06:30 AM</option>
                      <option value="07:00">07:00 AM</option>
                      <option value="07:30">07:30 AM</option> -->
                      <option value="08:00">08:00 AM</option>
                      <option value="08:30">08:30 AM</option>
                      <option value="09:00">09:00 AM</option>
                      <option value="09:30">09:30 AM</option>
                      <option value="10:00">10:00 AM</option>
                      <option value="10:30">10:30 AM</option>
                      <option value="11:00">11:00 AM</option>
                      <option value="11:30">11:30 AM</option>
                      <option value="12:00">12:00 PM</option>
                      <option value="12:30">12:30 PM</option>
                      <option value="13:00">01:00 PM</option>
                      <option value="13:30">01:30 PM</option>
                      <option value="14:00">02:00 PM</option>
                      <option value="14:30">02:30 PM</option>
                      <option value="15:00">03:00 PM</option>
                      <option value="15:30">03:30 PM</option>
                      <option value="16:00">04:00 PM</option>
                      <option value="16:30">04:30 PM</option>
                      <option value="17:00">05:00 PM</option>
                      <option value="17:30">05:30 PM</option>
                      <option value="18:00">06:00 PM</option>
                      <!-- <option value="18:30">06:30 PM</option>
                      <option value="19:00">07:00 PM</option>
                      <option value="19:30">07:30 PM</option>
                      <option value="20:00">08:00 PM</option>
                      <option value="20:30">08:30 PM</option>
                      <option value="21:00">09:00 PM</option>
                      <option value="21:30">09:30 PM</option>
                      <option value="22:00">10:00 PM</option>
                      <option value="22:30">10:30 PM</option>
                      <option value="23:00">11:00 PM</option>
                      <option value="23:30">11:30 PM</option> -->
                    </select>
                                        <div class="arrow"></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-5">
                            <div class="button-block">
                                <button class="btn comm-btn" (click)="rescheduleConf()">Reprogramar</button>
                            </div>
                        </div>
                    </div>
                    <div class="error-only-msg" *ngFor="let error of validationMessages">
                        <span>{{ error }} </span>
                    </div>
                    <!--<div class="error-only-msg" *ngIf="showSlotMsg">
            <span>Horario seleccionado no disponible.  Favor seleccionar otro.</span>
          </div>
          <div class="error-only-msg" *ngIf="showPreviousDateSelectedMessage">
            <span>Lo siento, no puede seleccionar ese horario ya que no es válido. Por favor seleccione una fecha u hora futura válida</span>
          </div>-->
                </div>
            </div>
        </div>
    </div>
</div>