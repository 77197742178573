<div class="all-settings-content authentication-container">
  <form ngNativeValidate #resetPasswordForm="ngForm">
    <div class="auth-block">
      <div class="row">
        <div class="col-sm-12 col-md-12">
          <div class="input-block extra">
            <input type="password" class="form-control" placeholder="Contrase&#241;a Anterior" name="oldPassword"
                   [(ngModel)]="resetPassword.oldPassword" />
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-sm-12 col-md-12">
          <div class="input-block extra">
            <input type="password" class="form-control" placeholder="Nueva Contrase&#241;a" name="newPassword" (input)="checkPasswordFormat()"
                   [(ngModel)]="resetPassword.newPassword" (focus)="focusFunction()" (keypress)="restrictSpace($event)"/>
          </div>
        </div>
      </div>
      <div class="row" *ngIf="showPasswordFormat">
        <div class="col-sm-12 col-md-12">
          <div class="password-rules">
            <div class="text-auth" [ngClass]="{'success': letterFlag}" *ngIf="showPasswordFormat">
              <img class="cross" src="../../../../assets/images/auth-error-cross.svg" />
              <img class="tick" src="../../../../assets/images/auth-error-tick.svg" />
              <span class="rules-info">Requiere una letra Mayuscula | Miniscula</span>
            </div>
            <div class="text-auth" [ngClass]="{'success': digitFlag}" *ngIf="showPasswordFormat">
              <img class="cross" src="../../../../assets/images/auth-error-cross.svg" />
              <img class="tick" src="../../../../assets/images/auth-error-tick.svg" />
              <span class="rules-info">Requiere un digito : 0-9</span>
            </div>
            <div class="text-auth " [ngClass]="{'success': charFlag}" *ngIf="showPasswordFormat">
              <img class="cross" src="../../../../assets/images/auth-error-cross.svg" />
              <img class="tick" src="../../../../assets/images/auth-error-tick.svg" />
              <span class="rules-info">Requiere un Caracter Especial : ! @ # $ . . .</span>
            </div>
            <div class="text-auth" [ngClass]="{'success': lengthFlag}" *ngIf="showPasswordFormat">
              <img class="cross" src="../../../../assets/images/auth-error-cross.svg" />
              <img class="tick" src="../../../../assets/images/auth-error-tick.svg" />
              <span class="rules-info">Contrase&#241;a requiere por lo menos 8 caracteres</span>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-sm-12 col-md-12">
          <div class="input-block extra">
            <input type="password" class="form-control" placeholder="Confirmar Nueva Contrase&#241;a" name="cnfPassword" (input)="checkConfirmPasswordFormat()"
                   [(ngModel)]="confirmPassword" (focus)="focusConfirmPasswordFunction()" (keypress)="restrictSpace($event)"/>
          </div>
        </div>
      </div>
      <div class="row" *ngIf="showPasswordFormat == false">
        <div class="col-sm-12 col-md-12">
          <div class="password-rules">
            <div class="text-auth" [ngClass]="{'success': letterFlag}" *ngIf="showPasswordFormat == false">
              <img class="cross" src="../../../../assets/images/auth-error-cross.svg" />
              <img class="tick" src="../../../../assets/images/auth-error-tick.svg" />
              <span class="rules-info">Requiere una letra Mayuscula | Miniscula</span>
            </div>
            <div class="text-auth" [ngClass]="{'success': digitFlag}" *ngIf="showPasswordFormat == false">
              <img class="cross" src="../../../../assets/images/auth-error-cross.svg" />
              <img class="tick" src="../../../../assets/images/auth-error-tick.svg" />
              <span class="rules-info">Requiere un digito : 0-9</span>
            </div>
            <div class="text-auth " [ngClass]="{'success': charFlag}" *ngIf="showPasswordFormat == false">
              <img class="cross" src="../../../../assets/images/auth-error-cross.svg" />
              <img class="tick" src="../../../../assets/images/auth-error-tick.svg" />
              <span class="rules-info">Requiere un Caracter Especial : ! @ # $ . . .</span>
            </div>
            <div class="text-auth" [ngClass]="{'success': lengthFlag}" *ngIf="showPasswordFormat  == false">
              <img class="cross" src="../../../../assets/images/auth-error-cross.svg" />
              <img class="tick" src="../../../../assets/images/auth-error-tick.svg" />
              <span class="rules-info">Contrase&#241;a requiere por lo menos 8 caracteres</span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div>
      <validation-summary [validationErrors]="validationMessages"></validation-summary>
    </div>
    <div class="button-block">
      <button class="btn comm-btn" (click)="resetPasswordFun()" >Cambiar Contrase&#241;a</button>
    </div>
  </form>
</div>
