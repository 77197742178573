import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-thankyou-message',
  templateUrl: './thankyou-message.component.html',
  styleUrls: ['./thankyou-message.component.css']
})
export class ThankyouMessageComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
