import { Component, OnInit } from '@angular/core';
import { AuthService } from '../../services/auth.service';
import { Router } from '@angular/router';
import { SharedDataService } from '../../services/sharedData.service';
import { DashboardService } from './../../api-services/dashboard.service';
import { iUserProfile } from '../../models/dashboard.model';
import { ToastrService } from "ngx-toastr";

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {

  constructor(
    private router: Router,
    private authService: AuthService,
    private service: DashboardService,
    private sharedService: SharedDataService,
      private toastr: ToastrService
  ) { }

  adjusterId?: number;
  userProfileData?: iUserProfile = {};
  count?: number = 0;
  showThis?: boolean = true;

  ngOnInit(): void {
    this.adjusterId = this.sharedService.getValue("accountId");
    this.getUserProfileData();
  }

  signOut() {
    this.authService.logout();
    this.router.navigate(['/login']);
  }

  //Method to get user profile data
  getUserProfileData() {
    this.service.getUserProfileData(this.adjusterId).subscribe(result => {
      if (result != null) {
        this.userProfileData = <iUserProfile>result;
      }
    }, error => {
    });
  }

  //url;

  //selectFile(event) {
  //    if (!event.target.files[0] || event.target.files[0].length == 0) {
  //        this.warningMessage("You must select an image");
  //        return;
  //    }

  //    var mimeType = event.target.files[0].type;

  //    if (mimeType.match(/image\/*/) == null) {
  //        this.warningMessage("You must select an image");
  //        return;
  //    }

  //    var reader = new FileReader();
  //    reader.readAsDataURL(event.target.files[0]);

  //    reader.onload = (_event) => {
  //        this.url = reader.result;
  //    }
  //}
  //selectClick() {
  //    this.showThis = false;
  //}
  //warningMessage(message) {
  //    this.toastr.error(message, "Error");
  //}
}
