import { Component, OnInit } from "@angular/core";
import { DashboardService } from ".././../../api-services/dashboard.service";
import { iConversationDataVM } from "../../../models/dashboard.model";
import { SharedDataService } from "../../../services/sharedData.service";
import { Router } from "@angular/router";

@Component({
  selector: "app-conversation",
  templateUrl: './conversation.component.html',
  styleUrls: ["./conversation.component.css"],
})
export class ConversationComponent implements OnInit {
  adjusterId?: number;
  conversationData?: iConversationDataVM = {};
  localeDateOffset?: number;

  constructor(
    private router: Router,
    private service: DashboardService,
    private sharedService: SharedDataService
  ) {}

  ngOnInit(): void {
    this.localeDateOffset = this.sharedService.getTimezonOffset();
    this.adjusterId = this.sharedService.getValue("accountId");
    this.getConversationWidgetData();
  }
  // Get conference conversation data
  getConversationWidgetData() {
    this.service.getConversationData(this.adjusterId, this.localeDateOffset).subscribe(
      (result) => {
        if (result != null) {
          this.conversationData = <iConversationDataVM>result;          
        }
      },
      (error) => {
        console.log(error);
      }
    );
  }
  // Navigate to chat/conversation screen
  goToConversationScreen(confId?: any) {
    this.sharedService.setValue("conversationConfId", confId);
    this.router.navigateByUrl("chat");
  }
}
