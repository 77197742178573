<div class="authentication-container">
    <div class="container">
        <div class="auth-content login-auth">
            <div class="logo-block">
                <img src="../../../../assets/images/auth-logo.svg" />
            </div>
            <div class="auth-title">
                <span>Bienvenido a CASCO Safety</span>
            </div>
            <div class="auth-text">
                <span>Ingresar&nbsp;su&nbsp;correo&nbsp;electr&#243;nico&nbsp;y&nbsp;contrase&#241;a</span>
            </div>
            <form id="mainForm" #userForm="ngForm" (ngSubmit)="onSubmit()">
                <div class="auth-block">
                    <div class="row">
                        <div class="col-sm-12 col-md-12">
                            <div class="input-block extra">
                                <input type="text" class="form-control" placeholder="Correo Electr&#243;nico" id="emailaddress" name="Email address" [(ngModel)]="emailaddress" maxlength="50" required />
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-sm-12 col-md-12">
                            <div class="input-block extra">
                                <input class="form-control" type="password" placeholder="Contrase&#241;a" id="password" name="Password" [(ngModel)]="password" maxlength="50" required />
                            </div>
                        </div>
                    </div>
                </div>
                <div class="forgot-pwd">
                    <a href="/request-verification-code"> &#191;Olvido su contrase&#241;a?</a>
                </div>
                <div>
                    <validation-summary [validationErrors]="validationMessages"></validation-summary>
                </div>
                <div class="button-block">
                    <button class="btn comm-btn" type="submit">Iniciar sesi&#243;n</button>
                </div>
                <!--<div class="code-text">
                    <a href="/register">Registre su cuenta</a>
                </div>
                <div class="code-text">
                    <a href="/activate-account">Activar cuenta</a>
                </div>-->
            </form>
        </div>
    </div>
</div>
