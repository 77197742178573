<div class="widgets total-calls">
    <!--<div class="more-cs-part">
        <span>Coming Soon</span>
    </div>-->
    <div class="title">
        <span>No. of calls</span>
    </div>
    <div class="count">
      <span>{{completedCallsCount}}</span>
    </div>
</div>
