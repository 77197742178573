import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { SharedDataService } from '../../services/sharedData.service';
import { HttpClient } from '@angular/common/http';
import { Location } from '@angular/common';

@Component({
  selector: 'app-errorpage',
  templateUrl: './errorpage.component.html',
  styleUrls: ['./errorpage.component.css']
})
export class ErrorpageComponent implements OnInit {
  errorObject: any;
  errorMessage: string;
  isRecoverable = true;
  isWarning: boolean;
  errorHeadline: string;
  displayContact: boolean = true;

  constructor(private router: Router, private sharedDataService: SharedDataService, private _location: Location, private httpClient: HttpClient) { }

  ngOnInit() {
    try {
      this.errorObject = this.sharedDataService.errorObject;
      if (this.errorObject == undefined) {
        this.errorObject = { status: '', message: '', url: '' };
      }

      switch (this.errorObject.status) {
        //case 500:
        //  this.errorMessage = 'A server error occurred!';
        //  break;
        //case 404:
        //  this.errorMessage = 'We can\'t find that page!';
        //  break;
        //case 401:
        //  break;
        //case 402:
        //  this.errorMessage = 'The licence has expired. Please contact your system administrator.';
        //  this.isRecoverable = false;
        //  break;
        //case 403:
        //  this.errorMessage = 'You don\'t have access to this resource!';
        //  break;
        //case 418:
        //  this.errorMessage = 'Internet Explorer is an unsupported browser.';
        //  this.isRecoverable = false;
        //  break;
        //case 503:
        //  this.errorMessage = 'The database is locked - a system save is underway.';
        //  this.isRecoverable = false;
        //  this.displayContact = false;
        //  break;
        default:
          this.errorMessage = 'Un error desconocido ocurrió!';
          break;
      }
      this.isWarning = (this.errorObject.type === 'warning');
    } catch (e) {
      this.errorMessage = 'Un error desconocido ocurrió!';
      this.isWarning = false;
      console.log(e);
    }

    this.errorHeadline = this.isWarning ? 'Por favor revise su conexión a internet.' : 'Por favor revise su conexión a internet.';

  }

  ngOnDestroy() {

  }

  goBack() {
    this.router.navigate(['']);
  }
}
