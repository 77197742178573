import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders, HttpEvent, HttpEventType, HttpRequest } from '@angular/common/http';
import { Observable, BehaviorSubject } from 'rxjs';
import { catchError, retry, map, tap, last } from 'rxjs/operators';
import { environment } from './../../environments/environment';
import { FileUploadModel } from './../models/file-upload.model';
import { AuthService } from './auth.service';
import { Routes, RouterModule, Router } from '@angular/router';
import { SharedDataService } from './sharedData.service';

@Injectable({
  providedIn: 'root'
})
export class FileUploadService {
  private headerOptions: HttpHeaders = {} as HttpHeaders;
  public uploadMessage: BehaviorSubject<FileUploadModel> = new BehaviorSubject<FileUploadModel>(null);
  public videoUploadMessage: BehaviorSubject<FileUploadModel> = new BehaviorSubject<FileUploadModel>(null);

  constructor(private http: HttpClient, private authService: AuthService,
    private router: Router, private sharedService: SharedDataService) {
    var token = this.sharedService.getValue("token");
    if (token) {
      this.setHeader("Authorization", `bearer ${token}`);
    }
  }

  public postSync(url: string, formData: FormData, fileDetail: File, params: any = undefined) {

    let headers = new HttpHeaders();
    headers.append('Content-Type', 'multipart/form-data');
    headers.append("Authorization", `Bearer ${this.sharedService.getValue("token")}`);

    const req = new HttpRequest('POST', this.fullUrl(url), formData,
      {
        headers: headers,
        params: params,
        reportProgress: true
      }
    );

    return this.http.request(req).pipe(
      map(event => this.getVideoEventMessage(event)),
      tap(message => this.showVideoProgress(message)),
      last()
    ).toPromise();
  }

  public showVideoProgress(messageObject) {
    if (!messageObject)
      return;
    let fileUploadMessage: FileUploadModel = {
      message: messageObject.message,
      percentage: messageObject.percentage
    } as FileUploadModel;
    this.videoUploadMessage.next(fileUploadMessage);
  }

  private getVideoEventMessage(event: HttpEvent<any>) {
    switch (event.type) {
      case HttpEventType.Sent:
        return { message: `Uploading vidoe file`, percentage: 0 };

      case HttpEventType.UploadProgress:
        const percentDone = Math.round(100 * event.loaded / event.total);
        return { message: `Video is ${percentDone}% uploaded.`, percentage: percentDone };

      case HttpEventType.Response:
        return { message: `Video was completely uploaded!`, percentage: 100, uploadedFile: event.body };

    }
  }

  public post(url: string, formData: FormData, fileDetail: File, params: any = undefined) {
  
    //let headers = new HttpHeaders();
    //headers.append('Content-Type', 'multipart/form-data');
    //headers.append("Authorization", `Bearer ${this.sharedService.getValue("token")}`);

    var httpOptions = {
      headers: new HttpHeaders({
        //'Content-Type': 'multipart/form-data',
        'Authorization': `Bearer ${this.sharedService.getValue("token")}`
      })
    };
    
    const req = new HttpRequest('POST', this.fullUrl(url), formData,
      {
        headers: new HttpHeaders({
          'Authorization': `Bearer ${this.sharedService.getValue("token")}`
        }),
        //headers: httpOptions.headers,
        params: params,
        reportProgress: true
      }
    );

    return this.http.request(req).pipe(
      map(event => this.getEventMessage(event)),
      tap(message => this.showProgress(message)),
      last(), // return last (completed) message to caller
      catchError((err) => { return this.handleError(err); })
    );
  }

  public showProgress(messageObject) {
   
    let fileUploadMessage: FileUploadModel = {
      //fileName: file.name,
      message: messageObject.message,
      percentage: messageObject.percentage
    } as FileUploadModel;
    this.uploadMessage.next(fileUploadMessage);
  }

  public setHeader(key, value) {
    this.headerOptions[key] = value;
  }

  private fullUrl(url: string): string {
     return environment.apiUrl + url;
  }

  private handleError(response: HttpErrorResponse) {
    var error = {
      status: response.status,
      message: ''
    };
    if (response.status === 401) {
      this.headerOptions = {} as HttpHeaders;
      this.router.navigate(['login']);
    }
    else if (response.error) {
      if (response.error.status) {
        error.status = response.error.status;
      }

      if (typeof "" == typeof response.error) {
        error.message = response.error;
      }
      else {
        error.message = response.error.message ? response.error.message : response.error.error_description;
      }

    }
    else {
      error.message = response.message;
    }

    return Observable.throw(error);
  }

  private getEventMessage(event: HttpEvent<any>) {
    switch (event.type) {
      case HttpEventType.Sent:
        return { message: `Uploading file`, percentage: 0 };

      case HttpEventType.UploadProgress:
        const percentDone = Math.round(100 * event.loaded / event.total);
        return { message: `File is ${percentDone}% uploaded.`, percentage: percentDone };

      case HttpEventType.Response:
        return { message: `File was completely uploaded!`, percentage: 100, uploadedFile: event.body };

      default:
        return { message: `File surprising upload event: ${event.type}.`, percentage: 0 };
    }
  }

  public getZip(url: string, header: any = undefined, params: any = undefined) {
    return this.downloadFile("application/zip", url, header, params);
  }

  private downloadFile(fileType: string, url: string, header: any = undefined, params: any = undefined) {
    return this.http.get(this.fullUrl(url), { headers: header ? header : this.headerOptions, params: params ? params : null, responseType: 'arraybuffer' })
      .pipe(
        map(response => {

          var array = response;
          try {
            return new Blob([array], { type: fileType });
          }
          catch (e) {
            return response;
          }
        }),
        catchError((err) => { return this.handleError(err); })
      );
  }
}
