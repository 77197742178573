<div class="terms-block">
    <div class="terms-container">
        <div class="initial-terms" *ngIf="!isTermsAndConditionFlag">
            <div class="initial-details">
                <img (click)="activateTermsAndConditionFlag(isTermsAndConditionActive)" src="../../../../assets/images/terms-img.svg" />
                <span>You can add your own custom Terms & Conditions, user will have to agree to your Terms & Conditions before accepting the scheduled call. </span>
                <div class="button-block">
                    <button (click)="activateTermsAndConditionFlag(isTermsAndConditionActive)" class="btn comm-btn"><svg xmlns="http://www.w3.org/2000/svg" width="8" height="8" viewBox="0 0 8 8"><path id="Union_88" data-name="Union 88" d="M-21334.227,1711.773v-3h-3a.5.5,0,0,1-.5-.5.5.5,0,0,1,.5-.5h3v-3a.5.5,0,0,1,.5-.5.5.5,0,0,1,.5.5v3h3a.5.5,0,0,1,.5.5.5.5,0,0,1-.5.5h-3v3a.5.5,0,0,1-.5.5A.5.5,0,0,1-21334.227,1711.773Z" transform="translate(21337.729 -1704.271)" fill="#fff" /></svg>Add Terms & Conditions</button>
                </div>
            </div>
        </div>        
        <div class="terms-content" *ngIf="isTermsAndConditionFlag">
            <div class="terms-top">
                <span class="terms-title">Version {{dbVersionNumber}}</span>
                <div class="btm-block">
                    <div class="btm-left">
                        <button *ngIf = "termsAndConditionData.id > 0" class="btn comm-btn left-btn" data-target="#remove-terms" data-toggle="modal"><svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14"><g id="Group_6616" data-name="Group 6616" transform="translate(-839.271 -206.271)"><circle id="Ellipse_794" data-name="Ellipse 794" cx="7" cy="7" r="7" transform="translate(839.271 206.271)" fill="#002062" /><rect id="Rectangle_6356" data-name="Rectangle 6356" width="6" height="1" rx="0.5" transform="translate(843.271 213.271)" fill="#fff" /></g></svg>Remove</button>
                        <button (click) = "editContent()" *ngIf = "termsAndConditionData.id > 0" [disabled] = "isEditable" class="btn comm-btn left-btn"><svg xmlns="http://www.w3.org/2000/svg" width="12.33" height="16.356" viewBox="0 0 12.33 16.356"><path id="Union_89" data-name="Union 89" d="M2609-1061.959v-8.509h5v8.509l-2.5,2.959Zm0-10.322V-1075h5v2.719Z" transform="translate(-2788.96 -373.523) rotate(30)" fill="#002062" /></svg>Edit</button>
                        <button (click)="previewContent()" class="btn comm-btn left-btn" data-target="#terms-data" data-toggle="modal"><svg id="Group_6608" data-name="Group 6608" xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18"><g id="Group_6607" data-name="Group 6607" transform="translate(0 3)"><path id="Path_4931" data-name="Path 4931" d="M9,28.667c-5.351,0-8.807,5.527-8.87,5.627a.6.6,0,0,0-.033.7l0,0c.01.021,2.912,5.67,8.9,5.67,5.963,0,8.86-5.593,8.9-5.661l.007-.012a.6.6,0,0,0-.032-.7h0c-.063-.1-3.519-5.627-8.87-5.627Zm0,1.8a4.2,4.2,0,1,1-4.2,4.2A4.2,4.2,0,0,1,9,30.467Zm0,2.4a1.8,1.8,0,1,0,1.8,1.8A1.8,1.8,0,0,0,9,32.867Z" transform="translate(0 -28.667)" fill="#002062" /></g></svg>Preview</button>
                    </div>
                    <div class="clear"></div>
                </div>
            </div>
            <div class="terms-text" [ngClass] = "isEditorNonEditable ? '' : 'non-edit-part' ">
                <angular-editor (ngModelChange) = "changeContent()" [placeholder]="'Enter text here...'" [(ngModel)]="termsAndConditionData.content" [config]="config"></angular-editor>
            </div>
            <div class="btm-btns">                
                <button (click) = "cancel()" [disabled] = "!isContentEditable" class="btn comm-btn only-can">Cancel</button>
                <div class="s-btn">
                    <button class="btn" (click) = "savePublishContent()">
                        <span>Save & Publish</span>
                    </button>
                    <div class="arrow-rt" [ngClass] = "{'not-al': !isContentEditable}">
                        <div class="dropdown">
                            <div class="dropdown-toggle" id="menu1" data-toggle="dropdown">
                                <div class="clear"></div>
                                <img class="arrow-only" src="../../../../assets/images/down-arw-terms.svg" />
                            </div>
                            <ul (click) = "saveContent()" class="dropdown-menu" role="menu" aria-labelledby="menu1">
                                <li>
                                    <span>Save</span>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div class="clear"></div>
                </div>
            </div>
        </div>

    </div>

    <div class="modal fade terms-pop" id="terms-data" data-backdrop="static" role="dialog">
        <div class="modal-dialog">
            <div class="modal-content">
                <div class="modal-header pop-title">
                    <button type="button" class="close" data-dismiss="modal">
                        <img src="../../../../assets/images/modal-close-img.svg" />
                    </button>
                    <h4 class="modal-title">
                        <span>Terms & Conditions</span>
                    </h4>
                </div>
                <div class="modal-body">
                    <div #content class="terms-info">
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="modal fade remove-terms" id="remove-terms" data-backdrop="static" aria-hidden="true" role="dialog">
        <div class="modal-dialog">
            <div class="modal-content">
                <div class="modal-header pop-title">
                    <button type="button" class="close" data-dismiss="modal">
                        <img src="../../../../assets/images/modal-close-img.svg" />
                    </button>
                    <h4 class="modal-title">
                        <span>Remove Terms & Conditions</span>
                    </h4>
                </div>
                <div class="modal-body">
                    <div class="remove-content">
                        <span class="rmv-top">Do you want to remove Terms & Conditions?</span>
                        <span class="rmv-btm">Your saved data will be removed and <br /> the customer will not be prompted for terms & conditions anymore.</span>
                        <div class="btn-section">
                            <button class="btn comm-btn cancel" data-dismiss="modal">No</button>
                            <button (click)="removeTermsAndCondition()" class="btn comm-btn">Continue</button>
                        </div>
                    </div>
                </div>
            </div>
            <div>
                <validation-summary [validationErrors]="validationMessages"></validation-summary>
            </div>
        </div>
    </div>
</div>
