import { Injectable } from '@angular/core';
import {
  Router,
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot
} from '@angular/router';

import { AuthService } from './auth.service';
import decode from 'jwt-decode';
import { SharedDataService } from './sharedData.service';

@Injectable({
  providedIn: 'root'
})
export class RoleGuard implements CanActivate {

  constructor(public auth: AuthService, public router: Router, private sharedService: SharedDataService) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {

    const token = this.sharedService.getValue('token');

    if (token) {

      // decode the token to get its payload
      const tokenPayload = decode(token);

      // check if route is restricted by role
      if (route.data.roles && route.data.roles.indexOf(tokenPayload.userRole) === -1) {
        // role not authorised so redirect to home page
        this.router.navigate(['/']);
        return false;
      }

      //token and refresh token expired, so redirect to login
      if (this.auth.getTokenExpireTime() != undefined && this.auth.getRefreshTokenExpireTime() != undefined && this.auth.isAccessTokenExpired() && this.auth.isRefreshTokenExpired()) {
        this.auth.logout();
        this.router.navigate(['/']);
        return false;
      }

      // authorised so return true
      return true;
    }

    // not logged in so redirect to login page with the return url
    this.router.navigate(['/']);
    return false;
  }
}
