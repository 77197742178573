import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpParams, HttpHeaders } from '@angular/common/http';
import { ClaimCase } from './../models/claimCase.model';
import { Conference, iCallHistoryList } from './../models/conference.model';
import { ApiClientService } from './api-client.service';
import { iClaimDetailList, iStateList, iLossTypeList } from './../models/claimDetail.model';
import { iWebRescheduleRequestList, iRescheduleAcceptRequestWeb } from './../models/dashboard.model';

@Injectable({
  providedIn: 'root'
})
export class ClaimCaseService {

  constructor(private apiClientService: ApiClientService) { }

  public insertOrUpdateClaimData(claimCase: ClaimCase): Observable<any> {
    return this.apiClientService.post<any>("claim/InsertOrUpdateClaimData", claimCase);
  }

  public getAllClaims(adjusterId: number): Observable<iClaimDetailList[]> {
    let params = new HttpParams();
    params = params.append('adjusterId', adjusterId.toString());

    return this.apiClientService.getList<iClaimDetailList>("claim/getAllClaims", undefined, params);
  }

  public getAllClaimList(): Observable<iClaimDetailList[]> {
    return this.apiClientService.getList<iClaimDetailList>("claim/getAllClaimList", undefined,undefined,false);
  }

  public getAllClaimsRefresh(adjusterId: number): Observable<iClaimDetailList[]> {
    let params = new HttpParams();
    params = params.append('adjusterId', adjusterId.toString());

    return this.apiClientService.getListWithoutLoader<iClaimDetailList>("claim/getAllClaims", undefined, params);
  }

  public insertConference(data: any): Observable<any> {
    return this.apiClientService.post<any>("Conference/InsertConference", data);
  }

  public createGoogleCalendarMeeting(data: any): Observable<any> {
    return this.apiClientService.post<any>("Conference/createGoogleCalendarMeeting", data);
  }

  public getAdjusterCalendarPreference(adjusterId: number): Observable<any> {
    let params = new HttpParams();
    params = params.append('adjusterId', adjusterId.toString());

    return this.apiClientService.getSingle<any>("Conference/getAdjusterCalendarPreference", undefined, params);
  }

  public getCallHistory(adjusterId: number): Observable<iCallHistoryList[]> {
    let params = new HttpParams();
    params = params.append('adjusterId', adjusterId.toString());

    return this.apiClientService.getList<iCallHistoryList>("conference/getCallHistory", undefined, params);
  }

  public getClaimDetails(claimCaseId: number): Observable<any> {
    let params = new HttpParams();
    params = params.append('claimCaseId', claimCaseId.toString());

    return this.apiClientService.getSingle<any>("claim/getClaimDetails", undefined, params);
  }

  public getClaimCount(adjusterId: number): Observable<any> {
    let params = new HttpParams();
    params = params.append('adjusterId', adjusterId.toString());

    return this.apiClientService.getSingle<any>("claim/getClaimCount", undefined, params);
  }

  public getStates(countryId: number): Observable<any> {
    let params = new HttpParams();
    params = params.append('countryId', countryId.toString());
    return this.apiClientService.getListWithoutLoader<iStateList>("claim/getStates", undefined, params);
  }

  public getLossTypes(tenantId: number): Observable<any> {
    let params = new HttpParams();
    params = params.append('tenantId', tenantId.toString());
    return this.apiClientService.getList<iLossTypeList>("claim/getLossTypes", undefined, params);    
  }

  public resendInvite(caseId: number): Observable<any> {
    let params = new HttpParams();
    params = params.append('caseId', caseId.toString());
    return this.apiClientService.getList<any>("claim/resendInvite", undefined, params);
  }

  public getRescheduleData(confId: number): Observable<any> {
    let params = new HttpParams();
    params = params.append('confId', confId.toString());

    return this.apiClientService.getSingle<any>("conference/getRescheduleData", undefined, params);
  }  

  public getAllLossTypeEditClaim(tenantId: number, lossTypeId: number): Observable<any> {
    let params = new HttpParams();
    params = params.append('tenantId', tenantId.toString());
    params = params.append('lossTypeId', lossTypeId.toString());
    return this.apiClientService.getList<iLossTypeList>("claim/getAllLossTypeEditClaim", undefined, params);
  }

  public sendClaimLimitReachNotification(adjusterId: number): Observable<any> {
    let params = new HttpParams();
    params = params.append('adjusterId', adjusterId.toString());

    return this.apiClientService.getSingle<any>("claim/sendClaimLimitReachNotification", undefined, params);
  }  

  public getTicketFromODOO(odooTicketId: number,odooClientId: number): Observable<any> {
    let params = new HttpParams();
    params = params.append('odooTicketId', odooTicketId.toString());
    params = params.append('odooClientId', odooClientId.toString());
    return this.apiClientService.getList<any>("claim/GetTicketFromODOO", undefined, params);
  }

  public getCustomerTicketFromODOO(odooClientId: number): Observable<any> {
    let params = new HttpParams();
    params = params.append('odooClientId', odooClientId.toString());
    return this.apiClientService.getList<any>("ContactUs/GetCustomerTicketFromODOO", undefined, params);
  }

  public getCustomerFromODOO(emailId: string): Observable<any> {
    let params = new HttpParams();
    params = params.append('emailId', emailId.toString());
    return this.apiClientService.getList<any>("ContactUs/GetCustomerFromODOO", undefined, params);
  }
}
