import { Pipe, PipeTransform } from '@angular/core';
import { DatePipe } from '@angular/common';

@Pipe({
  name: 'localizedDateTime'
})
export class LocalizedDateTimePipe extends DatePipe {
  public transform(value): any {
    if (value && value != "" ) {
      var utcTime = new Date(value);
      return super.transform(new Date(utcTime.getTime() - (utcTime.getTimezoneOffset() * 60000)), 'dd/MM/yy h:mm a');
    } else {
      return "";
    }
  }
}

@Pipe({
  name: 'localizedDateTime'
})
export class LocalizedDateTimePipeFormat extends DatePipe {
  public transform(value): any {
    if (value && value != "") {
      var utcTime = new Date(value);
      return super.transform(new Date(utcTime.getTime() - (utcTime.getTimezoneOffset() * 60000)), 'HH:mm');
    } else {
      return "";
    }
  }
}
