import { Component, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import { ToastrService } from 'ngx-toastr';
import { ContactUsDetailService } from 'src/app/api-services/contactus-detail.service';
import { SettingService } from 'src/app/api-services/setting.service';
import { ContactUsDetails, PublicFormValidationMessage } from 'src/app/models/contactus-detail';
import { iCountryList } from 'src/app/models/setting.model';
import { SharedDataService } from 'src/app/services/sharedData.service';
import decode from 'jwt-decode';
import { ActivatedRoute, Router } from '@angular/router';
import { Constants } from 'src/app/shared/constant';
import { LocalizedDateTimePipe, LocalizedDateTimePipeFormat } from 'src/app/pipes/localized-date-time.pipe';
import { DatePipe } from '@angular/common';
@Component({
    selector: 'app-contact-form',
    templateUrl: './contact-form.component.html',
    styleUrls: ['./contact-form.component.css']
})
export class ContactFormComponent implements OnInit {

    public minDate = new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate());
    dropdownSettings = {};
    dropdownSettingsForActivity = {};
    model: ContactUsDetails = new ContactUsDetails();
    contactUsFileDetail: any;
    fileDetail: any;
    countryList?: iCountryList[] = [];
    selectedCountry?: iCountryList = {};
    validationMessages: any[] = [];
    countryDropDownList = [];
    riskTypesDropDownList = [];
    activityTypeDropDownList = [];
    invitationDate = new Date();
    verificationLink: string;
    userData: any;
    isCustomerExistInAdjusify = false;
    availableSlotList: any[] = [];
    timeSlot: any[] = [];
    tenantUserList = [];
    // public minDate = new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate());
    public myFilter = (d: Date): boolean => {
        const day = d.getDay();
        // Prevent Saturday and Sunday from being selected.
        return day !== 0 && day !== 6;
    }

    constructor(private contactUsDetailService: ContactUsDetailService,
        private settingService: SettingService,
        private sharedService: SharedDataService,
        private toastr: ToastrService,
        private route: ActivatedRoute,
        private router: Router,
        private localizedDateTimePipe: LocalizedDateTimePipeFormat,

    ) {
        this.route.queryParams.subscribe(params => {
            this.verificationLink = params['token'];
        });

    }


    ngOnInit() {
        this.contactUsDetailService.verifyToken(this.verificationLink).subscribe(data => {
            if (data) {
                this.invitationDate = new Date();
                this.userData = decode(this.verificationLink);
                if (this.userData.exisingCustomer) {
                    this.isCustomerExistInAdjusify = true;
                    this.model = JSON.parse(this.userData.exisingCustomer);
                }
                this.model.emailAddress = this.userData.emailId;
                this.model.fullName = this.userData.fullName;
                this.model.firstName = this.userData.firstName;
                this.model.lastName = this.userData.lastName;
                this.model.emailAddress = this.userData.emailId;
                this.model.oddoId = this.userData.oddoId;
                this.model.accountId = 0;
                this.loadConfiguration();

            } else {
                alert("Link expired")
            }
        })

    }

    // Setting
    loadConfiguration() {
        this.model.callScheduleTime = 'None';
        this.countryDropDownList = [
            { item_text: 'Nicaragua' },
            { item_text: 'EL Salvador' },
            { item_text: 'Guatemala' },
            { item_text: 'Otro' },
        ];

        this.riskTypesDropDownList = [
            { item_text: 'Craneana' },
            { item_text: 'Auditiva' },
            { item_text: 'Respiratorio' },
            { item_text: 'Manos' },
            { item_text: 'Pies' },
            { item_text: 'Cuerpo' },
            { item_text: 'Alturas' },
            { item_text: 'Pegamentos Adhesivos' },
            { item_text: 'Lubricantes' },
            { item_text: 'Otro' },
        ];

        this.activityTypeDropDownList = [
            { item_text: 'Diario' },
            { item_text: 'Semanal' },
            { item_text: 'Quincenal' },
            { item_text: 'Mensual' },
            { item_text: 'Trimestral' },
            { item_text: 'Semestral' },
            { item_text: 'Annual' }
        ];

        this.dropdownSettings = {
            singleSelection: false,
            idField: 'item_text',
            textField: 'item_text',
            selectAllText: 'Select All',
            unSelectAllText: 'UnSelect All',
            itemsShowLimit: 15,
            allowSearchFilter: true,
            showSelectedItemsAtTop: false
        };

        this.dropdownSettingsForActivity = {
            singleSelection: true,
            idField: 'item_text',
            textField: 'item_text',
            selectAllText: 'Select All',
            unSelectAllText: 'UnSelect All',
            itemsShowLimit: 15,
            allowSearchFilter: true,
            showSelectedItemsAtTop: false
        };

        this.getCountryList();
        this.getAllTenantUser();
        //this.getAvailableSlot(this.invitationDate);
    }

    /* FILE UPLOAD CODE */
    handleInputChange(e) {
        var file = e.dataTransfer ? e.dataTransfer.files[0] : e.target.files[0];
        var reader = new FileReader();
        this.fileDetail = file;
        reader.onload = this._handleReaderLoaded.bind(this);
        reader.readAsDataURL(file);
    }

    _handleReaderLoaded(e) {
        let reader = e.target;
        this.contactUsFileDetail = reader.result;
    }

    /* API CALLS */

    getCountryList() {
        this.settingService.getCountryList().subscribe(result => {
            if (result != null) {
                this.countryList = <iCountryList[]>result;
                if (this.countryList && this.countryList.length > 0) {
                    this.selectedCountry = this.countryList.find(x => x.isDefaultCountry == true);
                }
            }
        }, error => {

        });
    }

    getAllTenantUser() {
        this.settingService.getAllTenantUser().subscribe(result => {
            if (result != null) {
                this.tenantUserList = result;
            }
        }, error => {

        });
    }

    onChangeUser(){
        if (this.invitationDate && this.model.accountId > 0)
         {
            this.timeSlot = Constants.TimeSlot;
            this.settingService.getAvailableSlot(this.invitationDate.toDateString(),this.model.accountId).subscribe(result => {
                if (result != null) {
                    this.availableSlotList = result;
                    if (this.availableSlotList && this.availableSlotList.length > 0) {
                        this.availableSlotList.forEach(data => {
                            data['scheduleData'] = this.localizedDateTimePipe.transform(data.scheduledDate);
                        })
                        let test = this.availableSlotList;
                        this.timeSlot = Constants.TimeSlot.filter((elem) => !this.availableSlotList.find((slot) => elem.key == slot.scheduleData));
                    }

                }
            }, error => {

            });
        }
    }

    getAvailableSlot($event) {
        if ($event && this.model.accountId > 0)
         {
            this.timeSlot = Constants.TimeSlot;
            this.settingService.getAvailableSlot($event.toDateString(),this.model.accountId).subscribe(result => {
                if (result != null) {
                    this.availableSlotList = result;
                    if (this.availableSlotList && this.availableSlotList.length > 0) {
                        this.availableSlotList.forEach(data => {
                            data['scheduleData'] = this.localizedDateTimePipe.transform(data.scheduledDate);
                        })
                        let test = this.availableSlotList;
                        this.timeSlot = Constants.TimeSlot.filter((elem) => !this.availableSlotList.find((slot) => elem.key == slot.scheduleData));
                    }

                }
            }, error => {

            });
        }

    }

    sendContactUsRequest(request: any) {
        this.getFormError(request);
        if (request.valid && this.validationMessages.length == 0) {
            let httpRequestBody = new ContactUsDetails();
            httpRequestBody = request.value;
            httpRequestBody.createdDate = new Date(new Date().toUTCString());
            httpRequestBody.firstName = this.model.firstName;
            httpRequestBody.lastName = this.model.lastName;
            httpRequestBody.fullName = this.model.fullName;
            httpRequestBody.accountId = this.model.accountId;
            if (this.isCustomerExistInAdjusify) {
                httpRequestBody.mobileNumber = this.model['MobileNumber'];
                httpRequestBody.jobTitle = this.model['JobTitle'];
                httpRequestBody.company = this.model['Company'];
                httpRequestBody.countryId = this.model['CountryId'];
                httpRequestBody.isdCountryId = this.model['CountryId'];
                httpRequestBody.countryName = this.model['CountryName'];
                httpRequestBody.totalCollaborators = this.model['TotalCollaborators'];
                httpRequestBody.exposedCollaborators = this.model['ExposedCollaborators'];
            }
            httpRequestBody.emailAddress = this.model.emailAddress;
            if (this.model.oddoId == 0) {
                httpRequestBody.isdCountryId = this.selectedCountry.id;
            }
            httpRequestBody.token = this.verificationLink;
            var hours = Number(httpRequestBody.callScheduleTime.split(":")[0]);
            var minutes = Number(httpRequestBody.callScheduleTime.split(":")[1]);
            this.invitationDate.setHours(hours);
            this.invitationDate.setMinutes(minutes);
            this.invitationDate.setSeconds(0);
            httpRequestBody.invitationDate = new Date(this.invitationDate);
            console.log(httpRequestBody.invitationDate)
            console.log(new Date(this.invitationDate))

            if (request.value.riskTypes && request.value.riskTypes.length > 0) {
                if (request.value.riskTypes.map) {
                    httpRequestBody.riskTypes = request.value.riskTypes.map((item) => { return item.item_text }).join(',')
                }
            }
            if (request.value.activityTypes && request.value.activityTypes.length > 0) {
                if (request.value.activityTypes.map) {
                    httpRequestBody.activityTypes = request.value.activityTypes.map((item) => { return item.item_text }).join(',')
                }
            }
            if (request.value.countryName && request.value.countryName.length > 0) {
                if (request.value.countryName.map) {
                    httpRequestBody.countryName = request.value.countryName.map((item) => { return item.item_text }).join(',')
                }

            }

            if (this.contactUsFileDetail) {
                httpRequestBody.base64String = this.contactUsFileDetail;
                httpRequestBody.fileName = this.fileDetail.name;
                httpRequestBody.mimeType = this.fileDetail.type;
            }
            if (this.selectCountry) {
                let mobileNumber = this.selectedCountry.countryISDCode + ' ' + request.value.mobileNumber;
                httpRequestBody.phoneNumber = request.value.mobileNumber;
                httpRequestBody.mobileNumber = mobileNumber;
            }

            this.contactUsDetailService.sendContactUsRequest(httpRequestBody).subscribe((data: any) => {
                if (data != "success") {
                    httpRequestBody = new ContactUsDetails();
                    this.toastr.error(data);
                } else {
                    this.fileDetail = null;
                    this.model.callScheduleTime = 'None';
                    request.reset();
                    this.toastr.success('solicitud enviada con éxito', 'Aviso');
                    //sessionStorage.setItem('previousRoute', this.router.url);
                   this.router.navigate(["/ticket"])
                   //this.ngOnInit();
                }
            }, error => {
                // this.toastr.error('se produce un error ', 'Aviso');

            });
        }
    }

    /* OTHER FUNCTIONS */

    selectCountry(data?: any) {
        this.selectedCountry = {};
        this.selectedCountry = data;
    }

    restrictSpecialCharacterForPhoneNumber(event) {
        return this.sharedService.restrictSpecialCharacterForGivenRegex(event);
    }

    removeSpaceFromString(request: any, key: string) {
        let newValue = request.form.controls[key].value;
        if (newValue) {
            request.form.controls[key].patchValue(newValue.trim())
        }
    }

    getFormError(request) {
        this.validationMessages = [];
        // if (this.selectedCountry.countryISDCode == null) {
        //     this.validationMessages.push(`Seleccione el código de país`);
        // }

        if (this.invitationDate == null) {
            this.validationMessages.push('Seleccione la fecha de la invitación.');
            return;
        }
        else if (this.invitationDate < new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate())) {
            this.validationMessages.push('La fecha de la invitación no puede ser en el pasado.');
            return;
        }
        else if (this.invitationDate.getDay() == 0 || this.invitationDate.getDay() == 6) {
            this.validationMessages.push('La fecha de la invitación no puede ser durante el fin de semana.');
            return;
        }

        if (this.model.callScheduleTime == "None") {
            this.validationMessages.push('Por favor seleccione una hora válida.');
            return;
        }

        var dateToCheck = new Date();
        var tempInviDate = this.invitationDate;
        var dHour = dateToCheck.getHours();
        var dMin = dateToCheck.getMinutes();

        var hours = Number(this.model.callScheduleTime.split(":")[0]);
        var minutes = Number(this.model.callScheduleTime.split(":")[1]);
        tempInviDate.setHours(hours, minutes, 0, 0);
        dateToCheck.setHours(dHour, dMin, 0, 0);

        //if (this.invitationDate <= dateToCheck && hours <= dateToCheck.getHours() && minutes <= dateToCheck.getMinutes()) {
        //  this.validationMessages.push("Lo siento, no puede seleccionar ese horario ya que no es válido. Por favor seleccione una fecha u hora futura válida");
        //}

        if (tempInviDate < dateToCheck) {
            this.validationMessages.push("Lo siento, no puede seleccionar ese horario ya que no es válido. Por favor seleccione una fecha u hora futura válida");
        }

        return Object.keys(request.controls).map(key => {
            const errors = request.controls[key].errors;
            if (errors === null) { return null; }
            if (errors.required) {
                if (!(this.validationMessages.find(x => x == 'Por favor complete todos los campos obligatorios'))) {
                    this.validationMessages.push('Por favor complete todos los campos obligatorios');
                }
            }
            else if (errors.email) {
                let message = PublicFormValidationMessage.find(x => x.key == key && x.validationKey == 'email').message
                this.validationMessages.push(message);
            }
            else if ((errors.minlength) && key == 'mobileNumber') {
                let message = PublicFormValidationMessage.find(x => x.key == key && x.validationKey == 'invalid').message
                this.validationMessages.push(message);
            }
            else if ((errors.maxlength) && key == 'mobileNumber') {
                let message = PublicFormValidationMessage.find(x => x.key == key && x.validationKey == 'invalid').message
                this.validationMessages.push(message);
            }
            else {
                this.validationMessages.push(`${key} has an unknown error`);
            }
        });

    }

    onItemSelect(item: any) {
        console.log(item);
    }

    onSelectAll(items: any) {
        console.log(items);
    }

    resetForm(request) {
        this.validationMessages = [];
        this.fileDetail = null;
        this.model = new ContactUsDetails();
        this.model.callScheduleTime = 'None';
        if (this.countryList && this.countryList.length > 0) {
            this.selectedCountry = this.countryList.find(x => x.isDefaultCountry == true);
        }
        this.invitationDate = new Date();
        // request.reset();

    }

    searchUser(request: any) {
        this.contactUsDetailService.searchClient(request.value.emailAddress).subscribe((data: ContactUsDetails) => {
            if (data) {
                this.model = data;
                let selectedCountry = this.countryList.find(x => x.id == data.countryId);
                if (selectedCountry) {
                    this.selectCountry(selectedCountry);
                }
                if (this.model && this.model.countryName) {
                    let countryList = this.model.countryName.split(',');
                    let countryArray = [];
                    if (countryList && countryList.length > 0) {
                        countryList.forEach(data => {
                            countryArray.push({ 'item_text': data })
                        })
                        this.model.countryName = countryArray;
                    }
                }

            }
        })
    }
}
