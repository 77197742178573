<div class="container-area">
    <div class="content-area">
        <div class="main-title">
            <span>Tickets</span>
        </div>
        <div class="backlog-top">
            <div class="row">
                <div class="col-md-6">
                    <div class="add-block">
                        <button class="btn" (click)="addNewClaim()">
                            <!--data-toggle="modal" data-target="#puchase-data"--><img
                                src="../../../assets/images/add-claim-plus.svg" />Agregar Nuevo
                        </button>
                        <!-- <button *ngIf="tenantData.isPaymentDone" class="btn import" (click)="goToImportScreen()"><img
                                src="../../../assets/images/import-batch.svg" />Lote de importaci&#243;n</button>
                     -->
                    
                            </div>
                </div>
                <div class="col-md-6">
                    <div class="refresh-block">
                        <button class="btn" (click)="refreshClick()"><img
                                src="../../../assets/images/refresh-new-ico.svg" />Refrescar</button>
                    </div>
                    <!--<div class="toggle-part">
            <span class="side">Completed Call</span>
            <label class="switch">
              <input type="checkbox">
              <span class="slider round"></span>
            </label>
          </div>-->
                </div>
            </div>
        </div>
        <div class="backlog-desc-part">
            <div class="backlog-title-block">
                <div class="row">
                    <div class="col-md-1">
                        <div class="title-part first">
                            <span>Número de Ticket</span>
                            <!--<a href="">
                <span>Claim No.</span>
                <span class="arrow">
                  <img src="../../../assets/images/sort-up.svg" />
                  <img src="../../../assets/images/sort-down.svg" />
                </span>
              </a>-->
                        </div>
                    </div>
                    <div class="col-md-1">
                        <div class="title-part">
                            <span>N&#250;mero de Cliente</span>
                            <!--<a href="">
                <span>Claim No.</span>
                <span class="arrow">
                  <img src="../../../assets/images/sort-up.svg" />
                  <img src="../../../assets/images/sort-down.svg" />
                </span>
              </a>-->
                        </div>
                    </div>
                    <!--<div class="col-md-1">
            <div class="title-part">
              <span>Policy No.</span>
              <a href="">
                <span>Policy No.</span>
                <span class="arrow">
                  <img src="../../../assets/images/sort-up.svg" />
                  <img src="../../../assets/images/sort-down.svg" />
                </span>
              </a>
            </div>
          </div>-->
                    <div class="col-md-1">
                        <div class="title-part">
                            <span>Tipo de Caso</span>
                            <!--<a href="">
                  <span>Loss Type</span>
                  <span class="arrow">
                      <img src="../../../assets/images/sort-up.svg" />
                      <img src="../../../assets/images/sort-down.svg" />
                  </span>
              </a>-->
                        </div>
                    </div>
                    <!--<div class="col-md-1">
            <div class="title-part">
              <span>Loss Date</span>
              <a href="">
                <span>Loss Date</span>
                <span class="arrow">
                  <img src="../../../assets/images/sort-up.svg" />
                  <img src="../../../assets/images/sort-down.svg" />
                </span>
              </a>
            </div>
          </div>-->
                    <div class="col-md-1">
                        <div class="title-part">
                            <span>Nombre</span>
                            <!--<a href="">
                <span>Name</span>
                <span class="arrow">
                  <img src="../../../assets/images/sort-up.svg" />
                  <img src="../../../assets/images/sort-down.svg" />
                </span>
              </a>-->
                        </div>
                    </div>
                    <div class="col-md-1 small-aprt">
                        <div class="title-part">
                            <span>Depto.</span>
                            <!--<a href="">
                <span>Policy Holder Address</span>
                <span class="arrow">
                  <img src="../../../assets/images/sort-up.svg" />
                  <img src="../../../assets/images/sort-down.svg" />
                </span>
              </a>-->
                        </div>
                    </div>
                    <div class="col-md-1">
                        <div class="title-part">
                            <span>C&#243;digo Postal</span>
                            <!--<a href="">
                <span>Policy Holder Address</span>
                <span class="arrow">
                  <img src="../../../assets/images/sort-up.svg" />
                  <img src="../../../assets/images/sort-down.svg" />
                </span>
              </a>-->
                        </div>
                    </div>
                    <div class="col-md-2">
                        <div class="title-part">
                            <span>Correo Electr&#243;nico</span>
                            <!--<a href="">
                <span>Email</span>
                <span class="arrow">
                  <img src="../../../assets/images/sort-up.svg" />
                  <img src="../../../assets/images/sort-down.svg" />
                </span>
              </a>-->
                        </div>
                    </div>
                    <div class="col-md-1 mid-part">
                        <div class="title-part">
                            <span>Tel&#233;fono</span>
                            <!--<a href="">
                <span>Ph. Number</span>
                <span class="arrow">
                  <img src="../../../assets/images/sort-up.svg" />
                  <img src="../../../assets/images/sort-down.svg" />
                </span>
              </a>-->
                        </div>
                    </div>
                    <div class="col-md-1">
                        <div class="title-part">
                            <span>Fecha Factura</span>
                            <!--<a href="">
                <span>Ph. Number</span>
                <span class="arrow">
                  <img src="../../../assets/images/sort-up.svg" />
                  <img src="../../../assets/images/sort-down.svg" />
                </span>
              </a>-->
                        </div>
                    </div>
                    <div class="col-md-2 large-part">
                        <div class="title-part">
                            <span>Estatus de llamada</span>
                            <!--<a href="">
                <span>Call Status</span>
                <span class="arrow">
                  <img src="../../../assets/images/sort-up.svg" />
                  <img src="../../../assets/images/sort-down.svg" />
                </span>
              </a>-->
                        </div>
                    </div>
                </div>
            </div>
            <div class="backlog-desc-block" *ngFor="let item of claimDetailList; let i= index">
                <div class="row">
                    <div class="col-md-1">
                        <span class="info-desc-part first" title="{{item.odooTicketId}}">{{item.odooTicketId}}</span>
                    </div>
                    <div class="col-md-1">
                        <span class="info-desc-part" title="{{item.odooCustomerId}}">{{item.odooCustomerId}}</span>
                    </div>
                    <!--<div class="col-md-1">
            <span class="info-desc-part" title="{{item.policyNo}}">{{item.policyNo}}</span>
          </div>-->
                    <div class="col-md-1">
                        <span class="info-desc-part" title="{{item.lossType}}">{{item.lossType}}</span>
                    </div>
                    <!--div class="col-md-1">
            <span class="info-desc-part" title="{{item.lossDate}}">{{item.lossDate}}</span>
          </div>-->
                    <div class="col-md-1">
                        <span class="info-desc-part" title="{{item.name}}">{{item.name}}</span>
                    </div>
                    <div class="col-md-1 small-aprt">
                        <span class="info-desc-part" title="{{item.stateCode}}">{{item.stateCode}}</span>
                    </div>
                    <div class="col-md-1">
                        <span class="info-desc-part" title="{{item.zipCode}}">{{item.zipCode}}</span>
                    </div>
                    <div class="col-md-2">
                        <span class="info-desc-part" title="{{item.emailId}}">{{item.emailId}}</span>
                    </div>
                    <div class="col-md-1 mid-part">
                        <span class="info-desc-part" title="{{item.phoneNo}}">{{item.phoneNo}}</span>
                    </div>
                    <div class="col-md-1">
                        <span class="info-desc-part" title="{{item.createdDate | localizedDateTime}}">{{item.createdDate
                            | localizedDateTime}}</span>
                    </div>
                    <div class="col-md-2 large-part">
                        <div class="info-desc-part button-block"
                            *ngIf="item.confDateTime == null || item.confDateTime == undefined">
                            <button class="btn comm-btn" (click)="scheduleCall(item.claimCaseId)">Programar
                                llamada</button>
                        </div>
                        <span title="{{item.confDateTime | localizedDateTime}}" class="info-desc-part"
                            *ngIf="(item.confDateTime != null || item.confDateTime != undefined) && (item.confStatusId == 1)"><img
                                class="n-call" src="../../../assets/images/call-s-no.svg"
                                title="{{item.confDateTime | localizedDateTime}}" />{{item.confDateTime |
                            localizedDateTime}}<img class="call-no"
                                src="../../../assets/images/cl-nt-schdl.svg" /></span>
                        <span title="{{item.confDateTime | localizedDateTime}}" class="info-desc-part"
                            *ngIf="(item.confDateTime != null || item.confDateTime != undefined) && item.confStatusId == 2"><img
                                class="n-call" src="../../../assets/images/call-s-yes.svg"
                                title="{{item.confDateTime | localizedDateTime}}" />{{item.confDateTime |
                            localizedDateTime}}<a class="cl-btn" title="Llamar ahora"
                                (click)="goToVideoCallScreen(item.confId)"><svg xmlns="http://www.w3.org/2000/svg"
                                    width="18" height="18" viewBox="0 0 18 18">
                                    <g id="Group_5229" data-name="Group 5229" transform="translate(-0.423 -0.423)">
                                        <rect id="Rectangle_5309" data-name="Rectangle 5309" width="18" height="18"
                                            transform="translate(0.423 0.423)" fill="none" />
                                        <path id="Path_2" data-name="Path 2"
                                            d="M15.694,2.366C13.364.636,11.583.058,8.09.007V0C4.6.051,2.815.629.486,2.359A1.209,1.209,0,0,0,.012,3.5l.2,1.4a1.043,1.043,0,0,0,1.227.818l2.477-.508c.565-.112.508-.727.522-1.3.011-.469.261-.782,1.044-1.294a5.254,5.254,0,0,1,2.61-.52V2.1a5.254,5.254,0,0,1,2.61.52c.782.512,1.033.825,1.044,1.294.014.575-.043,1.19.522,1.3l2.477.508A1.043,1.043,0,0,0,15.969,4.9l.2-1.4A1.209,1.209,0,0,0,15.694,2.366Z"
                                            transform="translate(13.213 17.31) rotate(-135)" fill="#3ed000" />
                                    </g>
                                </svg></a></span>
                        <div class="details-dots dropdown">
                            <a href="javascript:void(0)" class="dropdown-toggle" data-toggle="dropdown">
                                <img src="../../../assets/images/details-dots.svg" />
                            </a>
                            <ul class="dropdown-menu">
                                <li><a (click)="editClick(item.claimCaseId)"><svg xmlns="http://www.w3.org/2000/svg"
                                            width="14.595" height="14.595" viewBox="0 0 14.595 14.595">
                                            <g id="Group_5784" data-name="Group 5784"
                                                transform="translate(-0.035 -0.035)">
                                                <g id="Group_5776" data-name="Group 5776"
                                                    transform="translate(1.014 2.035)">
                                                    <g id="Group_5782" data-name="Group 5782"
                                                        transform="translate(1.021)">
                                                        <path id="Path_4103" data-name="Path 4103"
                                                            d="M30.869,14.333a1.633,1.633,0,0,0-1.159.48l-.679.679,2.317,2.318.679-.679a1.639,1.639,0,0,0-1.159-2.8Zm-2.707,2.028L21.5,23.025v2.318h2.317l6.663-6.664Z"
                                                            transform="translate(-21.5 -14.333)" fill="#002062" />
                                                    </g>
                                                </g>
                                            </g>
                                        </svg>Editar</a></li>
                                <li *ngIf="item.confStatusId == 1"><a (click)="resendInvite(item.claimCaseId)"><svg
                                            id="Group_5786" data-name="Group 5786" xmlns="http://www.w3.org/2000/svg"
                                            width="14" height="14" viewBox="0 0 14 14">
                                            <g id="Group_5780" data-name="Group 5780"
                                                transform="translate(0.933 0.472)">
                                                <path id="Path_4101" data-name="Path 4101"
                                                    d="M17.533,5.8a1.785,1.785,0,0,0-1.094.373L11.722,9.818l2.545,2V9.528A.933.933,0,0,1,15.2,8.6h4.667a.933.933,0,0,1,.933.933v2.29l2.545-2L18.627,6.173A1.785,1.785,0,0,0,17.533,5.8Zm-.007,3.721a.467.467,0,0,0-.459.473v.933h-.933a.467.467,0,1,0,0,.933h.933V12.8a.467.467,0,1,0,.933,0v-.933h.933a.467.467,0,1,0,0-.933H18V10a.467.467,0,0,0-.474-.473Zm-6.059,1.283v5.53l3.1-3.1Zm12.133,0-3.1,2.434,3.1,3.1Zm-8.3,3.013L11.66,17.462H23.407L19.765,13.82l-1.222.96a1.629,1.629,0,0,1-2.018,0Z"
                                                    transform="translate(-11.467 -5.801)" fill="#002062" />
                                            </g>
                                        </svg>Invitar de nuevo</a></li>
                                <li
                                    *ngIf="item.showReschedule && (item.confDateTime != null || item.confDateTime != undefined)">
                                    <a (click)="openRescheduleModal(item.confId)"><svg
                                            xmlns="http://www.w3.org/2000/svg" width="14.208" height="14.208"
                                            viewBox="0 0 14.208 14.208">
                                            <g id="Group_5959" data-name="Group 5959" transform="translate(0.157)">
                                                <g id="Group_5961" data-name="Group 5961"
                                                    transform="translate(-0.157 0.204)">
                                                    <g id="Group_5960" data-name="Group 5960"
                                                        transform="translate(0.84 0.84)">
                                                        <path id="Path_4163" data-name="Path 4163"
                                                            d="M13.121,10.32a.562.562,0,0,0-.56.56v.56H10.88a.565.565,0,0,0-.56.568V13.4H22.643V12.009a.565.565,0,0,0-.56-.568H20.4v-.56a.562.562,0,0,0-.56-.56h-.56a.562.562,0,0,0-.56.56v.84H14.241v-.84a.562.562,0,0,0-.56-.56Zm0,.56h.56v1.68h-.56V10.88Zm6.162,0h.56v1.68h-.56V10.88ZM10.32,13.961v8.122a.281.281,0,0,0,.28.28H22.363a.281.281,0,0,0,.28-.28V13.961Zm1.68.56h.28v.56h-.56V14.8A.281.281,0,0,1,12,14.521Zm.56,0h.56v.56h-.56Zm.84,0h.56v.56H13.4Zm.84,0h.28a.281.281,0,0,1,.28.28v.28h-.56Zm-2.521.84h.56v.56h-.56Zm2.521,0h.56v.56h-.56Zm1.4.28h4.2a.281.281,0,0,1,.28.28v1.285l.361-.364a.282.282,0,0,1,.4.4l-.84.84a.285.285,0,0,1-.4,0l-.84-.84a.282.282,0,0,1,.4-.4l.361.364V16.2H15.641a.28.28,0,1,1,0-.56Zm-3.921.56h.56v.56h-.56Zm2.521,0h.56v.56h-.56Zm-2.521.84h.56v.56H12a.281.281,0,0,1-.28-.28Zm.84,0h.56v.56h-.56Zm.84,0h.56v.56H13.4Zm.84,0h.56v.28a.281.281,0,0,1-.28.28h-.28Zm4.2,1.68h2.521a.281.281,0,0,1,.28.28v2.521a.281.281,0,0,1-.28.28H18.442a.281.281,0,0,1-.28-.28V19a.281.281,0,0,1,.28-.28Zm.28.56v1.96h1.96v-1.96Z"
                                                            transform="translate(-10.32 -10.32)" fill="#002062" />
                                                    </g>
                                                </g>
                                            </g>
                                        </svg>Reprogramar</a></li>
                                <li class="conv" *ngIf="item.confStatusId == 2 || item.confResStatusId != null"><a
                                        (click)="getToConversationScreen(item.confId)"><svg
                                            xmlns="http://www.w3.org/2000/svg" width="14" height="14"
                                            viewBox="0 0 30 30">
                                            <g id="Group_6509" data-name="Group 6509" transform="translate(-1108 -392)">
                                                <g id="Group_4950" data-name="Group 4950"
                                                    transform="translate(-112 80)">
                                                    <g id="Rectangle_830" data-name="Rectangle 830"
                                                        transform="translate(1220 312)" fill="none" stroke="#002062"
                                                        stroke-width="1">
                                                        <rect width="30" height="30" rx="4" stroke="none" />
                                                        <rect x="0.5" y="0.5" width="29" height="29" rx="3.5"
                                                            fill="none" />
                                                    </g>
                                                </g>
                                                <g id="Group_6511" data-name="Group 6511"
                                                    transform="translate(1114 397)">
                                                    <g id="Group_6510" data-name="Group 6510"
                                                        transform="translate(1.667 2.5)">
                                                        <path id="Path_4866" data-name="Path 4866"
                                                            d="M16,21.5a1.672,1.672,0,0,0-1.667,1.667v10l2.5-2.5h7.5A1.672,1.672,0,0,0,26,29V23.167A1.672,1.672,0,0,0,24.333,21.5Zm11.667,4.167V29a3.337,3.337,0,0,1-3.333,3.333h-5v.833A1.672,1.672,0,0,0,21,34.833h7.5l2.5,2.5v-10a1.672,1.672,0,0,0-1.667-1.667Z"
                                                            transform="translate(-14.333 -21.5)" fill="#002062" />
                                                    </g>
                                                </g>
                                            </g>
                                        </svg>Chat</a></li>
                                <li *ngIf="item.confInvitationCode != null || item.confInvitationCode != undefined">
                                    <a (click)="copyMessage(getValue(item.confInvitationCode))"><svg id="PIN" xmlns="http://www.w3.org/2000/svg" width="14" height="14"
                                            viewBox="0 0 80 80">
                                            <path id="Path_4994" data-name="Path 4994"
                                                d="M30.206,22.933a7.273,7.273,0,1,0,7.273,7.273A7.273,7.273,0,0,0,30.206,22.933Zm21.818,0A7.273,7.273,0,1,0,59.3,30.206,7.273,7.273,0,0,0,52.024,22.933Zm21.818,0a7.273,7.273,0,1,0,7.273,7.273A7.273,7.273,0,0,0,73.842,22.933ZM30.206,44.752a7.273,7.273,0,1,0,7.273,7.273,7.273,7.273,0,0,0-7.273-7.273Zm43.636,0a7.27,7.27,0,0,0-6.712,4.474l2.543,5.895a13.7,13.7,0,0,1,4.389-.739,12.746,12.746,0,0,1,4.07.668A14.253,14.253,0,0,1,80.746,53.6c.069-.029.144-.042.213-.071a7.578,7.578,0,0,0,.156-1.506,7.271,7.271,0,0,0-7.273-7.273Zm-20.483.81a5.5,5.5,0,0,0-1.087.156,6.013,6.013,0,0,0-4.119,8.21L64.261,91.435c-8.585-.571-12.3-1.275-18.366,1.321-2.764,1.182-5.568,3.837-4.233,6.953l.5,1.179,14.872,2.045h45.895V67.273l-2.564-5.987A4.533,4.533,0,0,0,94.5,58.9a5.469,5.469,0,0,0-2.216,1.57A2.358,2.358,0,0,1,89.1,60.8a5.4,5.4,0,0,0-5.5-.518,6.574,6.574,0,0,0-2.862,2.28,2.181,2.181,0,0,1-3.153.341,5.832,5.832,0,0,0-6.214-.653,6.488,6.488,0,0,0-3.224,2.791A1.19,1.19,0,0,1,66,64.972L59.325,49.5a6.361,6.361,0,0,0-5.966-3.935ZM30.206,66.57a7.273,7.273,0,1,0,7.273,7.273A7.273,7.273,0,0,0,30.206,66.57Zm16.477,2.372A7.237,7.237,0,0,0,51.9,81.1Z"
                                                transform="translate(-22.933 -22.933)" fill="#002062" />
                                        </svg>PIN: 
                                        {{item.confInvitationCode | slice: 0:3}} {{item.confInvitationCode | slice: 3:6}}</a></li>
                            </ul>
                        </div>
                        <div class="clear"></div>
                        <!--<a class="complete-arw"><img src="../../../assets/images/completed-calls-arrow.svg" /></a>
            <div class="clear"></div>-->
                    </div>
                </div>
            </div>

            <!--<div class="more-block">
          <a href="">See More<i class="fa fa-angle-down" aria-hidden="true"></i></a>
      </div>-->
        </div>
    </div>
</div>

<!--<div class="modal fade no-pop" id="puchase-data" data-backdrop="static" aria-hidden="true" role="dialog">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header no-title">
        <button type="button" class="close" data-dismiss="modal">
          <img src="../../../../assets/images/modal-close-img.svg" />
        </button>
        <h4 class="modal-title">
          <span>Le gusta nuestros servicios?</span>
        </h4>
      </div>
      <div class="modal-body">
        <div class="pop-info-section">
          <div class="img-part">
            <img src="../../../assets/images/purchase-img.svg" />
          </div>
          <div class="desc-part">
            <span>{{freeTrialEndMessage}}</span>
          </div>
          <div class="button-block">
            <button class="btn comm-btn" type="button" data-dismiss="modal" (click)="redirect()">Let's go</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>-->
<div class="modal fade no-pop" id="puchase-data" data-backdrop="static" aria-hidden="true" role="dialog">
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-header no-title">
                <button type="button" class="close" data-dismiss="modal">
                    <img src="../../../../assets/images/modal-close-img.svg" />
                </button>
            </div>
            <div class="modal-body">
                <div class="pop-info-section">
                    <div class="row">
                        <div class="col-md-5">
                            <div class="img-part">
                                <img src="../../../assets/images/purchase-img.svg" />
                                <div class="price">
                                    <span class="price-top">$</span>
                                    <span class="price-mdl"> 12</span>
                                    <span class="price-btm">por Caso</span>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-7">
                            <div class="desc-part">
                                <span class="desc-top-part">Ha alcanzado su limite de casos gratis.</span>
                                <span class="desc-btm-part">{{freeTrialEndMessage}}</span>
                            </div>
                            <div class="button-block">
                                <button class="btn comm-btn" type="button" data-dismiss="modal"
                                    (click)="redirect()">Contactar Asesor</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="mobile-warning">
    <span><img src="../../../assets/images/error-mobile.svg" /> El portal de usuarios de CASCO Safety no es compatible
        con este dispositivo. Por favor, acceda a CASCO Safety en pantalla completa desde su escritorio. </span>
</div>

<div class="modal fade reshedule-pop" id="backlogResheduler" data-backdrop="static" aria-hidden="true" role="dialog">
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-header pop-title">
                <button type="button" class="close" data-dismiss="modal">
                    <img src="../../../assets/images/close-pop.svg" />
                </button>
                <h4 class="modal-title">
                    <span class="only-time">Escoger Fecha y Horario</span>
                </h4>
            </div>
            <div class="modal-body">
                <div class="approve-part">
                    <span class="name-part">{{rescheduleModalData.initials}}</span>
                    <div class="only-approve">
                        <span class="approve-name">{{rescheduleModalData.fullName}}</span>
                        <span class="partner-text">{{rescheduleModalData.emailId}} </span>
                        <span class="partner-text">ha sugerido <span class="bold-block">at
                                {{rescheduleModalData.dateNewScheduleDate | localizedDateTime}}</span></span>
                    </div>
                    <div class="clear"></div>
                </div>
                <div class="schedule-opt">
                    <div class="row">
                        <div class="col-md-7">
                            <div class="row">
                                <div class="col-md-6">
                                    <div class="input-block">
                                        <!--<input type="text" class="form-control" />-->
                                        <input name="dtRescheduleDate" id="dtRescheduleDate" placeholder="mm/dd/yy"
                                            [min]="minDate" [owlDateTimeTrigger]="dtRescheduleDate"
                                            [owlDateTime]="dtRescheduleDate" [owlDateTimeFilter]="myFilter"
                                            class="form-control date-control" [(ngModel)]="rescheduleDate" required>
                                        <owl-date-time [pickerType]="'calendar'" #dtRescheduleDate></owl-date-time>
                                        <div [owlDateTimeTrigger]="dtRescheduleDate"></div>
                                        <div class="arrow"></div>
                                    </div>
                                </div>
                                <div class="col-md-6 pad-only">
                                    <div class="input-block">
                                        <select class="form-control" [(value)]="selected" [(ngModel)]="selected"
                                            name="scheduleTime">
                                            <option value="None">Ninguno</option>
                                            <option value="08:00">08:00 AM</option>
                                            <option value="08:30">08:30 AM</option>
                                            <option value="09:00">09:00 AM</option>
                                            <option value="09:30">09:30 AM</option>
                                            <option value="10:00">10:00 AM</option>
                                            <option value="10:30">10:30 AM</option>
                                            <option value="11:00">11:00 AM</option>
                                            <option value="11:30">11:30 AM</option>
                                            <option value="12:00">12:00 PM</option>
                                            <option value="12:30">12:30 PM</option>
                                            <option value="13:00">01:00 PM</option>
                                            <option value="13:30">01:30 PM</option>
                                            <option value="14:00">02:00 PM</option>
                                            <option value="14:30">02:30 PM</option>
                                            <option value="15:00">03:00 PM</option>
                                            <option value="15:30">03:30 PM</option>
                                            <option value="16:00">04:00 PM</option>
                                            <option value="16:30">04:30 PM</option>
                                            <option value="17:00">05:00 PM</option>
                                            <option value="17:30">05:30 PM</option>
                                        </select>
                                        <div class="arrow"></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-5">
                            <div class="button-block">
                                <button class="btn comm-btn" (click)="rescheduleConf()">Reprogramar</button>
                            </div>
                        </div>
                    </div>
                    <div class="error-only-msg" *ngFor="let error of validationMessages">
                        <span>{{ error }} </span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="modal fade ex-pop" id="puchase-data-c" data-backdrop="static" aria-hidden="true" role="dialog">
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-header no-title">
                <button type="button" class="close" data-dismiss="modal">
                    <img src="../../../../assets/images/modal-close-img.svg" />
                </button>
                <h4 class="modal-title">
                    <span class="f-top">Ha alcanzado su limite de casos gratis.</span>
                    <span class="f-btm">Please Contactar Administrator</span>
                </h4>
            </div>
        </div>
    </div>
</div>