import { Component, Inject, LOCALE_ID, OnInit } from '@angular/core';
import { DashboardService } from './../../api-services/dashboard.service';
import { Router } from '@angular/router';
import { SharedDataService } from '../../services/sharedData.service';
import { iDropDown } from '../../models/setting.model';
import { iEventsVM, iEventsDetailsVM } from '../../models/dashboard.model';

@Component({
  selector: 'app-full-calender-view',
  templateUrl: './full-calender-view.component.html',
  styleUrls: ['./full-calender-view.component.css']
})
export class FullCalenderViewComponent implements OnInit {
  dates:Date = new Date(2);
  //events: any = [];
  weekday = [];
  todayValue = "(" + new Date().getDate() + " " + new Date().toLocaleString(this.locale, { month: 'short' }) + ")";
  weekFromDate: string;
  currentWeekDate = new Date();
  days: number = 0;
  teammateList?: iDropDown[] = [];
  tenantId?: number;
  adjusterId?: number;
  loggedInUser?: number;
  isSuperAdmin: boolean = false;
  userRole?: string;
  selectedTeamMate?: iDropDown = {};
  validationMessages: any;
  events: iEventsVM[] = [];
 
  constructor(private router: Router, private service: DashboardService, private sharedService: SharedDataService,
    @Inject(LOCALE_ID) public locale: string
    ) { this.adjusterId = this.loggedInUser = this.sharedService.getValue("accountId"); }

  ngOnInit(): void {

    if (this.sharedService.getValue("userRole") == 'Super Admin') {
      this.isSuperAdmin = true;
      this.getTeamMemberListForSuperUser();
    }

    this.populateWeekDays(this.currentWeekDate);
    this.loadCalendarForMe();

    const view = document.getElementById('box');
    view.scrollIntoView();
  }

  generateCalendarWeekView(adjusterId) {
    this.validationMessages = [];
    this.service.getCalendarView(adjusterId, this.sharedService.getDate(this.dates[0]), this.sharedService.getDate(this.dates[1]), this.sharedService.getTimezonOffset(), this.sharedService.getTodayUTCDateAsString()).subscribe(result => {
      if (result != null) {
        this.events = <iEventsVM[]>result;
      }
     }, error => {
        this.populateApiErrorMessages(error.message);
    });
  }

  getTeamMemberListForSuperUser() {
    this.validationMessages = [];
    this.tenantId = this.sharedService.getValue("tenantId");
    this.service.getTeamMateList(this.tenantId).subscribe(result => {
      if (result != null) {
        this.teammateList = <iDropDown[]>result;
      }
    }, error => {
        this.populateApiErrorMessages(error.message);
    });
  }

  populateWeekDays(dateToPopulateWeek) {

    var fromAndToDates = getWeekDays(dateToPopulateWeek);
    this.dates[0] = fromAndToDates.from;
    this.dates[1] = fromAndToDates.to;

    this.weekFromDate = fromAndToDates.from.getDate() + " " + fromAndToDates.from.toLocaleString(this.locale, { month: 'short' }) + "-" + fromAndToDates.to.getDate() + " " + fromAndToDates.to.toLocaleString(this.locale, { month: 'short' }) + ", " + fromAndToDates.from.getUTCFullYear()

    var fromDate: Date = new Date(this.dates[0]);
    var toDate: Date = new Date(this.dates[1]);
    this.weekday[0] = (fromDate.getDate() + " domingo");
    this.weekday[1] = (new Date(fromDate.setDate(fromDate.getDate() - fromDate.getDay() + 1)).getDate() + " lunes");
    this.weekday[2] = (new Date(fromDate.setDate(fromDate.getDate() - fromDate.getDay() + 2)).getDate() + " martes");
    this.weekday[3] = (new Date(fromDate.setDate(fromDate.getDate() - fromDate.getDay() + 3)).getDate() + " miércoles");
    this.weekday[4] = (new Date(fromDate.setDate(fromDate.getDate() - fromDate.getDay() + 4)).getDate() + " jueves");
    this.weekday[5] = (new Date(fromDate.setDate(fromDate.getDate() - fromDate.getDay() + 5)).getDate() + " viernes");
    this.weekday[6] = (toDate.getDate() + " sábado");
  }

  moveNextORPreviousWeek(value) {
    this.currentWeekDate = this.addORSubstractDays(this.currentWeekDate, value);
    this.populateWeekDays(this.currentWeekDate);
    this.generateCalendarWeekView(this.adjusterId);
  }

  loadCalendarForMe() {
    this.selectedTeamMate = {};
    this.selectedTeamMate.id = -1;
    this.selectedTeamMate.name = 'Asignarme';
    this.adjusterId = this.loggedInUser;
    this.generateCalendarWeekView(this.loggedInUser);
  }

  loadCalendarForTeamMember(data) {
    this.selectedTeamMate = {};
    this.selectedTeamMate = data;
    this.adjusterId = data.id;
    this.generateCalendarWeekView(this.adjusterId);
  }

  addORSubstractDays(date: Date, numberofDays: number): Date {
    let d = new Date(date);
    d.setTime(d.getTime() - numberofDays * 24 * 60 * 60 * 1000);
    return new Date((d.getMonth() + 1) + "/" + d.getDate() + "/" + d.getFullYear()); }

  callNow(confId: number) {
    this.sharedService.setValue('conferenceId', confId);
    this.router.navigateByUrl('video-call');
  }

  isCurrentDay(day: string) {
    var today = new Date().getDate();
    if (day.split(' ')[0].trim() == today.toString()) {
      return true;
    }
    return false;
  }

  populateApiErrorMessages(message: string) {
    this.validationMessages.push(message);
  }

  redirectToPage(originalEvent: iEventsVM, event: iEventsDetailsVM) {

    if (this.loggedInUser == this.adjusterId) {
      if (event.confStatusId == 2) {
        this.sharedService.setValue('conferenceId', event.conferenceId);
        this.router.navigateByUrl('video-call');
      }
      else if (event.confStatusId == 4) {
        this.sharedService.setValue('conferenceId', event.conferenceId);
        this.router.navigateByUrl('review');
      }
    }
  }

  getTitle(event: iEventsDetailsVM) {
      if (event.confStatusId == 2) {
        if (event.rescheduledConferenceId != 1 && event.rescheduledConferenceId != 2) {
          return "Al hacer clic aquí, accederá a la pantalla de la videollamada.";
        }
      }
      else if (event.confStatusId == 4) {
        return "Al hacer clic aquí, accederá a la pantalla de la videollamada.";
      }
  }


}

export enum WEEK_DAYS {
  Sunday = 0,
  Monday = 1,
  Tuesday = 2,
  Wednesday = 3,
  Thursday = 4,
  Friday = 5,
  Saturday = 6,
}

export const getWeekDays = (
  curr: Date,
  firstDay: WEEK_DAYS = WEEK_DAYS.Sunday
): { from: Date; to: Date  } => {
  const cd = curr.getDate() - curr.getDay();
  const from = new Date(curr.setDate(curr.getDate() - curr.getDay()));
  const to = new Date(curr.setDate(curr.getDate() - curr.getDay() + 6));

  return {
    from,
    to
  };
};
