<div class="container-area">
    <div class="extra-one">
        <div class="content-area bg-content">
            <div class="main-title">
                <span>Resumen & Notas</span>
            </div>
            <div class="review-main-container">
                <div class="row">
                    <div class="col-md-3">
                        <div class="customer-block">
                            <div class="cust-video-block">
                                <div class="img-right" *ngIf="selectedItem">
                                    <img src="{{selectedItem?.data}}"
                                        *ngIf="selectedItem?.isImage && !selectedItem?.isDoc" />
                                    <video id="received_video_main" controls="controls"
                                        *ngIf="!selectedItem?.isImage && !selectedItem?.isDoc">
                                        <source src="{{selectedItem?.data}}" type="{{videoType}}">
                                    </video>
                                    <div class="ex-part" *ngIf="!selectedItem?.isImage && selectedItem?.isDoc">
                                        <span class="ex-long-doc"></span>
                                        <img class="docx-long" src="{{selectedItem?.data}}" />
                                    </div>
                                </div>
                            </div>
                            <div class="option-block">
                                <div class="button-block">
                                    <div class="btm-part">
                                        <button class="btn" disabled></button>
                                        <button class="btn" disabled></button>
                                        <button class="btn" disabled></button>
                                        <button class="btn" disabled></button>
                                    </div>
                                </div>
                                <input *ngIf="selectedItem" class="form-control" type="text"
                                    placeholder="Ingresar nota para imagen mostrado." [(ngModel)]="artifactNotes.notes"
                                    (change)="addArtifactNotes(selectedItem.id)" />
                            </div>
                        </div>
                    </div>
                    <div class="col-md-9">
                        <div class="adjuster-block">
                            <div class="adjuster-top">
                                <div class="row">
                                    <div class="col-md-9">
                                        <div class="right-block">
                                            <div class="row">
                                                <div class="col-md-4 right-border">
                                                    <div class="name-block">
                                                        <span class="name-info">{{setInitials()}}</span>
                                                        <span class="name-part">
                                                            {{reviewData.firstName}} {{reviewData.lastName}}
                                                            <span class="email-part">
                                                                <span class="mail-only">{{reviewData.emailId}}</span>
                                                                <a class="copy-text"
                                                                    [cdkCopyToClipboard]="reviewData.emailId"
                                                                    title="copiar al portapapeles"></a>
                                                            </span>
                                                        </span>
                                                        <div class="clear"></div>
                                                    </div>
                                                </div>
                                                <div class="col-md-4 right-border">
                                                    <div class="name-block">
                                                        <span class="email-part">
                                                            Número de Ticket
                                                            <span class="name-part">
                                                                <span
                                                                    class="mail-only max-only">{{reviewData.ticketId}}</span>
                                                                <a class="copy-text bottom"
                                                                    [cdkCopyToClipboard]="reviewData.ticketId"
                                                                    title="copiar al portapapeles"></a>
                                                            </span>
                                                        </span>
                                                        <div class="clear"></div>
                                                    </div>
                                                </div>
                                                <div class="col-md-4">
                                                    <div class="name-block">
                                                        <span class="email-part">
                                                            N&#250;mero de Cliente
                                                            <span class="name-part">
                                                                <span
                                                                    class="mail-only max-only">{{reviewData.customerId}}</span>
                                                                <a class="copy-text bottom"
                                                                    [cdkCopyToClipboard]="reviewData.customerId"
                                                                    title="copiar al portapapeles"></a>
                                                            </span>
                                                        </span>
                                                        <div class="clear"></div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-md-3">
                                        <div class="more-part">
                                            <a (click)="cancelReview()"><img
                                                    src="../../../assets/images/less-img.svg" />Atr&#225;s</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="adjuster-middle">
                                <div class="title-up">
                                    <div class="row">
                                        <div class="col-md-6">
                                            <div class="snap-title">
                                                <img src="../../../assets/images/cam-top.svg" />Todas Fotos
                                                ({{imageList?.length}})
                                            </div>
                                        </div>
                                        <div class="col-md-6">
                                            <div class="option-section">
                                                <span *ngIf="showDeleteIcon" (click)="deleteArtifact()">
                                                    <img class="dlt" src="../../../assets/images/delete-opt.svg" />
                                                    Borrar Seleccionado
                                                </span>
                                                <a *ngIf="!showDeleteIcon" (click)="selectClick()"
                                                    class="select-dlt"><img
                                                        src="../../../assets/images/slcted-imgs.svg" /> Seleccionar
                                                    im&#225;genes</a>
                                                <a *ngIf="showDeleteIcon" (click)="cancelClick()"
                                                    class="select-dlt">Cancelar</a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-md-12">
                                        <div class="thumb-section">
                                            <div class="img-section">
                                                <ul>
                                                    <li *ngFor="let item of imageList; let i = index">
                                                        <a [ngClass]="{'border': assignClass && itemId == item.artifactId}"
                                                            (click)="getSelectedItem(item)">
                                                            <img src="{{item.data}}"
                                                                *ngIf="item.isImage && !item.isDoc" />
                                                            <video id="received_video" controls="controls"
                                                                *ngIf="!item.isImage && !item.isDoc">
                                                                <source src="{{item.data}}" type="{{item.videoType}}">
                                                            </video>
                                                        </a>
                                                        <a [ngClass]="{'border': assignClass && itemId == item.artifactId}"
                                                            *ngIf="item.isDoc && !item.isImage"
                                                            (click)="getSelectedItem(item)">
                                                            <span class="long-doc"></span>
                                                        </a>
                                                        <img *ngIf="item.isDoc && !item.isImage" class="docx"
                                                            src="{{item.data}}" />

                                                        <span class="number">{{item.artifactNumber}}</span>
                                                        <div [ngClass]="{'overlay': item.assignClass == false}"
                                                            (click)="getSelectedItem(item)"
                                                            *ngIf="item.assignClass == false">
                                                            <span (click)="getSelectedItem(item)"><img
                                                                    src="../../../assets/images/tick-mark-new.svg" /></span>
                                                        </div>
                                                    </li>
                                                </ul>
                                                <div class="clear"></div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="adjuster-bottom">
                                <div class="row">
                                    <div class="col-md-8">
                                        <div class="notes-down">
                                            <div class="notes-top-title">
                                                <img src="../../../assets/images/notes-rv.svg" /> Notas de Llamada
                                            </div>
                                            <input type="text" class="form-control"
                                                placeholder="Poner sus comentarios aqui."
                                                [(ngModel)]="confNotes.notes" />
                                        </div>
                                    </div>
                                    <div class="col-md-2">
                                        <div class="check-part">
                                            <label class="container-dot">
                                                Generar Reporte PDF
                                                <input type="checkbox" checked="checked" [(ngModel)]="isSavePDF">
                                                <span class="checkmark"></span>
                                            </label>
                                        </div>
                                    </div>
                                    <div class="col-md-2">
                                        <div class="button-block">
                                            <button class="btn cancel" (click)="cancelReview()"
                                                routerLinkActive="active">Cancelar</button>
                                            <button class="btn save" (click)="addConfNotes()"><img
                                                    src="../../../assets/images/save-rv.svg" />Guardar</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div>
                <validation-summary [validationErrors]="validationMessages"></validation-summary>
            </div>
        </div>
    </div>
    <img id='imgToExport' src="../../../assets/images/header-logo.svg" style='display:none' />
</div>