import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { SharedDataService } from '../../../services/sharedData.service';
import { DashboardService } from '.././../../api-services/dashboard.service';
import { iWebRescheduleRequest, iWebRescheduleRequestList, iRescheduleAcceptRequestWeb } from '../../../models/dashboard.model';
import { VideoCallService } from './../../../api-services/video-call.service';
import { Conference, ConferenceHistoryResponse } from './../../../models/conference.model';
import { PushAndroidPushtokenService } from '../../../api-services/push-android-pushtoken.service';
import { PushNotificationRequest, NotificationData } from '../../../models/push-android-pushtoken-request.model';
import { Constants } from "../../../shared/constant";
import { DatePipe } from '@angular/common';
import { ToastrService } from 'ngx-toastr';
declare var $: any;

@Component({
  selector: 'app-reschedule',
  templateUrl: './reschedule.component.html',
  styleUrls: ['./reschedule.component.css']
})
export class RescheduleComponent implements OnInit {

  constructor(private router: Router,
    private service: DashboardService,
    private sharedService: SharedDataService,
    private datepipe: DatePipe,
    private toastr: ToastrService,
    private videoCallService: VideoCallService,
    private pushAndroidService: PushAndroidPushtokenService) { }

  confRescheduleList?: iWebRescheduleRequest = {};
  rescheduleModalData?: iWebRescheduleRequestList = {};
  adjusterId?: number;
  showData?: boolean = true;
  public maxDate = new Date();
  public minDate = new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate());
  rescheduleDate = new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate());
  selected = 'None';
  rescheduleData?: iRescheduleAcceptRequestWeb = {};
  validationMessages: any;
  public conference: Conference = {} as Conference;
  public conferenceHistoryResponse: ConferenceHistoryResponse = {} as ConferenceHistoryResponse;

  public myFilter = (d: Date): boolean => {
    const day = d.getDay();
    // Prevent Saturday and Sunday from being selected.
    return day !== 0 && day !== 6;
  }

  ngOnInit(): void {
    this.validationMessages = [];
    this.minDate = new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate());
    this.rescheduleDate = new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate());
    this.adjusterId = this.sharedService.getValue("accountId");
    this.selected = 'None';

    $('#myCarousel').carousel({
      interval: false,
    });
    this.getRescheduleListWeb();
  }

  //Method to get all conference reschedule request from mobile user
  getRescheduleListWeb() {
    this.service.getRescheduleListWeb(this.adjusterId).subscribe(result => {
      if (result != null) {
        this.confRescheduleList = <iWebRescheduleRequest>result;

        if (this.confRescheduleList.totalCount == 0)
          this.showData = false;
        else
          this.showData = true;

        this.confRescheduleList.rescheduleList.forEach(obj => {
          obj.oldScheduleDate = this.datepipe.transform(this.sharedService.ConvertUTCTimeToLocalTime(obj.dateOldScheduleDate), 'dd-MMM-yyyy');
          obj.oldScheduleTime = this.datepipe.transform(this.sharedService.ConvertUTCTimeToLocalTime(obj.dateOldScheduleDate), 'h:mm a');
          obj.newScheduleDate = this.datepipe.transform(this.sharedService.ConvertUTCTimeToLocalTime(obj.dateNewScheduleDate), 'dd-MMM-yyyy');
          obj.newScheduleTime = this.datepipe.transform(this.sharedService.ConvertUTCTimeToLocalTime(obj.dateNewScheduleDate), 'h:mm a');
          obj.newScheduleEndTime = this.datepipe.transform(this.sharedService.ConvertUTCTimeToLocalTime(obj.dateNewScheduleEndTime), 'h:mm a');
        });
      }
    }, error => {
      this.populateApiErrorMessages(error.message);
    });
  }

  //Method to open reschedule modal pop-up
  openRescheduleModal(data?: any) {
    this.validationMessages = [];
    $("#resheduler").modal("show");
    this.rescheduleDate = new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate());
    this.selected = 'None';
    this.rescheduleModalData = data;
  }

  //Method to reschedule conference
  rescheduleConf() {

    this.validationMessages = [];

    this.populateValidateMessages();

    if (this.validationMessages.length === 0) {

      this.rescheduleData.confId = this.rescheduleModalData.confId;
      this.rescheduleData.adjusterId = this.adjusterId;
      this.rescheduleData.updateDate = new Date();

      var hours = Number(this.selected.split(":")[0]);
      var minutes = Number(this.selected.split(":")[1]);
      this.rescheduleDate.setHours(hours);
      this.rescheduleDate.setMinutes(minutes);
      this.rescheduleDate.setSeconds(0);
      this.rescheduleDate = new Date(this.rescheduleDate.toUTCString());

      this.rescheduleData.proposedDate = this.rescheduleDate;

      this.service.rescheduleConferenceWeb(this.rescheduleData).subscribe((data: any) => {
        if (data !== null) {
          if (data != "success")
            this.validationMessages.push('El intervalo de tiempo seleccionado no está disponible, seleccione otro.');
          else {
            this.sharedService.rescheduleFlag.next(true);
            $("#resheduler").modal("hide");
            this.rescheduleDate = new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate());
            this.selected = 'None';
            this.getRescheduleListWeb();
            this.successMsg("Conferencia reprogramada correctamente.");
            this.sendPushNotificationForReschedule();
          }
        }
      }, error => {
        this.populateApiErrorMessages(error.message);
      });
    }
  }
  sendPushNotificationForReschedule() {
    this.videoCallService.getConferenceInfoById(this.rescheduleModalData.confId).subscribe(
      response => {
        //If Invitation is not accepted then response will be null
        if (response != null) {
          this.conference = response;
          if (this.conference.pushToken != null) {
            this.getConferenceHistory();
          }
        }
      },
      error => {
      });
  }
  getConferenceHistory() {
    this.service.getConferenceHistoryForWeb(this.rescheduleModalData.confId, this.conference.voipToken).subscribe(
      response => {
        if (response != null) {
          this.conferenceHistoryResponse = response;
          this.conferenceHistoryResponse.callList[0].notificationType = "reschedule";
          this.conferenceHistoryResponse.callList[0].title = this.conferenceHistoryResponse.callList[0].companyName;
          this.conferenceHistoryResponse.callList[0].body = Constants.pushNotificationRescheduleBody;
          this.conferenceHistoryResponse.callList[0].successCode = this.conferenceHistoryResponse.successCode;
          this.conferenceHistoryResponse.callList[0].message = this.conferenceHistoryResponse.message;
          if (this.conference.deviceType.toLowerCase() == "ios") {
            let notification: NotificationData = {
              title: this.conferenceHistoryResponse.callList[0].companyName,
              body: Constants.pushNotificationRescheduleBody
            } as NotificationData;
            let request: PushNotificationRequest = {
              to: this.conference.pushToken,
              data: this.conferenceHistoryResponse.callList[0],
              notification: notification
            } as PushNotificationRequest
            this.pushAndroidService.sendPushNotificaton(request).subscribe(
              response => {
              }
            );
          }
          else {
            let request: PushNotificationRequest = {
              to: this.conference.pushToken,
              data: this.conferenceHistoryResponse.callList[0]
            } as PushNotificationRequest
            this.pushAndroidService.sendPushNotificaton(request).subscribe(
              response => {
              }
            );
          }
        }
      },
      error => {
      });
  }
  //Method to accept reschedule conference request
  acceptRescheduleConf(confId?: any) {

    this.rescheduleData.confId = confId;
    this.rescheduleData.adjusterId = this.adjusterId;
    this.rescheduleData.updateDate = new Date();

    this.service.acceptConferenceWeb(this.rescheduleData).subscribe((data: any) => {
      if (data !== null) {
        if (data != "success")
          this.warningMsg(data);
        else {          
          this.sharedService.rescheduleFlag.next(true);
          this.getRescheduleListWeb();
          this.successMsg("Conferencia aceptada con éxito.");
        }
      }
    }, error => {
      this.populateApiErrorMessages(error.message);
    });

  }

  warningMsg(msg?: any) {
      this.toastr.warning(msg, 'Aviso')
  }

  successMsg(msg?: any) {
      this.toastr.success(msg, 'Exito')
  }

  populateValidateMessages() {

    if (this.selected == "None") {
      this.validationMessages.push('Por favor seleccione una hora válida.');
      return;
    }

    if (this.rescheduleDate == null) {
      this.validationMessages.push("Seleccione la fecha de reprogramación.");
      return;
    }
    else if (this.rescheduleDate < new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate())) {
      this.validationMessages.push("La fecha de reprogramación no puede ser en el pasado.");
      return;
    }
    else if (this.rescheduleDate.getDay() == 0 || this.rescheduleDate.getDay() == 6) {
      this.validationMessages.push('La fecha reprogramada no puede ser durante el fin de semana.');
      return;
    }

    var dateToCheck = new Date();
    var rescheduleDateToCheck = this.rescheduleDate;
    var hours = Number(this.selected.split(":")[0]);
    var minutes = Number(this.selected.split(":")[1]);
    rescheduleDateToCheck.setHours(hours);
    rescheduleDateToCheck.setMinutes(minutes)

    if (rescheduleDateToCheck <= dateToCheck) {
      this.validationMessages.push("Lo siento, no puede seleccionar este horario. Por favor seleccionar un horario futuro.");
    }
  }

  populateApiErrorMessages(message: string) {
    this.validationMessages.push(message);
  }

}
