import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { SharedDataService } from '../../../services/sharedData.service';
import { SettingService } from '.././../../api-services/setting.service';
import { iGetUser, iAddUser } from '../../../models/setting.model';
import { ToastrService } from 'ngx-toastr';
declare var $: any;

@Component({
  selector: 'app-manage-user',
  templateUrl: './manage-user.component.html',
  styleUrls: ['./manage-user.component.css']
})
export class ManageUserComponent implements OnInit {

  constructor(private router: Router,
    private service: SettingService,
    private sharedService: SharedDataService,
    private toastr: ToastrService) { }

  validationMessages: any;
  showInvite?: boolean = false;
  addUser?: iAddUser = {};
  userList?: iGetUser[] = [];
  userData?: iGetUser = {};
  count?: number = 0;
  deleteId?: number;
  disbaleInvite: boolean = true;
  resendEmail: boolean = false;

  ngOnInit(): void {
    this.addUser.tenantId = this.sharedService.getValue("tenantId");
    this.getUserList();
  }

  refreshMethod() {
    this.validationMessages = [];
    this.addUser.tenantId = this.sharedService.getValue("tenantId");
    this.getUserList();
    this.showInvite = false;
    this.addUser.emailId = "";
  }

  addMember() {
    this.showInvite = true;
  }

  sentInvitation() {

    this.resendEmail = false;

    this.validationMessages = [];

    this.populateValidateMessages();

    if (this.validationMessages.length === 0) {

      this.service.addUser(this.addUser).subscribe(result => {
        if (result != null) {
          if (result == "inviteAgain") { this.resendEmail = true; }
          else if (result == "activated") { this.successmsg("Usuario activado con éxito."); this.addUser.emailId = ""; this.getUserList(); }
          else if (result == "emailSent") { this.successmsg("Correo electrónico enviado con éxito."); this.addUser.emailId = ""; this.getUserList(); }
          else { this.populateApiErrorMessages(result); }
        }
      }, error => {
        this.populateApiErrorMessages(error.message);
      });
    }
  }

  resendInvitation() {

    this.resendEmail = false;

    this.populateValidateMessages();

    if (this.validationMessages.length === 0) {

      this.service.resendEmail(this.addUser.emailId.toString()).subscribe(result => {
        if (result != null) {
          if (result == "emailSent") { this.successmsg("Correo electrónico enviado con éxito."); this.addUser.emailId = ""; } else {
            this.populateApiErrorMessages(result);
          }
        }
      }, error => {
        this.populateApiErrorMessages(error.message);
      });
    }
  }

  getUserList() {
    this.service.getUserList(this.addUser.tenantId).subscribe(result => {
      if (result != null) {
        this.userList = <iGetUser[]>result;

        this.userList.forEach((d) => {
          this.count += 1;

        });
      }
    }, error => {
      this.populateApiErrorMessages(error.message);
    });
  }

  openDeleteModal(data?: any) {
    this.userData = data;
    $("#dlt-data").modal("show");
  }

  deleteUser() {

    this.service.deleteUser(this.userData.id).subscribe(result => {
      if (result != null) {
        if (result) {
          $("#dlt-data").modal("hide");
          this.getUserList();
        }

      }
    }, error => {
      this.populateApiErrorMessages(error.message);
    });
  }

  populateValidateMessages() {

    if (this.addUser.emailId === undefined || this.addUser.emailId === null || this.addUser.emailId.length === 0) {
      this.validationMessages.push('Correo electrónico requerido.');
    }
    else if (!this.isValidEmail(this.addUser.emailId)) {
      this.validationMessages.push('Please enter proper email address.');
    }
  }

  isValidEmail(email) {
    var re = /[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,3}$/;
    return re.test(email);
  }

  populateApiErrorMessages(message: string) {
    this.validationMessages.push(message);
  }

  focusFunction() {
    this.disbaleInvite = false;
  }

  successmsg(message) {
      this.toastr.success(message, 'Exito')
  }
  //errorsmsg() {
  //    this.toastr.error("Toastr Error Notification", 'Error')

  //}
  //infotoastr() {
  //    this.toastr.info("Important News", 'Information');
  //}
  //toastrwaring() {
  //    this.toastr.warning("Battery about to Die", 'Warning');
  //}  

}
