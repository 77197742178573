<div class="privacy-container">
    <div class="container">
        <div class="block-title">
            <span>Términos y Condiciones</span>
        </div>
        <div class="privacy-content">
            <div class="details-block">
                <p>
                    Estas condiciones de uso ("Condiciones") rigen el uso que usted hace de CASCO Safety ("CASCO") y todos sus productos y servicios (a travez de página web, tienda e-commerce, y aplicaciones móviles, entre otros) denominados de ahora en adelante "Servicios", excepto en los casos en que indiquemos expresamente que se aplican otras condiciones (y no estas). 
                    <br /><br />
                    Cuando usted crea una cuenta de CASCO, inicia sesión, o usa uno de los Servicios, acepta estas condiciones.
                </p>
            </div>
            <br />
            <div class="deatils-part">
                <div class="title-top">
                    <span>1. Objeto</span>
                </div>
                <p>
                    Estos servicios, están destinados a brindarle a sus usuarios la oportunidad de comunicarse con todas las soluciones y opciones de asistencia y asesoria que CASCO ofrece a usted, así como de realizar funciones con acciones directas y específicas de su interes, incluidas, compras en línea, videollamadas, llamadas de voz, chat, acceso de meta-datos y multimedia, tales como fotos, audios, videos, ubicación física actual, dimensiones físicas de sus instalaciones, entre otras, y la creación de contenido con respecto a esos servicios. 
                    <br /><br />
                    Para los fines de este Acuerdo, "Contenido" significa cualquier archivo, documento, grabación, registro de chat, transcripciones y datos similares que envíe por medios electrónicos a través de internet, así como cualquier otra información que pueda cargar o transmitir a nosotros en relación con los servicios.
                    <br /><br />
                    Este Acuerdo lo vincula a usted y el resto de los usuarios a un código de conducta ética destinado a lograr este propósito. El éxito de nuestros Servicios depende del cumplimiento de los términos de este acuerdo por parte de usted y de todos los demás usuarios. Si bien haremos todo lo posible para hacer cumplir los términos de este Acuerdo, como se establece a continuación en las Secciones 5, 6 y 7, no podemos garantizar ni representar que otros visitantes de hecho se adherirán a este Acuerdo. 
                    <br /><br />
                    Los servicios ofrecidos por CASCO, están destinados para consultas, asesoria y fines comerciales en materia de seguridad ocupacional únicamente, por lo tanto al brindar su consentimiento, usted acepta que tiene al menos 18 años de edad y, por lo tanto, es completamente capaz y competente para aceptar los términos, condiciones, obligaciones, representaciones y garantías establecidas en este Acuerdo.

                </p>

                <div class="title-top">
                    <span>2.	Acuerdo.</span>
                </div>
                <p>
                    Al manifestar su consentimiento, acepta estar sujeto contractualmente a los términos de este Acuerdo. Además, acepta que su consentimiento, otorgado electrónicamente, tendrá el mismo efecto legal que si hubiera sido firmado personalmente por usted. En la medida en que lo permita la ley, este acuerdo está destinado a reemplazar cualquier disposición de la ley aplicable que de otro modo podría limitar su aplicabilidad o efecto porque se celebró electrónicamente.
                </p>

                <div class="title-top">
                    <span>3.	Acceso.</span>
                </div>
                <p>
                    Al aceptar este acuerdo, usted tendrá acceso a explorar todos los Servicios de forma parcial o total en dependencia de los fines para el cual lo esta usando. 
                    <br /><br />
                    Tenga en cuenta, que con la creación de sus cuentas y/o credenciales de acceso usted será el responsable de mantener la seguridad y confidencialidad de las mismas. 
                    <br /><br />
                    Si usted considera que sus credenciales se han visto comprometidas, podrá cambiarlas en cualquier momento. Sin embargo, sus privilegios de acceso están condicionados a su adhesión a los términos de este acuerdo, por lo que nos reservamos el derecho de negarle temporal o permanentemente el acceso a los Servicios y/o privilegios de acceso en cualquier momento si, entre otros, no ha cumplido con las términos de este acuerdo o su estatus de crédito se ha visto afectado. 
                    <br /><br />
                    Usted acepta y comprende que al otorgarle acceso, no nos obligamos a mantener estos Servicios, y nos reservamos expresamente el derecho de utilizar y/o eliminar cualquier contenido que pueda mantener y almacenar en los Servicios en cualquier forma y ubicación que no lo identifique personalmente, por lo que debe guardar una copia de cualquier contenido que transmita, almacene o comparta por estos Servicios ya que no nos comprometemos a retener copias de ningún contenido de los Servicios.
                </p>

                <div class="title-top">
                    <span>4. Consentimiento para el uso del contenido.</span>
                </div>
                <p>
                    Usted acepta que podemos usar y mantener su dirección de correo electrónico que proporciona como un identificador de cuenta único con el fin de proporcionar y mejorar los Servicios. Usted acepta que podemos recopilar y utilizar información que incluye, entre otros, información sobre su ubicación física, sistema operativo, detalles de la aplicación y sistema, que se recopila periódicamente para facilitar la prestación de los Servicios a usted y verificar el cumplimiento de los términos de este Acuerdo. 
                    <br /><br />
                    Podemos utilizar esta información, siempre que se recopile de forma anónima en una forma que no lo identifique personalmente, para mejorar nuestros Servicios. 
                    <br /><br />
                    En todo momento, su información será tratada de acuerdo con nuestra política de privacidad, que se incorpora como referencia en este Acuerdo y se puede ver en <a href="https://cascosafety.com/politica-de-privacidad">https://cascosafety.com/politica-de-privacidad</a>.  No veremos, accederemos ni procesaremos su contenido, excepto: (i) según lo autorizado o instruido por usted en este acuerdo o en cualquier otro acuerdo entre las partes, o (ii) según sea necesario para cumplir con nuestras políticas, la ley aplicable o solicitud gubernamental. Usted acepta cumplir con todos los deberes legales que le sean aplicables en virtud del envío de su contenido dentro de los servicios.
                </p>

                <div class="title-top">
                    <span>5.	Derechos de autor.</span>
                </div>
                <p>
                    Usted acepta, declara y garantiza que es el único responsable del contenido transmitido como parte de los servicios que se le brindan a través de las diferentes plataformas y que posee los derechos sobre dicho contenido o que ha obtenido el permiso firmado y por escrito de los propietarios de todos los derechos sobre el mismo. Por consiguiente, deberá conservar la propiedad de los derechos de autor de cualquier material original que comparta en nuestros servicios (en la medida en que dicho material pueda tener derecho a protección de derechos de autor).
                    <br /><br />
                    Aceptamos aplicar medidas de seguridad, técnicas, organizativas y administrativas para mantener su contenido protegido de acuerdo con los estándares de la industria. Bajo ninguna circunstancia seremos responsables por: (i) cualquier contenido que se transmita o visualice durante el uso de los Servicios, (ii) errores u omisiones en el contenido, (iii) cualquier pérdida o daño de cualquier tipo incurrido como resultado del uso, acceso o denegación de acceso al contenido. 
                    <br /><br />
                    Al utilizar los servicios, nos da su consentimiento para almacenar grabaciones de cualquiera o todas las llamadas de video o audio en las que participa, si dichas grabaciones se almacenan en nuestros sistemas. Al aceptar este Acuerdo, usted da su consentimiento para la grabación de todas las interacciones mientras usa los Servicios. 
                </p>

                <div class="title-top">
                    <span>6.	Uso permitido.</span>
                </div>
                <p>
                    Usted acepta y reconoce que al usar nuestros Servicios, un asesor tendrá la capacidad de comunicarse con usted y acceder a ciertas funcionalidades de información proporcionada por usted a través de los Servicios, que incluyen, información personal y/o corporativa; así como tomar control de la cámara de su dispositivo para tomar fotografías, hacer uso del flash, grabar videos, realizar video llamadas, y conservar una copia de estos archivos multimedia, entre otros, siempre y cuando la app este en uso, esto con el objetivo de poder brindar soluciones acertadas a sus necesidades específicas y mejorar nuestro Servicios para todos nuestros clientes.
                    <br /><br />
                    CASCO en ningún momento tendrá la capacidad y/o intención de hacer uso de estas funcionalidades de asesoría cuando la app no se este utilizando, ni sin su conocimiento. 
                </p>

                <div class="title-top">
                    <span>7.	Uso prohibido.</span>
                </div>
                <p>
                    Usted acepta que no utilizará los Servicios para: (i) modificar, desmontar, descompilar, preparar trabajos derivados, realizar ingeniería inversa o intentar obtener acceso al código fuente de los Servicios; (ii) utilizar los Servicios con conocimiento o negligencia de una manera que abuse, interfiera o interrumpa nuestras redes o servicios; (iii) participar en actividades que sean ilegales, fraudulentas, falsas o engañosas, incluida dicha actividad mientras se realiza un uso permitido según la Sección 6, (iv) transmitir a través de los servicios cualquier material que pueda infringir la propiedad intelectual u otros derechos de terceros; (v) crear o comparar un producto o servicio competitivo, o copiar cualquier característica, función o gráfico de los productos o servicios; (vi) utilizar los servicios para comunicar cualquier mensaje o material que sea acosador, difamatorio, amenazante, obsceno, indecente o ilegal, que dé lugar a responsabilidad civil o que constituya o fomente una conducta que podría constituir un delito penal, bajo cualquier ley o reglamento aplicable; (vii) participar en cualquier actividad o usar los servicios de cualquier manera que pueda dañar, deshabilitar, sobrecargar, deteriorar o interferir o interrumpir los servicios, o cualquier servidor o red conectado a los servicios (viii) usar los servicios de una manera que viole la ley aplicable, incluidas, entre otras, las leyes y reglamentos que aseguran la privacidad de información, la moral y las buenas costumbres, u ofrecer vender o beneficiarse de los productos o servicios ofrecidos a través del sitio sin obtener primero nuestro permiso expreso por escrito, que puede ser denegado a nuestro exclusivo criterio.
                </p>

                <div class="title-top">
                    <span>8. Violaciones de uso.</span>
                </div>
                <p>
                    Usted es el único responsable del cumplimiento de todas esas leyes y regulaciones y dicha violación de esta Sección 8 puede someterlo a la terminación inmediata y permanente de los privilegios de acceso. Haremos todo lo posible, de buena fe, para eliminar o restringir la disponibilidad del Contenido en violación de este Acuerdo. Las disposiciones de la sección 7 están destinadas a implementar esta política, pero no a imponernos una obligación contractual de emprender, o abstenerse de emprender, cualquier curso de conducta en particular. Si cree que alguien ha violado los términos de este acuerdo, le pedimos que nos notifique de inmediato por correo electrónico a la siguiente dirección: 
                     <a  href="mailto:info@cascosafety.com">info@cascosafety.com</a>. 
                     Debe utilizar esta dirección si desea asegurarse de que recibamos su queja. Para responder lo más rápido posible a cualquier queja, proporcione tantos detalles como sea posible, incluyendo (i) la naturaleza del derecho infringido; (ii) todos los hechos que le hagan creer que un derecho ha sido violado o infringido; (iii) la ubicación precisa donde se encuentra el material infractor; (iv) cualquier motivo para creer que la persona infractora no estaba autorizada para transmitir el contenido o no tenía una defensa válida (incluida la defensa del uso legítimo); y (v) si se conoce, la identidad de la persona o personas que publicaron el contenido infractor u ofensivo. Al presentar una queja y seguir los procedimientos de la Sección 7, usted acepta que se considerará que el fondo de su queja constituye una representación hecha bajo pena de perjurio según las leyes locales. Además, acepta, a su cargo, defendernos e indemnizarnos contra cualquier responsabilidad en la que podamos incurrir por nuestra respuesta a su queja, incluidos, entre otros, los honorarios razonables de los abogados. Usted reconoce que al establecer un procedimiento de quejas asumimos el papel de buena fe y, acepta renunciar a cualquier reclamo. 
                    </p>

                <div class="title-top">
                    <span>9. Derechos de propiedad.</span>
                </div>
                <p>Conservamos todos los derechos de propiedad sobre los Servicios, logotipos y nombres de dominio (las "Marcas") asociados y/o mostrados en el sitio. No puede realizar ninguna acción ni intentar ninguna acción para obstruir, eliminar, disfrazar o alterar nuestras marcas.</p>

                <div class="title-top">
                    <span>10.	Apple App Store y Google Play Store.</span>
                </div>
                <p>
                    Esta Sección solo se aplica en la medida en que esté utilizando nuestra aplicación móvil en un dispositivo iOS o Android, incluido cualquier dispositivo iOS o Android que ejecute versiones no autorizadas o caducadas de sus respectivos sistemas operativos. Usted reconoce que estos Términos son únicamente entre Usted y Nosotros, no con Apple Inc. ("Apple") o Google, LLC ("Google"), ni Apple ni Google son responsables de los Servicios, Servicios Pagos o el Contenido de los mismos.
                    <br /><br />
                    Acepta cumplir con todos los términos y condiciones que le imponga Apple y/o Google según corresponda. Ni Apple ni Google tienen la obligación de proporcionar ningún servicio de mantenimiento y/o soporte con respecto a los Servicios. Ni Apple ni Google son responsables de abordar ningún reclamo suyo o de un tercero en relación con los servicios y/o uso de los productos adquiridos en alguno de nuestros Servicios, incluidos: (a) reclamo por responsabilidad por productos; (b) cualquier reclamo de que los Servicios no se ajustan a cualquier requisito legal o reglamentario aplicable; o (c) reclamaciones derivadas de la protección del consumidor o legislación similar. Ni Apple ni Google son responsables de la investigación, la defensa, el acuerdo y el descargo de cualquier reclamo de terceros de que los Servicios y / o Su posesión y uso de los Servicios infrinjan los derechos de propiedad intelectual de un tercero. Acepta cumplir con los términos aplicables de terceros al utilizar los Servicios. 
                </p>

                <div class="title-top">
                    <span>11.	Compra en linea. </span>
                </div>
                <p>
                    Algunas funciones como compra en línea están disponibles en nuestros Servicios: Cuando realiza una compra en la aplicación, usted tendrá la seguridad que cumpliremos los procedimientos de seguridad y confidencialidad de informacion que la ley establece.
                    <br /><br />
                    Las compras dentro de la aplicación pueden tomar la forma de una suscripción. 
                    <br /><br /> 
                    Servicios de pago directo. Cuando elige comprarnos un producto o servicio pagado directamente, e ingresa una forma de pago, acepta que: (i) Cumplirá con su obligación de pagar el Servicio pagado antes de la fecha de pago. se debe; (ii) cualquier información de pago que proporcione es verdadera y precisa; (iii) está autorizado a utilizar el método de pago que proporcione; (iv) Tenemos permiso para retener la información y el método de pago, incluida toda la información de tarjetas de crédito y débito enviada por Usted y Su banco emisor o la red de pago correspondiente. Sus garantías de pago y nuestros derechos a cobrar el pago incluyen cualquier obligación que pueda tener de pagar cualquier impuesto o cargo de envío descrito como aplicable al producto que está comprando. Además, usted acepta que será responsable de cualquier cargo por devolución de cargo de la tarjeta de crédito, así como de cualquier costo de cobro razonable, incluidos, entre otros, los honorarios razonables de la agencia de cobro y los honorarios de los abogados en los que incurramos como resultado de su incumplimiento de pago puntual.
                </p>

                <p>A)	Precios. Nuestros precios para los servicios de pago no incluyen ninguna de las tarifas de terceros en las que incurra en relación con el uso de un servicio de pago, incluidas, entre otras, las tarifas cobradas por uso de termnar P.O.S </p>
                 <p>B)	Cambios en las tarifas o precios. Es posible que cambiemos el precio de cualquier producto o servicio y agreguemos nuevas tarifas y cargos para ciertas funciones o para reflejar un cambio en las reglas comerciales o legales, pero le notificaremos con anticipación los cambios en las tarifas recurrentes. Cualquier aumento en los cargos por el mismo servicio o producto no se aplicaría hasta el vencimiento de su ciclo de facturación actual en ese momento, a menos que se indique específicamente lo contrario en nuestro aviso, y entrará en vigencia no antes de la próxima compra. </p>
                 <p>C)	 Metodos de pago. Aceptamos el pago a través de tarjetas de débito y crédito, así como otras formas de pago en efectivo (por ejemplo, transferencias por compra click) como métodos de pago para los productos y servicios (cada uno, un "Método de Pago Aceptado"), sujeto a los procedimientos y reglas contenidos en el Acuerdo. Además, acepta que tenemos permiso para retener y / o compartir con instituciones financieras y empresas de procesamiento de pagos (incluidas las instituciones o empresas que retenemos en el futuro) información sobre su compra y la información de pago enviada para procesar su compra, y para utilizar la dirección de correo electrónico enviada por usted para proporcionarle avisos y divulgaciones relacionadas con renovaciones y cargos recurrentes si fuere necesario. </p>
                 <p>D)	Facturación: Al momento de realizar una compra en el sitio y habiendo confirmado la misma, usted tendrá acceso a un comprobante de pago numerado, la cual reflejará el monto pagado. Este documento podrá ser utilizado para justificar el monto de la transacción realizada y para solicitar cualquier revisión por garantía del producto indicado en la misma. </p>
                
                <div class="title-top">
                    <span>12.	Garantías.</span>
                </div>
                <p>
                    Para proporcionarle los servicios descritos en este acuerdo, podemos ofrecer garantías sobre los beneficios u oportunidades que pueda obtener en este sitio. en particular la garantía de seguridad que representa las opciones de compra, los medios de pago y la calidad de los productos comercializados en el sitio. No obstante, por la presente renunciamos a todas las garantías expresas o implícitas referidas a aptitud para un propósito particular com a título y perjuicios por el no uso correcto de la información proporcionada en el sitio. Este sitio se pone a su disposición “tal cual”sin ninguna garantía en la forma de cómo usted haga uso del sitio.
                </p>

                <div class="title-top">
                    <span>13.	Limitación de responsabilidad. </span>
                </div>
                <div class="title-top">
                    <span>
                        No seremos responsables de ningún daño o lesión que acompaña o resulte del uso del sitio, servicio o servicios pagados proporcionados de conformidad con este acuerdo. Esto incluye pero no se limita a cualquier daño o lesión causada por cualquiera de las siguientes situaciones: (1) uso o incapacidad de uso del sitio, (2) error, (3) omisión, (4) interrupción, (5) defecto, (6) retraso en operación o (7) transmisión virus del equipo a través del cual accede al sitio o (8) falla de línea .
                    </span>  
                </div>

                
                <div class="title-top">
                    <span>14.	Modificaciones / Terminación.</span>
                </div>
                <p>
                    A. En general. Nuestros empleados no están autorizados a modificar los términos de este Acuerdo. Este Acuerdo puede modificarse solo (1) mediante la obtención de nuestro consentimiento por escrito en un acuerdo notariado firmado por el Director General y cualquier otra persona designada para administrar nuestros asuntos cotidianos o; (2) como se establece a continuación en la Sección 14 (b).
                    <br /><br />
                    B. Revisiones y enmiendas periódicas. Usted acepta que podemos modificar los términos de este acuerdo a nuestro exclusivo criterio. Si lo hacemos, le notificaremos por correo electrónico a la dirección que nos proporcionó y publicando un aviso en nuestra página de inicio durante treinta (30) días consecutivos antes de la fecha de vigencia de cualquier modificación a este acuerdo. Usted acepta que será su responsabilidad iniciar sesión en este sitio al menos una vez cada treinta (30) días, revisar cualquier correo electrónico que pueda recibir de nosotros (incluido el correo electrónico filtrado como spam) y notificarnos a <a href="mailto:info@cascosafety.com">info@cascosafety.com</a> de inmediato. Si usted cambia de cuenta de correo electrónico o si prefiere recibir notificaciones nuestras en una cuenta diferente a la que está utilizando actualmente es necesario notificarlo con anterioridad. De lo contrario, será su responsabilidad y obligación verificar la sección legal de este sitio cada vez que acceda al mismo, para determinar si ha habido algún cambio en este acuerdo. Puede notificarnos a <a href="mailto:info@cascosafety.com">info@cascosafety.com</a> en cualquier momento antes o dentro de los treinta (30) días posteriores a la entrada en vigor de cualquier modificación, para informarnos que no acepta la modificación propuesta. 
                    <br /><br />
                    C. Término. A menos que rescindamos sus privilegios de acceso o usted decida rescindir este Acuerdo, permanecerá en vigor mientras esté autorizado a acceder a este sitio. Usted acepta que en caso de que decida rescindir este acuerdo o si sus privilegios de acceso se suspenden o cancelan (incluso por falta de pago de conformidad con las Secciones 11), continuará estando sujeto a todas las obligaciones establecidas en este Acuerdo. Salvo que se disponga lo contrario en esta sección, usted no estará obligado por ninguna modificación a este acuerdo que pueda entrar en vigencia después de que usted o nosotros terminemos nuestra relación contractual.
                    <br /><br />
                    D. Indemnización, jurisdicción y resolución de disputas. Este acuerdo, y todas las disputas que puedan surgir en virtud del mismo, se regirán por la legislación local. Usted acepta la jurisdicción personal y la competencia de la autoridad sobre el conflicto que pueda derimirse, y renuncia a cualquier objeción a dicha jurisdicción o lugar. Cualquier reclamo en virtud de este acuerdo puede presentarse dentro de un (1) año después de que surja la causa de la acción. 
                    En cumplimiento de la Sección 13, pero sin estar en conflicto con ella, no se podrá solicitar ni recibir compensación alguna por daños que no sean los gastos de bolsillo, excepto que la parte que prevalezca tendrá derecho a los costos y honorarios de los abogados. En el caso de cualquier controversia o disputa que surja de o en relación con al uso del sitio entre nosotros y usted, los servicios o los servicios pagos, las partes intentarán, de inmediato y de buena fe, resolver dicha disputa. Si las partes no pueden resolver dicha disputa dentro de un tiempo razonable (que no exceda los treinta (30) días), cualquiera de las partes puede someter dicha controversia o disputa a un trámite de mediación. Si la disputa no puede resolverse a través de la mediación, entonces las partes tendrán la libertad de ejercer cualquier derecho o recurso disponible para ellos bajo la ley aplicable en la jurisdicción donde hiciere uso de los Servicios y tenga presencia CASCO.

                </p>

                <div class="title-top">
                    <span>15. Construcción.</span>
                </div>
                <p>
                    En caso de que una o más de las disposiciones contenidas en este acuerdo, en su totalidad o en parte, por cualquier motivo, se consideren inválidas, ilegales o inaplicables en cualquier aspecto, dicha invalidez, ilegalidad o inaplicabilidad no afectará las otras disposiciones de este acuerdo, y las partes negociarán de buena fe para modificar este acuerdo a fin de efectuar la intención original de las partes lo más fielmente posible a fin de llevar a cabo el acuerdo como se contemplaba originalmente en la mayor medida posible.
                </p>

                <p><strong>Actualización de estas Condiciones</strong><br /><br /> 
                    Es posible que modifiquemos nuestros Servicios y/o las políticas, y que debamos actualizar estas Condiciones de modo que reflejen nuestros Servicios y las políticas con precisión. A menos que la ley disponga lo contrario, te enviaremos una notificación (por ejemplo, mediante nuestros Servicios o correo electrónico) antes de modificar estas Condiciones, dando así la oportunidad de revisar los cambios antes de que entren en vigor. Si sigues usando el Servicio, quedarás sujeto a las Condiciones actualizadas. Si no quieres aceptar estas ni otras Condiciones actualizadas, puedes solicitar eliminar tu contenido y/o tu cuenta por medio de nuestros Servicios o correo electrónico.
                    <br /><br />
                    En caso de tener dudas o solicitar mayor información estaremos felices de asistirte y puedes contactarte con nosotros por correo electrónico, el tiempo de respuesta variara según la fecha en la que nos contactes y la disponibilidad en el momento del personal de atención al cliente: <a href="mailto:info@cascosafety.com">info@cascosafety.com</a>
                  
                </p>

               

            </div>
        </div>
    </div>
</div>