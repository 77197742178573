import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient, HttpErrorResponse, HttpHeaders, HttpParams } from '@angular/common/http';
import { environment } from './../../environments/environment'
import { catchError, retry, map } from 'rxjs/operators';
import { PushAndroidPushtokenResponse } from '../models/push-android-pushtoken-response.model';
import { PushAndroidPushtokenRequest, PushNotificationRequest } from '../models/push-android-pushtoken-request.model';
import { empty, throwError } from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class PushAndroidPushtokenService {

  constructor(private http: HttpClient) { }
  private headerOptions: HttpHeaders = {} as HttpHeaders;

  public sendPushToken(pushTokenRequestParams: PushAndroidPushtokenRequest): Observable<PushAndroidPushtokenResponse> {
    var header: HttpHeaders = {} as HttpHeaders;
    header["Authorization"] = "key=AAAAQ1EAc9A:APA91bGuj6lri-GW5L3ZJadIkFRud957IsSUiUaEe32wINHF6eJ5cHC4_TkDoqATJXtBUj58V5q2QUpbvYxUtOiAQ374kSqW8LCjhMq1x6X6vcSzKqWDaE46OmJ1vzlbX5Jzqok7V1fk";
    this.headerOptions = header;
    return this.post<PushAndroidPushtokenResponse>("send", pushTokenRequestParams, header, true);
  }

  private fullUrl(url: string): string {
    //console.log("full url " + environment.androidPushTokenApiUrl + url);
    return environment.androidPushTokenApiUrl + url;
  }

  public post<T>(url: string, data: any, header: any = undefined, showLoading: boolean = true) {
    return this.http.post<T>(this.fullUrl(url), data, { headers: header ? header : this.headerOptions })
      .pipe(
        map(response => {
          return response;
        }),
        catchError((err) => { return this.handleError(err); })
      );
  }

  private handleError(response: any) {
    //console.log(response);
    var error = {
      status: response.status,
      message: ''
    };
    if (response.error) {
      if (response.error.status) {
        error.status = response.error.status;
      }

      if (typeof "" == typeof response.error) {
        error.message = response.error;
      }
      else {
        error.message = response.error.message ? response.error.message : response.error.error_description;
      }

    }
    else {
      error.message = response.message;
    }
    console.log(error);
    return throwError(response.error);
    //return Observable.throw(error);
  };

  public sendPushNotificaton(pushTokenRequestParams: PushNotificationRequest): Observable<PushAndroidPushtokenResponse> {
    var header: HttpHeaders = {} as HttpHeaders;
    header["Authorization"] = "key=AAAATxyOkBk:APA91bGUeILCb4YFC-8V8_HMVKWrauU3mslQe8g2e4XQkaO4OegXkSFKiVsVWSy_NrLBv-y3srKTL42Jwp2-85ivZTsQNsIwhL17RCHB7LXxqn67RqrefCaUKlpQ5Z9eezLcSwNMqV8K";
    this.headerOptions = header;
    return this.post<PushAndroidPushtokenResponse>("send", pushTokenRequestParams, header, true);
  }
}
