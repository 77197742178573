<div class="contact-form-container">
  <div class="container">
    <div class="contact-form-block">
      <div class="logo-block">
        <img src="../../../../assets/images/auth-logo.svg" />
      </div>
      <section class="user-verification-sec">
        <div class="container">
          <div class="title-desc-block text-center">
            <div class="block-title">
              <h3>Bienvenido a CASCO</h3>
            </div>
            <div class="block-desc">
              <p>Ingrese la información para continuar</p>
            </div>
          </div>
          <div class="form-block">
            <form #frmCreateClient="ngForm" class="welcome-form" (ngSubmit)="verifyCustomerDetail(frmCreateClient);" autocomplete="off">
              <div class="row">
                <div class="col-sm-12 col-md-6">
                  <div class="input-block">
                    <input type="text" class="form-control" placeholder="Primer Nombre" id="firstname" name="firstname"
                      [(ngModel)]="model.firstName" #firstName="ngModel" maxlength="50" required=""
                      >
                  </div>
                </div>
                <div class="col-sm-12 col-md-6">
                  <div class="input-block">
                    <input type="text" class="form-control" placeholder="Apellidos" id="lastName" name="lastName"
                      maxlength="50"
                      [(ngModel)]="model.lastName" #lastName="ngModel" maxlength="50" required=""
                      >
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-sm-12">
                  <div class="input-block">
                    <input type="email" email class="form-control" placeholder="Correo Electrónico" id="emailId"
                      name="emailId"
                      [(ngModel)]="model.emailId" #emailId="ngModel" maxlength="50" required=""
                      >
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-sm-12">
                  <div class="input-block">
                    <input type="email" email class="form-control" placeholder="Confirmar Correo Electrónico"
                      id="confirmEmailId" name="confirmEmailId"
                      [(ngModel)]="model.confirmEmailId" #confirmEmailId="ngModel" maxlength="50" required=""
                      >
                  </div>
                </div>
              </div>
              <div>
                <validation-summary [validationErrors]="validationMessages"></validation-summary>
            </div>
              <div class="row">
                <div class="col-sm-12">
                  <div class="action-btn text-center">
                    <button type="submit"  class="btn comm-btn">Someter</button>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </section>
    </div>
  </div>
</div>