import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { NgForm } from '@angular/forms'
import { SharedDataService } from '../../../services/sharedData.service';
import { TenantRegistrationService } from '.././../../api-services/tenant-registration.service';
import { SettingService } from '.././../../api-services/setting.service';
import { iTenantRegistration, iReturnId } from '../../../models/tenant-registration.model';
import { AuthService } from '../../../services/auth.service';
import { iCountryList } from '../../../models/setting.model';

@Component({
  selector: 'app-registration',
  templateUrl: './registration.component.html',
  styleUrls: ['./registration.component.css']
})
export class RegistrationComponent implements OnInit {

  constructor(private router: Router,
    private service: TenantRegistrationService,
    private sharedService: SharedDataService,
    private authService: AuthService,
    private settingService: SettingService) {
    this.authService.redirectToMainPageIfUserIsLoggedIn();
  }

  @ViewChild('registrationForm')
  registrationForm: NgForm;
  tenantRegistration: iTenantRegistration = {};
  returnId: iReturnId = {};
  validationMessages: any;
  message?: string;
  hideButtons: boolean = false;
  countryList?: iCountryList[] = [];
  selectedCountry?: iCountryList = {};
  placeHolder: string = "Phone Number";

  ngOnInit(): void {
    //localStorage.removeItem("tenantId");
    //localStorage.removeItem("accountId");    
    this.getCountryList();
    this.tenantRegistration.isdCountryId = 1;
    if (this.sharedService.getValue("tenantRegistartionData") != null || this.sharedService.getValue("tenantRegistartionData") != undefined) {
      this.getRegistrationData();
    }

    //this.tenantRegistration.tenantId = this.sharedService.getValue("tenantId");

    //if (this.tenantRegistration.tenantId != null && this.tenantRegistration.tenantId != 0 && this.tenantRegistration.tenantId != undefined) {
    //  this.getRegistrationData();
    //}
    //else
    //  this.tenantRegistration.tenantId = 0;
  }

  //saveTenantRegistration() {

  //  this.validationMessages = [];

  //  this.populateValidateMessages();

  //  if (this.validationMessages.length === 0) {

  //    this.service.tenantRegistration(this.tenantRegistration).subscribe(result => {
  //      if (result != null) {
  //        this.returnId = <iReturnId>result;

  //        if (this.returnId.message == "Added." || this.returnId.message == "Updated.") {
  //          this.sharedService.setValue('tenantId', this.returnId.tenantId);
  //          this.sharedService.setValue('accountId', this.returnId.accountId);
  //          this.router.navigate(['/account-selection']);
  //        }
  //        else {
  //          this.validationMessages.push(this.returnId.message);
  //        }
  //      }
  //    }, error => {
  //        this.populateApiErrorMessages(error.message);
  //    });
  //  }
  //}

  //getRegistrationData() {
  //  this.service.getRegistrationDetails(this.tenantRegistration.tenantId).subscribe(result => {
  //    if (result != null) {
  //      this.tenantRegistration = <iTenantRegistration>result;
  //    }
  //  }, error => {
  //    this.populateApiErrorMessages(error.message);
  //  });
  //}

  populateValidateMessages() {
    if (this.tenantRegistration.firstName === undefined || this.tenantRegistration.firstName === null || this.tenantRegistration.firstName.trim().length == 0 ||
      this.tenantRegistration.lastName === undefined || this.tenantRegistration.lastName === null || this.tenantRegistration.lastName.trim().length == 0 ||
      this.tenantRegistration.businessName === undefined || this.tenantRegistration.businessName === null || this.tenantRegistration.businessName.trim().length == 0 ||
      this.tenantRegistration.phoneNo === undefined || this.tenantRegistration.phoneNo === null || this.tenantRegistration.phoneNo.trim().length == 0 ||
      this.tenantRegistration.emailId === undefined || this.tenantRegistration.emailId === null || this.tenantRegistration.emailId.trim().length == 0) {
      this.validationMessages.push('Ingrese los datos adecuados.');
    }
    else if (!this.isValidEmail(this.tenantRegistration.emailId)) {
      this.validationMessages.push('Por favor ingrese un correo electrónico válido.');
    }
    else if (!this.sharedService.isValidPhoneNo(this.tenantRegistration.phoneNo)) {
      this.validationMessages.push('Ingrese un número de teléfono válido.');
    }
  }

  saveTenantRegistration() {
    this.validationMessages = [];
    this.populateValidateMessages();

    if (this.validationMessages.length === 0) {
      this.service.tenantRegistration(this.tenantRegistration.emailId).subscribe(result => {
        if (result != null) {
          this.message = <string>result;

          if (this.message == "No Record Found.") {
            this.tenantRegistration.isdCountryId = this.selectedCountry.id;
            this.tenantRegistration.isdCountry = this.selectedCountry;
            this.sharedService.setValue('tenantRegistartionData', JSON.stringify(this.tenantRegistration));
            this.router.navigate(['/account-selection']);
          }
          else {
            this.validationMessages.push(this.message);
            if (this.message.includes("You already have an account with Adjustify")) {
              this.hideButtons = true;
            }
          }
        }
      }, error => {
        this.populateApiErrorMessages(error.message);
      });
    }
  }

  getRegistrationData() {
    this.tenantRegistration = <iTenantRegistration>JSON.parse(this.sharedService.getValue("tenantRegistartionData"));
    this.selectedCountry = this.tenantRegistration.isdCountry;
    this.placeHolder = "";
  }

  isValidEmail(email) {
    var re = /[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,3}$/;
    return re.test(email);
  }

  populateApiErrorMessages(message: string) {
    this.validationMessages.push(message);
  }

  checkSpecialCharacter(event) {
    var regex = new RegExp("^[a-zA-Z0-9 ]+$");
    var key = String.fromCharCode(!event.charCode ? event.which : event.charCode);
    if (!regex.test(key)) {
      event.preventDefault();
      return false;
    }
  }

  onPaste(event: ClipboardEvent) {
    var regex = new RegExp("^[a-zA-Z0-9 ]+$");
    if (!regex.test(event.clipboardData.getData('text'))) {
      event.preventDefault();
      return false;
    }
  }

  getCountryList() {
    this.settingService.getCountryList().subscribe(result => {
      if (result != null) {
        this.countryList = <iCountryList[]>result;
        this.selectedCountry = this.countryList[0];
      }
    }, error => {
      this.populateApiErrorMessages(error.message);
    });
  }

  selectCountry(data?: any) {
    this.selectedCountry = {};
    this.selectedCountry = data;
  }
  focusFunction() {
      this.placeHolder = "";
  }

  restrictSpecialCharacterForPhoneNumber(event) {
    return this.sharedService.restrictSpecialCharacterForGivenRegex(event);
  }

  restrictPhoneNumberCharactersOnPaste(event: ClipboardEvent) {
    return this.sharedService.restrictGivenRegexCharactersOnPaste(event);
  }
}
