import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AddClaimComponent } from './components/add-claim/add-claim.component';
import { BacklogDetailsComponent } from './components/backlog-details/backlog-details.component';
import { BacklogComponent } from './components/backlog/backlog.component';
import { ImportBatchListComponent } from './components/backlog/import-batch-list/import-batch-list.component';
import { ImportBatchComponent } from './components/backlog/import-batch/import-batch.component';
import { ConfirmPasswordComponent } from './components/before-login/confirm-password/confirm-password.component';
import { LoginComponent } from './components/before-login/login/login.component';
import { AccountSelectionComponent } from './components/before-login/registration/account-selection/account-selection.component';
import { ActivateAccountComponent } from './components/before-login/registration/activate-account/activate-account.component';
import { ActivateComponent } from './components/before-login/registration/activate/activate.component';
import { CodeRequestComponent } from './components/before-login/registration/code-request/code-request.component';
import { InviteUserComponent } from './components/before-login/registration/invite-user/invite-user.component';
import { RegistrationComponent } from './components/before-login/registration/registration.component';
import { ThankyouMessageComponent } from './components/before-login/registration/thankyou-message/thankyou-message.component';
import { RequestVerificationCodeComponent } from './components/before-login/request-verification-code/request-verification-code.component';
import { VerificationCodeConfirmationComponent } from './components/before-login/verification-code-confirmation/verification-code-confirmation.component';
import { CallHistoryDetailsComponent } from './components/call-history-details/call-history-details.component';
import { CallScheduleComponent } from './components/call-schedule/call-schedule.component';
import { ValidationSummaryComponent } from './components/common/validation-summary.component';
import { CallHistoryComponent } from './components/dashboard/call-history/call-history.component';
import { DashboardComponent } from './components/dashboard/dashboard.component';
import { ErrorpageComponent } from './components/errorpage/errorpage.component';
import { FullCalenderViewComponent } from './components/full-calender-view/full-calender-view.component';
import { MainSettingsComponent } from './components/main-settings/main-settings.component';
import { ReviewComponent } from './components/review/review.component';
import { VideoCallComponent } from './components/video-call/video-call.component';
import { PasswordChangedSuccessComponent } from './components/before-login/password-changed-success/password-changed-success.component';
import { ConversationFullViewComponent } from './components/conversation-full-view/conversation-full-view.component';
import { PaymentSuccessfulComponent } from './components/payment-successful/payment-successful.component';
import { RoleGuard } from './services/role.guard.service';
import { ImportGuard } from './services/import.guard.service';
import { ContactFormComponent } from './components/contact-form/contact-form.component';            
import { GettingStardedComponent } from './components/new-contact-us-flow/getting-starded/getting-starded.component';
import { ThankYouForRegistrationComponent } from './components/new-contact-us-flow/thank-you-for-registration/thank-you-for-registration.component';
import { TermConditionsComponent } from './components/term-conditions/term-conditions.component';
import { PrivacyPolicyComponent } from './components/privacy-policy/privacy-policy.component';
import { FaqComponent } from './components/faq/faq.component';


const routes: Routes = [
  { path: '', component: LoginComponent },
  { path: 'login', component: LoginComponent },
  // { path: 'register', component: RegistrationComponent },
  { path: 'account-selection', component: AccountSelectionComponent },
  { path: 'activate', component: ActivateComponent },
  { path: 'confirm-password', component: ConfirmPasswordComponent },
  { path: 'validation-summary', component: ValidationSummaryComponent },
  { path: 'errorpage', component: ErrorpageComponent },
  { path: 'request-verification-code', component: RequestVerificationCodeComponent },
  { path: 'verification-code-confirmation', component: VerificationCodeConfirmationComponent },
  { path: 'dashboard', component: DashboardComponent, canActivate: [RoleGuard], data: { roles: ['Super Admin', 'Regular User'] } },
  { path: 'backlog', component: BacklogComponent, canActivate: [RoleGuard], data: { roles: ['Super Admin', 'Regular User'] } },
  { path: 'call-schedule', component: CallScheduleComponent, canActivate: [RoleGuard], data:  { roles: ['Super Admin', 'Regular User'] } },
  { path: 'claim', component: AddClaimComponent, canActivate: [RoleGuard], data: { roles: ['Super Admin', 'Regular User'] } },
  { path: 'video-call', component: VideoCallComponent, canActivate: [RoleGuard], data: { roles: ['Super Admin', 'Regular User'] } },
  { path: 'call-history', component: CallHistoryComponent, canActivate: [RoleGuard], data: { roles: ['Super Admin', 'Regular User'] } },
  { path: 'call-history-details', component: CallHistoryDetailsComponent, canActivate: [RoleGuard], data: { roles: ['Super Admin', 'Regular User'] } },
  { path: 'review', component: ReviewComponent, canActivate: [RoleGuard], data: { roles: ['Super Admin', 'Regular User'] } },
  { path: 'backlog-details', component: BacklogDetailsComponent, canActivate: [RoleGuard], data: { roles: ['Super Admin', 'Regular User'] } },
  { path: 'main-settings', component: MainSettingsComponent, canActivate: [RoleGuard], data: { roles: ['Super Admin', 'Regular User'] } },
  { path: 'import-batch', component: ImportBatchComponent, canActivate: [ImportGuard], data: { roles: ['Super Admin', 'Regular User'] } },
  { path: 'import-batch-list', component: ImportBatchListComponent, canActivate: [ImportGuard], data: { roles: ['Super Admin', 'Regular User'] } },
  { path: 'activate-account', component: ActivateAccountComponent },
  { path: 'code-request', component: CodeRequestComponent },
  { path: 'thank-you-message', component: ThankyouMessageComponent },
  { path: 'invite-user', component: InviteUserComponent },
  { path: 'full-calendar-view', component: FullCalenderViewComponent, canActivate: [RoleGuard], data: { roles: ['Super Admin', 'Regular User'] } },
  { path: 'chat', component: ConversationFullViewComponent, canActivate: [RoleGuard], data: { roles: ['Super Admin', 'Regular User'] } },
  { path: 'password-changed-success', component: PasswordChangedSuccessComponent },
  { path: 'payment-successful', component: PaymentSuccessfulComponent, canActivate: [RoleGuard], data: { roles: ['Super Admin'] } },
  { path: 'contact-form', component: ContactFormComponent },
  { path: 'ticket', component: GettingStardedComponent },
  { path: 'thank-you', component: ThankYouForRegistrationComponent },
  { path: 'terms-conditions', component: TermConditionsComponent },
  { path: 'privacy-policy', component: PrivacyPolicyComponent },
  { path: 'faq', component: FaqComponent },
  //{ path: 'home', component: HomeComponent, canActivate: [AuthGuard] },
  //{ path: '**', redirectTo: '/errorpage' }
];
@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
