import { Component, OnInit, HostListener } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient, HttpHeaders, HttpResponse, HttpEventType, HttpRequest } from '@angular/common/http';
import { SharedDataService } from '../../../services/sharedData.service';
import { ImportBatchService } from '../../../api-services/import-batch.service';
import { FileUploadService } from '../../../services/file-upload.service';
import { iImporBatch } from '../../../models/import-batch.model';
import { environment } from '../../../../environments/environment';
import { saveAs } from 'file-saver';
import { Subscription } from 'rxjs';
declare var $: any;

@Component({
  selector: 'app-import-batch',
  templateUrl: './import-batch.component.html',
  styleUrls: ['./import-batch.component.css']
})
export class ImportBatchComponent implements OnInit {

  constructor(private httpClient: HttpClient,
    private sharedService: SharedDataService,
    private router: Router,
    private service: ImportBatchService,
    private fileUploadService: FileUploadService) { }

  uploadedFileName: string;
  isFileSelected: boolean = false;
  fileDetails: FormData = new FormData();
  public percentage: number = 0;
  importBatch?: iImporBatch = {};
  public uploadImageObservable: Subscription;
  userId?: number;
  validationMessages: any;

  ngOnInit(): void {
    this.validationMessages = [];
    this.userId = this.sharedService.getValue("accountId");

    this.fileUploadService.uploadMessage.subscribe(obj => {
      if (obj) {
        if (obj.percentage && obj.percentage <= 100) {
          this.percentage = obj.percentage;
        }
      }
    });

  }

  @HostListener('drop', ['$event']) onDrop(event) {
    event.preventDefault();
    event.stopPropagation();
    var files = event.dataTransfer.files;
    this.fileEvent(files);
  }

  @HostListener('dragover', ['$event']) onDragOver(event) {
    event.preventDefault();
  }

  @HostListener('dragenter', ['$event']) onDragEnter(event) {
    event.preventDefault();
  }

  @HostListener('dragend', ['$event']) onDragEnd(event) {
    event.preventDefault();
  }

  @HostListener('dragleave', ['$event']) onDragLeave(event) {
    event.preventDefault();
  }

  //below method will check whether file is selected or not, if not, alert will be showned.
  //if file selected, then the name of the file will be set within the text box and upload button will be visible therafter.
  public fileEvent(files) {
    var selectedFile = files[0].name;
    if (selectedFile == null || selectedFile == '' || selectedFile == undefined) {
      alert("Por favor seleccione un archivo.");
    }
    else {

      if (files[0].name.toLowerCase().substring(files[0].name.length - 4) != "xlsx") {
        alert("Por favor cargue el archivo de Excel.");
      }
      else {
        this.uploadedFileName = selectedFile;
        this.fileDetails = new FormData();
        this.fileDetails.append('file', files[0]);
        this.isFileSelected = true;

        this.uploadImageObservable = this.service.uploadFile(this.fileDetails, this.userId).subscribe(result => {
          if (result != null) {
            this.importBatch = <iImporBatch>result.uploadedFile;
            this.sharedService.setValue('importBatch', JSON.stringify(this.importBatch));
            this.router.navigateByUrl('import-batch-list');
          }
        }, error => {
            this.populateApiErrorMessages(error.message);
        });
      }
    }
  }

  //Download sample template
  public DownloadTemplate() {
    const filename = 'Import_Batch_Sample_Template.xlsx';
    this.httpClient.get(environment.apiUrl + 'importBatch/downloadFileTemplate', {
      headers: this.getAuthoriseHeader(),
      observe: 'response',
      responseType: 'blob'
    })
      .subscribe(
        data => {
          var contentType = data.headers.get('content-type');
          var blob = new Blob([data.body], { type: contentType });
          saveAs(blob, filename);
        },
        error => {

        });
  }

  public getHeader(key, value) {
    var header: HttpHeaders = {} as HttpHeaders;
    header[key] = value;
    return header;
  }

  private getAuthoriseHeader() {
    var token = this.sharedService.getValue('token');
    if (token) {
      let token = JSON.parse(this.sharedService.getValue('token'));
      if (token) {
        return this.getHeader("Authorization", `Bearer ${token}`);
      }
      return {} as HttpHeaders;
    }
  }

  //Cancel button click event
  public cancelClick() {
    this.router.navigateByUrl('backlog');
  }

  //Method to cancel uploading
  public cancelUploading() {
    this.uploadImageObservable.unsubscribe();
    this.isFileSelected = false;
    this.deleteFileFromServer();
  }

  //Method to delete uploaded file from the user
  deleteFileFromServer() {
    this.service.deleteUploadedFile(this.userId).subscribe(result => {
      if (result != null) {
        
      }
    }, error => {

    });
  }

  populateApiErrorMessages(message: string) {
    this.validationMessages.push(message);
  }

}
