<div class="payment-success-container">
    <div class="container">
        <div class="row">
            <div class="col-md-5">
                <div class="payment-info">
                    <img src="../../../assets/images/header-logo.svg" />
                    <div class="payment-title">
                        <span class="p-top">Pago Exitoso!</span>
                        <span class="p-btm">Su informacion de pago ha sido enviado a su correo electronico.</span>
                    </div>
                    <div class="amount-desc">
                        <span class="a-top">Numero Factura</span>
                        <span class="a-btm">{{invoiceReceipt.invoiceNumber}}</span>
                    </div>
                    <div class="amount-desc">
                        <span class="a-top">Monto Pagado</span>
                        <span class="a-btm">$ {{invoiceReceipt.paidAmount}}</span>
                    </div>
                    <div class="amount-desc">
                        <span class="a-top">Metodo de Pago</span>
                        <span class="a-btm">Stripe</span>
                    </div>
                    <a routerLink="/dashboard" class="b-home">Regresar a Inicio</a>
                </div>
            </div>
            <div class="col-md-1"></div>
            <div class="col-md-6">
                <div class="img-blk">
                    <img src="../../../assets/images/payment-s-img.svg" />
                </div>
            </div>
        </div>
    </div>
</div>
