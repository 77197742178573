<div class="all-settings-content">
    <div class="integration-content">
        <!--<div class="int-block">
            <div class="overlay">
                <span>Coming Soon</span>
            </div>
            <div class="row">
                <div class="col-md-2">
                    <div class="img-block">
                        <img src="../../../../assets/images/google-drive.svg" />
                    </div>
                </div>
                <div class="col-md-10">
                    <div class="input-block">
                        <input type="text" class="form-control" placeholder="https://samplelinkaddressoverhere.com">
                        <button class="btn">link</button>
                    </div>
                </div>
            </div>
        </div>
        <div class="int-block">
            <div class="overlay">
                <span>Coming Soon</span>
            </div>
            <div class="row">
                <div class="col-md-2">
                    <div class="img-block">
                        <img src="../../../../assets/images/drop-box.svg" />
                    </div>
                </div>
                <div class="col-md-10">
                    <div class="input-block">
                        <input type="text" class="form-control" placeholder="https://samplelinkaddressoverhere.com">
                        <button class="btn">link</button>
                    </div>
                </div>
            </div>
        </div>
        <div class="int-block">
            <div class="overlay">
                <span>Coming Soon</span>
            </div>
            <div class="row">
                <div class="col-md-2">
                    <div class="img-block">
                        <img src="../../../../assets/images/one-drive.svg" />
                    </div>
                </div>
                <div class="col-md-10">
                    <div class="input-block">
                        <input type="text" class="form-control" placeholder="https://samplelinkaddressoverhere.com">
                        <button class="btn">link</button>
                    </div>
                </div>
            </div>
        </div>-->
        <div class="row">
            <div class="col-md-4">
                <div class="int-box">
                    <div class="int-title">
                        <span>DropBox</span>
                    </div>
                    <div class="int-img-block">
                        <img class="int-img" src="../../../../assets/images/drop-box-img.svg" />
                    </div>
                    <span *ngIf = "!isDropBoxAccountSetup" class="int-text"><a href="{{storageRedirectURL}}">La cuenta está configurada</a></span>
                    <span *ngIf = "isDropBoxAccountSetup" class="int-text only-lay">La cuenta est&#225; configurada</span>
                    <div class="int-toggle">
                        <span class="side">Activar Cuenta</span>
                        <label class="switch">
                            <input type="checkbox" [disabled] = "!isDropBoxAccountSetup" [checked] = "isDropBoxActive" [(ngModel)]="isDropBoxActive" (click) = "setDropBoxAccount(isDropBoxActive)">
                            <span class="slider round"></span>
                        </label>
                    </div>
                    <span *ngIf = "isDropBoxActive" class="bottom-text">Su data de llamadas sera grabado en Dropbox</span>
                    <span *ngIf = "!isDropBoxActive" class="bottom-text">Activando esta cuenta, toda su data de llamadas sera guardado en Dropbox</span>
                </div>
            </div>
            <div class="col-md-4">
                <div class="int-box">
                    <div class="int-title">
                        <span>Google Drive</span>
                    </div>
                    <div class="int-img-block">
                        <img class="int-img" src="../../../../assets/images/google-drive-img.svg" />
                    </div>
                    <span class="int-text only-lay">Proximamente</span>
                    <!--<div class="int-toggle">
                        <span class="side">Activar Cuenta</span>
                        <label class="switch">
                            <input type="checkbox">
                            <span class="slider round"></span>
                        </label>
                    </div>
                    <span class="bottom-text">Su data de llamadas sera grabado en Dropbox</span>-->
                    <!--<span class="bottom-text">Activando esta cuenta, toda su data de llamadas sera guardado en Dropbox</span>-->
                </div>
            </div>
            <div class="col-md-4">
                <div class="int-box">
                    <div class="int-title">
                        <span>One Drive</span>
                    </div>
                    <div class="int-img-block">
                        <img class="int-img" src="../../../../assets/images/one-drive-img.svg" />
                    </div>
                    <span class="int-text only-lay">Proximamente</span>
                    <!--<div class="int-toggle">
                        <span class="side">Activar Cuenta</span>
                        <label class="switch">
                            <input type="checkbox">
                            <span class="slider round"></span>
                        </label>
                    </div>
                    <span class="bottom-text">Su data de llamadas sera grabado en Dropbox</span>-->
                    <!--<span class="bottom-text">Activando esta cuenta, toda su data de llamadas sera guardado en Dropbox.</span>-->
                </div>
            </div>
        </div>
        <div class="ex-text">
            <span>Conectar su cuenta CASCO a uno de estos proveedores para guardar su data seguramente.</span>
        </div>
        <div>
            <validation-summary [validationErrors]="validationMessages"></validation-summary>
        </div>
    </div>
</div>
