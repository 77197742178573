<div class="authentication-container">
  <div class="container">
    <div class="auth-content code-auth">
      <div class="logo-block">
        <img src="../../../../assets/images/auth-logo.svg" />
      </div>
      <div class="auth-title">
        <span>Solicitar Codigo de Activacion</span>
      </div>
      <div class="auth-text">
        <span>Ingresar su correo electronico y le enviaremos un codigo de activacion. </span>
      </div>
      <form ngNativeValidate #codeResendForm="ngForm">
        <div class="auth-block">
          <div class="row">
            <div class="col-sm-12 col-md-12">
              <div class="input-block extra">
                <input type="text" class="form-control" placeholder="Correo electronico" name="emailId" [(ngModel)]="emailId"
                       pattern="[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,3}$" />
              </div>
            </div>
          </div>
        </div>
        <div>
          <validation-summary [validationErrors]="validationMessages"></validation-summary>
        </div>
        <div class="button-block">
          <button class="btn comm-btn" (click)="resendActivationCode()">Aplicar</button>
        </div>
      </form>
    </div>
  </div>
</div>
