import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { SharedDataService } from '../../../services/sharedData.service';
import { DashboardService } from '.././../../api-services/dashboard.service';
import { iDayViewCount } from '../../../models/dashboard.model';

@Component({
  selector: 'app-week-data',
  templateUrl: './week-data.component.html',
  styleUrls: ['./week-data.component.css']
})
export class WeekDataComponent implements OnInit {

  constructor(private router: Router,
    private service: DashboardService,
    private sharedService: SharedDataService) { }

  adjusterId?: number;
  weeklyCount?: iDayViewCount = {};
  monthlyCount?: iDayViewCount = {};

  ngOnInit(): void {
    this.adjusterId = this.sharedService.getValue("accountId");
    //this.getWeeklyCallCount();
    this.getMonthlyCallCount();

    this.sharedService.rescheduleFlag.subscribe((newItem) => {
      if (newItem) {
        this.getMonthlyCallCount();
      }
    });
  }

  //Method to get weekly call counts
  getWeeklyCallCount() {
    this.service.getWeeklyCallCount(this.adjusterId).subscribe(result => {
      if (result != null) {
        this.weeklyCount = <iDayViewCount>result;
      }
    }, error => {
    });
  }

  //Method to get monthly call counts
  getMonthlyCallCount() {
    this.service.getMonthlyCallCount(this.adjusterId, this.sharedService.getTodayUTCDateAsString(), this.sharedService.getTimezonOffset()).subscribe(result => {
      if (result != null) {
        this.monthlyCount = <iDayViewCount>result;
      }
    }, error => {
    });
  }

  ngOnDestroy(): void {
    //this.sharedService.rescheduleFlag.unsubscribe();
  }

}
