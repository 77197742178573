import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { SharedDataService } from './services/sharedData.service';
import { Router } from '@angular/router';
import { User } from './models/user';
import { AuthService } from './services/auth.service';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.css']
})

export class AppComponent implements OnInit {
    title = 'Remote Adjuster';
    showloader: boolean;
    private subscription;
    currentUser: User;
    isUserLoggedIn: Observable<boolean>;

    constructor(private sharedService: SharedDataService,
        private router: Router, private authService: AuthService) { //this.authService.logout();
        this.isUserLoggedIn = new Observable();        
    }

    ngOnInit() {
        this.isUserLoggedIn = this.authService.isLoggedInObservable();
        this.subscription = this.sharedService.loader.subscribe(obj => {
            setTimeout(() => this.showloader = obj, 0)
        });
    }

    ngOnDestroy() {
        this.subscription.unsubscribe();
    }

    hasRoute(route: string) {
        return this.router.url.includes(route);
      }
}