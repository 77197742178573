<div class="authentication-container">
  <div class="container">
    <div class="auth-content confirm-auth">
      <div class="logo-block">
        <img src="../../../../assets/images/auth-logo.svg" />
      </div>
      <div class="auth-title">
        <span>Bienvenido a CASCO Safety</span>
      </div>
      <div class="confirm-text">
        <span>Su correo electr&#243;nico es: <a>{{emailId}}</a></span>
      </div>
      <form>
        <div class="auth-block">
          <div class="row">
            <div class="col-sm-6 col-md-6">
              <div class="input-block">
                <input type="password" class="form-control" placeholder="Contrase&#241;a" [(ngModel)]="passwordData.password" (input)="checkPasswordFormat()"
                       id="password" name="password" maxlength="50" (focus)="focusFunction()" (keypress)="restrictSpace($event)"/>
                <div class="password-rules">
                  <div class="text-auth" [ngClass]="{'success': letterFlag}" *ngIf="showPasswordFormat">
                    <img class="cross" src="../../../../assets/images/auth-error-cross.svg" />
                    <img class="tick" src="../../../../assets/images/auth-error-tick.svg" />
                    <span class="rules-info">Requiere una letra Mayuscula | Miniscula</span>
                  </div>
                  <div class="text-auth" [ngClass]="{'success': digitFlag}" *ngIf="showPasswordFormat">
                    <img class="cross" src="../../../../assets/images/auth-error-cross.svg" />
                    <img class="tick" src="../../../../assets/images/auth-error-tick.svg" />
                    <span class="rules-info">Requiere un digito 0-9</span>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-sm-6 col-md-6">
              <div class="input-block">
                <input type="password" class="form-control" placeholder="Confirmar Contrase&#241;a" (focus)="focusConfirmPasswordFunction()"
                       id="cnfPassword" name="cnfPassword" maxlength="50" [(ngModel)]="confirmPassword" (input)="checkConfirmPasswordFormat()" (keypress)="restrictSpace($event)"/>
                <div class="password-rules">
                  <div class="text-auth " [ngClass]="{'success': charFlag}" *ngIf="showPasswordFormat">
                    <img class="cross" src="../../../../assets/images/auth-error-cross.svg" />
                    <img class="tick" src="../../../../assets/images/auth-error-tick.svg" />
                    <span class="rules-info">Requiere un Caracter Especial : ! @ # $ . . .</span>
                  </div>
                  <div class="text-auth" [ngClass]="{'success': lengthFlag}" *ngIf="showPasswordFormat">
                    <img class="cross" src="../../../../assets/images/auth-error-cross.svg" />
                    <img class="tick" src="../../../../assets/images/auth-error-tick.svg" />
                    <span class="rules-info">Contrase&#241;a requiere por lo menos 8 caracteres</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div>
          <validation-summary [validationErrors]="validationMessages"></validation-summary>
        </div>
        <div class="button-block">
          <button class="btn comm-btn" (click)="updatePassword()">Activar</button>
        </div>
        <!--<div class="already-status">
          <a href="/login" class="register-only">Login with different Account</a>
        </div>-->
      </form>
    </div>
  </div>
</div>
