import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { HttpParams, HttpHeaders, HttpClient } from '@angular/common/http';
import { ApiClientService } from './api-client.service';
import {
  iTenantRegistration, iConfirmActivation, iPassword, iPlanSelection, iReturnId,
  iSaveRegistrationData, iForgotPassword, iTenant, iHubSpotSaveRegistrationData
} from './../models/tenant-registration.model';
import { environment } from '../../environments/environment';
import { map, catchError, finalize } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})

export class TenantRegistrationService {

  constructor(private apiClientService: ApiClientService, private httpClient: HttpClient) { }

  public tenantRegistration(emailId: string): Observable<any> {
    let params = new HttpParams();
    params = params.append('emailId', emailId.toString());

    return this.apiClientService.getSingle<any>("tenant/tenantRegistration", undefined, params);
  }

  //public tenantRegistration(tenantRegistration: iTenantRegistration): Observable<iReturnId> {
  //  return this.apiClientService.post<iReturnId>("tenant/tenantRegistration", tenantRegistration);
  //}

  public saveRegistrationData(tenantRegistration: iSaveRegistrationData): Observable<iReturnId> {
    return this.apiClientService.post<iReturnId>("tenant/saveRegistrationData", tenantRegistration);
  }

  public updatePaymentPlan(planSelection: iPlanSelection): Observable<any> {
    return this.apiClientService.post<any>("tenant/updatePaymentPlan", planSelection);
  }

  public confirmActivationCode(confirmActivation: iConfirmActivation): Observable<any> {
    return this.apiClientService.post<any>("tenant/confirmActivationCode", confirmActivation);
  }

  public updatePassword(password: iPassword): Observable<any> {
    return this.apiClientService.post<any>("tenant/updatePassword", password);
  }

  public getRegistrationDetails(tenantId: number): Observable<any> {
    let params = new HttpParams();
    params = params.append('tenantId', tenantId.toString());

    return this.apiClientService.getSingle<any>("tenant/getRegistration", undefined, params);
  }

  public getEmailId(accountId: number): Observable<any> {
    let params = new HttpParams();
    params = params.append('accountId', accountId.toString());

    return this.apiClientService.getSingle<any>("tenant/getEmailId", undefined, params);
  }

  public confirmUserActivation(activationCode: string): Observable<any> {
    let params = new HttpParams();
    params = params.append('activationCode', activationCode.toString());

    return this.apiClientService.getSingle<any>("tenant/confirmUserActivation", undefined, params);
  }

  public resendActivationCode(emailId: string): Observable<any> {
    let params = new HttpParams();
    params = params.append('emailId', emailId.toString());

    return this.apiClientService.getSingle<any>("tenant/resendActivationCode", undefined, params);
  }

  public forgotPasswordSendActivationCode(emailId: string): Observable<any> {
    let params = new HttpParams();
    params = params.append('emailId', emailId.toString());

    return this.apiClientService.getSingle<any>("tenant/forgotPasswordSendActivationCode", undefined, params);
  }

  public updateForgotPassword(password: iForgotPassword): Observable<any> {
    return this.apiClientService.post<any>("tenant/updateForgotPassword", password);
  }

  public getTenantById(tenantId: number): Observable<any> {
    let params = new HttpParams();
    params = params.append('tenantId', tenantId.toString());

    return this.apiClientService.getSingle<any>("tenant/getTenantById", undefined, params);
  }

  public saveRegistrationDataInHubSpot(ihubSpotSaveRegistrationData: iHubSpotSaveRegistrationData): Observable<any> {

    var formData: any = new FormData();
    formData.append("appregistrationapi", ihubSpotSaveRegistrationData.appregistrationapi);
    formData.append("firstname", ihubSpotSaveRegistrationData.firstName);
    formData.append("lastname", ihubSpotSaveRegistrationData.lastName);
    formData.append("company", ihubSpotSaveRegistrationData.company);
    formData.append("phone", ihubSpotSaveRegistrationData.phone);
    formData.append("email", ihubSpotSaveRegistrationData.email);
    formData.append("type_of_account", ihubSpotSaveRegistrationData.type_of_account);

    return this.httpClient.post<any>(environment.hubspotApiUrl, formData).pipe(
      map(res => res)
    );
  }
}
