import { BrowserModule } from '@angular/platform-browser';
import { NgModule, ErrorHandler, LOCALE_ID } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HttpClientModule } from '@angular/common/http';
import { SharedDataService } from './services/sharedData.service';
import { LoginComponent } from './components/before-login/login/login.component';
import { RegistrationComponent } from './components/before-login/registration/registration.component';
import { AccountSelectionComponent } from './components/before-login/registration/account-selection/account-selection.component';
import { ConfirmPasswordComponent } from './components/before-login/confirm-password/confirm-password.component';

import { HTTP_INTERCEPTORS, HttpClient } from '@angular/common/http';
import { AuthInterceptor } from '../app/services/authInterceptor.service'
import { HttpClientService, httpClientServiceCreator } from '../app/services/httpclient.service';
import { ErrorpageComponent } from './components/errorpage/errorpage.component'
import { ErrorsHandler } from './error-handler/error-handler';
import { RequestVerificationCodeComponent } from './components/before-login/request-verification-code/request-verification-code.component';
import { VerificationCodeConfirmationComponent } from './components/before-login/verification-code-confirmation/verification-code-confirmation.component';
import { DashboardComponent } from './components/dashboard/dashboard.component';
import { SideBarComponent } from './components/side-bar/side-bar.component';
import { HeaderComponent } from './components/header/header.component';
import { OverviewStatsComponent } from './components/dashboard/overview-stats/overview-stats.component';
import { WeeklyStatsComponent } from './components/dashboard/weekly-stats/weekly-stats.component';
import { YourTeamComponent } from './components/dashboard/your-team/your-team.component';
import { CallHistoryComponent } from './components/dashboard/call-history/call-history.component';
import { CalendarViewComponent } from './components/dashboard/calendar-view/calendar-view.component';
import { FormsModule } from '@angular/forms';
import { ApiClientService } from './api-services/api-client.service';
import { VideoCallService } from './api-services/video-call.service';
import { ClaimCaseService } from './api-services/claim-case.service';
import { TenantRegistrationService } from './api-services/tenant-registration.service';
import { SettingService } from './api-services/setting.service';
import { ImportBatchService } from './api-services/import-batch.service';

import { AuthGuard } from './services/auth.guard';
import { AuthService } from './services/auth.service';
import { ValidationSummaryComponent } from './components/common/validation-summary.component';
import { BacklogComponent } from './components/backlog/backlog.component';
import { CallScheduleComponent } from './components/call-schedule/call-schedule.component';
import { AddClaimComponent } from './components/add-claim/add-claim.component';
import { VideoCallComponent } from './components/video-call/video-call.component';
import { ReviewComponent } from './components/review/review.component';
import { OwlDateTimeIntl, OwlDateTimeModule, OwlNativeDateTimeModule, OWL_DATE_TIME_LOCALE } from 'ng-pick-datetime';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CallHistoryDetailsComponent } from './components/call-history-details/call-history-details.component';
import { BacklogDetailsComponent } from './components/backlog-details/backlog-details.component';
import { DatePipe } from '@angular/common';
import { MainSettingsComponent } from './components/main-settings/main-settings.component';
import { SettingsComponent } from './components/main-settings/settings/settings.component';
import { ManageUserComponent } from './components/main-settings/manage-user/manage-user.component';
import { IntegrationComponent } from './components/main-settings/integration/integration.component';
import { ImportBatchComponent } from './components/backlog/import-batch/import-batch.component';
import { ImportBatchListComponent } from './components/backlog/import-batch-list/import-batch-list.component';
import { ActivateAccountComponent } from './components/before-login/registration/activate-account/activate-account.component';
import { CodeRequestComponent } from './components/before-login/registration/code-request/code-request.component';
import { ClipboardModule } from '@angular/cdk/clipboard'
import { WeekDataComponent } from './components/dashboard/week-data/week-data.component';
import { TotalCallsComponent } from './components/dashboard/total-calls/total-calls.component';
import { InviteUserComponent } from './components/before-login/registration/invite-user/invite-user.component';
import { FullCalenderViewComponent } from './components/full-calender-view/full-calender-view.component';
import { ActivateComponent } from './components/before-login/registration/activate/activate.component'
import { RoleGuard } from './services/role.guard.service';
import { ResetPasswordComponent } from './components/main-settings/reset-password/reset-password.component';
import { PasswordChangedSuccessComponent } from './components/before-login/password-changed-success/password-changed-success.component';
import { ToastrModule } from 'ngx-toastr';
import { NumberOnlyDirective } from './directives/app-number-only.directive';
import { TwoDigitDecimaNumberDirective } from './directives/app-decimal-only.directive';
import { LocalizedDateTimePipe, LocalizedDateTimePipeFormat } from './pipes/localized-date-time.pipe';
import { ConversationComponent } from './components/dashboard/conversation/conversation.component';
import { RescheduleComponent } from './components/dashboard/reschedule/reschedule.component';
import { ImportGuard } from './services/import.guard.service';
import { ConversationFullViewComponent } from './components/conversation-full-view/conversation-full-view.component';
import { TermsConditionsComponent } from './components/main-settings/terms-conditions/terms-conditions.component';
import { AngularEditorModule } from '@kolkov/angular-editor';
import { ConfigurationComponent } from './components/main-settings/configuration/configuration.component';
import { PaymentSettingComponent } from './components/main-settings/payment-setting/payment-setting.component';
import { MyPaymentComponent } from './components/main-settings/my-payment/my-payment.component';
import { PaymentSuccessfulComponent } from './components/payment-successful/payment-successful.component';

import { registerLocaleData } from '@angular/common';
import localeEs from '@angular/common/locales/es';
import { ContactFormComponent } from './components/contact-form/contact-form.component';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { ContactUsDetailService } from './api-services/contactus-detail.service';
import { GettingStardedComponent } from './components/new-contact-us-flow/getting-starded/getting-starded.component';
import { ThankYouForRegistrationComponent } from './components/new-contact-us-flow/thank-you-for-registration/thank-you-for-registration.component';
import { PrivacyPolicyComponent } from './components/privacy-policy/privacy-policy.component';
import { TermConditionsComponent } from './components/term-conditions/term-conditions.component';
import { FaqComponent } from './components/faq/faq.component';

registerLocaleData(localeEs, 'es');

@NgModule({
  declarations: [
    AppComponent,
    ErrorpageComponent,
    LoginComponent,
    RegistrationComponent,
    AccountSelectionComponent,
    ConfirmPasswordComponent,
    RequestVerificationCodeComponent,
    VerificationCodeConfirmationComponent,
    DashboardComponent,
    SideBarComponent,
    HeaderComponent,
    OverviewStatsComponent,
    WeeklyStatsComponent,
    YourTeamComponent,
    CallHistoryComponent,
    CalendarViewComponent,
    ValidationSummaryComponent,
    BacklogComponent,
    CallScheduleComponent,
    AddClaimComponent,
    VideoCallComponent,
    ReviewComponent,
    CallHistoryDetailsComponent,
    BacklogDetailsComponent,
    MainSettingsComponent,
    SettingsComponent,
    ManageUserComponent,
    IntegrationComponent,
    ImportBatchComponent,
    ImportBatchListComponent,
    ActivateAccountComponent,
    CodeRequestComponent,
    WeekDataComponent,
    TotalCallsComponent,
    InviteUserComponent,
    FullCalenderViewComponent,
    ActivateComponent,
    ResetPasswordComponent,
    PasswordChangedSuccessComponent,
    NumberOnlyDirective,
    TwoDigitDecimaNumberDirective,
    LocalizedDateTimePipe,
    ConversationComponent,
    RescheduleComponent,
    ConversationFullViewComponent,
    TermsConditionsComponent,
    ConfigurationComponent,
    PaymentSettingComponent,
    MyPaymentComponent,
    PaymentSuccessfulComponent,
    ContactFormComponent,
    GettingStardedComponent,
    ThankYouForRegistrationComponent,
    PrivacyPolicyComponent,
    TermConditionsComponent,
    FaqComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    FormsModule,
    BrowserAnimationsModule,
    OwlDateTimeModule,
    OwlNativeDateTimeModule,
    
    ClipboardModule,
    AngularEditorModule,
    ToastrModule.forRoot(),
    NgMultiSelectDropDownModule.forRoot()
  ],
  providers: [
    { provide: LOCALE_ID, useValue: 'es' },
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
    ApiClientService,
    AuthGuard,
    RoleGuard,
    ImportGuard,
    AuthService,
    VideoCallService,
    HttpClientService,
    ClaimCaseService,
    SharedDataService,
    DatePipe,
    TenantRegistrationService,
    SettingService,
    ImportBatchService,
    OwlDateTimeIntl,
    LocalizedDateTimePipe,
    ContactUsDetailService,
    LocalizedDateTimePipeFormat
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
