<div class="config-content">
    <div class="config-block">
        <div class="config-top">
            <span class="config-title">Configuraci&#243;n de Tipo de Casos</span>
            <button data-target="#add-type" data-toggle="modal" class="btn comm-btn">Agregar Nuevo</button>
            <div class="clearfix"></div>
        </div>
        <div class="config-btm">
            <div class="config-desc">
                <!--*ngIf="showGrid"-->
                <div class="config-sub-title">
                    <span class="type-only">ID Tipo de Caso</span>
                    <span class="desc-only">Descripci&#243;n</span>
                    <span class="dt-only">Fecha & Hora</span>
                    <span class="status-only">Activo</span>
                </div>
                <div class="clear"></div>
                <div class="config-main-block">

                    <div>
                        <div *ngFor="let item of lossTypeList; let i = index">
                            <div class="config-info">
                                <span class="type-btm">
                          {{item.userLossTypeIdString}}
                      </span>
                                <span class="desc-btm-part" title="{{item.lossType}}">
                          {{item.lossType}}
                      </span>
                                <span class="dt-btm-part">
                          {{item.createdDate | date:'dd/MM/yy h:mm a'}} 
                      </span>
                                <span class="status-btm-part">
                          <label class="container-dot">
                              <input type="checkbox" checked="checked" [(ngModel)]="item.isActive" (click)="openRemoveModal(item)">
                              <span class="checkmark"></span>
                                </label>
                                </span>
                            </div>
                            <div class="clear"></div>
                        </div>
                    </div>
                    <!--<div class="dummy-block" *ngIf ="!showGrid">
                <div class="overlay"></div>
                <div class="config-info">
                    <span class="type-btm">
                        xxxxx
                    </span>
                    <span class="desc-btm-part">
                        Water Damage
                    </span>
                    <span class="dt-btm-part">
                        07 / 10 / 20 &nbsp;&nbsp; 5:39 PM
                    </span>
                    <span class="status-btm-part">
                        <label class="container-dot">
                            <input type="checkbox" checked="checked">
                            <span class="checkmark"></span>
                        </label>
                    </span>
                </div>
                <div class="clear"></div>
                <div class="config-info">
                    <span class="type-btm">
                        xxxxx
                    </span>
                    <span class="desc-btm-part">
                        Fire Damage
                    </span>
                    <span class="dt-btm-part">
                        08 / 11 / 20 &nbsp;&nbsp; 6:39 PM
                    </span>
                    <span class="status-btm-part">
                        <label class="container-dot">
                            <input type="checkbox" checked="checked">
                            <span class="checkmark"></span>
                        </label>
                    </span>
                </div>
                <div class="clear"></div>
                <div class="config-info">
                    <span class="type-btm">
                        xxxxx
                    </span>
                    <span class="desc-btm-part">
                        Fire Damage
                    </span>
                    <span class="dt-btm-part">
                        08 / 11 / 20 &nbsp;&nbsp; 6:39 PM
                    </span>
                    <span class="status-btm-part">
                        <label class="container-dot">
                            <input type="checkbox" checked="checked">
                            <span class="checkmark"></span>
                        </label>
                    </span>
                </div>
                <div class="clear"></div>
            </div>-->
                </div>
            </div>
        </div>
        <!--<div class="btm-sec">
        <button class="btn comm-btn cancel">Cancel</button>
        <button class="btn comm-btn">Save</button>
    </div>
    <div class="clear"></div>-->
        <div>
            <validation-summary [validationErrors]="validationMessages"></validation-summary>
        </div>
    </div>
</div>

<div class="modal fade add-type" id="add-type" data-backdrop="static" aria-hidden="true" role="dialog">
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-header pop-title">
                <button type="button" class="close" (click)="closeAddModal()">
          <img src="../../../../assets/images/modal-close-img.svg" />
        </button>
                <h4 class="modal-title">
                    <span>Agregar Tipo de Caso</span>
                </h4>
            </div>
            <div class="modal-body">
                <div class="remove-content authentication-container">
                    <div class="row">
                        <div class="col-md-3">
                            <div class="input-block">
                                <label class="input-label">ID Tipo de Caso</label>
                                <input appNumberOnly type="text" class="form-control" placeholder="Ingresar ID" [(ngModel)]="newLossType.userLossTypeId" />
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="input-block">
                                <label class="input-label">Descripci&#243;n</label>
                                <input type="text" class="form-control" placeholder="Ingresar Descripcion" maxlength="300" [(ngModel)]="newLossType.lossType" />
                            </div>
                        </div>
                        <div class="col-md-3">
                            <button class="btn comm-btn" (click)="addLossType()">Agregar</button>
                        </div>
                    </div>
                </div>
                <div>
                    <validation-summary [validationErrors]="validationMessagesAdd"></validation-summary>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="modal fade remove-type" id="remove-type" data-backdrop="static" aria-hidden="true" role="dialog">
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-header pop-title">
                <button type="button" class="close" (click)="closeRemoveModal()">
          <img src="../../../../assets/images/modal-close-img.svg" />
        </button>
                <h4 class="modal-title">
                    <span>Desactivar Tipo de Caso</span>
                </h4>
            </div>
            <div class="modal-body">
                <div class="remove-content">
                    <span class="rmv-btm">Esta seguro que quiere desactivar <br /> tipo de caso <strong>{{activeDeactiveLossType.lossType}}</strong>?</span>
                    <div class="btn-section">
                        <button class="btn comm-btn" (click)="activeDeActiveLossType()">Si</button>
                        <button class="btn comm-btn cancel" (click)="closeRemoveModal()">No</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>