import { Injectable } from "@angular/core";
import { HttpErrorResponse, HttpHeaders } from "@angular/common/http";
import { of as observableOf, throwError, Subject, BehaviorSubject, Observable } from "rxjs";
import { map, catchError, delay } from "rxjs/operators";
import { ApiClientService } from "./../api-services/api-client.service";

import * as jwt_decode from "jwt-decode";
import { SharedDataService } from "./sharedData.service";
import { Router } from "@angular/router";
import { environment } from "./../../environments/environment";
import { AuthModel } from "./../models/auth.model";

@Injectable({
  providedIn: "root",
})
export class AuthService {
  errorData: { errorTitle: string; errorDesc: string };
  loggedIn: BehaviorSubject<boolean> = new BehaviorSubject(false);
  public isUserLoggedIn: BehaviorSubject<boolean> = new BehaviorSubject(localStorage.getItem("currentUser") ? true : false);
  redirectUrl: string;

  constructor(private router: Router, private apiClientService: ApiClientService, private sharedService: SharedDataService) { }
 
  login(emailAddress: string, password: string) {
    var data = "username=" + emailAddress + "&password=" + encodeURIComponent(password) + "&grant_type=password";
    var reqHeader = new HttpHeaders({ 'Content-Type': 'text/plain' });
    return this.apiClientService.post("token", data, reqHeader).pipe(
      map(res => res),
      catchError(this.handleError)
    );
  }

  getToken(authRequest: AuthModel) {
    return this.apiClientService.post("tenant/token", authRequest).pipe(
      map((outPut) => {
        if (outPut) {
          var token = outPut as any;
          var decoded = jwt_decode(JSON.stringify(token.access_token));
          if (decoded) {
            this.isUserLoggedIn.next(true);
            this.setInitialLocalStorage(outPut);
          }
        }
      }),
      catchError(err => {
        return throwError(err.error);
      }),
    );
  }

  setInitialLocalStorage(completeToken) {

    this.sharedService.setLocalStorage('currentUser', JSON.stringify(completeToken));
    this.sharedService.setLocalStorage('token', JSON.stringify(completeToken.access_token));

    var decoded = jwt_decode(JSON.stringify(completeToken.access_token));
    this.sharedService.setLocalStorage('accountId', decoded.accountId);
    this.sharedService.setLocalStorage('userRole', decoded.userRole);
    this.sharedService.setLocalStorage('tenantId', decoded.tenantId);

    //console.log("New token value : " + JSON.stringify(completeToken.access_token))
  }

  setTokenPropertiesInLocalStorage(completeToken) {

    //console.log("complete token : " + completeToken);
    //console.log("refreshToken strigify : " + completeToken.refresh_token);
    //console.log("token_issueTime : " + completeToken[".issued"]);
    //console.log("token_expireTime : " + completeToken[".expires"]);

    this.sharedService.setLocalStorage("refreshToken", JSON.stringify(completeToken.refresh_token));

    // This will use for bot access_token & refresh_token
    this.sharedService.setLocalStorage("token_issueTime", completeToken[".issued"]);
    this.sharedService.setLocalStorage("token_expireTime", completeToken[".expires"]);

    //console.log("decoded : " + decoded);


    var decoded = jwt_decode(JSON.stringify(completeToken.access_token));
    this.sharedService.setLocalStorage("refreshTokenTimeOut", decoded.refreshTokenTimeOut);
    //console.log("new refreshTokenTimeOut : " + decoded.refreshTokenTimeOut);
  }

  isUserAlreadyLoggedIn() {
    if (this.sharedService.getValue("token")) {
      return true;
    }
    return false;
  }

  redirectToMainPageIfUserIsLoggedIn() {
    if (this.isUserAlreadyLoggedIn()) {
      this.router.navigate(["dashboard"]);
    }
  }

  redirectToMainPage(response) {
    var token = response as any;
    var decoded = jwt_decode(JSON.stringify(token.access_token));
    //console.log(JSON.stringify(token.access_token));
    this.setTokenPropertiesInLocalStorage(token);

    if (decoded.authKey) {
      var httpVal = decoded.isSecure == 'True' ? 'https://' : 'http://';
      window.location.href = httpVal + decoded.domainName + environment.mainDomain + 'login?key=' + decoded.authKey;
    }
    else {
      this.isUserLoggedIn.next(true);
      this.setTokenPropertiesInLocalStorage(response);
      this.setInitialLocalStorage(response);
      this.router.navigate(["/dashboard"]);
    }
  }

  isLoggedInObservable() {
    return this.isUserLoggedIn.asObservable();
  }

  getAuthorizationToken() {
    return JSON.parse(this.sharedService.getValue("token"));
  }

  logout() {
    localStorage.clear();
    this.isUserLoggedIn.next(false);
  }

  // Generate access & refresh token and set into local storage
  refreshToken(): Observable<any> {
    var data ="grant_type=refresh_token&refresh_token=" + this.getRefereshToken();
    var reqHeader = new HttpHeaders({ "Content-Type": "text/plain" });
    return this.apiClientService.post("token", data, reqHeader).pipe(
      map((res) => res),
      catchError(err => {
        return throwError(err.error);
      }),
    );
  }

  isAccessTokenExpired(): boolean {
    var isTokenExpired = true;
    var _tokenExpireDateTime = new Date(this.getTokenExpireTime());
    //console.log("Access token will expire on ? : " + _tokenExpireDateTime);
    var _currentUTCTime = new Date();
    isTokenExpired = (_tokenExpireDateTime < _currentUTCTime);
    return isTokenExpired;
  }

  isRefreshTokenExpired(): boolean {
    var isRefreshTokenExpired = true;
    var refreshTokenTimeOutMinutes = this.getRefreshTokenExpireTime();

    var _tokenExpireDateTime = new Date(this.getTokenExpireTime());
    var _refreshTokenExpireDateTime = new Date(_tokenExpireDateTime.getTime() + refreshTokenTimeOutMinutes * 60000);
   
    //console.log("Refresh token will expire on ? : " + _refreshTokenExpireDateTime);
    var _currentUTCTime = new Date();
    isRefreshTokenExpired = (_refreshTokenExpireDateTime < _currentUTCTime);
    return isRefreshTokenExpired;
  }

  getRefereshToken() {
    return JSON.parse(this.sharedService.getValue("refreshToken"));
  }

  getTokenIssueTime() {
    return this.sharedService.getValue("token_issueTime");
  }

  getTokenExpireTime() {
    return this.sharedService.getValue("token_expireTime");
  }

  getRefreshTokenExpireTime() {
    return this.sharedService.getValue("refreshTokenTimeOut");
  }

  private handleError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error("An error occurred:", error.error.message);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong.
      console.error(
        `Backend returned code ${error.status}, ` + `body was: ${error.error}`
      );
    }

    // return an observable with a user-facing error message
    this.errorData = {
      errorTitle: "¡UPS! La solicitud de inicio de sesión falló",
      errorDesc: "La información de inicio de sesión no es válida.",
    };
    return throwError(this.errorData);
  }
}
