<div class="container-area">
  <div class="extra-one">
    <div class="content-area bg-content">
      <div class="import-batch">
        <div class="only-title">
          <span>Lote de Importaci&#243;n</span>
        </div>
        <div class="process-top" *ngIf="!isFileSelected">
          <div class="upload-block">
            <div class="upload-area" draggable="true">
              <div class="upload-img">
                <img src="../../../../assets/images/upload-file.svg" />
              </div>
              <div class="text-part">
                <span class="top">Arrastrar & Soltar Archivo</span>
                <span class="btm">O</span>
              </div>
              <label>
                <span class="input-section">
                  <input type="file" id="browseNew" style="display:none" (change)="fileEvent($event.target.files)">
                    Buscar
                </span>
              </label>
            </div>
          </div>
          <div class="notes-section">
            <span>Nota: Por favor seguir el orden de campos a como son suministrados o simplemente descargar <img src="../../../../assets/images/excel-img.svg" /> <a (click)="DownloadTemplate()"> Sample.xlsx</a> </span>
          </div>
          <div class="button-block">
            <button class="btn comm-btn" (click)="cancelClick()">Cancelar</button>
          </div>
        </div>

        <div class="process-section" *ngIf="isFileSelected">
          <div class="row">
            <div class="col-md-2">
              <div class="img-section">
                <img src="../../../../assets/images/excel-img.svg" />
              </div>
            </div>
            <div class="col-md-8 pad-left">
              <div class="file-name">
                <span>{{uploadedFileName}}</span>
              </div>
            </div>
            <div class="col-md-2">
              <img class="cross" src="../../../../assets/images/excel-file-close.svg" (click)="cancelUploading()" />
            </div>
          </div>
          <div class="progress-block">
            <span style="width: {{percentage}}%"></span>
          </div>
          <div class="process-down">
            <img src="../../../../assets/images/excel-down.svg" />
          </div>
        </div>
      </div>
    </div>
    <div class="error-only-msg" *ngFor="let error of validationMessages">
      <span>{{ error }} </span>
    </div>
  </div>
</div>
