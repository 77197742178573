<div class="container-area">
    <div class="extra-one">
        <div class="content-area bg-content">
            <div class="backlog-details-container">
                <a class="back" href="/backlog"><img src="../../../assets/images/back-btn.svg" /></a>
                <div class="backlog-desc-content">
                    <div class="row">
                        <div class="col-md-2">
                            <span class="bd-top">Número de Ticket</span>
                            <span class="bd-bottom">FL01-123434</span>
                        </div>
                        <div class="col-md-2">
                            <span class="bd-top">Policy Number</span>
                            <span class="bd-bottom">CGFE456845</span>
                        </div>
                        <div class="col-md-3">
                            <span class="bd-top">Loss Type</span>
                            <span class="bd-bottom">Water Leakage</span>
                        </div>
                        <div class="col-md-2">
                            <span class="bd-top">Loss Date</span>
                            <span class="bd-bottom">03/22/2019</span>
                        </div>
                        <div class="col-md-3">
                            <span class="bd-top">Call Status</span>
                            <span class="bd-bottom">04/25/2020 &nbsp; 3:00PM</span>
                        </div>
                    </div>
                </div>
                <div class="backlog-desc-content">
                    <div class="row">
                        <div class="col-md-2">
                            <span class="bd-top">Name</span>
                            <span class="bd-bottom">Andy Smith</span>
                        </div>
                        <div class="col-md-2">
                            <span class="bd-top">Phone No.</span>
                            <span class="bd-bottom">+1 444-456-126</span>
                        </div>
                        <div class="col-md-3">
                            <span class="bd-top">Email address</span>
                            <span class="bd-bottom">andysmith11@rdg.com</span>
                        </div>
                        <div class="col-md-5">
                            <span class="bd-top">Address</span>
                            <span class="bd-bottom">A 101 Roosevelt, Alkmlas. Orlando, Florida, 34994</span>
                        </div>
                    </div>
                </div>
                <div class="bottom-section">
                    <div class="row">
                        <div class="col-md-3">
                            <span class="title-info">Call History</span>
                            <ul>
                                <li>04/25/2020 &nbsp; 3:00PM</li>
                                <li>04/27/2020 &nbsp; 2:30PM</li>
                            </ul>
                        </div>
                        <div class="col-md-2">
                            <div class="details-desc-part">
                                <ul>
                                    <li><a class="details-bd" href="#">View Details</a></li>
                                    <li><a class="details-bd" href="#">View Details</a></li>
                                </ul>
                            </div>
                        </div>
                        <div class="col-md-3">
                            <span class="title-info">Logs</span>
                            <ul>
                                <li>04/25/2020 &nbsp; 3:00PM</li>
                                <li>04/27/2020 &nbsp; 2:30PM</li>
                            </ul>
                        </div>
                        <div class="col-md-4"></div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>