import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { Router, Data } from '@angular/router';
import { SettingService } from './../../../api-services/setting.service';
import { SharedDataService } from './../../../services/sharedData.service';
import { iGetInvoice, iGetPaidInvoice } from '../../../models/tenant-payment-info.model';
import { iDropDown } from '../../../models/setting.model';
import { ToastrService } from 'ngx-toastr';
import { loadStripe, Stripe } from '@stripe/stripe-js';
import { environment } from '../../../../environments/environment';
import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
import { Constants } from '../../../shared/constant';
pdfMake.vfs = pdfFonts.pdfMake.vfs;

declare var $: any;

@Component({
  selector: 'app-my-payment',
  templateUrl: './my-payment.component.html',
  styleUrls: ['./my-payment.component.css']
})
export class MyPaymentComponent implements OnInit {

  constructor(private router: Router,
    private service: SettingService,
    private sharedService: SharedDataService,
    private toastr: ToastrService) { }

  validationMessages: any;
  pendingInvoiceList?: iGetInvoice[] = [];
  paidInvoiceData?: iGetPaidInvoice = {};
  paidInvoiceList?: iGetInvoice[] = [];
  lastInvoiceId?: number = 0;
  tenantId?: number;
  yearList?: iDropDown[] = [];
  selectedYear?: iDropDown = {};
  showPending?: boolean = true;
  selectedInvoiceId?: string[] = [];
  totalPayAmount: number = 0;
  sessionId: string;
  stripe: any;
  totalPaidInvoices?: number;
  showLoadMore?: boolean = true;
  selectAllPendingInvoices: boolean = false;

  @Output() pendingCountEmitter?: EventEmitter<any> = new EventEmitter<any>();

  ngOnInit(): void {
    this.validationMessages = [];
    this.tenantId = this.sharedService.getValue("tenantId");
    this.getPendingInvoices();
    this.getYearList();
  }

  refreshMethod() {
    this.validationMessages = [];
    this.tenantId = this.sharedService.getValue("tenantId");
    this.getPendingInvoices();
    this.getYearList();
  }

  //Method to get all pending invoices
  getPendingInvoices() {
    this.service.getPendingInvoices(this.tenantId, this.sharedService.getTimezonOffset()).subscribe(result => {
      if (result != null) {
        this.pendingInvoiceList = <iGetInvoice[]>result;
        this.pendingCountEmitter.emit(this.pendingInvoiceList.length);

        if (this.pendingInvoiceList.length == 0)
          this.showPending = false;
        else
          this.showPending = true;
      }
    }, error => {
      this.populateApiErrorMessages(error.message);
    });
  }

  //Method to add or remove invoice id
  addRemoveInvoiceId(event, invoiceNumber, amount) {
    if (event.target.checked) {
      this.selectedInvoiceId.push(invoiceNumber.toString());
      this.totalPayAmount = this.totalPayAmount + parseInt(amount);
      this.pendingInvoiceList.forEach(obj => {
        if (obj.invoiceNumber == invoiceNumber)
          obj.isInvoiceSelected = true;
      });

      if (this.pendingInvoiceList.length == this.selectedInvoiceId.length)
        this.selectAllPendingInvoices = true;

    }
    else {
      this.selectAllPendingInvoices = false;
      this.totalPayAmount = this.totalPayAmount - parseInt(amount);
      var index = this.selectedInvoiceId.indexOf(invoiceNumber);
      this.selectedInvoiceId.splice(index, 1);

      this.pendingInvoiceList.forEach(obj => {
        if (obj.invoiceNumber == invoiceNumber)
          obj.isInvoiceSelected = false;
      });
    }
  }

  //Select All invoices
  selectAllPendingInvoicesMethod(event) {
    this.selectedInvoiceId = [];
    if (event.target.checked) {
      this.selectAllPendingInvoices = true;
      if (this.pendingInvoiceList != undefined && this.pendingInvoiceList.length > 0) {
        for (var i = 0; i < this.pendingInvoiceList.length; i++) {
          this.selectedInvoiceId.push(this.pendingInvoiceList[i].invoiceNumber);
          this.pendingInvoiceList[i].isInvoiceSelected = true;
          this.totalPayAmount = this.totalPayAmount + this.pendingInvoiceList[i].totalAmount;
        }
      }
    }
    else {
      this.totalPayAmount = 0;
      this.selectAllPendingInvoices = false;
      if (this.pendingInvoiceList != undefined && this.pendingInvoiceList.length > 0) {
        for (var i = 0; i < this.pendingInvoiceList.length; i++) {
          this.pendingInvoiceList[i].isInvoiceSelected = false;
        }
      }
    }
  }

  //Method to populate year drop down
  getYearList() {
    var currentYear = new Date().getFullYear().toString();
    this.service.getYearList(currentYear).subscribe(result => {
      if (result != null) {
        this.yearList = <iDropDown[]>result;
        this.selectRecent();
        this.getPaidInvoices();
      }
    }, error => {
      this.populateApiErrorMessages(error.message);
    });
  }

  //Method to select year
  selectYear(data?: any) {
    this.selectedYear = {};
    this.selectedYear = data;
    this.lastInvoiceId = 0;
  }

  //Method to select recent
  selectRecent() {
    this.selectedYear = {};
    this.selectedYear.id = 0;
    this.selectedYear.name = "Recent";
    this.lastInvoiceId = 0;
  }

  //Method to get all paid invoices
  getPaidInvoices() {
    this.service.getPaidInvoices(this.tenantId, this.sharedService.getTimezonOffset(), this.lastInvoiceId, this.selectedYear.id).subscribe(result => {
      if (result != null) {
        this.paidInvoiceData = <iGetPaidInvoice>result;
        this.paidInvoiceList.push(...this.paidInvoiceData.paidList);

        if (this.lastInvoiceId == 0)
          this.totalPaidInvoices = this.paidInvoiceData.totalPaidCount;

        this.lastInvoiceId = this.paidInvoiceData.lastInvoiceId;

        if (this.paidInvoiceList.length == this.totalPaidInvoices)
          this.showLoadMore = false;
        else
          this.showLoadMore = true;
      }
    }, error => {
      this.populateApiErrorMessages(error.message);
    });
  }

  populateApiErrorMessages(message: string) {
    this.validationMessages.push(message);
  }

  configureSessionWithInvoices() {
    this.service.createCheckoutSession(this.selectedInvoiceId, this.totalPayAmount).subscribe(result => {      
      this.sessionId = result.id;
      if (this.sessionId != null) {
        this.makeStripePayment();
      }
    }, error => {
      this.populateApiErrorMessages(error.error);
    });
  }

  makeStripePayment() {
    loadStripe(environment.stripe_publish_key).then(stripe => {
      this.stripe = stripe;
      this.stripe.redirectToCheckout({
        sessionId: this.sessionId
      }).then(obj => {
        if (obj.error) {
          console.log('error: ' + JSON.stringify(obj));
        }
        else if (obj.paymentIntent) {
          console.log('Payment Intent: ' + JSON.stringify(obj.paymentIntent));
        }
      });

      this.stripe.retrievePaymentIntent(environment.stripe_publish_key)
        .then(function (result) {
          // Handle result.error or result.paymentIntent
          console.log(result);
        });
    });
  }

  getInvoiceDetail(invoiceId){
    this.service.getInvoicePDF(invoiceId, this.sharedService.getTimezonOffset()).subscribe(result => {      
      
      this.generatePDF(result);
    }, error => {      
      this.populateApiErrorMessages(error.error);
    });
  }

  generatePDF(invoiceData)
  {  
    var documentDefinition = {
      info: {
        title: 'Invoice Receipt'
      },
      pageMargins: [30, 20, 0, 100],
      footer: function (page, pages) {
        return {                 
          table: {
            widths: [250,50,300],
            body: [
                [
                  { svg: Constants.lineSVG, height : 5, width : 590, colSpan: 3 },{},{}
                ],
                [ 
                  { text : 'Pay Securely at', fontSize: 12, bold: true, margin : [35, 0, 0, 0],color : '#002062' },
                  '',
                  ''],
                [
                  { text : 'Adjustify.co', fontSize: 20, bold: true, margin : [35, 0, 0, 0],color : '#002062' },
                  { text: 'Page: ' + page.toString() + '/' + pages.toString(), fontSize: 10, margin : [0, 0, 0, 0], color: '#707070', bold : true },
                  { text : 'Thank you for your business!', fontSize: 12, bold:true, alignment: 'center',color : '#002062' }
                ],
                [
                    { svg: Constants.addressSVG, width : 400, colSpan: 3, margin: [90,0,0,0] },{},{}
                ]
            ]
        },        
        layout: 'noBorders'
        };
      },
      content: [],
    };

    documentDefinition.content.push({
      alignment: 'justify',
      columns: [
        [
          {
            svg: Constants.adjustifyLogSVG ,
            margin: [-13, 0, 0, 0]
          },            
          {
            text: invoiceData.fullName,
            bold: true,
            color: '#002062',
            fontSize: 14,
            margin: [0, 2, 0, 0]
          },
          { 
            text: this.sharedService.arrangeAddress(invoiceData.addressLine1,invoiceData.addressLine2, invoiceData.addressLine3, invoiceData.city, invoiceData.stateName),
            color: '#002062',
            fontSize: 12,
            margin: [0, 0, 0, 0]
          },
          { 
            text: this.sharedService.arrangeAddress(invoiceData.zipCode, invoiceData.countryName, '','',''),
            color: '#002062',
            fontSize: 12 
          }
        ],
        [
          {
            text: 'Invoice',
            fontSize: 20,
            bold: true,
            alignment: 'left',
            color: '#002062',
            margin: [25, 12, 0, 2]
          },
          {
            text: ['Invoice No.  ', { text : invoiceData.invoiceNumber, bold: true }],
            alignment: 'left',
            fontSize: 12,
            color: '#002062',
            margin: [25, 0, 0, 2]
          },
          { 
            text: ['Date of Issue:  ', {text : invoiceData.dateOfIssueString, bold: true }],
            alignment: 'left',
            fontSize: 12,
            color: '#002062',
            margin: [25, 0, 0, 2]
          },
          { 
            text: [ 'Billing Period:  ', { text : invoiceData.billingPeriod, bold: true }],
            alignment: 'left',
            fontSize: 12,
            color: '#002062',
            margin: [25, 0, 0, 2]
          }
        ]
      ]    
    });

    var column = [];
    column.push({text : 'Número de Ticket', margin: [-5, 0, 0, 5], bold: true, fontSize : 11, color : '#002062', border: [false, false, false, false] }, 
    {text : 'Created Date', bold: true, margin: [0, 0, 0, 5], fontSize : 11, color : '#002062', border: [false, false, false, false] }, 
    {text : 'Created By', bold: true, margin: [0, 0, 0, 5], fontSize : 11, color : '#002062', border: [false, false, false, false] }, 
    {text : 'Amount', bold: true, alignment:'right', margin: [0, 0, 0, 5], fontSize : 11, color : '#002062', border: [false, false, false, false] });
      
    documentDefinition.content.push({
      alignment: 'justify',
          style: 'tableExample',
          table: {
            widths: [100, 100, 210, 90],
            headerRows: 1,
            body: this.buildTableBody(invoiceData.usageClaimList, column)            
          },
          layout:  {
            vLineColor: function (i, node) {
              return '#7E7E7E';
            },
            hLineWidth: function (i, node) {
              return 0.01;
            },
            hLineHeight: function (i, node) {
              return 2.1;
            }
          },
          margin: [0, 40, 0, 0]
        }
    );

    documentDefinition.content.push({
      alignment: 'justify',
          style: 'tableExample',
          table: {
            widths: [100, 100, 180, 30, 93],
            body: [
              ["","","","", ""],
              ["","","", {text : "Total", margin: [0,5,0,0], bold: 'true', fontSize: 11, color: '#002062', alignment: 'right' } ,{ text: `$${invoiceData.totalAmount}.00`, margin: [0,5,3,0], bold: 'true', fontSize: 11, color: '#002062', alignment: 'right' }]      
            ]},
          layout: 'noBorders'
        }
    );

    pdfMake.createPdf(documentDefinition).open();
  }

  buildTableBody(data, columns) {
    let counter = 1;
    var body = [];

    body.push(columns);

    data.forEach(function(row) {
        var dataRow = [];
        if (counter != data.length)
        {
          dataRow.push({ text : row.claimNumber, margin: [-5, 0, 0, 0], fontSize: 10, color: '#707070', border: [false, true, false, false] });
          dataRow.push({ text : row.createdDateString, margin: [0, 0, 0, 0], fontSize: 10, color: '#707070', border: [false, true, false, false] });
          dataRow.push({ text : row.createdBy, margin: [0, 0, 0, 0], fontSize: 10, color: '#707070', border: [false, true, false, false] });
          dataRow.push({ text : `$${row.amount}.00`, alignment:'right', margin: [0, 0, 3, 0], fontSize: 10, color: '#707070', border: [false, true, false, false] });
        } else {
          // This will apply for last row
          dataRow.push({ text : row.claimNumber, margin: [-5, 0, 0, 0], fontSize: 10, color: '#707070', border: [false, true, false, true] });
          dataRow.push({ text : row.createdDateString, margin: [0, 0, 0, 0], fontSize: 10, color: '#707070', border: [false, true, false, true] });
          dataRow.push({ text : row.createdBy, margin: [0, 0, 0, 0], fontSize: 10, color: '#707070', border: [false, true, false, true] });
          dataRow.push({ text : `$${row.amount}.00`, alignment:'right', margin: [0, 0, 3, 0], fontSize: 10, color: '#707070', border: [false, true, false, true] });
        }
        counter++;
        body.push(dataRow);
    });

    return body;
  }
}
