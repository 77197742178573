import { Injectable, Injector } from "@angular/core";
import { AuthService } from "./auth.service";
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent } from "@angular/common/http";
import { Router } from "@angular/router";
import { SharedDataService } from "./sharedData.service";
import { empty, Observable, Subject, BehaviorSubject, throwError } from "rxjs";
import { catchError, switchMap, take, filter } from "rxjs/operators";
import { ToastrService } from "ngx-toastr";

@Injectable()
export class AuthInterceptor implements HttpInterceptor {

  private refreshTokenInProgress = false;
  private refreshTokenSubject: Subject<any> = new BehaviorSubject<any>(null);

  public constructor(private route: Router, private sharedDataService: SharedDataService, private authService: AuthService,
    private toastr: ToastrService,
    ) { }

  public intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    if (!window.navigator.onLine) {
      // If internet connectivity is down
      this.sharedDataService.errorObject = { status: 503, message: "No Internet. Please check your network.", url: "" };
      this.route.navigate(["/errorpage"]);
      return empty();
    }
    else {
      if (this.authService.isUserAlreadyLoggedIn()) {

        const accessTokenExpired = this.authService.isAccessTokenExpired();
        const refreshTokenExpired = this.authService.isRefreshTokenExpired();

        //console.log("Access token expired ? : " + accessTokenExpired);
        //console.log("Refresh Token expired ? : " + refreshTokenExpired);

        //If accesstoken not expire, process the intercept http request
        if (!accessTokenExpired) {
          return next.handle(this.injectToken(req)).pipe(
            catchError(err => {
              this.showErrorMessage(err);
              if (err.status == '401' || err.status == '400' || err.status == '0') {
               this.authService.logout();
               this.route.navigate(['/login']);
              }
              return throwError(err);
            }),
          );;
        }

        if (req.url.indexOf("token") !== -1) {
          return next.handle(req).pipe(
            catchError(err => {
              this.showErrorMessage(err);
              if (err.status == '401' || err.status == '400' || err.status == '0') {
               this.authService.logout();
               this.route.navigate(['/login']);
              }
              return throwError(err);
            }),
          );;
        }

        //If accesstoken and refreshtoken both expired then redirect to login page
        if (accessTokenExpired && refreshTokenExpired) {
          this.refreshTokenInProgress = false;
          this.refreshTokenSubject.complete();
          this.authService.logout();
          this.route.navigate(['/']);
          return empty();
        }

        //If accesstoken expired but refreshtoken still alive
        if (accessTokenExpired && !refreshTokenExpired) {

          //If request is for token then call it without authorisation. Interecepter will this call when calling token/refreshtoken method.
          if (req.url.indexOf("token") !== -1) {
            return next.handle(req).pipe(
              catchError(err => {
                this.showErrorMessage(err);
                if (err.status == '401' || err.status == '400' || err.status == '0') {
                 this.authService.logout();
                 this.route.navigate(['/login']);
                }
                return throwError(err);
              }),
            );
          }

          //If refreshtokenInprogress is false, call refresh token (token method). Get the response, set token and then call the request in pipeline. 
          if (!this.refreshTokenInProgress) {
            this.refreshTokenInProgress = true;
            this.refreshTokenSubject.next(null);
            return this.authService.refreshToken().pipe(

              catchError((error: any) => {
                this.refreshTokenInProgress = false;
                this.refreshTokenSubject.complete();
                this.authService.logout();
                this.route.navigate(['/']);
                return throwError(error);
              }),
              switchMap((authResponse) => {
                console.log("RefreshToken called at : " + new Date());
                this.authService.setInitialLocalStorage(authResponse);
                this.authService.setTokenPropertiesInLocalStorage(authResponse);
                this.refreshTokenInProgress = false;
                this.refreshTokenSubject.next(authResponse.refreshToken);
                return next.handle(this.injectToken(req)).pipe(
                  catchError(err => {
                    if (err.status == '401' || err.status == '400' || err.status == '0') {
                     this.authService.logout();
                     this.route.navigate(['/login']);
                    }
                    return throwError(err);
                  }),
                );
              }),
            );
          } else {
            return this.refreshTokenSubject.pipe(
              filter((result) => result !== null),
              take(1),
              switchMap((res) => {
                return next.handle(this.injectToken(req)).pipe(
                  catchError(err => {
                    if (err.status == '401' || err.status == '400' || err.status == '0') {
                     this.authService.logout();
                     this.route.navigate(['/login']);
                    }
                    return throwError(err);
                  }),
                );;
              }),
              catchError(err => {
                this.authService.logout();
                this.route.navigate(['/']);
                return throwError(err);
              }),
            );
          }
        }
      } else {
        return next.handle(req).pipe(
          catchError(err => {
            this.showErrorMessage(err);
            if (err.status == '401' || err.status == '400' || err.status == '0') {
             this.authService.logout();
             this.route.navigate(['/login']);
            }
            return throwError(err);
          }),
        );
      }
    }
  }

  //Add Authorisation header if not already exist in incoming request
  injectToken(request: HttpRequest<any>) {

    //If request is not android push token service then only add authorisation as this service has its own authorisation key
    if (!request.url.includes('fcm.googleapis.com/fcm/')) {
      const token = this.authService.getAuthorizationToken();
      return request.clone({
        setHeaders: {
          Authorization: `Bearer ${token}`,
        },
      });
    }
    else {
      return request;
    }
  }

  showErrorMessage(err){
    if (err.status == '999') {
      this.toastr.error(err.error.message);
    }
  }
}
