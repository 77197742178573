import { Injectable, Inject, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { NgForm } from '@angular/forms';
import { Observable, BehaviorSubject, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SharedDataService implements OnDestroy {

  private baseAppUrl: string = "";
  public errorObject: any;
  public loader: Subject<boolean> = new Subject();
  public loaderCount: number = 0;
  rescheduleFlag: BehaviorSubject<boolean> = new BehaviorSubject(false);

  constructor(@Inject('BASE_URL') baseUrl: string, private router: Router) {

    this.baseAppUrl = baseUrl;

  }


  //set local storage
  setLocalStorage(key, value) {
    localStorage.setItem(key, value.toString());
  }

  getAppRootUrl() {
    // Get app root url.
    return this.baseAppUrl;
  }

  // this function will be called at form level to check form is modified or not.
  checkIsFormDirty(definitionForm: NgForm) {
    if (definitionForm.form.dirty) {
      //set variable to true
    }
  }

  showLoader() {
    this.loaderCount++;
    this.loader.next(true);
  }

  hideLoader() {
    this.loaderCount--;
    if (this.loaderCount <= 0) {
      this.loader.next(false);
    }
  }

  public setValue(Key: string, value: any) {
    localStorage[Key] = value;
  }
  public getValue(Key: string) {
    return localStorage[Key];
  }

  getDate(d) {
    return d.getFullYear() + "-" + this.getTwoDigits(d.getMonth() + 1) + "-" + this.getTwoDigits(d.getDate());
  }


  getTwoDigits(d) {
    var temp = "00" + d;
    return (temp).substr(temp.length - 2, 2);
  }

  ///Just allow 10 degits for now
  isValidPhoneNo(phoneNo) {
    //var re = /^\+?([0-9]{1})\)?[-]?([0-9]{3})?[-]?([0-9]{3})?[-]?([0-9]{4})$/;
    //var re = /([+^]\d{1,4}[-\s]?){1}\d{5}/;
    //var re = /([+^]\d{1,4}[-\s]?){1}(\d|\.|\-|\/)*/;    
    var re = /(^[0-9]{1,4}[-\s]?){1}[0-9]*/;
    //var re = /^[0-9]{10,15}$/;
    return re.test(phoneNo);
  }

  convertLocalDatetoUTCDate(date) {
    return new Date(date.getUTCFullYear(), date.getUTCMonth(), date.getUTCDate(), date.getUTCHours(), date.getUTCMinutes(), date.getUTCSeconds());
  }

  ConvertUTCTimeToLocalTime(UTCDateString) {
    var utcTime = new Date(UTCDateString);
    return new Date(utcTime.getTime() - (utcTime.getTimezoneOffset() * 60000));
  }

  getTimezonOffset() {
    return (new Date().getTimezoneOffset() / 60);
  }

  ///This function returns the UTC time with timezone you are in
  /// E.g. Date with GMT if you are in GMT. When converting it in .net it will automatically covert in GMT.
  // Therefore in api we need to use date.ToUniversalTime() to get UTC time.
  getTodayUTCDateAsString() {
    return new Date().toUTCString();
  }

  restrictSpecialCharacterForGivenRegex(event) {
    var regexString = "^[0-9 +-]+$";
    var regex = new RegExp(regexString);
    var key = String.fromCharCode(!event.charCode ? event.which : event.charCode);
    if (!regex.test(key)) {
      event.preventDefault();
      return false;
    }
  }

  restrictGivenRegexCharactersOnPaste(event: ClipboardEvent) {
    var regexString = "^[0-9 +-]+$";
    var regex = new RegExp(regexString);
    if (!regex.test(event.clipboardData.getData('text'))) {
      event.preventDefault();
      return false;
    }
  }

  ngOnDestroy(): void {
    this.rescheduleFlag.complete();
  }

  removeSpace(content)
  {
      return content.replace(/^ +/g,'').replace(/  +/g, ' ');
  }

  isContentEmpty(content)
  {
    let _content = content.replace( /(<([^>]+)>)/ig, '').replace(/&nbsp;/gi,'').replace(/\s+/g, ' ');
    return _content === ' ' ? true : (_content === '' ? true : false);
  }

  isValidZipCode(zipCode) {

    //What we are saying here is that a valid zip code can either have 5 digits or 5 digits followed by an hyphen(-) and ends with four digits. So, zip codes 38980 and 83900-8789 will pass validation. However, 83900- or 839008789 will not pass our validation test.
    var re = /^\d{5}$|^\d{5}-\d{4}$/;
    return re.test(zipCode);
  }

  arrangeAddress(addressLine1, addressLine2, addressLine3, city, stateName){
    var completeData = [addressLine1, addressLine2, addressLine3, city, stateName];
    return completeData.filter(Boolean).join(", ");
  }

  restrictSpace(e) {
    if (e.which === 32)
      return false;
  }
}
