<div class="widgets call-hsitory">
    <!--<div class="more-cs-part">
        <span>Coming Soon</span>
    </div>-->
    <div class="title-block">
        <div class="row">
            <div class="col-md-6">
                <div class="title">
                    <span>Reserva</span>
                </div>
            </div>
            <div class="col-md-6">
                <div class="more-part">
                    <a href="/backlog">Más<img src="../../../../assets/images/ds-more-img.svg" /></a>
                </div>
            </div>
        </div>
    </div>
    <div class="details-part-mdl">
        <div class="row">
            <div class="col-md-6">
                <span class="img-left">
                    <img src="../../../../assets/images/backlog-img-widget.svg" />
                </span>
            </div>
            <div class="col-md-6">
                <div class="call-info-widget">
                    <span class="outof">{{backlogCount.completedCalls}}</span><span class="total">/{{backlogCount.totalCalls}}</span>
                </div>
                <div class="text-part">
                    <span class="only-info">Programar llamada</span>
                </div>
            </div>
        </div>
    </div>
    <div class="btm-desc">
        <div class="row">
            <div class="col-md-8">
                <div class="progress-block">
                    <span style="width: {{backlogCount.completedPercentage}}%"></span>
                </div>
            </div>
            <div class="col-md-4">
                <div class="left-btm">
                    <span class="left-opt">{{backlogCount.remainingCalls}} Left</span>
                </div>
            </div>
        </div>
    </div>
</div>