
<div class="overlay" [hidden]="!showloader">
    <div class="loader-part">
        <div class="sh1"></div>
        <div class="sh2"></div>
        <h4 class="lt">Cargando</h4>
    </div>
</div>
<app-header *ngIf="(isUserLoggedIn | async) && !hasRoute('payment-successful')"></app-header>
<app-side-bar *ngIf="(isUserLoggedIn | async) && !hasRoute('payment-successful')"></app-side-bar>

<!--<app-header></app-header>
<app-side-bar></app-side-bar>-->

<div class="main-container">
  <router-outlet></router-outlet>
</div>
