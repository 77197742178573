import { Component, OnInit, AfterViewChecked, ElementRef, ViewChild } from "@angular/core";
import { Router } from '@angular/router';
import { SharedDataService } from '../../services/sharedData.service';
import { HttpClient, HttpHeaders, HttpResponse, HttpEventType, HttpRequest } from '@angular/common/http';
import { ConversationDetailService } from "./../../api-services/conversation-detail.service";
import { iConverationDetails, iTextMsg, iCallLogDetails, iFinalMessageList, iMediaFileDetailsVM, iCopyRequest } from "../../models/conversationDetail.model";
import { environment } from '../../../environments/environment';
import { saveAs } from 'file-saver';
import { interval, Subscription } from "rxjs";
import { Constants } from '../../shared/constant';
import { VideoCallService } from './../../api-services/video-call.service';
import { Conference, ConferenceHistoryResponse } from './../../models/conference.model';
import { PushAndroidPushtokenService } from '../../api-services/push-android-pushtoken.service';
import { PushNotificationRequest, NotificationData } from '../../models/push-android-pushtoken-request.model';
import { DashboardService } from "./../../api-services/dashboard.service";

@Component({
  selector: "app-conversation-full-view",
  templateUrl: "./conversation-full-view.component.html",
  styleUrls: ["./conversation-full-view.component.css"],
})

export class ConversationFullViewComponent implements OnInit {
  @ViewChild("scrollMe") scrollElement: ElementRef;

  @ViewChild('conversationDiv') private myScrollContainer: ElementRef;

  readonly textMsgType: number = Constants.mediaFileType['text'];
  readonly imageFileType: number = Constants.mediaFileType['image'];
  readonly documentFileType: number = Constants.mediaFileType['document'];
  readonly videoFileType: number = Constants.mediaFileType['video'];
  confId: number;
  converationDetails: iConverationDetails = {};
  callLogs?: iCallLogDetails[] = [];
  mediaFileDetails?: iMediaFileDetailsVM[] = [];
  tempMediaFileDetails?: iMediaFileDetailsVM[] = [];
  adjusterId?: number;
  textMsgReq?: iTextMsg = {};
  conversationList?: iFinalMessageList = {};
  isDetailTabActive: boolean;
  isFileTabActive: boolean;
  isCallLogTabActive: boolean;
  messageLength?: number = 0;
  localeDateOffset?: number;
  message?: string;
  reloadConversation: Subscription;
  isSelect: boolean;
  isListViewEnable: boolean;
  skip: number = 0;
  take: number = 10;
  totalCount: number = 0;
  dataLoaderCount: number = 1;
  showLoader: boolean;
  loaderMessage?: string;
  copyRequest?: iCopyRequest = {};
  messageIdList?: number[] = [];
  interval?: any;
  showChatIllustration?: boolean = true;
  showCallLogIllustration?: boolean = true;
  showFileIllustration?: boolean = true;
  mediaFileSkip: boolean;
  validationMessages: any;
  storageTypeId?: number;
  showMinSizeMsg?: boolean = true;
  initialScrollEvent: boolean = true;
  public conference: Conference = {} as Conference;
  public conferenceHistoryResponse: ConferenceHistoryResponse = {} as ConferenceHistoryResponse;

  constructor(private service: ConversationDetailService,
    private sharedService: SharedDataService,
    private httpClient: HttpClient,
    private router: Router,
    private dashboardService: DashboardService,
    private videoCallService: VideoCallService,
    private pushAndroidService: PushAndroidPushtokenService) { }

  ngOnInit(): void {
    this.localeDateOffset = this.sharedService.getTimezonOffset();
    this.adjusterId = this.sharedService.getValue("accountId");
    this.isDetailTabActive = true;
    const source = interval(10000);

    if (this.sharedService.getValue("conversationConfId")) {
      this.confId = +this.sharedService.getValue("conversationConfId");
      this.getConverationDetails();
      this.getAllConversation();
      this.getCallLogs();
    }

    this.interval = setInterval(() => {
      this.getAllConversationWithoutLoader();
    }, 10000);

    //this.reloadConversation = source.subscribe((val) => this.getAllConversationWithoutLoader());
  }

  ngAfterViewChecked() {
    this.scrollToBottom();
  }

  //Method to auto scroll to down to conversation
  scrollToBottom(): void {
    try {
      this.myScrollContainer.nativeElement.scrollTop = this.myScrollContainer.nativeElement.scrollHeight;
    } catch (err) { }
  }

  //Method to get conversation details data
  public getConverationDetails() {
    this.validationMessages = [];
    this.service.getConversationDetails(this.confId, this.localeDateOffset).subscribe(
      (result) => {
        if (result != null) {
          this.converationDetails = <iConverationDetails>result;
          this.sharedService.setValue(
            Constants.converationDetailsKey,
            JSON.stringify(this.converationDetails)
          );
        }
        this.getActiveStorageType();
      },
      (error) => {
        this.populateApiErrorMessages(error.message);
      }
    );
  }

  //Get active storage type
  public getActiveStorageType() {
    this.validationMessages = [];
    this.service.getActiveStorageType().subscribe(
      (result) => {
        if (result != null) {
          this.storageTypeId = result;
        }
      },
      (error) => {
        this.populateApiErrorMessages(error.message);
      }
    );
  }

  //Method to close storage size msg
  public closeStorageSizeMsg() {
    this.showMinSizeMsg = true;
  }

  //Set File tab view
  public setFileDisplayView() {
    if (this.isListViewEnable)
      this.isListViewEnable = false;
    else
      this.isListViewEnable = true;

    this.messageIdList = [];
    this.mediaFileDetails.forEach(obj => {
      obj.assignClass = null;
    });
  }

  //Method to get conversation detail data from session storage
  getConversationDataFromLocalStorage() {
    var conversationData = <iConverationDetails>(
      JSON.parse(this.sharedService.getValue(Constants.converationDetailsKey))
    );
    if (conversationData != null) {
      this.converationDetails = conversationData;
    } else {
      this.getConverationDetails();
    }
  }

  //Method to get all call logs
  public getCallLogs() {
    this.validationMessages = [];
    this.service.getCallLogs(this.confId, this.localeDateOffset).subscribe(
      (result) => {
        if (result != null) {
          this.callLogs = <iCallLogDetails[]>result;
          this.sharedService.setValue(
            Constants.callLogsKey,
            JSON.stringify(this.callLogs)
          );

          if (this.callLogs.length == 0)
            this.showCallLogIllustration = true;
          else
            this.showCallLogIllustration = false;
        }
      },
      (error) => {
        this.populateApiErrorMessages(error.message);
      }
    );
  }

  //Method on click event of detail tab
  public setDetailsTabData() {
    if (!this.showLoader) {
      this.setActiveTab(Constants.detailTab);
      this.getConversationDataFromLocalStorage();
    }
  }

  //Method on click event of file tab
  public setFilesTabData() {
    if (!this.showLoader) {
      this.setActiveTab(Constants.fileTab);
      this.clearMediaFileCollections();
      this.mediaFileSkip = true;
      this.getMediaFilesData();
    }
  }

  //Method to get files
  getMediaFilesData() {
    this.validationMessages = [];
    this.showLoader = true;
    this.loaderMessage = "Cargando";
    this.service.getMediaFileDetails(this.confId, this.localeDateOffset, this.skip, this.take).subscribe(
      (result) => {
        this.showLoader = false;

        if (result != null) {
          this.tempMediaFileDetails = <iMediaFileDetailsVM[]>result;

          if (this.tempMediaFileDetails.length == 0)
            this.showFileIllustration = true;
          else {
            this.showFileIllustration = false;
            this.tempMediaFileDetails.forEach(obj => {
              this.totalCount = obj.totalCount;
              switch (obj.messageType) {
                case Constants.mediaFileType["image"]:
                  obj.mediaFileData = 'data:image/' + obj.mimeType + ';base64,' + obj.fileData;
                  break;
                case Constants.mediaFileType["document"]:
                  if (obj.mimeType.toLowerCase() == 'pdf') {
                    obj.mediaFileData = "../../../assets/images/pdf-img.svg";
                  }
                  else {
                    obj.mediaFileData = "../../../assets/images/diff-doc-icon.svg";
                  }
                  break;
              }
            })
            this.mediaFileDetails.push(...this.tempMediaFileDetails);

            this.mediaFileSkip = false;
          }
        }
      }, (error) => {
        this.showLoader = false;
        this.populateApiErrorMessages(error.message);
      }
    );
  }

  //Method on click event of call log tab
  public setCallLogsTabData() {
    if (!this.showLoader) {
      this.setActiveTab(Constants.callLogTab);
      var callLogs = <iCallLogDetails[]>JSON.parse(this.sharedService.getValue(Constants.callLogsKey));
      if (callLogs != undefined) {
        this.callLogs = callLogs;
      } else {
        this.getCallLogs();
      }
    }
  }

  //Method on set current active tab
  setActiveTab(tabName) {
    this.isDetailTabActive = tabName === Constants.detailTab ? true : false;
    this.isFileTabActive = tabName === Constants.fileTab ? true : false;;
    this.isCallLogTabActive = tabName === Constants.callLogTab ? true : false;
  }

  //Method to save text msg
  public saveTextMsg() {
    this.validationMessages = [];
    if (this.message.length != 0 && this.message.trim() != '') {
      this.textMsgReq.confId = this.confId;
      this.textMsgReq.sendBy = this.adjusterId;
      this.textMsgReq.lastMessageId = 0;
      this.textMsgReq.message = this.message.trim();
      this.textMsgReq.localeDateOffset = this.localeDateOffset.toString();

      this.message = '';
      this.messageLength = 0;

      this.service.saveTextMsg(this.textMsgReq).subscribe((data: any) => {
        if (data) {
          this.getAllConversationWithoutLoader();
          this.sendPushNotificationForChat();
        }
      }, error => {
        this.populateApiErrorMessages(error.message);
      });
    }
    else
      return;
  }

  sendPushNotificationForChat() {
    this.videoCallService.getConferenceInfoById(this.confId).subscribe(
      response => {
        //If Invitation is not accepted then response will be null
        if (response != null) {
          this.conference = response;
          if (this.conference.pushToken != null) {
            this.getConferenceHistory();
          }
        }
      },
      error => {
      });
  }
  getConferenceHistory() {
    this.dashboardService.getConferenceHistoryForWeb(this.confId, this.conference.voipToken).subscribe(
      response => {
        if (response != null) {
          this.conferenceHistoryResponse = response;
          this.conferenceHistoryResponse.callList[0].notificationType = "chat";
          this.conferenceHistoryResponse.callList[0].title = this.conferenceHistoryResponse.callList[0].companyName;
          this.conferenceHistoryResponse.callList[0].body = Constants.pushNotificationChatBody;
          this.conferenceHistoryResponse.callList[0].successCode = this.conferenceHistoryResponse.successCode;
          this.conferenceHistoryResponse.callList[0].message = this.conferenceHistoryResponse.message;
          if (this.conference.deviceType.toLowerCase() == "ios") {
            let notification: NotificationData = {
              title: this.conferenceHistoryResponse.callList[0].companyName,
              body: Constants.pushNotificationChatBody
            } as NotificationData;
            let request: PushNotificationRequest = {
              to: this.conference.pushToken,
              data: this.conferenceHistoryResponse.callList[0],
              notification: notification
            } as PushNotificationRequest
            this.pushAndroidService.sendPushNotificaton(request).subscribe(
              response => {
              }
            );
          }
          else {
            //below need to uncomment once chat functionality will be implemented in android.
            let request: PushNotificationRequest = {
             to: this.conference.pushToken,
             data: this.conferenceHistoryResponse.callList[0]
            } as PushNotificationRequest
            this.pushAndroidService.sendPushNotificaton(request).subscribe(
             response => {
             }
            );
          }
        }
      },
      error => {
      });
  }

  //To get length of entered message
  onTextChange(value) {
    if (value == '')
      this.messageLength = 0;
    else
      this.messageLength = value.length;

    //this.messageLength = value.replace(/ /g, "").length;
    //this.messageLength = value.replace(/\s/g, "").length;
  }

  //Method to get all conversation
  public getAllConversation() {
    this.validationMessages = [];
    this.service.getAllConversation(this.confId, this.localeDateOffset).subscribe(
      (result) => {
        if (result != null) {
          this.conversationList = <iFinalMessageList>result;

          if (this.conversationList.groupMessageList.length == 0)
            this.showChatIllustration = true;
          else
            this.showChatIllustration = false;
        }
      },
      (error) => {
        this.populateApiErrorMessages(error.message);
      }
    );
  }

  //Method to get all conversation in background without loader
  public getAllConversationWithoutLoader() {
    this.validationMessages = [];
    this.service.getAllConversationWithoutLoader(this.confId, this.localeDateOffset).subscribe(
      (result) => {
        if (result != null) {
          this.conversationList = <iFinalMessageList>result;
          if (this.conversationList.groupMessageList.length == 0)
            this.showChatIllustration = true;
          else
            this.showChatIllustration = false;
          this.scrollToBottom();
        }
      },
      (error) => {
        this.populateApiErrorMessages(error.message);
      }
    );
  }

  //private method for download
  public getHeader(key, value) {
    var header: HttpHeaders = {} as HttpHeaders;
    header[key] = value;
    return header;
  }

  //private method for download
  private getAuthoriseHeader() {
    var token = this.sharedService.getValue('token');
    if (token) {
      let token = JSON.parse(this.sharedService.getValue('token'));
      if (token) {
        return this.getHeader("Authorization", `Bearer ${token}`);
      }
      return {} as HttpHeaders;
    }
  }

  //Download sample template
  public downloadFile(data?: any) {
    this.validationMessages = [];
    const filename = data.mediaFileName + data.mimeType;
    this.httpClient.get(environment.apiUrl + 'conversation/downloadFile', {
      headers: this.getAuthoriseHeader(),
      observe: 'response',
      responseType: 'blob',
      params: {
        'messageId': data.messageId
      }
    })
      .subscribe(
        data => {
          var contentType = data.headers.get('content-type');
          var blob = new Blob([data.body], { type: contentType });
          saveAs(blob, filename);
        },
        error => {
          this.populateApiErrorMessages(error.message);
        });
  }

  //Method to go to Review page
  goToReviewScreen() {
    this.sharedService.setValue('conferenceId', this.confId);
    this.sharedService.setValue('comingFromConversation', true);
    this.router.navigateByUrl('review');
  }

  ngOnDestroy() {
    //this.reloadConversation.unsubscribe();
    clearInterval(this.interval);
  }

  //Select button click
  public selectFiles() {
    this.isSelect = true;
  }

  //Cancel button click
  public cancelClick() {
    this.isSelect = false;
    this.messageIdList = [];
    this.mediaFileDetails.forEach(obj => {
      obj.assignClass = null;
    });
  }

  //Method to apply css to allow user for file selection
  public isFileSelected(messageType) {
    if (messageType != this.documentFileType)
      return true;

    return false;
  }

  //Method to select files for copy or delete
  public selectFilesForDeleteOrCopy(item?: any) {
    if (this.isSelect) { // && item.messageType != 4
      if (item.assignClass == true || item.assignClass == undefined || item.assignClass == null) {
        item.assignClass = false;
        this.messageIdList.push(item.messageId);
      }
      else {
        item.assignClass = true;
        const index: number = this.messageIdList.indexOf(item.messageId);
        if (index !== -1) {
          this.messageIdList.splice(index, 1);
        }
      }
    }
  }

  //Method to delete files
  public deleteFiles() {
    if (this.messageIdList.length > 0) {
      this.initialScrollEvent = true;
      this.validationMessages = [];
      this.showLoader = true;
      this.loaderMessage = "Deleting";
      this.service.deleteFiles(this.messageIdList).subscribe(
        (data: any) => {
          this.showLoader = false;
          if (data) {
            this.isSelect = false;
            this.mediaFileDetails.forEach((obj) => {
              obj.assignClass = null;
            });
            this.clearMediaFileCollections();
            this.getMediaFilesData();
          }
        },
        (error) => {
          this.populateApiErrorMessages(error.message);
          this.showLoader = false;
        }
      );
    }
  }

  //Method to clear media file collections
  private clearMediaFileCollections() {
    this.tempMediaFileDetails = [];
    this.mediaFileDetails = [];
    this.skip = 0;
    this.dataLoaderCount = 1;
    this.messageIdList = [];
  }

  //Check available size of active storage is less than 75 MB or not
  public getStorageSize() {
    this.validationMessages = [];
    if (this.storageTypeId != Constants.storageAccount["azure"]) {
      this.service.getStorageSize(this.storageTypeId).subscribe(
        (result) => {
          if (result != null) {
            this.showMinSizeMsg = result;
          }
        },
        (error) => {
          this.populateApiErrorMessages(error.message);
        }
      );
    }
    this.copyFiles();
  }

  //Method to copy files
  public copyFiles() {
    if (this.messageIdList.length > 0) {
      this.validationMessages = [];
      this.showLoader = true;
      this.loaderMessage = "Proceso de copiar";
      this.copyRequest.messageIdList = this.messageIdList;
      this.copyRequest.confId = this.confId;
      this.copyRequest.userId = this.adjusterId;
      this.copyRequest.storageTypeId = this.storageTypeId;
      this.copyRequest.odooCustomerId = this.converationDetails.odooCustomerId;
      this.copyRequest.odooTicketId = this.converationDetails.odooTicketId;
      this.service.copyFilesToReview(this.copyRequest).subscribe(
        (data: any) => {
          this.showLoader = false;
          if (data) {
            this.isSelect = false;
            this.messageIdList = [];
            this.mediaFileDetails.forEach((obj) => {
              obj.assignClass = null;
            });
          }
        },
        (error) => {
          this.populateApiErrorMessages(error.message);
          this.showLoader = false;
        }
      );
    }
  }

  //Method to lazy loading of files
  public appendData() {

    let scrollTop = this.scrollElement.nativeElement.scrollTop;
    let offSetHeight = this.scrollElement.nativeElement.offsetHeight;
    let scrollHeight = this.scrollElement.nativeElement.scrollHeight;

    if (Math.floor(offSetHeight + scrollTop) >= scrollHeight - 1) {

      if (!this.initialScrollEvent) {
        const totalExecute = Math.ceil(this.totalCount / this.take);

        if (this.dataLoaderCount + 1 <= totalExecute) {
          if (!this.mediaFileSkip) this.skip += this.take;
          this.getMediaFilesData();
          this.dataLoaderCount += 1;
        }
      }
    }
    this.initialScrollEvent = false;
  }

  populateApiErrorMessages(message: string) {
    this.validationMessages.push(message);
  }
}
