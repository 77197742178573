import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Router } from '@angular/router';
import { SharedDataService } from '../../../services/sharedData.service';
import { SettingService } from '.././../../api-services/setting.service';
import { TenantPaymentInfo, TenantPaymentMethodCard } from '../../../models/tenant-payment-info.model';
import { ToastrService } from 'ngx-toastr';
import { environment } from './../../../../environments/environment';
import { loadStripe, Stripe } from '@stripe/stripe-js';
declare var $: any;

@Component({
    selector: 'app-payment-setting',
    templateUrl: './payment-setting.component.html',
    styleUrls: ['./payment-setting.component.css']
})
export class PaymentSettingComponent implements OnInit {

    validationMessages: any[] = [] as any[];
    tenantPaymentInfo: TenantPaymentInfo = {} as TenantPaymentInfo;
    cardElement: any;
    stripe: any;
    addCard: boolean = false;
    editBilling: boolean = false;
    
    localeDateOffset: string;
    
    constructor(private router: Router,
        private service: SettingService,
        private sharedService: SharedDataService,
        private toastr: ToastrService) { }

    ngOnInit(): void {
        this.localeDateOffset = this.sharedService.getTimezonOffset().toString()

        this.createCardElements();
        this.getPaymentInfo();
    }

    createCardElements() {
        // loadStripe(environment.stripe_publish_key).then(stripe => {
        //     this.stripe = stripe;
        //     const elements = this.stripe.elements();
        //     this.cardElement = elements.create('card');
        //     this.cardElement.mount('#card-element');

        //     var that = this;

        //     this.cardElement.on('change', function (event) {
        //         that.validationMessages = [];
        //         if (event.complete) {
        //         } else if (event.error) {
        //             that.validationMessages.push(event.error.message);
        //         }
        //     });
        // });
    }

    getPaymentInfo() {
        // this.service.getTenantPaymentInfo().subscribe(result => {
        //     if (result != null) {
        //         this.tenantPaymentInfo = result;
        //     }
        // }, error => {
        //     this.populateApiErrorMessages(error.errorTitle);
        // });
    }

    populateValidateMessages() {
        
        if (this.tenantPaymentInfo.addressLine1.length > 0 && this.tenantPaymentInfo.addressLine1.trim().length === 0) {
            this.validationMessages.push('Please enter valid address line 1.');
        }

        if (this.tenantPaymentInfo.city.length > 0 && this.tenantPaymentInfo.city.trim().length === 0) {
            this.validationMessages.push('Ingrese una ciudad válida.');
        }

        if (this.tenantPaymentInfo.postalCode.length > 0 && this.tenantPaymentInfo.postalCode.trim().length === 0) {
            this.validationMessages.push('Ingrese un código postal válido.');
        }
    }

    subscribePayment() {
        this.validationMessages = [];

        this.populateValidateMessages();

        if (this.validationMessages.length > 0) {
            return
        }
        
        this.service.initiatePaymentSetup(this.tenantPaymentInfo).subscribe(result => {
            if (result != null) {
                this.sharedService.showLoader();
                this.tenantPaymentInfo = result;
                this.stripe.confirmCardSetup(
                    this.tenantPaymentInfo.clientSecret, {
                        payment_method: {
                            card: this.cardElement
                        },
                    }
                ).then(obj => {
                    if (obj.error) {
                        this.sharedService.hideLoader();
                        this.populateApiErrorMessages(obj.error.message);
                    }
                    else if (obj.setupIntent) {
                        this.sharedService.hideLoader();
                        if (obj.setupIntent.status === 'succeeded') {
                            console.log('Setup Intent: ' + JSON.stringify(obj.setupIntent));
                            this.tenantPaymentInfo.paymentMethodID = obj.setupIntent.payment_method;
                            this.updatePaymentMethodWithSubscription();
                        }
                    }
                });
            }
        }, error => {
            this.populateApiErrorMessages(error.errorTitle);
        });
    }

    updatePaymentMethodWithSubscription() {
        this.service.updatePaymentMethodWithSubsciption(this.tenantPaymentInfo).subscribe(result => {
            if (result > 0) {
                this.getPaymentInfo();
            }
        }, error => {
            this.populateApiErrorMessages(error.errorTitle);
        });
    }

    updatePaymentMethod() {
        this.service.updatePaymentMethod(this.tenantPaymentInfo).subscribe(result => {
            if (result > 0) {
                this.getPaymentInfo();
            }
        }, error => {
            this.populateApiErrorMessages(error.errorTitle);
        });
    }

    populateApiErrorMessages(message: string) {
        this.validationMessages.push(message);
    }

    updateBilling() {
        this.service.updateBillingAddress(this.tenantPaymentInfo).subscribe(result => {
            if (result) {
                this.editBilling = false;
                this.getPaymentInfo();
            }
        }, error => {
            this.populateApiErrorMessages(error.errorTitle);
        });
    }

    initiateCard() {
        this.service.createSetupIntent().subscribe(result => {
            if (result) {
                this.addCard = true;
                this.tenantPaymentInfo.clientSecret = result;
                loadStripe(environment.stripe_publish_key).then(stripe => {
                    this.stripe = stripe;
                    const elements = this.stripe.elements();
                    this.cardElement = elements.create('card');
                    this.cardElement.mount('#add-card-element');

                    var that = this;

                    this.cardElement.on('change', function (event) {
                        that.validationMessages = [];
                        if (event.complete) {
                        } else if (event.error) {
                            that.validationMessages.push(event.error.message);
                        }
                    });
                });
            }
        }, error => {
            this.populateApiErrorMessages(error.errorTitle);
        });
        
    }

    createCard() {
        this.sharedService.showLoader();
        this.stripe.confirmCardSetup(
            this.tenantPaymentInfo.clientSecret, {
                payment_method: {
                    card: this.cardElement
                },
            }
        ).then(obj => {
            if (obj.error) {
                this.sharedService.hideLoader();
                this.populateApiErrorMessages(obj.error.message);
                this.addCard = false;
            }
            else if (obj.setupIntent) {
                this.sharedService.hideLoader();
                if (obj.setupIntent.status === 'succeeded') {
                    console.log('Setup Intent: ' + JSON.stringify(obj.setupIntent));
                    this.tenantPaymentInfo.paymentMethodID = obj.setupIntent.payment_method;
                    this.updatePaymentMethodWithSubscription();
                    this.addCard = false;
                }
            }
        });
    }

    detachPaymentMethod(paymentMethod: TenantPaymentMethodCard) {
        this.service.detachPaymentMethod(paymentMethod).subscribe(result => {
            if (result) {
                if (this.tenantPaymentInfo.paymentMethodID == paymentMethod.paymentMethodID) {
                    this.tenantPaymentInfo.paymentMethodID = null;
                    this.updatePaymentMethod();
                }
                else {
                    this.getPaymentInfo();
                }
            }
        }, error => {
            this.populateApiErrorMessages(error.errorTitle);
        });
    }

    activatePaymentMethod(paymentMethod: TenantPaymentMethodCard) {
        this.tenantPaymentInfo.paymentMethodID = paymentMethod.paymentMethodID;
        this.updatePaymentMethodWithSubscription();
    }
}
