export class ContactUsDetails {
    fullName: string;
    mobileNumber: string;
    countryName: string | any;
    emailAddress: string;
    jobTitle: string;
    company: string;
    exposedCollaborators: string;
    totalCollaborators: string;
    isSafetyBasedPersonalityTest: string;
    riskTypes: string;
    activityName: string;
    activityTypes: string;
    isRiskIdentified: string;
    employeeActivity: string;
    isPPE: string;
    comment: string;
    isPhysicalVisit: string;
    base64String: string;
    fileName: string;
    mimeType: string;
    callScheduleTime:any;
    createdDate:any;
    firstName:string;
    lastName:string;
    isdCountryId:number;
    invitationDate:any;
    phoneNumber:any;
    countryId:any;
    oddoId:number;
    token:string;
    accountId:number;
}

export class CustomerViewModel{
    firstName:string;
    lastName:string;
    emailId:string;
    confirmEmailId:string;
}
export const PublicFormValidationMessage = [
    {
        key:"fullName",
        message:"Nombre es requerido.",
        validationKey:"required"
    },
    {
        key:"mobileNumber",
        message:"Celular es requerido.",
        validationKey:"required"
    },
    {
        key:"mobileNumber",
        message:"Ingrese un número de teléfono válido.",
        validationKey:"invalid"
    },
    {
        key:"emailAddress",
        message:"Correro es requerido.",
        validationKey:"required"
    },
    {
        key:"emailAddress",
        message:"Por favor ingrese una dirreción de correo válida.",
        validationKey:"email"
    },
    {
        key:"countryName",
        message:"Country Name es requerido.",
        validationKey:"required"
    },
    {
        key:"jobTitle",
        message:"Cargo es requerido.",
        validationKey:"required"
    },
    {
        key:"company",
        message:"Compañia es requerido.",
        validationKey:"required"
    },
    {
        key:"exposedCollaborators",
        message:"exposed Colaboradores Totales es requerido.",
        validationKey:"required"
    },
    {
        key:"totalCollaborators",
        message:"total Colaboradores Totales es requerido.",
        validationKey:"required"
    },
    {
        key:"isSafetyBasedPersonalityTest",
        message:" Cuenta actualmente con un Test de personalidad basada en la seguridad? requerido.",
        validationKey:"required"
    },
    {
        key:"riskTypes",
        message:"Sobre qué tipo de riesgos es su consulta requerido.",
        validationKey:"required"
    },
    {
        key:"activityName",
        message:"Cual es la actividad que realiza requerido.",
        validationKey:"required"
    },
    {
        key:"activityTypes",
        message:"Con qué frecuencia realiza esa actividad requerido.",
        validationKey:"required"
    },
    {
        key:"isRiskIdentified",
        message:"Ha identificado los riesgos específicos de la actividad requerido.",
        validationKey:"required"
    },
    {
        key:"employeeActivity",
        message:"Cual es el # de empleados que realizan esa activida o se exponen a ese riesgo requerido.",
        validationKey:"required"
    },
    {
        key:"isPPE",
        message:"Actualmente usan EPP para esta actividad requerido.",
        validationKey:"required"
    },
    {
        key:"comment",
        message:"Como podemos ayudarle requerido.",
        validationKey:"required"
    },
    {
        key:"isPhysicalVisit",
        message:"Requiere que realicemos una inspección virtual de su área durante la llamada para entender mejor sus requerimientos requerido.",
        validationKey:"required"
    },
]