<div class="container-area">
    <div class="extra-one">
        <div class="content-area bg-content">
            <div class="call-schedule">
                <div class="row">
                    <div class="col-md-4">
                        <div class="call-schedule-desc">
                            <div class="section-title">
                                <span>Programar una llamada</span>
                            </div>
                            <form id="mainForm" #scheduleCallForm="ngForm" (ngSubmit)="onSubmit()">
                                <div class="info-part">
                                    <div class="schedule-info">
                                        <div class="row">
                                            <div class="col-md-6">
                                                <span class="schedule-label">Número de Ticket</span>
                                                <span class="schedule-details" title="{{claim.claimNumber}}">{{claim.claimNumber}}</span>
                                            </div>
                                            <div class="col-md-6">
                                                <span class="schedule-label">N&#250;mero Cliente</span>
                                                <span class="schedule-details" title="{{claim.policyNumber}}">{{claim.policyNumber}} </span>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="schedule-info">
                                        <div class="row">
                                            <div class="col-md-6">
                                                <span class="schedule-label">Primer Nombre</span>
                                                <span class="schedule-details" title="{{claim.firstName}}">{{claim.firstName}}</span>
                                            </div>
                                            <div class="col-md-6">
                                                <span class="schedule-label">Apellidos</span>
                                                <span class="schedule-details" title="{{claim.lastName}}">{{claim.lastName}}</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="schedule-info">
                                        <div class="row">
                                            <div class="col-md-6">
                                                <span class="schedule-label">Tel&#233;fono</span>
                                                <span class="schedule-details" title="{{claim.phoneNumber}}">{{claim.phoneNumber}}</span>
                                            </div>
                                            <div class="col-md-6">
                                                <span class="schedule-label">Correo Electr&#243;nico</span>
                                                <span class="schedule-details" title="{{claim.email}}">{{claim.email}}</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="schedule-info">
                                        <div class="row">
                                            <div class="col-md-12">
                                                <span class="schedule-label">Programar una llamada</span>
                                                <div class="only-radios">
                                                    <label class="radio-inline">
                                        <input type="radio" name="meetingOpt" value="outlook" [(ngModel)]="calendarType">
                                        <span class="radiomark"></span>
                                        Outlook
                                    </label>
                                                    <label class="radio-inline">
                                        <input type="radio" name="meetingOpt" value="google" [(ngModel)]="calendarType">
                                        <span class="radiomark"></span>
                                        Google Calendar 
                                    </label>
                                                    <label class="radio-inline">
                                        <input type="radio" name="meetingOpt" value="adjustify" [(ngModel)]="calendarType">
                                        <span class="radiomark"></span>
                                        CASCO
                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="schedule-info">
                                        <div class="row">
                                            <div class="col-md-6">
                                                <span class="schedule-label">Programar una llamada</span>
                                                <input name="lastLoginFrom" id="lastLoginFrom" placeholder="mm/dd/yy" [min]="minDate" [owlDateTimeTrigger]="dtLastLoginFrom" [owlDateTime]="dtLastLoginFrom" class="form-control date-control" [(ngModel)]="selectedDate" [owlDateTimeFilter]="myFilter" required>
                                                <owl-date-time [pickerType]="'calendar'" #dtLastLoginFrom></owl-date-time>
                                                <div class="arrow" [owlDateTimeTrigger]="dtLastLoginFrom"></div>
                                            </div>
                                            <div class="col-md-6">
                                                <span class="schedule-label">Seleccionar Hora</span>
                                                <select class="form-control time arrow right" [(value)]="selected" [(ngModel)]="selected" name="scheduleTime">
                          <option value="None">Ninguno</option>
                          <!-- <option value="00:00">12:00 AM</option>
                          <option value="00:30">12:30 AM</option>
                          <option value="01:00">01:00 AM</option>
                          <option value="01:30">01:30 AM</option>
                          <option value="02:00">02:00 AM</option>
                          <option value="02:30">02:30 AM</option>
                          <option value="03:00">03:00 AM</option>
                          <option value="03:30">03:30 AM</option>
                          <option value="04:00">04:00 AM</option>
                          <option value="04:30">04:30 AM</option>
                          <option value="05:00">05:00 AM</option>
                          <option value="05:30">05:30 AM</option>
                          <option value="06:00">06:00 AM</option>
                          <option value="06:30">06:30 AM</option>
                          <option value="07:00">07:00 AM</option>
                          <option value="07:30">07:30 AM</option> -->
                          <option value="08:00">08:00 AM</option>
                          <option value="08:30">08:30 AM</option>
                          <option value="09:00">09:00 AM</option>
                          <option value="09:30">09:30 AM</option>
                          <option value="10:00">10:00 AM</option>
                          <option value="10:30">10:30 AM</option>
                          <option value="11:00">11:00 AM</option>
                          <option value="11:30">11:30 AM</option>
                          <option value="12:00">12:00 PM</option>
                          <option value="12:30">12:30 PM</option>
                          <option value="13:00">01:00 PM</option>
                          <option value="13:30">01:30 PM</option>
                          <option value="14:00">02:00 PM</option>
                          <option value="14:30">02:30 PM</option>
                          <option value="15:00">03:00 PM</option>
                          <option value="15:30">03:30 PM</option>
                          <option value="16:00">04:00 PM</option>
                          <option value="16:30">04:30 PM</option>
                          <option value="17:00">05:00 PM</option>
                          <option value="17:30">05:30 PM</option>
                          <!-- <option value="18:30">06:30 PM</option>
                          <option value="19:00">07:00 PM</option>
                          <option value="19:30">07:30 PM</option>
                          <option value="20:00">08:00 PM</option>
                          <option value="20:30">08:30 PM</option>
                          <option value="21:00">09:00 PM</option>
                          <option value="21:30">09:30 PM</option>
                          <option value="22:00">10:00 PM</option>
                          <option value="22:30">10:30 PM</option>
                          <option value="23:00">11:00 PM</option>
                          <option value="23:30">11:30 PM</option> -->
                        </select>
                                                <div class="arrow right"></div>
                                            </div>
                                        </div>
                                    </div>
                                    <div>
                                        <validation-summary [validationErrors]="validationMessages"></validation-summary>
                                    </div>
                                    <!--<div class="schedule-info">
    <div class="row">
      <div class="col-md-6">
        <span class="schedule-label">Select Date</span>
        <select class="form-control">
          <option>04/15/2020</option>
        </select>
        <div class="arrow"></div>
      </div>
      <div class="col-md-6">
        <span class="schedule-label">Select Time</span>
        <select class="form-control time">
          <option>03:00 PM</option>
        </select>
        <div class="arrow right"></div>
      </div>
    </div>
  </div>-->
                                    <div class="button-block">
                                        <button class="btn comm-btn cancel" routerLink="/backlog" routerLinkActive="active">Cancelar</button>
                                        <button class="btn comm-btn" type="submit">Guardar</button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                    <div class="col-md-8">
                        <div class="calender-view">
                            <!--<app-full-calender-view></app-full-calender-view>-->
                            <div class="overlay">
                                <span class="text">Proximamente Seleccion</span>
                                <span class="top-text">Por Vista de Calendario</span>
                                <span class="btm-text">Seleccionar la fecha y hora por la vista del Calendario.</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>