import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-thank-you-for-registration',
  templateUrl: './thank-you-for-registration.component.html',
  styleUrls: ['./thank-you-for-registration.component.css']
})
export class ThankYouForRegistrationComponent implements OnInit,OnDestroy {

  constructor(private router: Router) {

  }
 

  ngOnInit(): void {
    if (!sessionStorage.getItem('previousRoute')) {
      this.router.navigate(["/getting-start"])
    }
  }

  ngOnDestroy(): void {
    sessionStorage.removeItem('previousRoute')
  }

}
