import { Component, OnInit } from '@angular/core';
import { SharedDataService } from '../../services/sharedData.service';
import { iUserLastLoggedIn } from '../../models/setting.model';
import { SettingService } from '../../api-services/setting.service';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css']
})
export class DashboardComponent implements OnInit {

  constructor(private sharedService: SharedDataService,private settingService: SettingService) { }

  userRole?: string;
  userLastLoggedIn?: iUserLastLoggedIn = {};
  

  ngOnInit(): void {
    this.userRole = this.sharedService.getValue("userRole");
    this.saveUserLastLoggedIn();
  }
  //Method to save user's last logged in date and time
  saveUserLastLoggedIn() {
    this.userLastLoggedIn.currentDate = new Date(new Date().toUTCString());
    this.settingService.saveUserLastLoggedIn(this.userLastLoggedIn).subscribe(result => {

    });
  }
}
