import { Case } from './case.model';
import { Data } from '@angular/router';

export class ClaimCase {
  claimCaseId?: number;
  tenantId?: number;
  email?: string;
  uniqueNumber?: number;
  firstName?: string;
  lastName?: string;
  claimNumber?: string;
  policyNumber?: string;
  phoneNumber?: string;
  createdBy?: number;
  lossTypeId?: number;
  stateId?: number;
  lossDate?: string;
  lossType?: string;
  addressLine1?: string;
  addressLine2?: string;
  city?: string;
  zipCode?: string;
  sendInvitation?: boolean;
  invitationDate?: Date;
  callScheduleTime?: string;
  dateLossDate?: Date;
  dateInvitationDate?: Date;
  conferenceId?: number;
  confStatusId?: number;
  inviteAgainForPendingInvitation?: boolean;
  createdDate?: Date;
  isdCountryId?: number;
  country?: string;
  countryCode?: string;
  countryISDCode?: string;
  bothCountryCode?: string;
  calendarType?: string;
  isAddedFromAPI?: boolean;
  scheduledDate?:Date;
  scheduledTime?:string;
  odooCustomerId?:number;
  odooTicketId?:number;
  countryId?:number;
  caseCreatedByPlatFormId?:number;
}
