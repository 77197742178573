<div class="widgets conversation">
    <!--<div class="more-part">
        <span>Coming Soon</span>
    </div>-->
    <div class="title-block">
        <div class="row">
            <div class="col-md-6">
                <div class="btm-title">
                    <span>Conversación</span>
                </div>
            </div>
            <div class="col-md-6">
                <div class="count-part">
                    <span class="count-desc">(<span class="top-count">{{conversationData.totalUnreadCount}}</span><span class="full-count">/{{conversationData.totalCount}}</span>)</span>
                </div>
            </div>
        </div>
    </div>
    <div class="convo-details">
        <ul>
            <li *ngFor="let item of conversationData.messageList; let i= index">
                <a (click)="goToConversationScreen(item.conferenceId)">
                    <div class="approve-part">
                        <div class="only-desc">
                            <div class="name-text">
                                <span class="name-part">{{item.initials}}</span>
                                <span class="no-count">{{item.unreadCount}}</span>
                            </div>
                            <div class="details-approve">
                                <span class="approve-name" title="{{item.customerName}}">{{item.customerName}}</span>
                                <span class="msg-part" title="{{item.lastMessageText}}" *ngIf="item.messageTypeId==1">{{item.lastMessageText}}</span>
                                <span class="msg-part" title="{{item.mediaFileName}}" *ngIf="item.messageTypeId==2"><img src="../../../../assets/images/dashboard-img-part.svg" />{{item.mediaFileName}}</span>
                                <span class="msg-part" title="{{item.mediaFileName}}" *ngIf="item.messageTypeId==3"><img src="../../../../assets/images/dashboard-video-part.svg" />{{item.mediaFileName}}</span>
                                <span class="msg-part" title="{{item.mediaFileName}}" *ngIf="item.messageTypeId==4"><img src="../../../../assets/images/dashboard-file-part.svg" />{{item.mediaFileName}}</span>
                            </div>
                            <div class="clear"></div>
                        </div>
                        <div class="only-timings">
                            <span>{{item.messageDateString}}</span>
                        </div>
                        <div class="clear"></div>
                    </div>
                </a>
            </li>            
        </ul>
    </div>
</div>
